<div class="page-container container-fluid">
    <h2 class="page-title">Role Assignment</h2>
    <div class="page-content">
        <div>
            <div class="form-section">
                <h3 class="form-section-title">Selection Filter</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="fileType">Role Name <span id="starField">*</span></label>
                                <select [(ngModel)]="RoleID" (change)="RoleChange()" class="form-control">
                                    <option *ngFor="let record of RoleDD" value="{{record.ID}}">{{record.Role}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="fileName">Menu <span id="starField">*</span></label>
                                <select (change)="GetLevelTwoMenuDD()" [(ngModel)]="LevelOneMenuID" class="form-control">
                                    <option *ngFor="let record of LevelOneMenuDD" value="{{record.ID}}">{{record.Menu}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="LevelTwoMenuList != undefined" class="table-section">
                <h3 class="table-section-title">Page Name</h3>
                <div class="table-section-content">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Page Name</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of LevelTwoMenuList">
                                <td>{{record.Menu}}</td>
                                <td>
                                    <input [checked]="record.IsActive == 1" (change)="AssignMenuToRole(record.ID, $event)" type="checkbox" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="submit-section">
                <input type="submit" class="btn btn-primary pull-right" value="Submit" />
            </div> -->
        </div>
        <div class="submit-section modal-footer">
            <input type="submit" (click)="Submit()" class="btn btn-primary pull-right" data-toggle="modal" 
            data-target="#succeess-message-box" value="Submit" />
        </div>
    </div>
</div>

<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>