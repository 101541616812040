import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '../../../node_modules/@angular/router';
import { LoginModel } from '../Model/common.model';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  LoginModel: LoginModel;
  constructor(private Service: CommonService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.LoginModel = new LoginModel();
   }

  ngOnInit(): void {
  }

  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void{
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $("#succeess-message-box").modal("show");
  }

  Login() {
    this.Service.Login(this.LoginModel).subscribe((res: any) => {
      if(res.loginResult == true) {
        localStorage.setItem("RoleID", res.RoleID);
        localStorage.setItem("RoleName", res.RoleName);
        localStorage.setItem("UserName", res.UserName);
        localStorage.setItem("UserID", res.UserID);
        localStorage.setItem("CompanyID", res.CompanyID);
        localStorage.setItem("CompanyName", res.CompanyName);
        this.router.navigate(['/menuHeader'], { relativeTo: this.activatedRoute })
      }
      else {
        this.popupMessage("Please Enter Valid Credentials!", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
      }
    })
  }

  handleKeyboardEvent(event) {
    this.Service.Login(this.LoginModel).subscribe((res: any) => {
      if(res.loginResult == true) {
        localStorage.setItem("RoleID", res.RoleID);
        localStorage.setItem("RoleName", res.RoleName);
        localStorage.setItem("UserName", res.UserName);
        localStorage.setItem("UserID", res.UserID);
        localStorage.setItem("CompanyID", res.CompanyID);
        this.router.navigate(['/menuHeader'], { relativeTo: this.activatedRoute })
      }
      else {
        this.popupMessage("Please Enter Valid Credentials!", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
      }
    })
  }

}
