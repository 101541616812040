<div class="page-container container-fluid">
    <h2 class="page-title">SAP Help Documents</h2>
    <div class="page-content">
        <div class="table-section">
            <div class="table-section-content">
                <table datatable class="row-border hover table-striped">
                    <thead>
                        <tr>
                            <th>SR No</th>
                            <th>Document Name</th>
                            <th class="text-center">Document Type</th>
                            <th class="text-center">Document Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Questionnaire</td>
                            <td class="text-center">
                                <i class="fa fa-file-excel-o excel-icon" aria-hidden="true"></i>

                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary" href="../../../assets/documents/HSBC_SAP_Questionnaire.xlsx" target="_blank">Open</a>
                                <!-- <button class="btn btn-primary" onclick="window.open('../../../assets/video/ChildrensWildAnimal.mp4')">Open</button> -->
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Technical Specification Document</td>
                            <td class="text-center">
                                <i class="fa fa-file-word-o word-icon" aria-hidden="true"></i>
                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary" href="../../../assets/documents/HSBC_eBINS_TS.doc" target="_blank">Open</a>
                                <!-- <button type="pdf" class="btn btn-primary" onclick=" window.open('../../../assets/documents/pdf/SysBrij Installation Guide V1.pdf');"> Open </button> -->
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>User Manual</td>
                            <td class="text-center">
                                <i class="fa fa-file-word-o word-icon" aria-hidden="true"></i>
                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary" href="../../../assets/documents/HSBC_eBINS_user_manual.doc" target="_blank">Open</a>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
        </div>
        <!-- List Section Ended -->
    </div>
</div>