import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit } from '@angular/core';
import { CustomerWorkSetupModel, CustomerDetailsModel } from '../Model1/common1.model';

@Component({
  selector: 'app-customer-work-list',
  templateUrl: './customer-work-list.component.html',
  styleUrls: ['./customer-work-list.component.css']
})
export class CustomerWorkListComponent implements OnInit {
  CustomerWorkGrid: any;
  CustomerId: string="";
  CompanyDD: any;
  CustomerWorkSetupModel: CustomerWorkSetupModel;
  CustomerDetailsModel: CustomerDetailsModel;
  CustomerDetailsList: Array<CustomerDetailsModel>

  constructor(private Service: CommonService) { }

  ngOnInit(): void {
    this.GetCompanyDD();
    this.CustomerWorkSetupModel = new CustomerWorkSetupModel();
    this.CustomerDetailsModel = new CustomerDetailsModel();
    this.CustomerDetailsList = new Array<CustomerDetailsModel>();
  }

  GetCompanyDD() {
    this.Service.GetCompanyDD().subscribe((res) => {
      this.CompanyDD = res;
    })
  }
  CustomerWorklist(){
    this.Service.CustomerWorklist(this.CustomerId).subscribe((res) => {
      debugger;
      this.CustomerWorkGrid = res;
    })
  }
  Clear(){
    this.CustomerId = "";
    this.CustomerWorkGrid  = null;
  }
  GetCustomerWorkDetailView(Id) {
    debugger;
    this.Service.GetCustomerWorkDetailView(Id).subscribe((res: any) => {
      console.log(res)
        this.CustomerWorkSetupModel = res;;
        this.CustomerDetailsList = res.customerDetails;
    })
    this.showModal();
  }
  showModal(){
    $('#customer-work-view').appendTo("body").modal('show');
  }

}
