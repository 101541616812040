import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tally-codebase',
  templateUrl: './tally-codebase.component.html',
  styleUrls: ['./tally-codebase.component.css']
})
export class TallyCodebaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
