<div id="picture">
    <img class="img-responsive imgsize" src="../../assets/images/ConnectBank1.jpg" alt="">
</div>
<a class="gotopbtn" href="#"><i class="fa fa-arrow-up"></i></a>
<main>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <section class="landing-page-section" id="Overview">
                    <h2 class="landing-page-section-title">Overview:</h2>
                    <div class="landing-page-section-detail">
                        <p> Connect<span class="connectbankclr">Bank</span> is a unique offering for corporate customers
                            to do banking. It offers digitized,
                            seamless and automatic facilitator to do banking in real time basis.
                            Its set of IT tools which are installed on customer’s IT landscape and connected with the
                            bank platform. It offers following benefits for the business
                        </p>
                        <h4>• Digital Data Flow</h4>
                        <p>These tools, read data from customer’s ERP system, convert it into the payload and digitally
                            process it to bank IT system. It creates a traceability, digital signature of the data and
                            enables real time banking transaction processing.</p>
                        <h4>• Speed</h4>
                        <p>These tools can process massive data in one go and provides an enormous speed and
                            productivity for banking related transactions. These tools pick up bulk data and transfers
                            to bank system in no time saving hours of manual processing</p>
                        <h4>• Accuracy</h4>
                        <p>Since these are automated digital computer transactions, there is no or very minimal chance
                            of fat fingering or data inaccuracy during the processing.</p>
                        <h4>• System Connectivity</h4>
                        <p>These tools can connect with your ERP, Payroll or any other systems for reading and
                            processing the data. These tools work seamlessly on cloud, on premises servers or local
                            computers. These are all capable to connect multiple systems simultaneously and process the
                            data.</p>
                    </div>
                </section>
                <section class="landing-page-section" id="features">
                    <h2 class="landing-page-section-title">Features:</h2>
                    <div class="landing-page-section-detail">
                        <h4>1. Cash </h4>
                        <ul>
                            <li>• Outward payments for vendors</li>
                            <li>• Employee Payrolls</li>
                            <li>• Customer payments </li>
                            <li>• Advice printing</li>
                        </ul> <br>

                        <h4>2. Multi-Currency</h4>
                        <p>Vostro API based processing allows you to do transactions in multiple currencies</p><br>

                        <h4>3. Data Types</h4>
                        <ul>
                            <li>• File Based </li>
                            <li>• API based
                            </li>
                        </ul><br>
                        <h4>4. Payment Types</h4>
                        <h5 style="margin-left:20px">This took kit covers practically all types of payment methods used in India.</h5>
                        <ul>
                            <li>• NEFT </li>
                            <li>• RTGS</li>
                            <li>• IMPS</li>
                            <li>• Bulkpay (Salary & Others)</li>
                            <li>• COS - ACC</li>
                            <li>• ICO</li>
                            <li>• IDD</li>
                            <li>• NGNACH - Credit, Debit</li>
                            <li>• Nodal Merhant file & Payment</li>
                        </ul><br>
                        <h4>5. Data Extraction & Transfer</h4>
                        <p>These tools automatically extract data from your ERP systems for related transactions. All
                            read to deploy codes help to setup quickly and get it deployed in no time.
                            Once data is extracted, it automatically transfers to send it to bank system in encrypted
                            fashion
                        </p><br>
                        <h4>6. Reverse Feeds and Reconciliation</h4>
                        <p>Once data is sent to the bank and processed, it also picks up acknowledgement files from the
                            bank system and sends it back to your ERP system. There these reverse feeds get processed
                            automatically in the ERP and reconciles with the bank for these transactions.</p><br>
                        <h4>7. Configurable – Ala-a Carte</h4>
                        <p>These set of tools are all configurable and Ala-a Carte, meaning you can pick up
                            functionality that is relevant and applicable to your business.
                            You can pickup to send data in XML or XLS format, choose to send in encrypted or
                            non-encrypted format. You can also decide to just encrypt the data and choose to manually
                            upload or send it to the bank. Likewise, you have host of options on reverse feed side to
                            select from. You can select required reports from multiple available std reports and
                            options.
                        </p>
                    </div>
                </section>
                <section class="landing-page-section" id="howitworks">
                    <h2 class="landing-page-section-title">How it Works:</h2>
                    <div class="landing-page-section-detail">
                        <p>The Connect<span class="connectbankclr">Bank</span> tool kit is installed on your IT
                            landscape. Even if you have cloud or on premises
                            or local computers, this tools kit can be installed on any of these infrastructure
                            components. It works on Windows or Linux operating system with equal ease. It is compatible
                            with all Windows & Linux OS versions and components.
                            In short, this is most versatile tool kit which works on any of the operating systems and
                            servers.
                        </p>
                        <div class="row">
                            <div class="col-md" style="text-align: center;">
                                <video width="400" controls>
                                    <!-- <source src="../../assets/video/VIP schemes recording.mp4" type="video/mp4"> -->
                                    <source src="../../assets/video/Cb.mp4" type="video/mp4">
                                    Your browser does not support HTML video.
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="landing-page-section" id="howtostart">
                    <h2 class="landing-page-section-title">How to Start:</h2>
                    <div class="landing-page-section-detail">
                        <p>Its very simple to get started. Just simply fill-up the form below and our representative
                            will call you for details. In the form you can simply provide you contact details and no
                            need to worry about what would be applicable to your organization, what features needed or
                            not. We will help you with that and simply the selection.
                        </p>
                        <div class="container border border-secondary newform">
                            <form >
                                <h4>Apply for Connect<span class="connectbankclr">Bank</span></h4>
                                <hr>
                                <div class="form-row">
                                    <div class="form-group col-sm-5">
                                        <label>Name <span id="starField">*</span></label>
                                        <input class="form-control" name="Name" id="clearname" [(ngModel)]="ApplyForCBModel.Name">
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>

                                    <div class="form-group col-sm-5">
                                        <label>Email <span id="starField">*</span></label>
                                        <input class="form-control" name="Email" [(ngModel)]="ApplyForCBModel.Email">
                                    </div>

                                    <div class="form-group col-sm-5">
                                        <label>Mobile Number <span id="starField">*</span></label>
                                        <input class="form-control" name="Mobile_No"  [(ngModel)]="ApplyForCBModel.Mobile_No">
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>

                                    <div class="form-group col-sm-5">
                                        <label>Organization Name <span id="starField">*</span></label>
                                        <input class="form-control" name="OrganizationName" [(ngModel)]="ApplyForCBModel.OrganizationName">
                                    </div>

                                    <div class="form-group col-sm-5">
                                        <label>Organization Type <span id="starField">*</span></label>
                                        <select class="form-control ddwidth" name="OrganizationType" [(ngModel)]="ApplyForCBModel.OrganizationType" >
                                            <option selected value="">Choose...</option>
                                            <option value="Pvt Ltd">Pvt Ltd</option>
                                            <option value="LLC">LLC</option>
                                            <option value="Public Ltd">Public Ltd</option>
                                            <option value="MNC">MNC</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>

                                    <div class="form-group col-sm-5">
                                        <label> Do you know what tool sets needed for you ?
                                        </label>
                                        <select class="form-control ddwidth" name="ToolSet" [(ngModel)]="ApplyForCBModel.ToolSet">
                                            <option id="selectHeader" value="" > Select Type </option>
                                            <option value="Yes" >Yes</option>
                                            <option value="No" >No</option>
                                            <option value="Not Sure" >Not Sure</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-sm-5">
                                        <label>Any other comments ?</label>
                                        <input class="form-control" name="Comments" [(ngModel)]="ApplyForCBModel.Comments">
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>

                                    <div class="form-group col-sm-2">
                                        <label>Change Captcha <img src="../../assets/images/refresh.jpg" width="15px" style="margin-bottom: -11px;"(click)="cap()" > </label>
                                        <input class="form-control " id="capt" disabled  > 
                                    </div>
                                    <div class="form-group col-sm-2">
                                        <label>Enter captcha <span id="starField">*</span></label>

                                        <input class="form-control " id="textinput"  > 
                                    </div>
                                    <div class="col-sm-6">&nbsp;</div>

                                    <div class="form-group col-sm-5">
                                        <button type="button" class="btn btn-success submit" (click)="Submit()">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <p>Alternatively, you can also check the compatibility of this tool set for your organization.
                        </p>
                    </div>
                </section>
                <section class="landing-page-section">
                    <h2 class="landing-page-section-title" id="Compability">Compatibilty:</h2>
                    <div class="landing-page-section-detail">
                        <p>If you are not very sure, if Connect<span class="connectbankclr">Bank</span> will help you or
                            not, then you can simply fillup the
                            this form and check by yourself if this would be useful for you.
                        </p>
                        <div class="container border border-secondary newform">
                            <form>
                                <h4>Compatibility Check for Adapters</h4>
                                <hr>
                                <div class="form-row" >
                                    <div class="form-group col-sm-5">
                                        <label>Email</label>
                                        <input class="form-control" type="email">
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                    <div class="form-group col-sm-5">
                                        <label>Orgaization Name</label>
                                        <input class="form-control" >
                                    </div>
                                    <div class="form-group col-sm-5">
                                        <label> Do you have any ERP system?
                                        </label>
                                        <select class="form-control ddwidth" (click)="whichERP()" [(ngModel)]="firstField" name="firstField">
                                            <option id="selectHeader" value=0> Select Type </option>
                                            <option value=8>Yes</option>
                                            <option value=5>No</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                    <div class="form-group col-sm-5" >
                                        <label> Select ERP system
                                        </label>
                                        <select class="form-control ddwidth" disabled id="hideField" [(ngModel)]="secondField" name="secondField">
                                            <option id="selectHeader" value=0> Select Type </option>
                                            <option value=5>SAP</option>
                                            <option value=6>Oracle</option>
                                            <option value=7>Tally</option>
                                            <option value=3>Any Other</option>
                                        </select>
                                    </div>
                                    
                                    <div class="form-group col-sm-5">
                                        <label>How many times you process vendors in a month ?
                                        </label>
                                        <select class="form-control ddwidth" [(ngModel)]="thirdField" name="thirdField">
                                            <option id="selectHeader" value=0> Select Type </option>
                                            <option value=6>1 Time</option>
                                            <option value=7>2 Times</option>
                                            <option value=8>3 Times</option>
                                            <option value=9>More than 3 Times</option>
                                        </select>
                                    </div>

                                    <div class="col-sm-1">&nbsp;</div>
                                    <div class="form-group col-sm-5">
                                        <label>Do you process employee payments between the month?
                                        </label>
                                        <select class="form-control ddwidth" [(ngModel)]="fourthField" name="fourthField">
                                            <option id="selectHeader" value=0> Select Type </option>
                                            <option value=8>Yes</option>
                                            <option value=4>No</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-sm-5">
                                        <label>How many times you process employee payments in a month ?
                                        </label>
                                        <select class="form-control ddwidth" [(ngModel)]="fifthField" name="fifthField">
                                            <option id="selectHeader" value=0> Select Type </option>
                                            <option value=5>1 Time</option>
                                            <option value=6>2 Times</option>
                                            <option value=7>3 Times</option>
                                            <option value=8>More than 3 Times</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                    <div class="form-group col-sm-5" style="margin-top: 20px;">
                                        <label>How do you process vendor payments ?
                                        </label>
                                        <select class="form-control ddwidth" [(ngModel)]="sixthField" name="sixthField">
                                            <option id="selectHeader" value=0> Select Type </option>
                                            <option value=5>Manually</option>
                                            <option value=8>Bank Website</option>
                                            <option value=9>Manual Upload</option>
                                            <option value=10>Other</option>
                                        </select>
                                    </div>
                                    <div class="clearfix"></div>

                                    <div class="form-group col-sm-5 " [(ngModel)]="seventhField" name="seventhField">
                                        <label>How do you process customer debits?
                                        </label>
                                        <select class="form-control ddwidth ">
                                            <option id="selectHeader" value=0> Select Type </option>
                                            <option value=6>Manually</option>
                                            <option value=7>Bank Website</option>
                                            <option value=8>Manual Upload</option>
                                            <option value=9>Other</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                    <div class="form-group col-sm-5">
                                        
                                        <!-- <button type="button" class="btn btn-success submit" (click)="Submit2()">Submit</button> -->
                                        <button type="button" class="btn btn-success submit" data-toggle="modal" data-target="#exampleModalCenter" (click)="Submit2()">
                                            Submit
                                          </button>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <p>Once user completes this survey and submits the form, system will give him a score with need
                            to implement this system.</p>
                    </div>
                </section>
                <section class="landing-page-section">
                    <h2 class="landing-page-section-title" id="FAQs">FAQs:</h2>
                    <div class="landing-page-section-detail">
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="headingOne">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse1st" aria-expanded="false" aria-controls="collapse1st">We
                                                have SAP system, do these adapters work with SAP?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse1st" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="headingOne">
                                        <div class="panel-body">
                                            <p>Yes, we have standard adapters for SAP and SAP S/4 HANA. You just need to
                                                copy the code in your SAP system and it would be compatible with your
                                                ERP.
                                                Apart from SAP, we also have standard adapters for Tally & Oracle ERPs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading2">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse2" aria-expanded="false" aria-controls="collapse2">We use
                                                our homegrown ERP solution; will these work with this ERP?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse2" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading2">
                                        <div class="panel-body">
                                            <p>Yes, these adapters can work with any ERP or for that matter any IT
                                                systems. All you need is extract the relevant file from your ERP in .XLS
                                                format and rest will be automatic.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading3">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse3" aria-expanded="false" aria-controls="collapse3">We
                                                need to have reports on daily basis and monthly basis, how can it
                                                support it?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse3" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading3">
                                        <div class="panel-body">
                                            <p>There are multiple reports which are generated on daily and monthly
                                                basis. You can select and choose relevant reports needed and system will
                                                automatically place these reports on your system at a set frequency
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading4">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                                We use Linux operating system, do these adapters work on Linux?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse4" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading4">
                                        <div class="panel-body">
                                            <p>Yes, these are OS agnostic and it can work on any Windows or Linux system
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading5">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                Can these adapters send emails with report attachments?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse5" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading5">
                                        <div class="panel-body">
                                            <p>Yes, this is a standard feature available. You will need to provide a
                                                dedicated email address with credentials and allow email this email
                                                through your gateway. With that it can be setup to send reports emails
                                                as well as many other emails.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading6">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                                If there is a failure, how would we be notified and how corrections can
                                                happen?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse6" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading6">
                                        <div class="panel-body">
                                            <p>If you have enabled email functionality, system will send emails to
                                                designated recipients on any kind of failures. Also, you can always
                                                check in log files in the system.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading7">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                                How can we get started?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse7" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading7">
                                        <div class="panel-body">
                                            <p>Its very simple. Just fill up the form on contact us page and we will
                                                connect with you for further information.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading8">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                How data is transmitted to Bank?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse8" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading8">
                                        <div class="panel-body">
                                            <p>This system uses PGP encryption and Host to host connectivity to send
                                                data to the bank. Its most secured method of data transmission over
                                                internet.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading9">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                                This is confidential data for our organization, is its shared
                                                anywhere?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse9" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading9">
                                        <div class="panel-body">
                                            <p>No, The application is hosted on your IT landscape. So all data resides
                                                in your landscape and encrypted there. The encrypted data packet is sent
                                                to the bank over internet.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading10">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                                What is host to host connection?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse10" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading10">
                                        <div class="panel-body">
                                            <p>Host-to-Host is an automated, two-way data transfer service for
                                                high-volume online banking that gives you direct, anytime access to
                                                Business Online from various applications. Host-to-Host is a direct
                                                system-to-system integration between your ERP system and the bank, and
                                                with no user intermediary involved.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading11">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                                Do we need to have network connectivity with the bank?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse11" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading11">
                                        <div class="panel-body">
                                            <p>Yes, in order to use Host To Host connection which is secured connection,
                                                you need to establish direct connectivity with the bank. Its easy to
                                                setup this connectivity. Just fillup the form and Bank representative
                                                will connect with your network person to setup the connectivity.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading12">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                                How to setup network connectivity with the bank?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse12" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading12">
                                        <div class="panel-body">
                                            <p>Its easy to setup this connectivity. Just fillup the form and Bank
                                                representative will connect with your network person to setup the
                                                connectivity.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading13">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                                Does this system uses any Database?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse13" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading13">
                                        <div class="panel-body">
                                            <p>No, this does not use any kind of database..
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading14">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                                Are there any licenses for this system?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse14" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading14">
                                        <div class="panel-body">
                                            <p>No, this is completely free from any licenses
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading15">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                                Are there any third party applications used in the solution?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse15" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading15">
                                        <div class="panel-body">
                                            <p>Standard open source third party libraries are used in the system, which
                                                are worldwide used in any application.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading16">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                                After installation, if there is any issue how do we get the support?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse16" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading16">
                                        <div class="panel-body">
                                            <p>Once you login to the portal, you can create the support ticket and our
                                                support team will fix the issue.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading17">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                                Currently we use our own file format and don’t want to change it. Can we
                                                use this system?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse17" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading17">
                                        <div class="panel-body">
                                            <p>Yes, this system supports any input file format and structure. So if your
                                                ERP is creating a file output which is currently in use, it can consume
                                                that file as well.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading18">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse18" aria-expanded="false" aria-controls="collapse18">
                                                What if we want to use only one of the adapter??</a>
                                        </h3>
                                    </div>
                                    <div id="collapse18" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading18">
                                        <div class="panel-body">
                                            <p>Yes, its completely pick and choose method. You don’t need to use all the
                                                tools available in the system. Just select ones which are needed for
                                                your organization.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading19">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse19" aria-expanded="false" aria-controls="collapse19">
                                                Our organization does not allow installation of any other softwares, how
                                                can we handle it?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse19" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading19">
                                        <div class="panel-body">
                                            <p>You need to connect with your security team and get their approval for
                                                installation of this software in your IT landscape.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading20">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse20" aria-expanded="false" aria-controls="collapse20">
                                                Is this system compliant with VAPT?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse20" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading20">
                                        <div class="panel-body">
                                            <p>This is an application and VAPT is more towards the network
                                                vulnerability. You can connect with your risk or security team for VAPT
                                                with bank network.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="heading21">
                                        <h3 class="panel-title">
                                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                                href="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                                How much time is needed to setup the system?</a>
                                        </h3>
                                    </div>
                                    <div id="collapse21" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="heading21">
                                        <div class="panel-body">
                                            <p>The installation and testing happen with 3 to 5 business days. It depends
                                                on your organization’s preparedness to test and deploy the system. But
                                                it does not take more than 4 to 6 weeks from start to end.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</main>


  
  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header" style="background-color:grey">
          <h4 class="modal-title" id="exampleModalLongTitle" >Compatibility Score</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <h5>Your score is : {{score}}</h5>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button>
        </div>
      </div>
    </div>
</div>


<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;margin: 0px; "
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px; margin: 0px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" id="close" >Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

