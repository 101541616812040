<mat-toolbar>
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <h3>ConnectBank</h3>
  <div class="container">
    <div class="row text-right">
      <div class="col-md-12 col-sm-12">
        <button class="btnIcon" ><i class="fa fa-user fa-lg"  aria-hidden="true" title="{{UserName}} - {{RoleName}}"></i></button>
        <span class="navbarContent">{{UserName}} - {{RoleName}}</span>
      </div>
    </div>
  </div>
  
  <button class="btnIcon" style="margin-left: 10px;" (click)="logout()"><i class="fa fa-sign-out fa-lg" aria-hidden="true"></i></button>
 
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav">
    <img class="avatar mat-elevation-z8" src="../../../assets/images/Connectbank Logo.png" />
    <mat-divider></mat-divider>

    <button mat-button [matMenuTriggerFor]="menu1" class="menu-button" *ngIf="RoleID == '5' || RoleID == '3'">
      <mat-icon>perm_identity</mat-icon>
      <span>Users</span>
    </button>
    <mat-menu #menu1="matMenu">
      <button mat-menu-item [routerLink]="'../menuHeader/customerList1'">User List</button>
      <button mat-menu-item [routerLink]="'../menuHeader/createCustomer1'">New user</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menu2" class="menu-button" >
      <mat-icon>assessment</mat-icon>
      <span>Workflows</span>
    </button>
    <mat-menu #menu2="matMenu">
      <button mat-menu-item [routerLink]="'../menuHeader/workflowList1'">Workflow List</button>
      <button mat-menu-item [routerLink]="'../menuHeader/createnewworkflow1'">New Workflow</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menu3" class="menu-button" *ngIf="RoleID == '5' || RoleID == '3'" >
      <mat-icon>assignment</mat-icon>
      <span>Engagement Work</span>
    </button>
    <mat-menu #menu3="matMenu">
      <button mat-menu-item [routerLink]="'../menuHeader/customerWorkList'">Engagement Work List</button>
      <button mat-menu-item [routerLink]="'../menuHeader/createCustomerWork'">Create Engagement Work</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menu4" class="menu-button" >
      <mat-icon>help</mat-icon>
      <span>Help Files</span>
    </button>
    <mat-menu #menu4="matMenu">
      <button mat-menu-item [routerLink]="'../menuHeader/sysBrijHelpFile'">SysBRIJ</button>
      <button mat-menu-item [routerLink]="'../menuHeader/wrapperHelpFile'">Wrapper</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menu5" class="menu-button" >
      <mat-icon>code</mat-icon>
      <span>Code Base</span>
    </button>
    <mat-menu #menu5="matMenu">
      <button mat-menu-item [routerLink]="'../menuHeader/sysBrijCodeBase'">SysBRIJ</button>
      <button mat-menu-item [routerLink]="'../menuHeader/wrapperCodeBase'">Wrapper</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menu6" class="menu-button" *ngIf="RoleID == '5'">
      <mat-icon>settings</mat-icon>
      <span>Configuration</span>
    </button>
    <mat-menu #menu6="matMenu">
      <button mat-menu-item [routerLink]="'../menuHeader/roleMaster1'">Role Master</button>
      <button mat-menu-item [routerLink]="'../menuHeader/roleAssignment1'">Role Transaction Master</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menu7" class="menu-button" >
      <mat-icon>contact_support</mat-icon>
      <span>Contact Support</span>
    </button>
    <mat-menu #menu7="matMenu">
      <button mat-menu-item [routerLink]="'../menuHeader/contactSupportList1'">Contact Support List</button>
      <button mat-menu-item [routerLink]="'../menuHeader/createContactSupport1'">Create Contact Support</button>
    </mat-menu>
    
    <mat-divider></mat-divider>

  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content mat-elevation-z8">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

