import { jsPDF } from 'jspdf';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Subject } from '../../../node_modules/rxjs';
import { WorkflowListFilterNewModel, WorkflowRequirementFinalizedModel, WorkflowRequirementsModel, PaymentMethodsModel, AccountNumbersModel, StandartRequirementsModel } from '../Model1/common1.model';
import { Router, ActivatedRoute } from '@angular/router';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-work-flow-list1',
  templateUrl: './work-flow-list1.component.html',
  styleUrls: ['./work-flow-list1.component.css'],
})
export class WorkFlowList1Component implements OnInit {
  WorkflowGrid: any;
  WorkflowListFilterNewModel: WorkflowListFilterNewModel;
  WorkflowRequirementFinalizedModel: WorkflowRequirementFinalizedModel;
  WorkflowRequirementsModel: WorkflowRequirementsModel;
  PaymentMethodsModel: PaymentMethodsModel;
  PaymentMethodsList: Array<PaymentMethodsModel>;
  AccountNumbersModel: AccountNumbersModel;
  AccountNumberList: Array<AccountNumbersModel>;
  StandartRequirementsModel: StandartRequirementsModel;
  todayDate = new Date();
  file: any;
  ReportType = [];
  ReportTypeRetun = [];
  WorkflowID : any;
  ReportTypeString: string;
  url: any; 
	msg:string = "";
  UserName: string;
  CompanyName: string;
  WorkflowNo: string;
  WorkflowCreationDate: string;
  BankIMDD: any;
  RoleID: any;
  @ViewChild('pdfTable') pdfTable!: ElementRef;
  @ViewChild('ATPSentToUser') ATPSentToUser!: ElementRef;

  constructor(private service: CommonService, private router: Router, private ActivatedRoute: ActivatedRoute) {
    this.WorkflowListFilterNewModel = new WorkflowListFilterNewModel();
    this.WorkflowRequirementFinalizedModel =
      new WorkflowRequirementFinalizedModel();
    this.WorkflowRequirementsModel = new WorkflowRequirementsModel();
    this.PaymentMethodsModel = new PaymentMethodsModel();
    this.PaymentMethodsList = new Array<PaymentMethodsModel>();
    this.AccountNumbersModel = new AccountNumbersModel();
    this.AccountNumberList = new Array<AccountNumbersModel>();
    this.StandartRequirementsModel = new StandartRequirementsModel();
  }

  ngOnInit(): void {
    $('#pdfTable').hide();
    $('#ATPSentToUser').hide();
    $('.allRequirements').hide();
    this.UserName  = localStorage.getItem('UserName');
    this.CompanyName  = localStorage.getItem('CompanyName');
    this.RoleID  = localStorage.getItem('RoleID');
    console.log(localStorage.getItem('CompanyName'));
    this.GetBankIMDD();
  }
  GetBankIMDD() {
    this.service.GetBankIMDD().subscribe((res) => {
      this.BankIMDD = res;
    })
  }

  WorkflowList() {
    debugger;
    this.WorkflowListFilterNewModel.UserID = localStorage.getItem('UserID');
    this.WorkflowListFilterNewModel.RoleID = localStorage.getItem('RoleID');
    this.service.WorkflowListNew(this.WorkflowListFilterNewModel).subscribe((res: any) => {
      debugger;
        this.WorkflowGrid = res;
        this.WorkflowNo = res[0].WorkflowNo;
        this.WorkflowCreationDate = res[0].WorkflowCreationDate;
      });
  }

  Clear() {
    this.WorkflowListFilterNewModel = new WorkflowListFilterNewModel();
  }

  GetWorkflowDetailNew(ID:any, status:any) {
    debugger;
    this.service.GetWorkflowDetailNew(ID).subscribe((res: any) => {
      debugger;
      this.WorkflowRequirementsModel = res;
      this.PaymentMethodsList = res.AdapterDetailsNewList;
      this.AccountNumberList = res.EntitySetupNewList
      this.ATPSentPdfToUser();
    }); 
    if(status == 'view'){
      $('#RequirementsView').appendTo('body').modal('show');
    }
  }
  toShowAllReq(){
    this.GetWorkflowDetailNew(this.WorkflowID, 'edit');
    $('.allRequirements').show();
  }
  modal(status: any, ID: any) {
    this.WorkflowID = ID;
    if (status == '1') {
      this.router.navigate(['../createnewworkflow1/', this.WorkflowID], { relativeTo: this.ActivatedRoute })
    }
    else if (status == '2') {
      $('#requirementsModal').appendTo('body').modal('show');
      this.GetWorkflowDetailNew(this.WorkflowID, '');
    } 
    else if (status == '3') {
      $('#ATPApproval').appendTo('body').modal('show');
      this.GetWorkflowDetailNew(this.WorkflowID, '');
    } 
    else if (status == '4') {
      $('#QAInstance').appendTo('body').modal('show');
    }
    else if (status == '6') {
      $('#ProdInstance').appendTo('body').modal('show');
    }
    else if (status == '5') {
      $('#UATSignoff').appendTo('body').modal('show');
    }
    else if (status == '7') {
      $('#DeploymentCompletion').appendTo('body').modal('show');
    }
  }
  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void{
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $('#succeess-message-box').appendTo("body").modal('show');
  }

  HideShow(){
    $("#errorclose").show();
    $("#close").hide();
  }

  Submit1() {
    debugger
    
    if (this.StandartRequirementsModel.CloseWorkflow == '1'){
      this.StandartRequirementsModel.WorkflowID = this.WorkflowID;
      this.StandartRequirementsModel.WorkflowStatus = '8';
      this.service.StandartRequirements(this.StandartRequirementsModel).subscribe(res => {
        $('#requirementsModal').modal('hide');
        $("#errorclose").hide();
        $("#close").show();
        this.popupMessage("Workflow Closed Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");  
        this.WorkflowList();
      })
    }
    else {
      this.StandartRequirementsModel.WorkflowStatus = '3';
    this.StandartRequirementsModel.WorkflowID = this.WorkflowID;
    if (this.StandartRequirementsModel.StandardRequirements == "" || this.StandartRequirementsModel.StandardRequirements == undefined) {
      this.HideShow();
      this.popupMessage("Select Are these standard Requirements", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.StandartRequirementsModel.RequirementsFinalized == "" || this.StandartRequirementsModel.RequirementsFinalized == undefined) {
      this.HideShow();
      this.popupMessage("Select Requirements finalized", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else if (this.StandartRequirementsModel.ConfirmBankIM == "" || this.StandartRequirementsModel.ConfirmBankIM == undefined) {
      this.HideShow();
      this.popupMessage("Enter Confirm with Bank IM", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else if ((this.StandartRequirementsModel.RequirementsFinalized == '1') && (this.StandartRequirementsModel.SendATP == "" || this.StandartRequirementsModel.SendATP == undefined)) {
      this.HideShow();
      this.popupMessage("Select Send ATP", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else {
      this.service.StandartRequirements(this.StandartRequirementsModel).subscribe(res => {
        $('#requirementsModal').modal('hide');
        $("#errorclose").hide();
        $("#close").show();
        this.popupMessage("Workflow Created Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");  
        this.WorkflowList();
        
      })
    }
    }
  }
  Submit2() {
    debugger
    this.StandartRequirementsModel.WorkflowStatus = '4';
    this.StandartRequirementsModel.WorkflowID = this.WorkflowID;
    if (this.StandartRequirementsModel.AcceptTC == "" || this.StandartRequirementsModel.AcceptTC == undefined) {
      this.HideShow();
      this.popupMessage("Select Accept T&C", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else {
      this.service.StandartRequirements(this.StandartRequirementsModel).subscribe(res => {
        $('#ATPApproval').modal('hide');
        this.WorkflowList();
        $("#errorclose").hide();
        $("#close").show();
        this.popupMessage("Workflow Created Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id"); 
      })
    }
  }
  Submit3() {
    debugger 
    this.StandartRequirementsModel.WorkflowStatus = '5';
    this.StandartRequirementsModel.WorkflowID = this.WorkflowID;
    if (this.StandartRequirementsModel.ApprovedToQA == "" || this.StandartRequirementsModel.ApprovedToQA == undefined) {
      this.HideShow();
      this.popupMessage("Select Approved To QA", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else if (this.StandartRequirementsModel.ApprovedBy == "" || this.StandartRequirementsModel.ApprovedBy == undefined) {
      this.HideShow();
      this.popupMessage("Select Approved By", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else {
      this.service.StandartRequirements(this.StandartRequirementsModel).subscribe(res => {
        $('#QAInstance').modal('hide');
        this.WorkflowList();
        $("#errorclose").hide();
        $("#close").show();
        this.popupMessage("Workflow Created Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
      })
    }
  }
  Submit4() {
    debugger 
    this.StandartRequirementsModel.WorkflowStatus = '7';
    this.StandartRequirementsModel.WorkflowID = this.WorkflowID;
    if (this.StandartRequirementsModel.ApprovedToProd == "" || this.StandartRequirementsModel.ApprovedToProd == undefined) {
      this.HideShow();
      this.popupMessage("Select Generate Produaction instance", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else if (this.StandartRequirementsModel.ApprovedByProd == "" || this.StandartRequirementsModel.ApprovedByProd == undefined) {
      this.HideShow();
      this.popupMessage("Enter Approved By", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else {
      this.service.StandartRequirements(this.StandartRequirementsModel).subscribe(res => {
        $('#ProdInstance').modal('hide');
        this.WorkflowList();
        $("#errorclose").hide();
        $("#close").show();
        this.popupMessage("Workflow Created Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
      })
    }
  }
  Submit5() {
    debugger 
    this.StandartRequirementsModel.WorkflowStatus = '6';
    this.StandartRequirementsModel.WorkflowID = this.WorkflowID;
    if (this.StandartRequirementsModel.UATCompleted == "" || this.StandartRequirementsModel.UATCompleted == undefined) {
      this.HideShow();
      this.popupMessage("Select UAT Completed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else if (this.StandartRequirementsModel.UATApprovedBy == "" || this.StandartRequirementsModel.UATApprovedBy == undefined) {
      this.HideShow();
      this.popupMessage("Enter UAT Approved By", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else {
      this.service.StandartRequirements(this.StandartRequirementsModel).subscribe(res => {
        $('#UATSignoff').modal('hide');
        this.WorkflowList();
        $("#errorclose").hide();
        $("#close").show();
        this.popupMessage("Workflow Created Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
      })
    }
  }
  Submit6() {
    debugger 
    this.StandartRequirementsModel.WorkflowStatus = '10';
    this.StandartRequirementsModel.WorkflowID = this.WorkflowID;
    if (this.StandartRequirementsModel.DeploymentCompleted == "" || this.StandartRequirementsModel.DeploymentCompleted == undefined) {
      this.HideShow();
      this.popupMessage("Select Deployment Completed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.service.StandartRequirements(this.StandartRequirementsModel).subscribe(res => {
        $('#DeploymentCompletion').modal('hide');
        this.WorkflowList();
        $("#errorclose").hide();
        $("#close").show();
        this.popupMessage("Workflow Created Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
      })
    }
  }

  selectFile(event: any) {    
		if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
      this.url ="";
      this.StandartRequirementsModel.Attachment1="";
			return;
		}
		
		var mimeType = event.target.files[0].type;

		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
    this.StandartRequirementsModel.Attachment1FileName  = event.target.files[0].name;
  
		
		reader.onload = (_event) => { 
			this.msg = "";
			this.url = reader.result;  
      this.StandartRequirementsModel.Attachment1=this.url;
		}
	}

  getFile(event: any) {
    debugger;
    this.file = event.target.files[0];
    if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
      this.url ="";
      this.StandartRequirementsModel.Attachment2="";
			return;
		}
		
		var mimeType = event.target.files[0].type;
		
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
    this.StandartRequirementsModel.Attachment2FileName  = event.target.files[0].name;
    this.StandartRequirementsModel.Attachment2FileName = this.StandartRequirementsModel.Attachment2FileName.substr(this.StandartRequirementsModel.Attachment2FileName.lastIndexOf('.')+1)
		
		reader.onload = (_event) => { 
			this.msg = "";
			this.url = reader.result;  
      this.StandartRequirementsModel.Attachment2=this.url;
		}
  }

  public downloadAsPDF() {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download('SysBrijRequirements.pdf');
  }

  
  public ATPSentPdfToUser() {
    debugger;
    const pdfTable = this.ATPSentToUser.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    var data = pdfMake.createPdf(documentDefinition)
    pdfMake.createPdf(documentDefinition).getBase64((data) => {
      debugger
      this.StandartRequirementsModel.ATPSentPDF = data;
    })
  }
  public DownloadATP() {
    debugger;
    const pdfTable = this.ATPSentToUser.nativeElement;
    console.log(pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    var data = pdfMake.createPdf(documentDefinition)
    pdfMake.createPdf(documentDefinition).download( this.CompanyName +"_ATP.pdf" );
  }
  // DownloadATP(){
  //   debugger;
  //   let data = document.getElementById('ATPSentToUser');
  //   html2canvas(data).then(canvas => {
  //     let pdf = new jsPDF('p','cm','a4');
  //     pdf.save('akshay.pdf')
  //   })
  // }
  
}
