import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { CustomerWorkSetupModel, CustomerDetailsModel } from '../Model1/common1.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-customer-work',
  templateUrl: './create-customer-work.component.html',
  styleUrls: ['./create-customer-work.component.css']
})
export class CreateCustomerWorkComponent implements OnInit {
  CompanyDD: any;
  BankIMDD: any;
  CustomerWorkSetupModel: CustomerWorkSetupModel;
  CustomerDetailsModel: CustomerDetailsModel;
  CustomerDetailsList: Array<CustomerDetailsModel>
  url: any;
  msg: string = "";
  Id: any;


  constructor(private Service: CommonService, private ActivatedRoute: ActivatedRoute, private route: Router) {
    this.CustomerWorkSetupModel = new CustomerWorkSetupModel();
    this.CustomerDetailsModel = new CustomerDetailsModel();
    this.CustomerDetailsList = new Array<CustomerDetailsModel>();
  }

  ngOnInit(): void {
    this.GetCompanyDD();
    this.GetBankIMDD();
    this.Id = this.ActivatedRoute.snapshot.params["Id"];
    if(this.Id != undefined) {
      debugger;
      console.log(this.Id)
      this.GetCustomerWorkDetail(this.Id);
    }
  }

  GetCompanyDD() {
    this.Service.GetCompanyDD().subscribe((res) => {
      this.CompanyDD = res;
    })
  }
  GetBankIMDD() {
    this.Service.GetBankIMDD().subscribe((res) => {
      this.BankIMDD = res;
    })
  }
  
  GetCustomerWorkDetail(Id) {
    debugger;
    this.Service.GetCustomerWorkDetail(Id).subscribe((res: any) => {
        this.CustomerWorkSetupModel = res;;
        this.CustomerDetailsList = res.customerDetails;
        // $("#EmailDisabled").attr('disabled', 'disabled');
        // $("#NameDisabled").attr('disabled', 'disabled');
        // $("#DescDisabled").attr('disabled', 'disabled');
        // $("#AttachDisabled").attr('disabled', 'disabled');
    })
  }
  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void {
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $('#succeess-message-box').appendTo("body").modal('show');
  }

  HideShow() {
    $("#errorclose").show();
    $("#close").hide();
  }

  AddCart() {
    if (this.CustomerDetailsModel.Date == "" || this.CustomerDetailsModel.Date == undefined || this.CustomerDetailsModel.Date == null) {
      this.HideShow();
      this.popupMessage("Enter Date", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerDetailsModel.WorkComment == "" || this.CustomerDetailsModel.WorkComment == undefined || this.CustomerDetailsModel.WorkComment == null) {
      this.HideShow();
      this.popupMessage("Enter Work Comments", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.CustomerDetailsList.push(this.CustomerDetailsModel);
      this.CustomerDetailsModel = new CustomerDetailsModel();
    }
  }
  DeleteCart(index) {
    this.CustomerDetailsList.splice(index, 1);
  }
  Submit() {
    if (this.CustomerWorkSetupModel.CustomerId == "" || this.CustomerWorkSetupModel.CustomerId == undefined || this.CustomerWorkSetupModel.CustomerId == null) {
      this.HideShow();
      this.popupMessage("Select Customer Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerWorkSetupModel.BankIM == "" || this.CustomerWorkSetupModel.BankIM == undefined || this.CustomerWorkSetupModel.BankIM == null) {
      this.HideShow();
      this.popupMessage("Select Bank IM Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerWorkSetupModel.ATPSigned == "" || this.CustomerWorkSetupModel.ATPSigned == undefined || this.CustomerWorkSetupModel.ATPSigned == null) {
      this.HideShow();
      this.popupMessage("Select ATP Signed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerWorkSetupModel.CommAgreed == "" || this.CustomerWorkSetupModel.CommAgreed == undefined || this.CustomerWorkSetupModel.CommAgreed == null) {
      this.HideShow();
      this.popupMessage("Select Comm Agreed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerWorkSetupModel.PaidByCustomer == "" || this.CustomerWorkSetupModel.PaidByCustomer == undefined || this.CustomerWorkSetupModel.PaidByCustomer == null) {
      this.HideShow();
      this.popupMessage("Select Paid By Customer", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerWorkSetupModel.POReceived == "" || this.CustomerWorkSetupModel.POReceived == undefined || this.CustomerWorkSetupModel.POReceived == null) {
      this.HideShow();
      this.popupMessage("Select PO Received", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerWorkSetupModel.Invoiced == "" || this.CustomerWorkSetupModel.Invoiced == undefined || this.CustomerWorkSetupModel.Invoiced == null) {
      this.HideShow();
      this.popupMessage("Select Invoiced", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerWorkSetupModel.PaymentReceived == "" || this.CustomerWorkSetupModel.PaymentReceived == undefined || this.CustomerWorkSetupModel.PaymentReceived == null) {
      this.HideShow();
      this.popupMessage("Select Payment Received", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CustomerWorkSetupModel.StartDeployment == "" || this.CustomerWorkSetupModel.StartDeployment == undefined || this.CustomerWorkSetupModel.StartDeployment == null) {
      this.HideShow();
      this.popupMessage("Select Start Deployment", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.CustomerWorkSetupModel.customerDetails = this.CustomerDetailsList;
      debugger;
      this.Service.CustomerWorkSetup(this.CustomerWorkSetupModel).subscribe(res => {
        if(res == undefined){
          this.HideShow();
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
        }
        else {
          $("#errorclose").hide();
          $("#close").show();
          this.popupMessage("Data Inserted Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");         
        }
      });
    }
  }
  selectFile(event: any) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      this.url = "";
      this.CustomerDetailsModel.Attachment = "";
      return;
    }

    var mimeType = event.target.files[0].type;

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.CustomerDetailsModel.AttachmentFileName = event.target.files[0].name;


    reader.onload = (_event) => {
      this.msg = "";
      this.url = reader.result;
      this.CustomerDetailsModel.Attachment = this.url;
    }
  }
  SuccessRedirect() {
    debugger;
    if(this.Id != undefined) {
      this.route.navigate(['../../customerWorkList'], { relativeTo: this.ActivatedRoute })
      $("#succeess-message-box").modal("hide");
    }
    else {
      this.route.navigate(['../customerWorkList'], { relativeTo: this.ActivatedRoute })
      $("#succeess-message-box").modal("hide");
    }
  }

}
