<main>
    <div [ngClass]="
      {
        'main-left-section-container' : true,
        'main-left-section-update': menuToggleCheck == true
      }
      ">
      
        <div class="main-left-section cf">
            <app-navigation-menu></app-navigation-menu>
        </div>
    </div>
    <div [ngClass]="{
        'main-right-section':  true,
        'main-right-section-update': menuToggleCheck == true
      }">
        <div [ngClass]="{
          'main-right-sub-container': menuToggleCheck == false
        }">
            <app-header (menuToggleEvent)="menuToggle($event)"></app-header>

            <router-outlet></router-outlet>
        </div>
    </div>
</main>