<div class="container-fluid">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">Engagement Work List</h3>
        </div>
        <div class="col-xs-6 text-right">
            <a class="btn btn-success" [routerLink]="'../createCustomerWork'"> <i class="fa fa-plus fa-lg"
                    aria-hidden="true"></i> Create New</a>
        </div>
    </div>
    <hr>
    <div class="selectionFilterSection">
        <h3 class="selectionFilterTitle">Selection Filter</h3>
        <div class="row selectionFilterSectionBox">
            <div class="col-md-9">
                <div class="form-group">
                    <label>Customer Name</label>
                    <select [(ngModel)]="CustomerId" name="CustomerId" class="form-control"
                        style="display: inline-block; width: 50%; margin-left: 30px;">
                        <option value="">Select</option>
                        <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3 text-right SFBtnSection">
                <button type="submit" class="btn btn-primary" (click)="CustomerWorklist()">Search</button>
                <button type="reset" class="btn btn-danger clearBtn" (click)="Clear()">Clear</button>
            </div>
        </div>
    </div>
    <div class="listSection">
        <h3 class="selectionFilterTitle">List</h3>
        <div class="row listSectionBox">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">EW ID</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Bank IM Name</th>
                        <th scope="col">Creation Date</th>
                        <th scope="col">Edit</th>
                        <th scope="col">View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of CustomerWorkGrid">
                        <td>{{record.Id}}</td>
                        <td>{{record.CustomerId}}</td>
                        <td>{{record.BankIM}}</td>
                        <td>{{record.WhenEntered}}</td>
                        <td>
                            <button class="action-btn edit-btn"
                                [routerLink]="['../createCustomerWork', record.Id]">Edit</button>
                        </td>
                        <td><button class="action-btn view-btn"
                                (click)="GetCustomerWorkDetailView(record.Id)">View</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<div class="modal fade" id="customer-work-view" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            Engagement Work View
                            <span class="modalBtn">
                                <button type="button" class="close" style="margin: 5px 0px 5px;" data-dismiss="modal"
                                    aria-label="Close">
                                    <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid ">
                    <div class="Section1">
                        <div class="row pdfData section">
                            <div class="col-md-12">
                                <h4 class="reviewContent">Customer Name -
                                    <span>{{CustomerWorkSetupModel.CustomerId}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Bank IM Name - <span>{{CustomerWorkSetupModel.BankIM}}</span>
                                </h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">ATP Signed - <span>{{CustomerWorkSetupModel.ATPSigned}}</span>
                                </h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Comm Agreed -
                                    <span>{{CustomerWorkSetupModel.CommAgreed}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Paid By Customer -
                                    <span>{{CustomerWorkSetupModel.PaidByCustomer}}</span>
                                </h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">PO Received -
                                    <span>{{CustomerWorkSetupModel.POReceived}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Invoiced - <span>{{CustomerWorkSetupModel.Invoiced}}</span>
                                </h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Payment Received -
                                    <span>{{CustomerWorkSetupModel.PaymentReceived}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Start Deployment -
                                    <span>{{CustomerWorkSetupModel.StartDeployment}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Comments -
                                    <span>{{CustomerWorkSetupModel.CheckListComment}}</span></h4>
                            </div>
                            <div class="col-md-12 reviewContainer">
                                <table class="table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 20%;">Date</th>
                                            <th scope="col">Attachment</th>
                                            <th scope="col">Work Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of CustomerDetailsList">
                                            <td>{{data.Date}}</td>
                                            <td><a target="_blank" href="{{data.Attachment}}"><i class="fa fa-lg fa-eye" aria-hidden="true"></i> View</a></td>
                                            <td>{{data.WorkComment}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>