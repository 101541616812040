<div class="document-container container-fluid">
    <h1 class="document-title text-center">Omni Channel Wrapper Code -
        <span class="language-name">Android</span>
    </h1>
    <div class="row document-content-container">
        <div class="col-md-2">
            <div class="document-index">
                <ol>
                    <li>
                        <a>Overview</a>
                    </li>
                    <li>
                        <a>Pre-requisities</a>
                    </li>
                    <li>
                        <a routerLink="../wrapperAndroidDetail" fragment="stepsImplementation">Steps of Implementation</a>
                        <ul>
                            <li>
                                <a routerLink="../wrapperAndroidDetail" fragment="newPayment">newPayment</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperAndroidDetail" fragment="paymentEnquiry">paymentEnquiry</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperAndroidDetail" fragment="paymentCancellation">paymentCancellation</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperAndroidDetail" fragment="keystore">KeyStoreData</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperAndroidDetail" fragment="callApi">CallingAPI</a>
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        <div class="col-md-10 document-description-container">
            <section class="document-section overview-container">
                <h2 class="document-section-title">1. Overview</h2>
                <p>
                    Wrapper code has three functionalities which are submit payment, payment status enquiry and Order cancel and refund the payment.
                    This document describes the steps to be followed to include the Wrapper code in merchant website implementation.
                </p>
            </section>
            <section class="document-section overview-container">
                <h2 class="document-section-title">2. Pre-requisities</h2>
                <p>
                    Install packages given below inside merchant’s Website code:
                </p>
                <div class="pre-requisit-list-container">
                    <ul>
                        <li>Generate the public and private keys in .cerand .bks format respectively.</li>
                        <li>
                            Add below dependancy in gradle .module for encryption and decryption
                            <br> - implementation 'com.nimbusds:oauth2-oidc-sdk:9.8.1'
                        </li>
                        <li>
                            Use inherited methods from above library for encryption and decryption.
                        </li>
                        <li>
                            Add below dependancy for network calls (API call)
                            <br> implementation 'com.squareup.okhttp3:okhttp:4.7.2'
                        </li>
                        <li>
                            Add dependency for serialization and conversion or request and response.
                        </li>
                    </ul>
                    <p>implementation 'com.fasterxml.jackson.core:jackson-core:2.10.1'</p>
                    <p>implementation 'com.fasterxml.jackson.core:jackson-annotations:2.10.1'</p>
                    <p>implementation 'com.fasterxml.jackson.core:jackson-databind:2.10.1'</p>
                </div>
            </section>
            <section class="document-section overview-container" id="stepsImplementation">
                <h2 class="document-section-title">3. Steps of Implementation</h2>
                <p>
                    After installation of the packages, one needs to add methods of wrapper code into merchant’s website code. Merchant just
                    needs to copy and paste the methods into his coding files.
                </p>
                <p>
                    These logical methods are related to API call, encryption, decryption and Payment gateway functionality. Below are the details
                    of every method along with the method call.
                </p>
                <div class="method-detail" id="newPayment">
                    <app-wrapper-newpayment></app-wrapper-newpayment>
                </div>
                <div class="method-detail" id="paymentEnquiry">
                    <app-wrapper-paymentenquiry></app-wrapper-paymentenquiry>
                </div>
                <div class="method-detail" id="paymentCancellation">
                    <app-wrapper-paymentcancel></app-wrapper-paymentcancel>
                </div>
                <div class="method-detail" id="keystore">
                    <h3 class="method-title">keyStoreData</h3>
                    <p>This method returns key store and private, public keys. </p>
                    <p>These keys used to sign and encrypt the requested payload and decrypt, verify the encrypted response
                        from the Bank. </p>
                    <p>Need to mention proper keys location inside this method then only payment gateway functionalities will
                        work because encryption and decryption is dependent on these keys.</p>
                </div>
                <div class="method-detail" id="callApi">
                    <h3 class="method-title">CallingAPI</h3>
                    <p>This method has API calling code which helps to call Bank’s API.</p>
                </div>
            </section>
        </div>
    </div>
</div>