<div class="page-container container-fluid">
    <h2 class="page-title">User List</h2>
    <div class="form-submit">
        <a [routerLink]="'../createcustomer'" class="btn btn-primary">Create New</a>
    </div>
    <div class="page-content">
        <!-- Selection Filter Section Started -->
        <form>
            <div class="form-section">
                <h3 class="form-section-title">Selection Filters</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Company Name</label>
                                <select [(ngModel)]="UserListFilterModel.CompanyID" class="form-control"
                                    name="ddlCompanyID">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>User Name</label>
                                <input [(ngModel)]="UserListFilterModel.UserName" name="txtUserName" type="text"
                                    class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>User Mobile</label>
                                <input [(ngModel)]="UserListFilterModel.MobileNumber" name="MobileNumber"
                                    type="text" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <input type="submit" (click)="UserList()" class="btn btn-primary" value="Search" /> | 
                        <input type="reset" (click)="Clear()" class="btn btn-danger" value="Clear" />
                    </div>
                </div>
            </div>
        </form>
        <!-- Selection Filter Section Ended -->
        <!-- List Section Started -->
        <div class="table-section">
            <div class="table-section-content">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-striped">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Mobile</th>
                            <th>User Status</th>
                            <th>Edit</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of UserGrid">
                            <td>{{ record.CompanyName }}</td>
                            <td>{{ record.UserName }}</td>
                            <td>{{ record.UserEmail }}</td>
                            <td>{{ record.UserMobile }}</td>
                            <td>
                                <span *ngIf="record.UserStatus == 'True'">Active</span>
                                <span *ngIf="record.UserStatus == 'False'">Inactive</span>
                            </td>
                            <td>
                                <button [routerLink]="['../createcustomer', record.UserID, 'edit']"
                                    class="action-btn edit-btn">Edit</button>
                            </td>
                            <td>
                                <button [routerLink]="['../createcustomer', record.UserID, 'view']"
                                    class="action-btn view-btn">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="loaderActive == true" class="list-loader">
                <img src="../../assets/images/loader.gif" alt=""> Loading...
            </div>
        </div>
        <!-- List Section Ended -->

    </div>
</div>