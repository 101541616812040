<div class="container-fluid">
    <div class="row pageHeader">
        <div class="col-xs-12" style="padding: 0px;">
            <h3 class="pageTitle">Role Assignment</h3>
        </div>
    </div>
    <hr>
     <div class="selectionFilterSection">
        <h3 class="selectionFilterTitle">Selection Filter</h3>
        <div class="row selectionFilterSectionBox">
            
             <div class="col-md-4 ">
                <div class="form-group">
                    <label>Role Name</label>
                <select [(ngModel)]="RoleID" name="RoleID" (change)="RoleChange()" class="form-control">
                    <option value="">Select</option>
                    <option *ngFor="let record of RoleDD" value="{{record.ID}}">{{record.Role}}</option>
                </select>
                </div>
             </div>
            <div class="col-md-4 col-md-offset-2">
                <label>Menu</label>
                <select (change)="GetLevelTwoMenuDD()" [(ngModel)]="LevelOneMenuID" name="LevelOneMenuID" class="form-control">
                    <option value="">select</option>
                    <option *ngFor="let record of LevelOneMenuDD" value="{{record.ID}}">{{record.Menu}}</option>
                </select>
            </div> 
        </div> 
    </div>
  <div class="listSection">
        <h3 class="selectionFilterTitle" *ngIf="LevelTwoMenuList != undefined">List</h3>
        <div class="row listSectionBox" *ngIf="LevelTwoMenuList != undefined">
            <table class="table table-bordered" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th scope="col">Page Name</th>
                        <th scope="col">Select Menus</th>                    
                    </tr>
                </thead>
                  <tbody>
                    <tr *ngFor="let record of LevelTwoMenuList">
                        <td>{{record.Menu}}</td>
                        <td>
                            <input [checked]="record.IsActive == 1" (change)="AssignMenuToRole(record.ID, $event)" type="checkbox" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="col-md-12 text-right" style="margin-bottom: 5px;">
                <button class="btn"  style ="background-color:#1457d1ed ;" (click)="Submit()" class="btn btn-primary pull-right" >Submit</button>
            </div>
        </div>
    </div>      
</div>






<!-- <div class="container-fluid">
    <div class="header" style="margin-top: 10px;">
        <div class="row" style="margin: 5px 0px 0px 5px;">
            <div class="col-md-12" style="font-size: 40px;">
                <h3 style="margin-top: 0px; margin-left: -20px; font-size: 34px;">Role Assignment</h3>
            </div>
        </div>
    </div>

    <div class="selectionFilter" >
        <h5>Selection Filter</h5>
    </div>
    <div class="row"
        style="margin-top: 0px; border: 1px solid; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px;  width: 100%; margin-left: 0px;">
        <div class="Selectionfields">
            <div class="col-md-6">
                <label>Role Name</label>
                <select [(ngModel)]="RoleID" name="RoleID" (change)="RoleChange()">
                    <option value="">Select</option>
                    <option *ngFor="let record of RoleDD" value="{{record.ID}}">{{record.Role}}</option>
                </select>
            </div>
            <div class="col-md-6">
                <label>Menu</label>
                <select (change)="GetLevelTwoMenuDD()" [(ngModel)]="LevelOneMenuID" name="LevelOneMenuID">
                    <option value="">select</option>
                    <option *ngFor="let record of LevelOneMenuDD" value="{{record.ID}}">{{record.Menu}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="selectionFilter" *ngIf="LevelTwoMenuList != undefined">
        <h5>List</h5>
    </div>
    <div class="row" *ngIf="LevelTwoMenuList != undefined"
        style="margin-top: 0px; border: 1px solid; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px; width: 100%; margin-left: 0px;">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Page Name</th>
                    <th scope="col">Select Menus</th>
            </thead>
            <tbody>
                <tr *ngFor="let record of LevelTwoMenuList">
                    <td>{{record.Menu}}</td>
                    <td>
                        <input [checked]="record.IsActive == 1" (change)="AssignMenuToRole(record.ID, $event)" type="checkbox" style="width: 10%;" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="col-md-12 text-right" style="margin-bottom: 5px;">
            <button class="btn btn-primary" (click)="Submit()" class="btn btn-primary pull-right" >Submit</button>
        </div>
    </div>
</div> -->


<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>