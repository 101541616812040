<div class="document-container container-fluid">
    <h1 class="document-title text-center">Omni Channel Wrapper Code -
        <span class="language-name">PHP</span>
    </h1>
    <div class="row document-content-container">
        <div class="col-md-2">
            <div class="document-index">
                <ol>
                    <li>
                        <a>Overview</a>
                    </li>
                    <li>
                        <a>Pre-requisities</a>
                    </li>
                    <li>
                        <a routerLink="../wrapperPhpDetail" fragment="stepsImplementation">Steps of Implementation</a>
                        <ul>
                            <li>
                                <a routerLink="../wrapperPhpDetail" fragment="wrapperFile">Wrapper.php</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperPhpDetail" fragment="paymentPageFile">PaymentPage.php</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperPhpDetail" fragment="paymentEnquiryFile">PaymentEnquiry.php</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperPhpDetail" fragment="cancelRefundFile">CancelRefund.php</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperPhpDetail" fragment="callbackAPI">Callback Notification API</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a routerLink="../wrapperPhpDetail" fragment="codeDetails">Code Details</a>
                    </li>
                </ol>
            </div>
        </div>
        <div class="col-md-10 document-description-container">
            <section class="document-section overview-container">
                <h2 class="document-section-title">1. Overview</h2>
                <div class="section-tab">
                    <p>
                        Wrapper code has three functionalities which are submit payment, payment status enquiry and
                        Order cancel and refund the payment.
                        One needs to follow below points to implement these functionalities on merchants side.
                    </p>
                </div>
            </section>
            <section class="document-section overview-container">
                <h2 class="document-section-title">2. Pre-requisities</h2>
                <div class="section-tab">
                    <p>
                        Install packages given below inside merchant’s Website code:
                    </p>
                    <div class="pre-requisit-list-container">
                        <ul>
                            <li>"web-token/jwt-framework":"^2.2"</li>
                            <li>"web-token/jwt-key-mgmt": "^2.2"</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="document-section overview-container" id="stepsImplementation">
                <h2 class="document-section-title">3. Steps of Implementation</h2>
                <div class="section-tab">
                    <p>
                        After installation of the packages, need to add four files into the merchant’s project which are
                        related to the payment gateway
                        functionalities.
                    </p>
                    <ul>
                        <li class="method-detail" id="wrapperFile">
                            <h3 class="title-level-one">wrapper.php</h3>
                            <div>
                                <p>
                                    This file is the core file because it contains encryption, decryption, API call and
                                    log maintain method functionalities.
                                </p>
                            </div>
                        </li>
                        <li class="method-detail" id="paymentPageFile">
                            <h3 class="title-level-one">PaymentPage.php</h3>
                            <div>
                                <p>
                                    This file use is to submit the payment for that it required some data from merchant
                                    which merchant need to pass as argument
                                    in the method mentioned in this file and method name is newPayment Method –
                                    newPayment(txnRef, system,
                                    payment, merchant, customer, order, other)
                                </p>
                                <p>Argument details are mentioned below.</p>
                                <app-wrapper-newpayment></app-wrapper-newpayment>
                            </div>
                        </li>
                        <li class="method-detail" id="paymentEnquiryFile">
                            <h3 class="title-level-one">PaymentEnquiry.php</h3>
                            <div>
                                <p>
                                    This file use is to do the payment enquiry and to do payment enquiry merchant need
                                    to pass some details as argument to the
                                    method mentioned in this file and method name is paymentEnquiry Method -
                                    paymentEnquiry(txnRef, merchant).
                                </p>
                                <p>Argument details are mentioned below.</p>
                                <app-wrapper-paymentenquiry></app-wrapper-paymentenquiry>
                            </div>
                        </li>
                        <li class="method-detail" id="cancelRefundFile">
                            <h3 class="title-level-one">CancelRefund.php</h3>
                            <div>
                                <p>
                                    This file use is to do the payment cancel refund and to do payment cancel merchant
                                    need to pass some details as argument
                                    to the method mentioned in this file and method name is paymentCancellation Method -
                                    paymentCancellation(refundNotificationURL,
                                    txnRef, rfdRef, amount, currency, merId).
                                </p>
                                <app-wrapper-paymentcancel></app-wrapper-paymentcancel>
                            </div>
                        </li>
                        <li class="method-detail" id="callbackAPI">
                            <h3 class="title-level-one">Callback Notification API</h3>
                            <div>
                                <div class="description-container">
                                    <p>The Bank triggers this API and sends payment status back.</p>
                                    <p>Merchant needs to include below method in its Website code. Bank will call this
                                        code and send the payment
                                        status. Merchant can update its End User or store the status received as per its
                                        implementation.</p>
                                    <p>
                                        The API has two arguments named as paymentStatus and txnRefNo. Both of these
                                        arguments are type string.
                                        <br> In case of a successful payment set paymentStatus value to success.
                                        <br> In case of payment failure set paymentStatus value to fail.
                                    </p>
                                    <p>It gives an acknowledgement(response) in JSON format as mentioned below.</p>
                                </div>
                                <div class="code-view">
                                    <pre>
                                    <code [innerHTML]="apiResponseCode"></code>
                                  </pre>
                                </div>
                                <div class="description-container api-call-example">
                                    <p>Below is the API link:
                                        <br>http://php.copiacs.com/CallbackNotificationAPI.php?txnRef=txnRefNumber&paymentStatus=PaymentStatus
                                    </p>
                                    <p>
                                        Example of the link with values:
                                        <br> In case of success:
                                        http://php.copiacs.com/CallbackNotificationAPI.php?txnRef=234234&paymentStatus="success"
                                        <br> In case of failure:
                                        http://php.copiacs.com/CallbackNotificationAPI.php?txnRef=234234&paymentStatus="fail"
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section class="document-section overview-container" id="codeDetails">
                <h2 class="document-section-title">4. Code Details</h2>
                <div class="section-tab">
                    <p>The code includes five files mentioned below:</p>
                    <ul>
                        <li>Wrapper.Php</li>
                        <li>PaymentPage.php</li>
                        <li>PaymentEnquiry.php</li>
                        <li>CancelRefund.php</li>
                        <li>CallbackNotificationAPI.php</li>
                    </ul>
                    <div class="code-detail-container">
                        <h3 class="title-level-one">File Details</h3>
                        <ul>
                            <li class="method-detail">
                                <h3 class="title-level-two">Wrapper.php</h3>
                                <p>
                                    This file contains some important methods which help to do Payload encryption,
                                    calling API’s, Response decryption, Maintain
                                    log data, payment submit, payment enquiry and payment cancel refund.
                                </p>
                                <ol>
                                    <li class="method-detail-sub-container">
                                        <h5>
                                            <b>EncryptPayload(string $requestPayloadJSON): string</b>
                                        </h5>
                                        <p>This method is used to encrypt the payload.</p>
                                        <button title='copy code' class="copy-btn"
                                            (click)="copyBtn(encryptPayloadMethodCode)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button>
                                        <div class="code-view">
                                            <pre>
                                    <code [innerHTML]="encryptPayloadMethodCode"></code>
                                  </pre>
                                        </div>
                                    </li>
                                    <li class="method-detail-sub-container">
                                        <h5>
                                            <b>callingAPI(string $resoucePath,string $requestMsg): string</b>
                                        </h5>
                                        <p>This method is used to call API’s</p>
                                        <button title='copy code' class="copy-btn" (click)="copyBtn(callingApiCode)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button>
                                        <div class="code-view">
                                            <pre>
                                      <code [innerHTML]="callingApiCode"></code>
                                    </pre>
                                        </div>
                                    </li>
                                    <li class="method-detail-sub-container">
                                        <h5>
                                            <b>DecryptResponse(string $EncryptedResponse)</b>
                                        </h5>
                                        <p>This method decrypts the response message.</p>
                                        <button title='copy code' class="copy-btn"
                                            (click)="copyBtn(decryptResponseCode)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button>
                                        <div class="code-view">
                                            <pre>
                                        <code [innerHTML]="decryptResponseCode"></code>
                                      </pre>
                                        </div>
                                    </li>
                                    <li class="method-detail-sub-container">
                                        <h5>
                                            <b>newPayment($txnRef, $system, $payment, $merchant, $customer, $order,
                                                $other)</b>
                                        </h5>
                                        <p>This method is used to perform new payment./p>
                                            <button title='copy code' class="copy-btn"
                                                (click)="copyBtn(newPaymentCode)">
                                                <i class="fa fa-clone" aria-hidden="true"></i>
                                            </button>
                                        <div class="code-view">
                                            <pre>
                                          <code [innerHTML]="newPaymentCode"></code>
                                        </pre>
                                        </div>
                                    </li>
                                    <li class="method-detail-sub-container">
                                        <h5>
                                            <b>paymentEnquiry($txnRef, $merchant)</b>
                                        </h5>
                                        <p>This method is used to enquire the status of the payment.</p>
                                        <button title='copy code' class="copy-btn"
                                            (click)="copyBtn(paymentEnquiryCode)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button>
                                        <div class="code-view">
                                            <pre>
                                            <code [innerHTML]="paymentEnquiryCode"></code>
                                          </pre>
                                        </div>
                                    </li>
                                    <li class="method-detail-sub-container">
                                        <h5>
                                            <b>paymentCancellation($refundNotificationURL, $txnRef, $rfdRef, $amount,
                                                $currency, $merId)</b>
                                        </h5>
                                        <p>This method is used to cancel the order and refund the payment.</p>
                                        <button title='copy code' class="copy-btn" (click)="copyBtn(cancelPaymentCode)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button>
                                        <div class="code-view">
                                            <pre>
                                              <code [innerHTML]="cancelPaymentCode"></code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li class="method-detail-sub-container">
                                        <h5>
                                            <b>logMaintainFunction(string $log_msg)</b>
                                        </h5>
                                        <p>This method maintains the log of each transaction with time stamp.</p>
                                        <button title='copy code' class="copy-btn" (click)="copyBtn(logMaintainCode)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button>
                                        <div class="code-view">
                                            <pre>
                                                <code [innerHTML]="logMaintainCode"></code>
                                              </pre>
                                        </div>
                                    </li>
                                </ol>
                            </li>
                            <li class="method-detail">
                                <h3 class="title-level-two">PaymentPage.php</h3>
                                <p>This file merchant has to call on click of Payment button. It calls newPayment method
                                    inside it and returns
                                    the response.
                                </p>
                            </li>
                            <li class="method-detail">
                                <h3 class="title-level-two">PaymentEnquiry.php</h3>
                                <p>This file merchant has to call on click of Payment Enquiry button. It calls
                                    paymentEnquiry method inside
                                    it and returns the response.</p>
                            </li>
                            <li class="method-detail">
                                <h3 class="title-level-two">CancelRefund.php</h3>
                                <p>This file merchant has to call on click of Cancel Payment button. It calls
                                    paymentCancellation method inside
                                    it and returns the response.</p>
                            </li>
                            <li class="method-detail">
                                <h3 class="title-level-two">CallbackNotificationAPI.php</h3>
                                <p>This is a callback notification API file. This file contains callback notification
                                    API code.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>