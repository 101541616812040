<h3 class="title-level-one">newPayment</h3>
<div>
    <div class="description-container">
        <p>This method is used to submit the payment and it has to be calledfrom merchant’s website code which gets called on
            click of payment button. </p>
        <p>This is a parameterized method which requires some arguments to be passed in it.</p>
        <p>Below are the method details with the Method call. The arguments need to be of specific types as mentioned.</p>
    </div>
    <div class="syntax-container">
        <h4>Method Syntax
            <i class="fa-regular fa-colon"></i>
        </h4>
        <div class="section-tab">
            <p>newPayment(txnRef, system, payment, merchant, customer, order, other)</p>
        </div>
    </div>
    <div class="arguments-detail-container">
        <h4>Arguments Detail</h4>
        <div class="section-tab">
            <div class="arguments-detail">
                <h5 class="argument-name">txnRef</h5>
                <ul>
                    <li>This is transaction reference number.</li>
                    <li>It is string data type.</li>
                    <li>This should be unique.</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">txnRef : string (up to 25 chars)</span>
                                <div class="property-description">
                                    <p>Unique transaction ID/Reference code assigned by merchant</p>
                                    <p>No duplicate Transaction Reference is allowed</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">system </h5>
                <ul>
                    <li>Data type of this property is JSON object.</li>
                    <li>
                        This property has four properties named as redirectSuccessUrl, redirectFailUrl, redirectCancelUrl, notificationUrl.
                    </li>
                    <li>
                        Format of this property
                        <div class="code-view">
                            <pre>
                                      <code [innerHTML]="systemObject"></code>
                                    </pre>
                        </div>
                    </li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">redirectSuccessUrl : string range (up to 255 chars)</span>
                                <div class="property-description">
                                    <p>Define URL endpoint for redirecting customer back from Payment Gateway to Merchant website
                                        after completing a successful payment</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">redirectFailUrl: string range (up to 255 chars)</span>
                                <div class="property-description">
                                    <p>Define URL endpoint for redirecting customer back from Payment Gateway to Merchant website
                                        after any fail scenario is taken place</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">redirectCancelUrl: string range (up to 255 chars)</span>
                                <div class="property-description">
                                    <p>Define URL endpoint for redirecting customer back from Payment Gateway to Merchant website
                                        after customer cancels the payment</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">notificationUrl: string range (up to 255 chars)</span>
                                <div class="property-description">
                                    <p>Define URL endpoint for receiving payment result notification (server-to-server) from
                                        HSBC after payment completed</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">payment </h5>
                <ul>
                    <li>Data type of this property is JSON object.</li>
                    <li>
                        This property has few properties named as country, currency, amount, payment_option expiry, offers.
                    </li>
                    <li>
                        Format of this property
                        <div class="code-view">
                            <pre>
                                      <code [innerHTML]="paymentObject"></code>
                                    </pre>
                        </div>
                    </li>
                    <div class="property-details">
                        <div class="individual-property">
                            <span class="property-name">country: string enum: [IN] range: (up to 2 chars)</span>
                            <div class="property-description">
                                <p>Country Code (Format: ISO alpha-2)</p>
                                <table class="table table-bordered property-table">
                                    <thead>
                                        <tr>
                                            <th>Possible Value</th>
                                            <th>Definition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>IN</td>
                                            <td>India</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="individual-property">
                            <span class="property-name">currency: string enum [INR] range: (up to 3 chars) </span>
                            <div class="property-description">
                                <p>Payment Currency (Format: ISO 4217 Alpha)</p>
                                <table class="table table-bordered property-table">
                                    <thead>
                                        <tr>
                                            <th>Possible Value</th>
                                            <th>Definition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>INR</td>
                                            <td>Indian Rupee</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="individual-property">
                            <span class="property-name">amount: integer range 1 ≤ x ≤ 999999999999999 </span>
                            <div class="property-description">
                                <p>Payment Amount in 2 decimal places regardless whether the currency has decimal places or
                                    not</p>
                                <p>NOTICE: Do not use comma or dot. For example: Input 10000 instead of 100.00</p>
                            </div>
                        </div>
                        <div class="individual-property">
                            <span class="property-name">payment_option: string range (up to 64 chars) </span>
                            <div class="property-description">
                                <p>To restrict customer payment methods shown in the secured online Payment Page</p>
                                <table class="table table-bordered property-table">
                                    <thead>
                                        <tr>
                                            <th>Definition</th>
                                            <th>Possible Value of Payment Gateway #1</th>
                                            <th>Possible Value of Payment Gateway #2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>All Payment Options</td>
                                            <td>all</td>
                                            <td>all</td>
                                        </tr>
                                        <tr>
                                            <td>Credit Card</td>
                                            <td>creditcard</td>
                                            <td>card</td>
                                        </tr>
                                        <tr>
                                            <td>Debit Card</td>
                                            <td>debitcard</td>
                                            <td>card</td>
                                        </tr>
                                        <tr>
                                            <td>Net Banking</td>
                                            <td>netbanking</td>
                                            <td>netbanking</td>
                                        </tr>
                                        <tr>
                                            <td>Equated Monthly Installment</td>
                                            <td>emi</td>
                                            <td>emi</td>
                                        </tr>
                                        <tr>
                                            <td>Cash Card &amp; eWallet</td>
                                            <td>wallet</td>
                                            <td>wallet</td>
                                        </tr>
                                        <tr>
                                            <td>UPI &amp; GPay</td>
                                            <td>upi</td>
                                            <td>upi</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="individual-property">
                            <span class="property-name">expiry: string range (up to 25 chars) </span>
                            <div class="property-description">
                                <p>Local time. A GMT+5:30 timezone information is appended to the end of the timestamp to indicate
                                    this time is a India local time. Format: yyyy-MM-dd'T'HH:mm:ss±hh:mm</p>
                            </div>
                        </div>
                        <div class="individual-property">
                            <span class="property-name">offers: string[] range (up to 50 chars)</span>
                            <div class="property-description">
                                <p>The offer key(s) that bind offer(s) created in Merchant Portal</p>
                                <p>Gateway 1 - Only accept single key.</p>
                                <p>Gateway 2 - Multiple keys are accepted.</p>
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">merchant </h5>
                <ul>
                    <li>Data type of this property is string.</li>
                    <li>This property is for merchant id.</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">merId: string range: (up to 50 chars)</span>
                                <div class="property-description">
                                    <p>Distributed by HSBC for identifying each merchant's identity</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">customer </h5>
                <ul>
                    <li>Data type of this property is JSON object</li>
                    <li>
                        This property has four properties which are customer_firstname, customer_lastname, customer_email, customer_phone.
                    </li>
                    <li>
                        Format of this property
                        <div class="code-view">
                            <pre>
                                       <code [innerHTML]="customerObject"></code>
                                    </pre>
                        </div>
                    </li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">customer_firstname: string </span>
                                <div class="property-description">
                                    <p>Customer's First Name</p>
                                    <p>Gateway 1 - String range: (up to 60 chars)</p>
                                    <p>Gateway 2 - String range: (up to 20 chars)</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">customer_lastname: string range: (up to 20 chars) </span>
                                <div class="property-description">
                                    <p>Customer's Last Name</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">customer_email: string range: (up to 50 chars) </span>
                                <div class="property-description">
                                    <p>Customer's Email</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">customer_phone: string range: (up to 50 chars) </span>
                                <div class="property-description">
                                    <p>Customer's Phone</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">order </h5>
                <ul>
                    <li>Data type of this property is JSON object.</li>
                    <li>This property has two properties named as description and descriptions.</li>
                    <li>
                        Format of this property
                        <div class="code-view">
                            <pre>
                                      <code [innerHTML]="orderObject"></code>
                                    </pre>
                        </div>
                    </li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">description: string range: (up to 100 chars) </span>
                                <div class="property-description">
                                    <p>A brief Order Description that will be displayed in the Payment Page</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">descriptions: range (up to 20 objects) </span>
                                <div class="property-description">
                                    <p>Array of Product Descriptions in the basket</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">product_name: string range (up to 200 chars) </span>
                                <div class="property-description">
                                    <p>Product Item Name / Description</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">product_id: string range (up to 50 chars) </span>
                                <div class="property-description">
                                    <p>Product Numner / ID</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">unitAmt: integer range 1 ≤ x ≤ 999999999999999 </span>
                                <div class="property-description">
                                    <p>Payment Amount in 2 decimal places regardless whether the currency has decimal places
                                        or not
                                    </p>
                                    <p>NOTICE: Do not use comma or dot. For example: Input 10000 instead of 100.00</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">unit: integer range 1 ≤ x ≤ 99999999 </span>
                                <div class="property-description">
                                    <p>No. of Unit</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">subAmt: integer range 1 ≤ x ≤ 999999999999999 </span>
                                <div class="property-description">
                                    <p>Payment Amount in 2 decimal places regardless whether the currency has decimal places
                                        or not
                                    </p>
                                    <p>NOTICE: Do not use comma or dot. For example: Input 10000 instead of 100.00</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">other </h5>
                <ul>
                    <li>Data type of this property is JSON object.</li>
                    <li>
                        Format of this property
                        <div class="code-view">
                            <pre>
                                      <code [innerHTML]="otherObject"></code>
                                    </pre>
                        </div>
                    </li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">udfs: range (up to 20 objects) </span>
                                <div class="property-description">
                                    <p>Array of User Defined Fields</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">definition: string range (up to 1024 chars)</span>
                                <div class="property-description">
                                    <p>Merchant Defined Definition</p>
                                </div>
                            </div>
                            <div class="individual-property">
                                <span class="property-name">value: string range (up to 2048 chars) </span>
                                <div class="property-description">
                                    <p>Merchant Defined Value</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="description-container">
        <p>
            The method uses these arguments and set the payload which is required by the Payment Page Redirect API of the Bank.
        </p>
        <p>
            This method returnsa specific code, based on merchant id. If merchant uses gateway 1 then it returns code which help to redirect
            to the PayU page and if merchant uses gateway 2 then it returns code which help to redirect Razorpay page.
        </p>
    </div>
</div>