import { Component, OnInit } from '@angular/core';
import { RoleListFilterModel, RoleMasterSetupModel } from '../Model/common.model';
import { CommonService } from '../services/common.service';
import { Subject } from '../../../node_modules/rxjs';

@Component({
  selector: 'app-role-master1',
  templateUrl: './role-master1.component.html',
  styleUrls: ['./role-master1.component.css']
})
export class RoleMaster1Component implements OnInit {

  RoleDD: any;
  loaderActive: boolean = false;
  RoleMasterSetupModel: RoleMasterSetupModel;
  RoleMasterGrid: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  RoleListFilterModel: RoleListFilterModel;
  listSectionShow: any;
  constructor(private Service: CommonService) { 
    this.RoleMasterSetupModel = new RoleMasterSetupModel();
    this.RoleListFilterModel = new RoleListFilterModel();
  }

  ngOnInit(): void {
    this.GetRoleDD();
  }
  
  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void{
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $('#succeess-message-box').appendTo("body").modal('show');
  }
  HideShow(){
    $("#errorclose").show();
    $("#close").hide();
  }
  Submit(): void {
    debugger;
    if (this.RoleMasterSetupModel.RoleName == "" || this.RoleMasterSetupModel.RoleName == null || this.RoleMasterSetupModel.RoleName == undefined) {
      this.HideShow();
      this.popupMessage("Enter Role Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    } 
    else if (this.RoleMasterSetupModel.Description == "" || this.RoleMasterSetupModel.Description == null || this.RoleMasterSetupModel.Description == undefined) {
      this.HideShow();
      this.popupMessage("Enter Role Description", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.RoleMasterSetupModel.IsActive == "" || this.RoleMasterSetupModel.IsActive == null || this.RoleMasterSetupModel.IsActive == undefined) {
      this.HideShow();
      this.popupMessage("Select Status", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.Service.RoleMasterSetup(this.RoleMasterSetupModel).subscribe((data) => {
        debugger;
        if(data == "0"){
          this.HideShow();
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
        }
        else {
          $("#errorclose").hide();
          $("#close").show();
          this.popupMessage("Data updated successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          this.RoleMasterSetupModel.RoleName = "";
          this.RoleMasterSetupModel.Description = "";
          this.RoleMasterSetupModel.IsActive = "";
          this.RoleMasterList();
        }
      });
    }
  }

  RoleMasterList() {
    this.listSectionShow = "show";
    this.loaderActive = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      // paging: false,
      retrieve: true,
      searching: false
    };
    debugger;
    this.Service.RoleMasterList(this.RoleListFilterModel).subscribe((res) => {
      this.RoleMasterGrid = res;
      this.dtTrigger.next();
      this.loaderActive = false;
    })
  }

  GetRoleMasterDetail(RoleMasterDetail: any, status: any) {
    debugger;
    $('#roleSetup').appendTo("body").modal('show');
    this.RoleMasterSetupModel = RoleMasterDetail;
    if(status == 'view'){
      $("#RoleNameD").attr('disabled', 'disabled');
      $("#RoleDescD").attr('disabled', 'disabled');
      $("#StatusD").attr('disabled', 'disabled');
      $("#SubmitBtn").hide();
    }
    else{
      $("#RoleNameD").removeAttr('disabled');
      $("#RoleDescD").removeAttr('disabled');
      $("#StatusD").removeAttr('disabled');
      $("#SubmitBtn").show();
    }
    
  }

  NewRoleMaster() {
    
    $('#roleSetup').appendTo("body").modal('show');
    this.RoleMasterSetupModel = new RoleMasterSetupModel();
  }

  GetRoleDD() {
    this.Service.RoleDD().subscribe((res) => {
      this.RoleDD = res;
    })
  }

  Clear() {
    this.listSectionShow = "hide";
    this.RoleListFilterModel = new RoleListFilterModel();
  }

}
