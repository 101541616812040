<div class="page-container container-fluid">
    <h2 class="page-title">SysBrij Code Base Documents</h2>
    <div class="page-content">
        <!-- Selection Filter Section Started -->

        <h3>Access Zoho Books</h3>
        <p class="text">Welcome aboard! We’re glad that you’ve chosen Zoho Books for your accounting. Experience the
            power of smart accounting software which comes with the latest features you will need for all your
            accounting needs. Let’s get you rolling!
        </p>

        <ul>
            <h5>Have an account already? Log in to Zoho Books or create your account by signing up for Zoho Books.</h5>
            <li class="bullet">Sign up for Zoho Books</li>
            <li class="bullet">Log in to Zoho Books</li>
            <li class="bullet">Browser Support</li>
            <li class="bullet">Mobile Apps</li>
        </ul>

        <h3>What is Amazon Aurora?</h3>
        <p class="text">Amazon Aurora (Aurora) is a fully managed relational database engine that's compatible with
            MySQL and PostgreSQL. You already know how MySQL and PostgreSQL combine the speed and reliability of
            high-end commercial databases with the simplicity and cost-effectiveness of open-source databases. The code,
            tools, and applications you use today with your existing MySQL and PostgreSQL databases can be used with
            Aurora. With some workloads, Aurora can deliver up to five times the throughput of MySQL and up to three
            times the throughput of PostgreSQL without requiring changes to most of your existing applications.

            Aurora includes a high-performance storage subsystem. Its MySQL- and PostgreSQL-compatible database engines
            are customized to take advantage of that fast distributed storage. The underlying storage grows
            automatically as needed. An Aurora cluster volume can grow to a maximum size of 128 tebibytes (TiB). Aurora
            also automates and standardizes database clustering and replication, which are typically among the most
            challenging aspects of database configuration and administration.

            Aurora is part of the managed database service Amazon Relational Database Service (Amazon RDS). Amazon RDS
            is a web service that makes it easier to set up, operate, and scale a relational database in the cloud. If
            you are not already familiar with Amazon RDS, see the Amazon Relational Database Service User Guide.</p>

        <h5>The following points illustrate how Aurora relates to the standard MySQL and PostgreSQL engines available in
            Amazon RDS:</h5>

        <ul >
            <li class="bullet">You choose Aurora as the DB engine option when setting up new database servers through
                Amazon RDS.</li>
            <li class="bullet">Aurora management operations typically involve entire clusters of database servers that
                are synchronized through replication, instead of individual database instances. The automatic
                clustering, replication, and storage allocation make it simple and cost-effective to set up, operate,
                and scale your largest MySQL and PostgreSQL deployments.</li>
            <li class="bullet">Amazon RDS Custom for Oracle and Microsoft SQL Server</li>
            <li class="bullet">You can bring data from Amazon RDS for MySQL and Amazon RDS for PostgreSQL into Aurora by
                creating and restoring snapshots, or by setting up one-way replication. You can use push-button
                migration tools to convert your existing Amazon RDS for MySQL and Amazon RDS for PostgreSQL applications
                to Aurora.</li>
        </ul>
         
        <h3>AWS Management Console</h3>
        <p class="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores veritatis dolor, perferendis
            saepe quo recusandae illo ipsum praesentium vero. Eius illo sint fugiat pariatur tempore. Tempore magnam
            quia eum enim similique, sit alias molestias laboriosam, velit veniam assumenda quam dicta dolorem laborum
            sed recusandae esse, impedit nulla aspernatur neque inventore ipsa voluptate doloremque vero? Voluptatem
            nulla aliquam, odit excepturi quas ratione sapiente nam debitis consequatur, veniam delectus ad autem neque
            minus provident dolor repudiandae error voluptate voluptates modi velit! Exercitationem, quia facilis ex
            odio ad inventore repellat accusantium harum doloremque voluptate quaerat facere, assumenda voluptatibus
            laborum pariatur repellendus fugit. Odio non illum saepe adipisci, placeat sequi tempora veritatis, error
            deleniti aliquid ex ipsam, deserunt quae voluptas dolorem modi aperiam quis!</p>

        <h3>Overview of Amazon RDS</h3>
        <p class="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum placeat repellendus laborum
            delectus
            blanditiis velit, nobis dolor, ex enim error eligendi voluptatum beatae iste eum. Numquam, quaerat. Maxime
            totam aliquam laborum nisi sed neque error recusandae rerum beatae! Reiciendis repellendus qui vero fuga
            corporis error. Rem officia in cupiditate doloribus fuga corporis fugit, perspiciatis maxime, quam dolores
            possimus reprehenderit quae porro sequi ducimus! Maxime consequuntur provident nihil voluptatem, tenetur
            porro adipisci fugit at? Dignissimos numquam enim quasi consectetur neque, id quisquam animi nulla
            perferendis voluptate ut incidunt cum quod recusandae exercitationem! Quis, optio! Ad a, impedit illo
            voluptas ipsum ipsa, pariatur reiciendis reprehenderit ipsam quod officiis quaerat. Assumenda, at? Velit
            tempora temporibus eligendi eius tenetur modi quis dolores deserunt. Veniam sed est animi recusandae, earum
            laboriosam qui nostrum, deleniti rerum impedit officiis et pariatur aspernatur fugiat non fugit nobis? Ab
            modi, est repellendus eos hic alias dolorum illum id voluptatem libero aut quo necessitatibus voluptatum
            deleniti nulla dolore excepturi. Molestias, facilis officia vero tempore totam quibusdam adipisci dolorem.
            Hic cum voluptas soluta nam ipsa neque nulla omnis magnam expedita officiis consequuntur repellat beatae
            dolorum similique inventore, iure facere adipisci minus placeat delectus ullam saepe! Minima culpa amet
            labore dolore doloremque!
        </p>

        <ul>
            <h5>Topics</h5>
            <li class="bullet"> Amazon EC2 and on-premises databases</li>
            <li class="bullet">Amazon RDS and Amazon EC2</li>
            <li class="bullet">Amazon RDS Custom for Oracle and Microsoft SQL Server</li>
        </ul>

        <h3> RDS and Amazon EC2</h3>
        <p class="text">Amazon RDS is a managed database service. It's responsible for most management tasks. By
            eliminating tedious
            manual tasks, Amazon RDS frees you to focus on your application and your users. We recommend Amazon RDS over
            Amazon EC2 as your default choice for most database deployments.

            In the following table, you can find a comparison of the management models in Amazon EC2 and Amazon RDS.</p>

        <h3>Amazon RDS Custom for Oracle and Microsoft SQL Server</h3>
        <p class="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores eveniet reiciendis quasi? Odit
            libero vero
            optio eveniet ab nostrum ut eligendi provident harum laboriosam voluptate, natus voluptates animi quo
            molestiae itaque ex voluptatum. Ad amet reiciendis beatae voluptates est! Dolores esse pariatur ea a, id,
            provident, vero consectetur qui omnis ipsa magnam doloribus asperiores maxime deleniti quis itaque tenetur
            optio culpa. Ipsa deserunt excepturi corporis quos ullam est sequi facilis eos quam, aspernatur nisi
            architecto consequatur nulla mollitia asperiores odit expedita eius cumque animi deleniti sit molestias
            aliquam? Quas esse voluptates delectus fugit quaerat eveniet ratione amet iusto commodi obcaecati.</p>

        <h3>DB instances</h3>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus laboriosam dicta, quis voluptatibus
            dolores fugiat explicabo nulla dolor! Dicta deleniti qui quas magnam explicabo rem porro vel possimus nobis
            minus soluta voluptate quisquam, at nostrum quasi placeat officia doloremque quia tempore odio dolorem vero.
            Ipsum adipisci laboriosam nostrum voluptatibus? Tempora!</p>

        <h3>DB engine</h3>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex numquam et inventore sint ipsa, est veritatis
            fugit fugiat dolore, quam minima velit aperiam nemo voluptate sunt error, incidunt alias? Officiis similique
            atque commodi consequatur dicta, excepturi ullam veniam cumque eos labore culpa hic repellat, recusandae
            nemo facilis quaerat ipsam a fugiat suscipit nisi doloremque minima dolores ratione quam. Animi magni sunt
            minus debitis officiis, quia inventore esse ratione quas eveniet!</p>

        <h3>How to work with Amazon RDS</h3>
        <p>There are several ways that you can interact with Amazon RDS.

            AWS Management Console
            The AWS Management Console is a simple web-based user interface. You can manage your DB instances from the
            console with no programming required. To access the Amazon RDS console, sign in to the AWS Management
            Console
            and open the Amazon RDS console at https://console.aws.amazon.com/rds/. </p>

        <h3> Command line interface</h3>
        You can use the AWS Command Line Interface (AWS CLI) to access the Amazon RDS API interactively. To install
        the
        AWS CLI, see Installing the AWS Command Line Interface. To begin using the AWS CLI for RDS, see AWS Command
        Line
        Interface reference for Amazon RDS.

        <h3>Programming with Amazon RDS</h3>
        <p>If you are a developer, you can access the Amazon RDS programmatically. For more information, see Amazon RDS
            application programming interface (API) reference.

            For application development, we recommend that you use one of the AWS Software Development Kits (SDKs). The
            AWS
            SDKs handle low-level details such as authentication, retry logic, and error handling, so that you can focus
            on
            your application logic. AWS SDKs are available for a wide variety of languages. For more information, see
            Tools
            for Amazon web services .

            AWS also provides libraries, sample code, tutorials, and other resources to help you get started more
            easily.
            For more information, see Sample code & libraries.</p>

        <h3> How you are charged for Amazon RDS</h3>
        <p class="text">When you use Amazon RDS, you can choose to use on-demand DB instances or reserved DB instances.
            For more
            information, see DB instance billing for Amazon RDS.

            For Amazon RDS pricing information, see the Amazon RDS product page.</p>

        <p class="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus sint, natus debitis
            iusto aliquid,
            error qui labore inventore sit optio mollitia, odit est ipsum earum assumenda perferendis eos eveniet animi
            ullam. Iure, vero praesentium aperiam maxime dicta excepturi molestiae ad et quasi ut dignissimos id dolorem
            vel nihil reiciendis harum ratione dolores distinctio earum quos quia nulla numquam tenetur accusamus?
            Quibusdam sunt iste ut voluptatem, vitae nisi ex aspernatur. Voluptates cumque maiores consequatur amet
            veritatis officia, nesciunt dolor. Eos hic voluptatem eveniet consequuntur reiciendis eum ullam tenetur
            mollitia tempore?</p>

        <h3>Supported DB engines for DB instance classes</h3>
        <p class="text">The following are DB engine–specific considerations for DB instance classes:

            Microsoft SQL Server
            DB instance class support varies according to the version and edition of SQL Server. For instance class
            support
            by version and edition, see DB instance class support for Microsoft SQL Server.

            Oracle
            DB instance class support varies according to the Oracle Database version and edition. RDS for Oracle
            supports
            additional memory-optimized instance classes. These classes have names of the form
            db.r5.instance_size.tpcthreads_per_core.memratio. For the vCPU count and memory allocation for each
            optimized
            class, see Supported Oracle DB instance classes.

            In the following table, you can find details about supported Amazon RDS DB instance classes for each Amazon
            RDS
            DB engine.</p>

        <h3>AWS Management Console</h3>
        <p class="text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae obcaecati odit delectus natus
            temporibus modi
            libero iste dolorum ea soluta deleniti nobis aperiam repudiandae excepturi explicabo dolores rem quis quasi
            dolore, rerum distinctio eligendi numquam aliquam! Consequatur suscipit asperiores illo perspiciatis labore
            cumque, illum esse porro explicabo nihil maiores sapiente consequuntur iure harum nulla atque est
            consectetur
            facilis ex praesentium placeat odio. Quia cumque fuga, dolor cupiditate eveniet aliquid libero. Quisquam,
            architecto minima recusandae illum rerum quam facilis et vel iusto ducimus, cumque magni omnis natus
            veritatis
            maiores provident adipisci accusamus delectus consectetur doloremque repellat corrupti suscipit eaque?
            Facilis
            iusto nulla maxime dolorum, voluptates assumenda velit similique in ipsam. Reprehenderit laborum eveniet
            cumque
            voluptatibus molestias veritatis quos, deleniti beatae eos non perspiciatis consequatur neque quisquam
            recusandae quis deserunt! Nesciunt dignissimos esse, aliquam assumenda aperiam accusantium voluptatum odio
            saepe
            numquam tempora sint laudantium in voluptas, inventore velit cupiditate commodi dolores perspiciatis. Porro
            corrupti neque dicta aspernatur, expedita rem debitis esse autem delectus reprehenderit architecto minus
            nihil.
            Qui corporis doloremque alias et, ex nulla reiciendis tenetur nihil laudantium eum, sit excepturi sapiente
            iusto
            accusantium. Quae animi natus doloribus laboriosam sunt nihil voluptatum quaerat nobis accusamus quidem nemo
            quos quis illum deserunt optio eius quibusdam quasi quo quas, est aperiam alias iste consectetur eligendi.
            Suscipit at est molestias, mollitia fuga exercitationem minus maiores cupiditate praesentium doloribus
            expedita
            magni sed error ipsa numquam neque distinctio architecto quasi minima incidunt aperiam blanditiis excepturi
            repudiandae! Quia repellendus quaerat et maxime molestias quae aliquam, exercitationem veritatis. Ut,
            facilis.
            Eligendi perferendis rerum molestiae ex! Quas modi reiciendis, possimus magnam animi assumenda sed cum, aut
            itaque eaque dicta aperiam vero perferendis nobis molestiae voluptate recusandae, esse laboriosam nostrum
            sapiente. Inventore dolorem totam aut, doloremque, deleniti voluptatum ducimus fugit nostrum ex dolor natus
            id
            officia libero distinctio magni quod necessitatibus!</p>
           
            <h3>Using the AWS CLI to determine DB instance class support in AWS Regions</h3>
        <p class="text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde voluptas harum facere molestias
            quaerat autem
            maiores quidem, sapiente enim, eum, quae exercitationem nobis possimus itaque. Provident atque, incidunt nam
            quaerat praesentium laborum vel impedit modi veritatis quas voluptatum aspernatur quasi assumenda explicabo
            sapiente dolores officia aut placeat quae doloremque facilis magnam quibusdam. Tempora sed rem voluptatibus
            unde quis quibusdam exercitationem aliquid iure animi inventore voluptate eos nobis, quaerat expedita nulla
            quo minus accusamus repudiandae. Sit maiores quibusdam laborum eius accusantium quos quo aliquid. Id at et
            ab sunt animi non corrupti asperiores odit sequi, cupiditate, dolore atque dolorem corporis porro beatae
            similique? Eligendi molestias earum sit perspiciatis velit rerum debitis nemo fuga illo! Suscipit veniam
            repellendus iure excepturi veritatis molestias voluptas, consectetur nobis nisi numquam corporis nulla
            assumenda unde? Dignissimos, sapiente libero. Quod est optio, tempora sapiente minus beatae ut, voluptate
            dignissimos quo labore ipsam illum sit animi, magni ea.</p>


        <!-- List Section Ended -->
    </div>
</div>