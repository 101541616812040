<header class="header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="user-detail">
                    <p class="user-name">{{UserName}}
                        <!-- <span class="logged-in-time">Last Login: 2021-09-11 19:04:51.0</span> -->
                        <span class="user-role">{{RoleName}}</span>
                    </p>
                    
                </div>
            </div>
            <div class="col-md-6 col-sm-6 text-right">
                <ul class="header-menu">
                    <li class="inline-block">
                        <a title="Logout" (click)="logout()" class="menu-toggle">
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li class="inline-block">
                        <a (click)="menuToggle()" class="menu-toggle">
                            <i class="fa fa-bars"></i>
                            <!-- <i *ngIf="menuToggleValue == true" class="fa fa-times"></i> -->
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>