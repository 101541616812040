<div class="page-container container-fluid">
    <h2 class="page-title">Sysbij Help Documents</h2>
    <div class="page-content">
        <div class="form-field-section">
            <div class="row"
                style="margin-top: 0px; ; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px; width: 100%; margin-left: 0px;">
                <table datatable class="table table-bordered">
                    <thead>
                        <tr>
                            <th>SR No</th>
                            <th>Document Name</th>
                            <th class="text-center">Document Type</th>
                            <th class="text-center">Document Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Features</td>
                            <td class="text-center">
                                PDF
                                <!-- <i class="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i> -->
                                <!-- <mat-icon>insert_drive_file</mat-icon> -->

                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary" href="../../../assets/documents/SysBRIJ Deployments1.pdf"
                                    target="_blank">Open</a>
                                <!-- <button class="btn btn-primary" onclick="window.open('../../../assets/video/ChildrensWildAnimal.mp4')">Open</button> -->
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Technical Specification Document</td>
                            <td class="text-center">
                                PDF
                                <!-- <i class="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i> -->
                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary"
                                    href="../../../assets/documents/SYSBRIJ Technical Specification Document V1.pdf"
                                    target="_blank">Open</a>
                                <!-- <button type="pdf" class="btn btn-primary" onclick=" window.open('../../../assets/documents/pdf/SysBrij Installation Guide V1.pdf');"> Open </button> -->
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>User Manual</td>
                            <td class="text-center">
                                <!-- <i class="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i> -->
                                PDF
                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary" href="../../../assets/documents/SYSBRIJ User Guide V3.pdf"
                                    target="_blank">Open</a>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Questionnaire</td>
                            <td class="text-center">
                                PDF
                                <!-- <i class="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i> -->

                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary"
                                    href="../../../assets/documents/SysBRIJ Configuration Details V4.0.pdf"
                                    target="_blank">Open</a>
                                <!-- <button class="btn btn-primary" onclick="window.open('../../../assets/video/ChildrensWildAnimal.mp4')">Open</button> -->
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Installation Checklist</td>
                            <td class="text-center">
                                PDF
                                <!-- <i class="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i> -->
                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary"
                                    href="../../../assets/documents/SysBrij Installation-CheckList V4.pdf"
                                    target="_blank">Open</a>
                                <!-- <button type="pdf" class="btn btn-primary" onclick=" window.open('../../../assets/documents/pdf/SysBrij Installation Guide V1.pdf');"> Open </button> -->
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Installation Guide</td>
                            <td class="text-center">
                                PDF
                                <!-- <i class="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i> -->
                            </td>
                            <td class="text-center">
                                <a class="btn btn-primary"
                                    href="../../../assets/documents/SysBrij Installation Guide V1.pdf"
                                    target="_blank">Open</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- List Section Ended -->
    </div>
</div>