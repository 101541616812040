import { CBEnquiryModel } from './../Model/common.model';
import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-landing-page-new1',
  templateUrl: './landing-page-new1.component.html',
  styleUrls: ['./landing-page-new1.component.css']
})
export class LandingPageNew1Component implements OnInit {
  CBEnquiryModel: CBEnquiryModel;

  constructor(private Service: CommonService) {
    this.CBEnquiryModel = new CBEnquiryModel();
   }

  ngOnInit(): void {
  }
  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void {
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $("#succeess-message-box").modal("show");
  }

  HideShow() {
    $("#errorclose").show();
    $("#close").hide();
  }

  Submit(){
    if(this.CBEnquiryModel.FirstName == '' || this.CBEnquiryModel.FirstName == undefined){
      this.HideShow();
      this.popupMessage("Please enter first name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if(this.CBEnquiryModel.LastName == '' || this.CBEnquiryModel.LastName == undefined){
      this.HideShow();
      this.popupMessage("Please enter last name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if(this.CBEnquiryModel.Email == '' || this.CBEnquiryModel.Email == undefined){
      this.HideShow();
      this.popupMessage("Please enter email", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if(this.CBEnquiryModel.Mobile_No == '' || this.CBEnquiryModel.Mobile_No == undefined){
      this.HideShow();
      this.popupMessage("Please enter mobile number", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if(this.CBEnquiryModel.CompanyName == '' || this.CBEnquiryModel.CompanyName == undefined){
      this.HideShow();
      this.popupMessage("Please enter company name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if(this.CBEnquiryModel.Topic == '' || this.CBEnquiryModel.Topic == undefined){
      this.HideShow();
      this.popupMessage("Please select topic", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else{
      this.Service.CBEnquiry(this.CBEnquiryModel).subscribe((data) => {
        if (data == false) {
          this.HideShow();
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
        }
        else {
          $("#errorclose").hide();
          $("#close").show();
          this.HideShow();
          this.popupMessage("Application Successfully Submitted", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          this.CBEnquiryModel.FirstName = "";
          this.CBEnquiryModel.LastName = "";
          this.CBEnquiryModel.Email = "";
          this.CBEnquiryModel.Mobile_No = "";
          this.CBEnquiryModel.CompanyName = "";
          this.CBEnquiryModel.Topic = "";
          this.CBEnquiryModel.Help = "";
        }
      });
    }
  }

  myFunction() {
    debugger
    var input, filter, div, span, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    div = document.getElementById("myDiv");
    span = div.getElementsByClassName("panel-group");
    // span = div.getElementsByTagName("p");
    for (i = 0; i < span.length; i++) {
        a = span[i].getElementsByTagName("a")[0];
        debugger;
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            span[i].style.display = "";
        } else {
            span[i].style.display = "none";
        }
    }
}

}
