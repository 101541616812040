import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Subject } from '../../../node_modules/rxjs';
import { WorkflowListFilterModel } from '../Model/common.model';
// import { timeStamp } from 'console';

@Component({
  selector: 'app-work-flow-list',
  templateUrl: './work-flow-list.component.html',
  styleUrls: ['./work-flow-list.component.css']
})
export class WorkFLowListComponent implements OnInit {
  RoleID: string;
  CompanyDD: any;
  loaderActive: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  WorkflowGrid: any;
  WorkflowListModel: WorkflowListFilterModel;
  constructor(private service: CommonService) {
    this.WorkflowListModel = new WorkflowListFilterModel();
  }

  ngOnInit(): void {
    this.GetCompanyDD();
    this.RoleID = localStorage.getItem("RoleID");
    if(this.RoleID == '1') {
      this.WorkflowListModel.CompanyID = localStorage.getItem("CompanyID");
    }
  }

  GetCompanyDD() {
    this.service.GetCompanyDD().subscribe((res) => {
      this.CompanyDD = res;
    })
  }

  WorkflowList() {
    this.loaderActive = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      retrieve: true,
      // paging: false,
      searching: false
    };
    this.service.WorkflowList(this.WorkflowListModel).subscribe((res: any) => {
      debugger;
      this.WorkflowGrid = res;
      this.dtTrigger.next();
      this.loaderActive = false;
    })
  }

  Clear() {
    this.WorkflowListModel = new WorkflowListFilterModel();
  }
}
