<div class="page-container container-fluid">
    <h2 class="page-title">Wrapper Code Base Documents</h2>
    <div class="row1">
        <div class="row">
            <div class="col-sm-3">
                <a routerLink="wrapperNodeDetail" fragment="wrapperCodebase" class="technology-tiles nodejs">
                    <div class="img-container">
                        <img src="../../assets/images/nodejsLogo.png" alt="">
                    </div>
                    <h3 [routerLinkActive]="'tile-active'" class="technology-name">Node Js</h3>
                    <!-- <div class="btn-group btn-group-justified">
                    <a href="#" class="btn btn-default">Doc</a>
                    <a href="#" class="btn btn-default">Download</a>
                </div> -->
                </a>
            </div>
            <div class="col-sm-3">
                <a routerLink="wrapperPhpDetail" fragment="wrapperCodebase" class="technology-tiles php">
                    <div class="img-container">
                        <img src="../../assets/images/phpLogo.png" alt="">
                    </div>
                    <h3 [routerLinkActive]="'tile-active'" class="technology-name">Php</h3>
                    <!-- <div class="btn-group btn-group-justified">
                    <a href="#" class="btn btn-default">Doc</a>
                    <a href="#" class="btn btn-default">Download</a>
                </div> -->
                </a>
            </div>
            <div class="col-sm-3">
                <a routerLink="wrapperWordpressDetail" fragment="wrapperCodebase" class="technology-tiles wordpress" >
                    <div class="img-container">
                        <img src="../../assets/images/wordpressLogo.png" alt="">
                    </div>
                    <h3 [routerLinkActive]="'tile-active'" class="technology-name">Wordpress</h3>
                </a>
            </div>
            <div class="col-sm-3">
                <a routerLink="wrapperangularNodejsDetail" fragment="wrapperCodebase"
                    class="technology-tiles angular-nodejs">
                    <div class="img-container">
                        <div>
                            <img src="../../assets/images/angularNodejsLogo.png" alt="">
                        </div>
                    </div>
                    <h3 [routerLinkActive]="'tile-active'" class="technology-name">Angular + Node</h3>
                </a>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-3">
                <a routerLink="wrapperJavaDetail" fragment="wrapperCodebase" class="technology-tiles java">
                    <div class="img-container">
                        <img src="../../assets/images/javaLogo.png" alt="">
                    </div>
                    <h3 [routerLinkActive]="'tile-active'" class="technology-name">Java</h3>
                </a>
            </div>
            <div class="col-sm-3">
                <a routerLink="wrapperDotnetDetail" fragment="wrapperCodebase" class="technology-tiles dotnet">
                    <div class="img-container">
                        <img src="../../assets/images/dotNetLogo.png" alt="">
                    </div>
                    <h3 [routerLinkActive]="'tile-active'" class="technology-name">Dot Net</h3>
                </a>
            </div>
            <div class="col-sm-3">
                <a routerLink="wrapperAndroidDetail" fragment="wrapperCodebase" class="technology-tiles android">
                    <div class="img-container">
                        <img src="../../assets/images/androidLogo.png" alt="">
                    </div>
                    <h3 [routerLinkActive]="'tile-active'" class="technology-name">Android</h3>
                </a>
            </div>
            <div class="col-sm-3">
                <a routerLink="wrapperangularDotnetDetail" fragment="wrapperCodebase"
                    class="technology-tiles angular-dotnet">
                    <div class="img-container">
                        <img src="../../assets/images/angularDotnetLogo.jpg" alt="">
                    </div>
                    <h3 [routerLinkActive]="'tile-active'" class="technology-name">Angular + Dot Net</h3>
                </a>
            </div>
        </div>
    </div>
    <br>
    <div id="wrapperCodebase" #startPoint>
        <router-outlet></router-outlet>
        

    </div>
    <button *ngIf="activateGoTop" (click)="scrollToElement(startPoint)" class="scroll-top-btn"><i class="fa fa-arrow-up"></i></button>
    
</div>


