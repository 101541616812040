import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapper-codebase1',
  templateUrl: './wrapper-codebase1.component.html',
  styleUrls: ['./wrapper-codebase1.component.css']
})
export class WrapperCodebase1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
