<div class="container-fluid">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">Create User</h3>
        </div>
        <div class="col-xs-6 text-right">
            <a *ngIf="UserID == undefined" [routerLink]="'../customerList1'" class="btn btn-success">Back to List</a>
            <a *ngIf="UserID != undefined" [routerLink]="'../../../customerList1'" class="btn btn-success">Back to
                List</a>
        </div>
    </div>
    <hr>
    <div class="formSection">
        <div class="row formSectionBox">
            <div class="col-md-6 form-inline">
                <div class="form-group">
                    <label>Company Name <span id="starField">*</span></label>
                    <select [(ngModel)]="UserSetupModel.CompanyID" name="CompanyID" id="ddlCompanyName"
                        class="form-control companyName">
                        <option value="">Select</option>
                        <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}</option>
                    </select>
                    <button id="addCompanyBtn" (click)="addCompany()" 
                        class="btn-primary addCompanyBtn">&#43; New</button>
                </div>
            </div>
            <div class="col-md-6 form-inline">
                <div class="form-group">
                    <label>Date <span id="starField">*</span></label>
                    <input value="{{ todayDate | date: 'dd-MM-yyyy'}}" disabled class="form-control">
                </div>
            </div>
            <div class="col-md-6 form-inline">
                <label>User Name <span id="starField">*</span></label>
                <input [(ngModel)]="UserSetupModel.UserName" name="UserName" type="text" class="form-control" placeholder="Enter User Name" id="txtUserName">
            </div>
            <div class="col-md-6 form-inline">
                <label>User Email <span id="starField">*</span></label>
                <input [(ngModel)]="UserSetupModel.UserEmail" name="UserEmail" type="text" class="form-control" placeholder="Enter User Email" id="txtUserEmail">
            </div>
            <div class="col-md-6 form-inline">
                <label>User Mobile <span id="starField">*</span></label>
                <input [(ngModel)]="UserSetupModel.UserMobile" name="UserMobile" type="text" class="form-control" placeholder="Enter User Mobile" id="txtUserMobile">
            </div>
            <div class="col-md-6 form-inline">
                <label>Password <span id="starField">*</span></label>
                <input [(ngModel)]="UserSetupModel.Password" name="Password" type="password" class="form-control" placeholder="Enter Password" id="txtPassword">
            </div>
            <div class="col-md-6 form-inline">
                <label>Role <span id="starField">*</span></label>
                <select [(ngModel)]="UserSetupModel.RoleID" name="RoleID" class="form-control" id="ddlRole">
                    <option value="">Select</option>
                    <option *ngFor="let record of RoleDD" value="{{record.ID}}">{{record.Role}}</option>
                </select>
            </div>
            <div class="col-md-6 form-inline" *ngIf="UserSetupModel.RoleID == '1'">
                <label>Bank IM Name <span id="starField">*</span></label>
                <select [(ngModel)]="UserSetupModel.BankIMID" name="BankIMID" class="form-control" id="ddlRole">
                    <option value="">Select</option>
                    <option *ngFor="let record of BankIMDD" value="{{record.UserID}}">{{record.BankIMName}}</option>
                </select>
            </div>
            <div class="col-md-6 form-inline">
                <label>Status <span id="starField">*</span></label>
                <select [(ngModel)]="UserSetupModel.IsActive" name="IsActive" class="form-control" id="ddlIsActive">
                    <option value="">Select</option>
                    <option value="True">Active</option>
                    <option value="False">Inactive</option>
                </select>
            </div>
            <div class="col-md-12 text-right">
                <button (click)="Submit()"  id="SubmitBtn"  class="btn btn-primary">Submit</button>
            </div>
            
        </div>
    </div>
</div>

<div id="company-setup" class="modal fade" role="dialog">
    <form class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            New Company
                            <button type="button" class="close" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-inline">
                            <label for="">Company Name <span id="starField">*</span></label>
                            <input [(ngModel)]="CompanySetupModel.Name" name="txtCompanyName" placeholder="Enter company name" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-inline">
                            <label for="">Status <span id="starField">*</span></label>
                            <select [(ngModel)]="CompanySetupModel.IsActive" name="IsActive" class="form-control">
                                <option value="">Select</option>
                                <option value="True">Active</option>
                                <option value="False">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="CompanySubmit()" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
</div>


<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" id="close" data-dismiss="modal"
                    (click)="SuccessRedirect()">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>