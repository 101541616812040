<div class="page-container container-fluid">
    <h2 class="page-title">Contact Support List</h2>
    <div class="form-submit">
        <a [routerLink]="'../createContactSupport'" class="btn btn-primary">Create New</a>
    </div>
    <div class="page-content">
        <!-- Selection Filter Section Started -->
        <!-- <form>
            <div class="form-section">
                <h3 class="form-section-title">Selection Filters</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Your Email</label>
                                <input name="YourEmail" type="text" [(ngModel)]="ContactSopportModel.UserEmail"
                                    class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Your Name</label>
                                <input name="YourName" type="text" [(ngModel)]="ContactSopportModel.UserName"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <input type="submit" (click)="ContactSupportlist()" class="btn btn-primary" value="Search" /> |
                        <input type="reset" (click)="Clear()" class="btn btn-danger" value="Clear" />
                    </div>
                </div>
            </div>
        </form> -->
        <!-- Selection Filter Section Ended -->
        <!-- List Section Started -->
        <div class="table-section">
            <div class="table-section-content">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-striped">
                    <thead>
                        <tr>
                            <th>Your Email</th>
                            <th>Your Name</th>
                            <th>Status</th>
                            <th>Created By</th>
                            <th>Creation Date</th>
                            <th>Issued Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of ContactSupportGrid">
                            <td>{{record.UserEmail}}</td>
                            <td>{{record.UserName}}</td>
                            <td>{{record.IsActive}}</td>
                            <td>{{record.CreatedBy}}</td>
                            <td>{{record.CreationDate}}</td>
                            <td>{{record.IssuedDESC}}</td>
                            <td>
                                <!-- <button class="action-btn edit-btn"
                                    [routerLink]="['../createContactSupport', record.ID]">Edit</button> -->
                                <button class="action-btn view-btn"
                                    [routerLink]="['../createContactSupport', record.ID]">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="loaderActive == true" class="list-loader">
                <img src="../../assets/images/loader.gif" alt=""> Loading...
            </div>
        </div>
        <!-- List Section Ended -->

    </div>
</div>
