
export class WorkflowRequirementsModel {
    ID: string;
    FileTransmission: string="";
    TransmissionTypeID: string="";
    ACKFromBank: string="";
    ACKFileFolderNameID: string="";
    ReportFromBak: string="";
    ReportID: string="";
    ReportFolderID: string="";
    NeedEncryption: string="";
    FilesInBankFormat: string="";
    NeedEmail: string="";
    ReceipientEmail: string="";
    Vostro: string="";
    BICCode: string="";
    AgentName: string="";
    BuildingName: string="";
    StreetName: string="";
    TownName: string="";
    Pincode: string="";
    Country: string="";
    ERPComment: string="";
    AdditionalRequirement: string="";
    OperatingSystemID: string="";
    UserID: string="";
    WorkflowStatus: string="";
    WorkflowNo: string="";
    ProfileID: string="";
    RoleID: string="";
    StandardRequirements: string="";
    RequirementsFinalized: string="";
    ConfirmBankIM: string="";
    Attachment1: string="";
    SendATP: string="";
    AcceptTC: string="";
    SignedATPFileLocation: string="";
    ApprovedToQA: string="";
    ApprovedBy: string="";
    ApprovedToProd: string="";
    ApprovedByProd: string="";
    UATCompleted: string="";
    UATApprovedBy: string="";
    WorkflowSubmitDate: string="";
    ATPSentDate: string="";
    ATPApprovalDate: string="";
    UATRadyDate: string="";
    UATSignoffDate: string="";
    ProdInstanceDate: string="";
    DeployementCmpltDate: string="";
    DeploymentCompleted: string="";
    AdapterDetailsNewList: Array<PaymentMethodsModel>;
    EntitySetupNewList: Array<AccountNumbersModel>;
}

export class PaymentMethodsModel {
    PaymentType: string="";
    PaymentTypeID: string="";
    InputFileType: string="";
    InputFileTypeID: string="";
    FileIndentifier: string="";
    TransmissionType: string="";
    TransmissionTypeID: string="";
    Encryption: string="";
    EncryptionID: string="";
}

export class AccountNumbersModel {
    AccountName: string="";
    AccountNo: string="";
    Country: string="";
    CountryID: string="";
    BuildingName: string="";
    StreetName: string="";
    TownName: string="";
    Pincode: string="";
}

export class WorkflowListFilterNewModel {
    UserID: string;
    RoleID: string;
    WorkflowNo: string="";
    UserName: string="";
    IMName: string="";
}


export class WorkflowRequirementFinalizedModel {
    StandardRequirements: string="";
    RequirementFinalized: string="";
    ConfirmBankIM: string="";
    Attachment: string="";
    SendATP: string="";
    CloseWorkflow: string="";
}

export class StandartRequirementsModel{
    WorkflowID: string="";
    WorkflowStatus: string="";
    StandardRequirements: string="";
    RequirementsFinalized: string="";
    ConfirmBankIM: string="";
    Attachment1: string="";
    Attachment1FileName: string="";
    SendATP: string="";
    ATPSentPDF: string="";
    CloseWorkflow: string="";
    AcceptTC: string="";
    Attachment2: string="";
    Attachment2FileName: string="";
    ApprovedToQA: string="";
    ApprovedBy: string="";
    ApprovedToProd: string="";
    ApprovedByProd: string="";
    UATCompleted: string="";
    UATApprovedBy: string="";
    DeploymentCompleted: string="";
}

export class CustomerWorkSetupModel
{
    Id: string;
    CustomerId: string="";
    BankIM: string="";
    ATPSigned: string="";
    CommAgreed: string="";
    PaidByCustomer: string="";
    POReceived: string="";
    CheckListComment: string;
    StartDeployment: string="";
    Invoiced: string="";
    PaymentReceived: string="";
    customerDetails: Array<CustomerDetailsModel>;
}
export class CustomerDetailsModel
{
    HeaderId: string;
    Date: string;
    Attachment: string;
    AttachmentUrl: string;
    AttachmentFileName: string;
    WorkComment: string;
}


