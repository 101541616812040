import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { ContactSopportModel } from '../Model/common.model';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-create-contact-support1',
  templateUrl: './create-contact-support1.component.html',
  styleUrls: ['./create-contact-support1.component.css']
})
export class CreateContactSupport1Component implements OnInit {

  todayDate = new Date();
  ID: string;
  VID: string;
  ContactSopportModel: ContactSopportModel;
  UserID: string;
  RoleID: string;
  constructor(private Service: CommonService, private ActivatedRoute: ActivatedRoute, private route: Router) { 
    this.ContactSopportModel = new ContactSopportModel();
  }

  ngOnInit(): void {
    this.UserID = localStorage.getItem("UserID");
    this.RoleID = localStorage.getItem("RoleID");
    console.log(this.RoleID)
    this.ContactSopportModel.CreatedBy = localStorage.getItem("UserName");
    this.ID = this.ActivatedRoute.snapshot.params["ID"];
    if(this.ID != undefined) {
      this.GetContactSupportDetail(this.ID);
    }
  }

  GetContactSupportDetail(ID) {
    this.Service.GetContactSupportDetail(ID).subscribe((res: any) => {
        this.ContactSopportModel = res;
        $("#EmailDisabled").attr('disabled', 'disabled');
        $("#NameDisabled").attr('disabled', 'disabled');
        $("#DescDisabled").attr('disabled', 'disabled');
        $("#AttachDisabled").attr('disabled', 'disabled');
    })
  }

  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void{
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $('#succeess-message-box').appendTo("body").modal('show');
  }

  HideShow(){
    $("#errorclose").show();
    $("#close").hide();
  }
  Submit(): void {
    debugger;
    this.ContactSopportModel.ID = this.ID;
    this.ContactSopportModel.UserID = this.UserID
    this.ContactSopportModel.RoleID = this.RoleID
    if (this.ContactSopportModel.UserEmail == "" || this.ContactSopportModel.UserEmail == null || this.ContactSopportModel.UserEmail == undefined) {
      this.HideShow();
      this.popupMessage("Enter Your Email", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ContactSopportModel.UserName == "" || this.ContactSopportModel.UserName == null || this.ContactSopportModel.UserName == undefined) {
      this.HideShow();
      this.popupMessage("Enter Your Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ContactSopportModel.IssuedDESC == "" || this.ContactSopportModel.IssuedDESC == null || this.ContactSopportModel.IssuedDESC == undefined) {
      this.HideShow();
      this.popupMessage("Enter Issued Description", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    // else if (this.ContactSopportModel.IsActive == "" || this.ContactSopportModel.IsActive == null || this.ContactSopportModel.IsActive == undefined) {
    //   this.HideShow();
    //   this.popupMessage("Enter Status", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    // }
    else {
      this.Service.ContactSupportSetup(this.ContactSopportModel).subscribe((data) => {
        // alert(data);
        debugger;
        if(data == false){
          this.HideShow();
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
        }
        else {
          $("#errorclose").hide();
          $("#close").show();
          this.popupMessage("Data updated successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          // this.ContactSopportModel.UserEmail = "";
          // this.ContactSopportModel.UserName = "";
          // this.ContactSopportModel.IsActive = "";
          // this.ContactSopportModel.IssuedDESC = "";
          this.route.navigate(['../contactSupportList1'], { relativeTo: this.ActivatedRoute })
        }
      });
    }
  }
}
