import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  UserName: string;
  RoleName: string;
  menuToggleValue: boolean = true;
  @Output() menuToggleEvent = new EventEmitter<boolean>();
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    debugger;
    this.UserName = localStorage.getItem("UserName");
    this.RoleName = localStorage.getItem("RoleName");
  }

  logout() {
    localStorage.setItem('loginStatus','0');
    this.router.navigate([''], { relativeTo: this.activatedRoute })
  }

  menuToggle() {
    this.menuToggleValue = !this.menuToggleValue;
    this.menuToggleEvent.emit(!this.menuToggleValue);
  }

}
