import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Subject } from '../../../node_modules/rxjs';
import { UserListFilterModel } from '../Model/common.model';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  CompanyDD: any;
  loaderActive: boolean = false;
  menuToggleCheck: boolean = false;
  UserGrid: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  UserListFilterModel: UserListFilterModel;
  constructor(private Service: CommonService) {
    this.UserListFilterModel = new UserListFilterModel();
   }

  ngOnInit(): void {
    this.GetCompanyDD();
  }

  GetCompanyDD() {
    this.Service.GetCompanyDD().subscribe((res) => {
      this.CompanyDD = res;
    })
  }

  menuToggle(data) {
    this.menuToggleCheck = data;
  }

  UserList() {
    debugger
    this.loaderActive = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      retrieve: true,
      // paging: false,
      searching: false
    };
    this.Service.UserList(this.UserListFilterModel).subscribe((res) => {
      debugger;
      this.UserGrid = res;
      this.dtTrigger.next();
      this.loaderActive = false;
    })
  }

  Clear() {
    this.UserListFilterModel = new UserListFilterModel();
  }
}
