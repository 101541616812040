import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapper-help1',
  templateUrl: './wrapper-help1.component.html',
  styleUrls: ['./wrapper-help1.component.css']
})
export class WrapperHelp1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
