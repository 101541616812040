export class LoginModel {
    Email: string;
    Password: string;
}

export class CompanyDataModel {
    UserID: string;
    CompanyID: string = "";
    CompanyName: string;
    WorkflowID: string;
    WorkflowStatusID: string;
    WorkflowNo: string;
    WorkflowStatus: string;
    WhenEntered: string;
    UserName: string;
    UserEmail: string;
    UserMobile: string;
}

export class CustomerDataModel {
    CustomerName: string;
    CustomerBuildingNo: string;
    CustomerStreetName: string;
    CustomerTownName: string;
    CustomerPinCode: string;
    CustomerCountryID: string = "";
    CustomerProfileID: string;
    CustomerClientID: string;
    CustomerClientSecret: string;
}

export class AdapterRequirnmentsModel {
    ERPAdapter: string = "";
    ERPSystemID: string = "";
    ERPSpecificDetail:string;
    MapperNeeded: string = "";
    ReverseFeedNeeded: string = "";
    SftpFolderID: string = "";
    ReverseFeedEncrypted: string = "";
    ReportsNeeded: string = "";
    ReportID: string = "";
    AnyOtherRequirnment: string;
    EmailNotification: string = ""
    RecipientEmails: string;
    AdapterDetailsList: Array<AdapterDetailsModel>;
    ERPSystem: string;
}
export class AdapterDetailsModel{   
    PaymentTypeID: string = "";
    PaymentType: string = "";
    InputFileTypeID: string = "";
    InputFileType: string = "";
    FileIdentifier: string;
    TransmissionTypeID: string = "";
    TransmissionType: string = "";
    EncryptionNeeded: string = "";
    EncryptionTypeID: string = "";
    EncryptionType: string = "";
}

export class ReuirnmentsFinalizationModel {
    AreTheseStandardRequirnments: string = "";
    CustomizationNeeded: string = "";
    ReviewWithIM: string = "";
    IMName: string = "";
    Remarks: string = "";
    RequirnmentsFinalized: string = "";
}

export class ApprovalToProceedModel {
    TermsAndCondition: string = "";
    TermsAndConditionApproval: string = "";
    ApprovalDate: string;
}

export class InstanceCreationModel {
    EnableInstanceCreation: string = "";
}

export class UATApprovalModel {
    TestingCompleted: string = "";
    UATApproved: string = "";
    UATApproverName: string = "";
    EnableProductionInstance: string = "";
    AdopterName: string = "";
    DeployInProduction: string = "";
    UATAttachment: string = "";
}

export class GoLiveDetailsModel {
    ProductionInstanceCreated: string = "";
    KeyCreated: string = "";
    DryRunInProduction: string = "";
    ProductionDeploymentCompleted: string = "";
}

export class WorkflowSetupModel {
    CompanyData: CompanyDataModel;
    CustomerData: CustomerDataModel;
    AdapterRequirnments: AdapterRequirnmentsModel;
    ReuirnmentsFinalization: ReuirnmentsFinalizationModel;
    ApprovalToProceed: ApprovalToProceedModel;
    InstanceCreation: InstanceCreationModel;
    UATApproval: UATApprovalModel;
    GoLiveDetails: GoLiveDetailsModel;
    EntityDataList: Array<EntityDataModel>;
}

export class UserSetupModel {
    CompanyID: string = "";
    WhenEntered: string;
    UserName: string;
    UserEmail: string;
    UserMobile: string;
    Password: string;
    IsActive: string = "";
    RoleID: string = "";
    CreatedBy: string;
    BankIMName: string;
    BankIMEmail: string;
    UserID: string;
    BankIMID: string="";
}

export class CompanySetupModel {
    Name: string;
    IsActive: string = "";
}

export class RoleMasterSetupModel {
    RoleID: string;
    RoleName: string;
    Description: string;
    IsActive: string="";
}

export class RoleConfigurationModel {
    RoleID: string="";
    LevelOneMenuID: string="";
    LevelTwoMenuID: string="";
    IsActive: string="";
}

export class LevelTwoMenuListModel {
    Menu: string="";
    ID: string="";
    IsActive: string="";
    RoleID: string="";
}

export class UserListFilterModel {
    CompanyID: string = "";
    UserName: string;
    MobileNumber: string;
}

export class RoleListFilterModel {
    RoleID: string="";
    IsActive: string="";
}

export class WorkflowListFilterModel {
    CompanyID: string = "";
    WorkflowNo: string;
    UserName: string;
    IMName: string;
}

export class ContactSopportModel{
    ID: String;
    UserEmail: string="";
    UserName: string="";
    IsActive: string="";
    CreatedBy: string="";
    IssuedDESC: string="";
    UserID: string="";
    RoleID: string="";
}

export class EntityDataModel{
    EntityName: string;
    BuildingNumber: string;
    StreetName: string;
    TownName: string;
    PinCode: string;
    Country: string="";
    AcoountNoWithBank: string;
    BIC: string;
    ForwardingAgentNeeded: string="";
    ForwardingAgentName: string;
    ForwardingAgentBuildingName: string;
    ForwardingAgentStreetName: string;
    ForwardingAgentTownName: string;
    ForwardingAgentPinCode: string;
    ForwardingAgentCountry: string;    
}

export class ApplyForCBModel{
    Name:string;
    Email:string;
    Mobile_No:string;
    OrganizationName:string;
    OrganizationType:string="";
    ToolSet:string="";
    Comments:string;
}

export class CBEnquiryModel{
    FirstName:string;
    LastName:string;
    Email:string;
    Mobile_No:string;
    CompanyName:string;
    Topic:string="";
    Help:string;
}