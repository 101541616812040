<div class="page-container container-fluid">
    <h2 class="page-title">Role Master</h2>
    <div class="form-submit">
        <button type="button" data-toggle="modal" data-target="#roleSetup" (click)="NewRoleMaster()" class="btn btn-primary">Create New</button>
    </div>
    <div class="page-content">
        <!-- Selection Filter Section Started -->
        <form>
            <div class="form-section">
                <h3 class="form-section-title">Selection Filters</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Role Name</label>
                                <select [(ngModel)]="RoleListFilterModel.RoleID" name="RoleID" class="form-control">
                                    <option *ngFor="let record of RoleDD" value="{{record.ID}}">{{record.Role}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Status</label>
                                <select [(ngModel)]="RoleListFilterModel.IsActive" name="IsActive" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group form-btn-align">
                                <input type="submit" (click)="RoleMasterList()" class="btn btn-primary" value="Search" /> | 
                                <input type="reset" (click)="Clear()" class="btn btn-danger" value="Clear" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- Selection Filter Section Ended -->
        <!-- List Section Started -->
        <div class="table-section">
            <div class="table-section-content">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-striped">
                    <thead>
                        <tr>
                            <th>Role Name</th>
                            <th>Role Description</th>
                            <th>Status</th>
                            <th>Edit</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of RoleMasterGrid">

                            <td>{{ record.RoleName }}</td>
                            <td>{{ record.Description }}</td>
                            <td>
                                <span *ngIf="record.IsActive == 1">Active</span>
                                <span *ngIf="record.IsActive == 2">Inactive</span>
                            </td>
                            <td>
                                <button data-toggle="modal" data-target="#roleSetup" (click)="GetRoleMasterDetail(record)" class="action-btn edit-btn">Edit</button>
                            </td>
                            <td>
                                <button data-toggle="modal" data-target="#roleSetup" (click)="GetRoleMasterDetail(record)" class="action-btn view-btn">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="loaderActive == true" class="list-loader">
                <img src="../../assets/images/loader.gif" alt=""> Loading...
            </div>
        </div>
        <!-- List Section Ended -->
    </div>
</div>
<!-- Create New Role -->
<div id="roleSetup" class="modal fade" role="dialog">
    <form class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Role Setup</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Role Name <span id="starField">*</span></label>
                            <input [(ngModel)]="RoleMasterSetupModel.RoleName" name="RoleName" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Role Description <span id="starField">*</span></label>
                            <textarea [(ngModel)]="RoleMasterSetupModel.Description" name="Description" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Status <span id="starField">*</span></label>
                            <select [(ngModel)]="RoleMasterSetupModel.IsActive" name="IsActive" class="form-control">
                                <option value="">Select</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="Submit()" class="btn btn-primary" data-toggle="modal" 
                data-target="#succeess-message-box" data-dismiss="modal">Submit</button>
            </div>
        </div>
    </form>
</div>

<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>