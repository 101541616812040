import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { ContactSopportModel } from '../Model/common.model';
import { CommonService } from '../services/common.service';


@Component({
  selector: 'app-create-contact-support',
  templateUrl: './create-contact-support.component.html',
  styleUrls: ['./create-contact-support.component.css']
})

export class CreateContactSupportComponent implements OnInit {
  todayDate = new Date();
  ID: string;
  VID: string;
  ContactSopportModel: ContactSopportModel;
  constructor(private Service: CommonService, private ActivatedRoute: ActivatedRoute, private route: Router) { 
    this.ContactSopportModel = new ContactSopportModel();
  }

  ngOnInit(): void {
    this.ID = this.ActivatedRoute.snapshot.params["ID"];
    if(this.ID != undefined) {
      this.GetContactSupportDetail(this.ID);
    }
  }

  GetContactSupportDetail(ID) {
    this.Service.GetContactSupportDetail(ID).subscribe((res: any) => {
        this.ContactSopportModel = res;
        
    })
    // document.getElementById("titleHeading").innerHTML = "Edit Contact Support";
    // document.getElementById("aBtn").remove();
    // let pDiv = document.getElementById("changeBtn");
    // let btn = document.createElement("a");
    // btn.innerHTML = "Back to Lists";
    // btn.className = "btn btn-primary";
    // btn.id = "btn1"
    // pDiv.appendChild(btn);
    // btn.addEventListener("click", this.btnChange);
    // console.log(btn)
  }
  btnChange() {
    // this.route.navigate(['/contactSupportList']);
    // window.location.pathname = 'masterPage/contactSupportList'
  }

  // Submit() {
  //   debugger;
  //   this.ContactSopportModel.ID = this.ID;
  //   this.Service.ContactSupportSetup(this.ContactSopportModel).subscribe((res) => {
  //     debugger;
  //     if(res == true) {
  //       alert("Customer Setup Successfull");
  //     }
  //     else {
  //       alert("Some Error Occured");
  //     }
  //   })
  // }

  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void{
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
  }
  HideShow(){
    $("#errorclose").show();
    $("#close").hide();
  }
  Submit(): void {
    debugger;
    this.ContactSopportModel.ID = this.ID;
    if (this.ContactSopportModel.UserEmail == "" || this.ContactSopportModel.UserEmail == null || this.ContactSopportModel.UserEmail == undefined) {
      this.HideShow();
      this.popupMessage("Enter Your Email", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ContactSopportModel.UserName == "" || this.ContactSopportModel.UserName == null || this.ContactSopportModel.UserName == undefined) {
      this.HideShow();
      this.popupMessage("Enter Your Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ContactSopportModel.IssuedDESC == "" || this.ContactSopportModel.IssuedDESC == null || this.ContactSopportModel.IssuedDESC == undefined) {
      this.HideShow();
      this.popupMessage("Enter Issued Description", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ContactSopportModel.IsActive == "" || this.ContactSopportModel.IsActive == null || this.ContactSopportModel.IsActive == undefined) {
      this.HideShow();
      this.popupMessage("Enter Status", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.Service.ContactSupportSetup(this.ContactSopportModel).subscribe((data) => {
        debugger;
        if(data == "0"){
          this.HideShow();
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
        }
        else {
          $("#errorclose").hide();
          $("#close").show();
          this.popupMessage("Data updated successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          this.ContactSopportModel.UserEmail = "";
          this.ContactSopportModel.UserName = "";
          this.ContactSopportModel.IsActive = "";
          this.ContactSopportModel.IssuedDESC = "";
        }
      });
    }
  }
}
