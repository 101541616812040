import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menu = [
    {
      levelOneMenu: "Reports",
      hasSubmenu: true,
      showSubMenu: false,
      levelTwoMenus: [
        {
          levelTwoMenu: "Workflow List",
          hasSubmenu: false,
          link: "workflowList",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "Deployment Speed",
          hasSubmenu: false,
          link: "#",
          IsRouterLink: true
        }
      ]
    },
    {
      levelOneMenu: "Customers",
      hasSubmenu: true,
      showSubMenu: false,
      levelTwoMenus: [
        {
          levelTwoMenu: "Customer List",
          hasSubmenu: false,
          link: "customerList",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "New Customer",
          hasSubmenu: false,
          link: "createcustomer",
          IsRouterLink: true
        }
      ]
    },
    {
      levelOneMenu: "Workflows",
      hasSubmenu: true,
      showSubMenu: false,
      levelTwoMenus: [
        {
          levelTwoMenu: "Workflow List",
          hasSubmenu: false,
          link: "workflowList",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "New Workflow",
          hasSubmenu: false,
          link: "createnewworkflow",
          IsRouterLink: true
        }
      ]
    },
    {
      levelOneMenu: "Help Files",
      hasSubmenu: true,
      showSubMenu: false,
      link: "",
      levelTwoMenus: [
        {
          levelTwoMenu: "SAP",
          hasSubmenu: false,
          link: "sapHelp",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "Oracle",
          hasSubmenu: false,
          link: "oracleHelp",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "Tally",
          hasSubmenu: false,
          link: "tallyHelp",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "SysBrij",
          hasSubmenu: false,
          link: "sysbrijHelp",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "Wrapper",
          hasSubmenu: false,
          link: "wrapperHelp",
          IsRouterLink: true
        }
      ]
    },
    {
      levelOneMenu: "Code Base",
      hasSubmenu: true,
      showSubMenu: false,
      link: "",
      levelTwoMenus: [
        {
          levelTwoMenu: "SAP",
          hasSubmenu: false,
          link: "sapCodebase",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "Oracle",
          hasSubmenu: false,
          link: "oracleCodebase",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "Tally",
          hasSubmenu: false,
          link: "tallyCodebase",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "SysBrij",
          hasSubmenu: false,
          link: "sysbrijCodebase",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "Wrapper",
          hasSubmenu: false,
          link: "wrapperCodebase",
          IsRouterLink: true
        }
      ]
    },
    {
      levelOneMenu: "Configuration",
      hasSubmenu: true,
      showSubMenu: false,
      link: "",
      levelTwoMenus: [
        {
          levelTwoMenu: "Role Master",
          hasSubmenu: false,
          link: "roleMaster",
          IsRouterLink: true
        },
        {
          levelTwoMenu: "Role Transaction Master",
          hasSubmenu: false,
          link: "roleAssignment",
          IsRouterLink: true
        }
      ]
    }
  ];
  constructor() { }

  getMenus(): any {
    return this.menu;
  }

  // For Nested Menu
  menuToggle(childIndex, ParentIndex, windowClick) {
    debugger;
    if (windowClick == false) {
      if (ParentIndex == undefined) {
        this.menu[childIndex].showSubMenu = !this.menu[childIndex].showSubMenu;
      }
      for (let i = 0; i < this.menu.length; i++) {
        if (i != childIndex) {
          this.menu[i].showSubMenu = false;
        }
      }
    }
    else {
      for (let i = 0; i < this.menu.length; i++) {
        this.menu[i].showSubMenu = false
      }
    }
  }
}
