<div class="container-fluid">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">Create Contact Support</h3>
        </div>
        <div class="col-xs-6 text-right">
            <a *ngIf="ID == undefined" [routerLink]="'../contactSupportList1'" class="btn btn-success">Back to List</a>
            <a *ngIf="ID != undefined" [routerLink]="'../../contactSupportList1'" class="btn btn-success">Back to
                List</a>
        </div>
    </div>
    <hr>
    <div class="formSection">
        <div class="row formSectionBox">
            <div class="col-md-6 form-inline">
                <label>Your Email <span id="starField">*</span></label>
                <input name="YourEmail" id="EmailDisabled" [(ngModel)]="ContactSopportModel.UserEmail" type="email"  placeholder="Your Email" class="form-control">
            </div>
            <div class="col-md-6 form-inline">
                <label>Your Name <span id="starField">*</span></label>
                <input name="YourName" id="NameDisabled" type="text" [(ngModel)]="ContactSopportModel.UserName"  placeholder="Your Name" class="form-control">
            </div>
            <div class="col-md-6 form-inline">
                <label>Created By</label>
                <input name="CreatedBy" disabled type="text"  [(ngModel)]="ContactSopportModel.CreatedBy" class="form-control" >
            </div>
            <div class="col-md-6 form-inline">
                <div class="form-group">
                <label>Creation date</label>
                <input  name="CreationDate" class="creationdate" disabled value="{{ todayDate | date: 'dd-MM-yyyy'}}" class="form-control">
                </div>
            </div>
            <div class="col-md-12 form-inline" id="arrachmentbottom">
                <label>Attachment</label>
                <input type="file" id="AttachDisabled" class="form-control">
            </div>
            <div class="col-md-12 form-inline textArea">
                <label>Issued Description <span id="starField">*</span></label>
                <textarea name="IssueDescription" required class="form-control" id="DescDisabled" cols="30" [(ngModel)]="ContactSopportModel.IssuedDESC"
                placeholder="Issued Description"></textarea>
            </div>
            <div class="col-md-12 text-right">
                <button (click)="Submit()"  id="SubmitBtn"  class="btn btn-primary">Submit</button>
            </div> 
        </div>
    </div>
</div>



<!-- <div class="container-fluid" >
    <div class="header" style="margin-top: 10px;">
        <div class="row" style="margin: 5px 0px 0px 5px;">
            <div class="col-md-8" style="font-size: 40px;">
                <label style="margin-top: 0px; margin-left: -20px; font-size: 34px;">Create Contact Support</label>
            </div>
            <div class="col-md-4 text-right" style="margin-bottom: 10px;">
                <button *ngIf="ID == undefined" [routerLink]="'../contactSupportList1'" class="btn saveBtn">Back To List</button>
                <button *ngIf="ID != undefined" [routerLink]="'../../contactSupportList1'" class="btn saveBtn">Back To List</button>
            </div>
        </div>
    </div>
    <div class="form-field-section">
        <form >
            <div class="row">
                <div class="col-md-6">
                    <label>Your Email <span id="starField">*</span></label>
                    <input name="YourEmail" id="EmailDisabled" [(ngModel)]="ContactSopportModel.UserEmail" type="email"  placeholder="Your Email">
                </div>
                <div class="col-md-6">
                    <label>Your Name <span id="starField">*</span></label>
                    <input name="YourName" id="NameDisabled" type="text" [(ngModel)]="ContactSopportModel.UserName"  placeholder="Your Name">
                </div>
                
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Created By</label>
                    <input name="CreatedBy" disabled type="text"  [(ngModel)]="ContactSopportModel.CreatedBy"  >
                </div>
                <div class="col-md-6">
                    <label>Creation date</label>
                    <input name="CreationDate" disabled value="{{ todayDate | date: 'dd-MM-yyyy'}}">
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <label>Attachment</label>
                    <input type="file" id="AttachDisabled">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label>Issued Description <span id="starField">*</span></label>
                    <textarea name="IssueDescription" required class="form-control" id="DescDisabled" cols="30" [(ngModel)]="ContactSopportModel.IssuedDESC"
                        placeholder="Issued Description"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-right" style="margin-top: 15px;">
                    <button class="btn btn-primary" (click)="Submit()" *ngIf="ID == undefined" >Submit</button>
                </div>
            </div>
        </form>
    </div>
</div> -->
<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
