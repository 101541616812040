import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sysbrij-help1',
  templateUrl: './sysbrij-help1.component.html',
  styleUrls: ['./sysbrij-help1.component.css']
})
export class SysbrijHelp1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
