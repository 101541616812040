import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sysbrij-codebase1',
  templateUrl: './sysbrij-codebase1.component.html',
  styleUrls: ['./sysbrij-codebase1.component.css']
})
export class SysbrijCodebase1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
