<nav class="navbar fixed-top navbar-dark navbar-expand-md bg-primary justify-content-center">
    <a href="/" class="navbar-brand d-flex w-40 mr-auto logoStyle"><img src="../../assets/images/CBLogo.png" class="CBLogo" alt=""></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar3">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
        <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
            <li class="nav-item active">
                <a class="nav-link" href="#">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="color: white;" href="#FeaturesSection">Features</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="color: white;" href="#DeploymentSection">Deployment</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="color: white;" href="#HowItWorkSection">How it Works</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="color: white;" href="#ContactUsSection">Contact</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="color: white;" href="#FAQSection">FAQs</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="color: white; margin-left: 40px;" [routerLink]="'/login'">Sign in</a>
            </li>
            <li class="nav-item">
                <a class="btn btn-warning"  href="#ContactUsSection">Sign up</a>
            </li>
        </ul>
    </div>
</nav>
<a class="gotopbtn" href="#"><i class="fa fa-arrow-up"></i></a>
<div class="container">
    <div class="row" style="margin-top: 5rem;">
        <div class="col-md-12 text-center">
            <h1 class="CBHeading">Connectbank is a Portal to Automate Vendor Payments</h1>
            <h3 class="SubTitle">Unique B2B platform to configure sysBRIJ Application to do automated vendor payments </h3>
            <div>
                <!-- <button class="btn btn-warning">Sign up now</button>
                <button class="btn btn-warning ml-3">Request demo</button> -->
                <a class="btn btn-warning"  href="#ContactUsSection">Sign up now</a>
                <a class="btn btn-warning ml-3"  href="#ContactUsSection" >Request demo</a>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <img class="LaptopImg" src="../../assets/images/LaptopImage.png" alt="Laptop Handling Image">
    </div>
    <div class="OverviewSection" id="OverviewSection">
        <div class="row mt-5">
            <div class="col-md-12 text-center">
                <h1 class="SectionHeading">Overview</h1>
            </div>
            <div class="mt-3 PageContent">
                <p><b>Connectbank.in</b> is a web portal which is used to configure and deploy the vendor payment tool
                    named – SysBRIJ. On the connect bank portal you need to sign up & create your login. Once you have
                    signed up, you can initiate a workflow and specify your payment requirements. Post that, the AI
                    based bot will create an application specific to your needs. You can download the application and do
                    end to end testing. Once UAT is successful, you can download the production version and start using
                    it for your vendor payments.</p>
                <p>At any stage during the process, our team would be available for your assistance and any support that
                    may
                    be needed. Working though the portal is extremely easy & you can be done in few simple steps
                </p>
            </div>
        </div>
        <div class="row mt-5 CompanyClientsSection">
            <div class="col-md-12 text-center ">
                <h3 class="SubTitle"><b>The SysBRIJ is already working successfully for many large organizations</b></h3>
            </div>
            <div class="col-md-4 mt-3">
                <img class="ClientLogo" src="../../assets/images/ParleArgoLogo.png" alt="">
            </div>
            <div class="col-md-4 mt-3">
                <img class="ClientLogo" src="../../assets/images/TataInternationalLogo.png" alt="">
            </div>
            <div class="col-md-4 mt-3">
                <img class="ClientLogo" src="../../assets/images/KFinLogo.png" alt="">
            </div>
            <div class="col-md-4 mt-3">
                <img class="ClientLogo" src="../../assets/images/SudarshanLogo.png" alt="">
            </div>
            <div class="col-md-4 mt-3">
                <img class="ClientLogo" src="../../assets/images/HAPLogo.png" alt="">
            </div>
        </div>
    </div>

    <div class="FeaturesSection" id="FeaturesSection">
        <div class="row mt-5">
            <div class="col-md-12 text-center">
                <h1 class="SectionHeading">SysBRIJ Features</h1>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-5">
                <h3 class="FeautuersHeading">Universal to Deploy</h3>
                <p class="PageContent">The tool can be installed on any Windows or Linus OS. Works agnostic to OS
                    versions.
                    You can install it on Any Cloud or Wieb Server or on a laptop.</p>
            </div>
            <div class="col-md-7">
                <img class="FeautersImage" src="../../assets/images/Feature.png" alt="">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-7">
                <img class="FeautersImage" src="../../assets/images/Feature1.png" alt="">
            </div>
            <div class="col-md-5">
                <h3 class="FeautuersHeading">All Payment Types</h3>
                <p class="PageContent">SysBRIJ can handle all payment types such as NEFT, RTGS, IMPS, Bulk Pay, Multi
                    currency, International payments or any other.</p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-5">
                <h3 class="FeautuersHeading">Data Transfer Types </h3>
                <p class="PageContent">It can do File Transfers or API based data transfer to the bank system. It can
                    accept any file formats such as CSV, TXT or XLS.</p>
            </div>
            <div class="col-md-7">
                <img class="FeautersImage" src="../../assets/images/Feature2.png" alt="">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-7">
                <img class="FeautersImage" src="../../assets/images/Feature3.png" alt="">
            </div>
            <div class="col-md-5">
                <h3 class="FeautuersHeading">Secured Data Transfer</h3>
                <p class="PageContent">All data from your system to bank is encrypted with PGP keys and tarnsfer in a
                    secured transfer mode.</p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-5">
                <h3 class="FeautuersHeading">Reverse Feeds</h3>
                <p class="PageContent">Every payment transfer done with the bank can be tracked via reverse feed files.
                    Day end report, Mt 940 , Status check and balance check are available as a standard functionality.
                </p>
            </div>
            <div class="col-md-7">
                <img class="FeautersImage" src="../../assets/images/Feature4.png" alt="">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-7">
                <img class="FeautersImage" src="../../assets/images/Feature5.png" alt="">
            </div>
            <div class="col-md-5">
                <h3 class="FeautuersHeading">Configurable Setup</h3>
                <p class="PageContent">The application is configurable meaning you can opt for only required
                    functionality such as file transfer, encryption only or file mapping only and so on.</p>
            </div>
        </div>
    </div>

    <div class="DeploymentSection" id="DeploymentSection">
        <div class="row mt-5">
            <div class="col-md-12 text-center">
                <h1 class="SectionHeading">SysBRIJ Deployment</h1>
                <h3 class="SubTitle"><b>Follow these simple steps to complete the sysBRIJ - The Vendor Payment Application in no time</b>
                </h3>
            </div>
        </div>
        <div class="CardSection mt-3">
            <div class="row ">
                <div class="col-md-4 mt-5">
                    <div class="card cardCenter">
                        <img class="card-img-top" src="../../assets/images/Card1.png" alt="">
                        <div class="card-body">
                            <h5 class="card-title">1. Sign Up</h5>
                            <p class="card-text">Follow these simple steps to complete the sysBRIJ - The Vendor Payment
                                Application in no time.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-5">
                    <div class="card cardCenter">
                        <img class="card-img-top" src="../../assets/images/Card2.png" alt="">
                        <div class="card-body">
                            <h5 class="card-title">2. Define Scope</h5>
                            <p class="card-text">Start your work flow on connect bank portal & define all payment
                                requirements.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-5">
                    <div class="card cardCenter">
                        <img class="card-img-top" src="../../assets/images/Card3.png" alt="">
                        <div class="card-body">
                            <h5 class="card-title">3. Complete Paperwork</h5>
                            <p class="card-text">You need to sign Terms & conditions to use the app & allow the
                                deployment on your.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-5">
                    <div class="card cardCenter">
                        <img class="card-img-top" src="../../assets/images/Card4.png" alt="">
                        <div class="card-body">
                            <h5 class="card-title">4. Download Application</h5>
                            <p class="card-text">Based on the scope, system creates a dedicated application Download the
                                app for testing.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-5">
                    <div class="card cardCenter">
                        <img class="card-img-top" src="../../assets/images/Card5.png" alt="">
                        <div class="card-body">
                            <h5 class="card-title">5. Complete testing</h5>
                            <p class="card-text">Do complete User Acceptance Testing to ensure data, connectivity & use
                                cases to ensure no mistakes.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-5">
                    <div class="card cardCenter">
                        <img class="card-img-top" src="../../assets/images/Card6.png" alt="">
                        <div class="card-body">
                            <h5 class="card-title">6. Start Vendor Payment</h5>
                            <p class="card-text">On successful UAT, system creates a Production instance for you.
                                Download it & start vendor payments.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="HowItWorkSection" id="HowItWorkSection">
        <div class="row mt-5">
            <div class="col-md-12 text-center">
                <h1 class="SectionHeading">How It Works?</h1>
            </div>
            <div class="mt-3 PageContent">
                <p>Connect bank.in is a web portal which is used to define your vendor payment needs and configure it.
                    In any business, there is a need to do payments to vendors, employees and many other parties. Gone
                    are the days when business will create files from its ERP system and upload it on the bank portal.
                    This vendor payment activity can be done automatically by connecting with your bank.
                    This Connectbank portal facilitates this vendor payment automation.
                </p>
            </div>
        </div>
        <div class="row mt-3">
            <h1 class="SectionHeading">How SysBRIJ Works?</h1>
            <div class="mt-3 PageContent">
                <p>SysBRIJ is an application for vendor payments. Using the workflow on the portal, you can download the
                    SysBRIJ application.
                    Once installed, SysBRIJ connects to your ERP & Bank to transfer data in both directions. Following
                    steps explain how SysBRIJ works.
                </p>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <h3 class="WorkHeading">Pickup the file</h3>
                    <p class="PageContent">Once installed, SysBRIJ picks up files from designated folder at 15 min
                        frequency .It can read any type of file from any location. It can also convert the file in
                        required format using mapper functionality.</p>
                </div>
                <div class="col-md-6">
                    <img class="WorkImage" src="../../assets/images/Work1.png" alt="">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <img class="WorkImage" src="../../assets/images/Work2.png" alt="">
                </div>
                <div class="col-md-6">
                    <h3 class="WorkHeading">Encrypt the file</h3>
                    <p class="PageContent">Once the file is picked up, SysBRIJ uses PGP alogrithm and encrypts the file.
                        It can be decrypted only with other key pair which is stored in bank's system. So file & its
                        data is completely secured during the transfer.</p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <h3 class="WorkHeading">Transfer the file</h3>
                    <p class="PageContent">SysBRIJ uses another encryption key pair to do secured File Transfer from
                        your ERP to Bank's IT system.</p>
                </div>
                <div class="col-md-6">
                    <img class="WorkImage" src="../../assets/images/Work3.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="ContactUsSection" id="ContactUsSection">
        <div class="row mt-5">
            <div class="col-md-12 text-center">
                <h1 class="SectionHeading">Contact Us</h1>
                <h3 class="SubHeading">Any support needed at anytime, just fill up the form</h3>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-5">
                <img class="ContactUsImage" src="../../assets/images/ContactUsImage.png" alt="">
            </div>
            <div class="col-md-7">
                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" class="form-control" placeholder="Enter first name">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" class="form-control" placeholder="Enter last name">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="text" class="form-control" placeholder="Enter email">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Mobile Number</label>
                                <input type="text" class="form-control" placeholder="Enter Mobile number">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Company Name</label>
                                <input type="text" class="form-control" placeholder="Enter company name">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Select the Topic</label>
                                <select class="form-control">
                                    <option value="">Select</option>
                                    <option value="General Enquiry">General Enquiry</option>
                                    <option value="Request for Demo">Request for Demo</option>
                                    <option value="Registration">Registration</option>
                                    <option value="Support Query">Support Query</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>How can we help?</label>
                                <textarea class="form-control" placeholder="Please sahre what you want us to help"
                                    cols="30" rows="2"></textarea>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-warning" style="width: 100%;" (click)="Submit()">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="FAQSection" id="FAQSection">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="SectionHeading">Frequently asked questions</h1>
                <h3 class="SubHeading">You can go through these questions or search within the list</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse1"
                            aria-expanded="true">
                            <span class="title">We have SAP system, do these adapters work with SAP?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse1" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Yes, we have standard adapters for SAP and SAP S/4 HANA. You just need to
                                copy the code in your SAP system and it would be compatible with your
                                ERP.
                                Apart from SAP, we also have standard adapters for Tally & Oracle ERPs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse2"
                            aria-expanded="true">
                            <span class="title">We use our homegrown ERP solution will these work with this
                                ERP?</span>
                            <!-- <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span> -->
                        </div>
                        <div id="collapse2" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Yes, these adapters can work with any ERP or for that matter any IT
                                systems. All you need is extract the relevant file from your ERP in .XLS
                                format and rest will be automatic.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse3"
                            aria-expanded="true">
                            <span class="title">We need to have reports on daily basis and monthly basis, how can it
                                support it?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse3" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                There are multiple reports which are generated on daily and monthly
                                basis. You can select and choose relevant reports needed and system will
                                automatically place these reports on your system at a set frequency
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse4"
                            aria-expanded="true">
                            <span class="title">We use Linux operating system, do these adapters work on
                                Linux?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse4" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Yes, these are OS agnostic and it can work on any Windows or Linux system
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse5"
                            aria-expanded="true">
                            <span class="title">Can these adapters send emails with report attachments?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse5" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Yes, this is a standard feature available. You will need to provide a
                                dedicated email address with credentials and allow email this email
                                through your gateway. With that it can be setup to send reports emails
                                as well as many other emails.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse6"
                            aria-expanded="true">
                            <span class="title">If there is a failure, how would we be notified and how corrections
                                can
                                happen?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse6" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                If you have enabled email functionality, system will send emails to
                                designated recipients on any kind of failures. Also, you can always
                                check in log files in the system.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse7"
                            aria-expanded="true">
                            <span class="title">How can we get started?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse7" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Its very simple. Just fill up the form on contact us page and we will
                                connect with you for further information.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse8"
                            aria-expanded="true">
                            <span class="title">How data is transmitted to Bank?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse8" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                This system uses PGP encryption and Host to host connectivity to send
                                data to the bank. Its most secured method of data transmission over
                                internet.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse9"
                            aria-expanded="true">
                            <span class="title">This is confidential data for our organization, is its shared
                                anywhere?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse9" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                No, The application is hosted on your IT landscape. So all data resides
                                in your landscape and encrypted there. The encrypted data packet is sent
                                to the bank over internet.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse10"
                            aria-expanded="true">
                            <span class="title">What is host to host connection?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse10" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Host-to-Host is an automated, two-way data transfer service for
                                high-volume online banking that gives you direct, anytime access to
                                Business Online from various applications. Host-to-Host is a direct
                                system-to-system integration between your ERP system and the bank, and
                                with no user intermediary involved.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse11"
                            aria-expanded="true">
                            <span class="title">Do we need to have network connectivity with the bank?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse11" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Yes, in order to use Host To Host connection which is secured connection,
                                you need to establish direct connectivity with the bank. Its easy to
                                setup this connectivity. Just fillup the form and Bank representative
                                will connect with your network person to setup the connectivity.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse12"
                            aria-expanded="true">
                            <span class="title">How to setup network connectivity with the bank?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse12" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Its easy to setup this connectivity. Just fillup the form and Bank
                                representative will connect with your network person to setup the
                                connectivity.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse13"
                            aria-expanded="true">
                            <span class="title">Does this system uses any Database?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse13" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                No, this does not use any kind of database..
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse14"
                            aria-expanded="true">
                            <span class="title">Are there any licenses for this system?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse14" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                No, this is completely free from any licenses
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse15"
                            aria-expanded="true">
                            <span class="title">Are there any third party applications used in the solution?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse15" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Standard open source third party libraries are used in the system, which
                                are worldwide used in any application.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse16"
                            aria-expanded="true">
                            <span class="title">After installation, if there is any issue how do we get the
                                support?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse16" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Once you login to the portal, you can create the support ticket and our
                                support team will fix the issue.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse17"
                            aria-expanded="true">
                            <span class="title">Currently we use our own file format and don’t want to change it.
                                Can we
                                use this system?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse17" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Yes, this system supports any input file format and structure. So if your
                                ERP is creating a file output which is currently in use, it can consume
                                that file as well.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse18"
                            aria-expanded="true">
                            <span class="title">What if we want to use only one of the adapter?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse18" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                Yes, its completely pick and choose method. You don’t need to use all the
                                tools available in the system. Just select ones which are needed for
                                your organization.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse19"
                            aria-expanded="true">
                            <span class="title">Our organization does not allow installation of any other softwares,
                                how
                                can we handle it?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse19" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                You need to connect with your security team and get their approval for
                                installation of this software in your IT landscape.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse20"
                            aria-expanded="true">
                            <span class="title">Is this system compliant with VAPT?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse20" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                This is an application and VAPT is more towards the network
                                vulnerability. You can connect with your risk or security team for VAPT
                                with bank network.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header collapsed" data-toggle="collapse" data-target="#collapse21"
                            aria-expanded="true">
                            <span class="title">How much time is needed to setup the system?</span>
                            <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
                        </div>
                        <div id="collapse21" class="collapse" data-parent="#accordionExample">
                            <div class="card-body">
                                The installation and testing happen with 3 to 5 business days. It depends
                                on your organization’s preparedness to test and deploy the system. But
                                it does not take more than 4 to 6 weeks from start to end.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br><br><br>
</div>