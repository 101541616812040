<div class="document-container container-fluid">
    <h1 class="document-title text-center">Omni Channel Wrapper Code -
      <span class="language-name">Angular + NodeJs</span>
    </h1>
    <div class="row document-content-container">
      <div class="col-md-2">
        <div class="document-index">
          <ol>
            <li>
              <a>Overview</a>
            </li>
            <li>
              <a>Pre-requisities</a>
            </li>
            <li>
              <a routerLink="../wrapperangularNodejsDetail" fragment="stepsImplementation">Steps of Implementation</a>
              <ul>
                <li>
                  <a routerLink="../wrapperangularNodejsDetail" fragment="newPayment">newPayment</a>
                </li>
                <li>
                  <a routerLink="../wrapperangularNodejsDetail" fragment="paymentEnquiry">paymentEnquiry</a>
                </li>
                <li>
                  <a routerLink="../wrapperangularNodejsDetail" fragment="paymentCancellation">paymentCancellation</a>
                </li>
                <li>
                  <a routerLink="../wrapperangularNodejsDetail" fragment="keyStoreData">keyStoreData</a>
                </li>
                <li>
                  <a routerLink="../wrapperangularNodejsDetail" fragment="callingAPI">callingAPI</a>
                </li>
                <li>
                  <a routerLink="../wrapperangularNodejsDetail" fragment="callbackNotification">Callback Notification API</a>
                </li>
              </ul>
            </li>
            <li>
              <a routerLink="../wrapperangularNodejsDetail" fragment="codeDetails">Code Details</a>
            </li>
          </ol>
        </div>
      </div>
      <div class="col-md-10 document-description-container">
        <section class="document-section overview-container">
          <h2 class="document-section-title">1. Overview</h2>
          <div class="section-tab">
            <div class="description-container">
              <p>
                Wrapper code has three functionalities which are submit Online payment, payment enquiry and cancel and refund the payment.
                One needs to follow below points to implement these functionalities on merchants’side.
              </p>
            </div>
          </div>
        </section>
        <section class="document-section overview-container">
          <h2 class="document-section-title">2. Pre-requisities</h2>
          <div class="section-tab">
            <div class="description-container">
              <p>
                Install packages given below inside merchant’s Website code:
              </p>
            </div>
            <div class="pre-requisit-list-container">
              <ul>
                <li>"^2.2""node-jose": "^2.0.0"</li>
                <li>"winston": "^3.3.3"</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="document-section overview-container" id="stepsImplementation">
          <h2 class="document-section-title">3. Steps of Implementation</h2>
          <div class="section-tab">
            <div class="description-container">
              <p>
                After installation of the packages, one needs to add methods of wrapper code into code of merchant’s website. Merchant just
                need to copy and paste the methods into his coding files.
              </p>
              <p>
                These logical methods are related to the Bank’s API call, encryption, decryption and Payment gateway functionality. Below
                are the details of every method along with the method call.
              </p>
            </div>
            <ul>
              <li class="method-detail" id="newPayment">
                <app-wrapper-newpayment></app-wrapper-newpayment>
              </li>
              <li class="method-detail" id="paymentEnquiry">
                <app-wrapper-paymentenquiry></app-wrapper-paymentenquiry>
              </li>
              <li class="method-detail" id="paymentCancellation">
                <app-wrapper-paymentcancel></app-wrapper-paymentcancel>
              </li>
              <li class="method-detail" id="keyStoreData">
                <h3 class="title-level-one">KeyStoreData</h3>
                <div>
                  <div class="description-container">
                    <p>This method returns key store and private, public keys. </p>
                    <p>These keys used to sign and encrypt the requested payload and decrypt, verify the encrypted response from
                      the Bank. </p>
                    <p>Need to mention proper keys location inside this method then only payment gateway functionalities will
                      work because encryption and decryption is dependent on these keys.</p>
                  </div>
                </div>
              </li>
              <li class="method-detail" id="callingAPI">
                <h3 class="title-level-one">CallingApi</h3>
                <div>
                  <div class="description-container">
                    <p>This method is internally calling the Bank’s API and returns the response to the calling block of code.</p>
                  </div>
                </div>
              </li>
              <li class="method-detail" id="callbackNotification">
                <h3 class="title-level-one">Callback Notification API</h3>
                <div>
                  <div class="description-container">
                    <p>The Bank triggers this API and sends payment status back.</p>
                    <p>Merchant needs to include below method in its Website code. Bank will call this code and send the payment
                      status. Merchant can update its End User or store the status received as per its implementation.</p>
                    <p>The API has two arguments named as paymentStatus and txnRefNo. Both of these arguments are type string.
                      <br>In case of a successful payment set paymentStatus value to success.
                      <br>In case of payment failure set paymentStatus value to fail.
                    </p>
                    <p>It gives an acknowledgement(response) in JSON format as mentioned below.</p>
                  </div>
                  <div class="code-view">
                    <pre>
                                    <code [innerHTML]="apiResponseCode"></code>
                                  </pre>
                  </div>
                  <div class="description-container api-call-example">
                    <p>Below is the API link:
                      <br>http://nodejs.copiacs.com/CallbackNotification/:paymentStatus/:txnRefNo</p>
                    <p>
                      Example of the link with values:
                      <br> In case of success: http://nodejs.copiacs.com/CallbackNotification/success/452545
                      <br> In case of failure: http://nodejs.copiacs.com/CallbackNotification/fail/452545
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section class="document-section overview-container" id="codeDetails">
          <h2 class="document-section-title">4. Code Details</h2>
          <div class="section-tab">
            <p>The code includes seven methods, one API and constant.js file.</p>
            <div class="method-detail">
              <h3 class="title-level-one">List of Methods</h3>
              <ul>
                <li>newPayment(txnRef, system, payment, merchant, customer, order, other)</li>
                <li>paymentEnquiry(txnRef, merchant)</li>
                <li>paymentCancellation(refundNotificationURL, txnRef, rfdRef, amount, currency, merId)</li>
                <li>CallingAPI(message, APILink, merchant)</li>
                <li>keyStoreData()</li>
                <li>logMaintainFunction(payload, encyption, response, decryption, errorMsg, label)</li>
                <li>cleanObject(obj)</li>
              </ul>
            </div>
            <div class="method-detail">
              <h3 class="title-level-one">API Name</h3>
              <ul>
                <li>CallbackNotification</li>
              </ul>
            </div>
            <div class="method-detail">
              <h3 class="title-level-one">File Name</h3>
              <ul>
                <li>Constant.js</li>
              </ul>
            </div>
            <div class="code-detail-container">
              <h3 class="title-level-one">Details of each Method</h3>
              <ol>
                <li class="method-detail">
                  <h3 class="title-level-two">newPayment</h3>
                  <p>This method is used to perform a new payment.</p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(paymentMethodCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="paymentMethodCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">paymentEnquiry</h3>
                  <p>This method is used to perform the Payment Status Enquiry.</p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(paymentEnquiryMethodCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="paymentEnquiryMethodCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">paymentCancellation</h3>
                  <p>This method is used for to cancel the order and refund the payment.</p>
                  <div class="code-view">
                    <button title='copy code' class="copy-btn" (click)="copyBtn(paymentCancellationMethodCode)">
                      <i class="fa fa-clone" aria-hidden="true"></i>
                    </button>
                    <pre>
                            <code [innerHTML]="paymentCancellationMethodCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">callingAPI</h3>
                  <p>This method is used to call the APIs.</p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(callingAPICode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="callingAPICode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">keyStoreData</h3>
                  <p>This method returns thekeys in the required format and the key store, which helps to encrypt the payload.</p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(keystoreDataMethodCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="keystoreDataMethodCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">logMaintainFunction</h3>
                  <p>This method maintains the log of each transaction. Just need to mention the log file path in Constant.js
                    file.
                  </p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(logMaintainFunctionMethodCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="logMaintainFunctionMethodCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">cleanObject</h3>
                  <p>
                    This method uses to adjust the optional properties inside the payload. If optional property value is blank or empty then
                    this method removes those properties from payload while sending payload as request parameter to API.
                  </p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(cleanObjectMethodCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="cleanObjectMethodCode"></code>
                          </pre>
                  </div>
                </li>
              </ol>
            </div>
            <div class="code-detail-container">
              <h3 class="title-level-one">API</h3>
              <ul>
                <li class="method-detail">
                  <h3 class="title-level-two">CallbackNotification</h3>
                  <p>
                    This is an API created at merchant side. HSBC will call this API from their end to send payment status of current transaction
                    to the merchant side and merchant send response in acknowledgment.
                  </p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(callbackNotificationAPICode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                                <code [innerHTML]="callbackNotificationAPICode"></code>
                              </pre>
                  </div>
                </li>
              </ul>
            </div>
            <div class="code-detail-container">
              <h3 class="title-level-one">File</h3>
              <ul>
                <li class="method-detail">
                  <h3 class="title-level-two">constant.js</h3>
                  <p>
                    This file contains the all-important data related the whole functionality where merchant can replace with their own data.
                    If merchant already have constant file then copy the all properties of this file in their Constant file.
                  </p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(constantFileCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                                <code [innerHTML]="constantFileCode"></code>
                              </pre>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>