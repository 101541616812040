<h3 class="title-level-one">paymentCancellation</h3>
<div>
    <div class="description-container">
        <p>
            This method is used to cancel and refund the submitted payment and It has to be called from merchant’s code fororder cancel
            and refund which gets called on click of ‘Order cancel’ or ‘Refund’ button.
        </p>
        <p>
            This is parameterized methodArguments should be of specific types as mentioned below.
        </p>
    </div>
    <div class="syntax-container">
        <h4>Method Syntax</h4>
        <div class="section-tab">
            <p>paymentCancellation(refundNotificationURL, txnRef, rfdRef, amount, currency, merId)</p>
        </div>
    </div>
    <div class="arguments-detail-container">
        <h4>Arguments Detail</h4>
        <div class="section-tab">
            <div class="arguments-detail">
                <h5 class="argument-name">refundNotificationURL </h5>
                <ul>
                    <li>Data type of this property is string.</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">refundNotificationUrl: string range (up to 255 chars) </span>
                                <div class="property-description">
                                    <p>Define URL endpoint for receiving refund result notification (server-to-server) from
                                        HSBC after refund completed</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">txnRef </h5>
                <ul>
                    <li>Data type of this property is string.</li>
                    <li>Property value should be transaction reference number.</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">txnRef: string range (up to 25 chars)</span>
                                <div class="property-description">
                                    <p>Merchant to pass Transaction Reference that refers to one specific transaction</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">rfdRef</h5>
                <ul>
                    <li>Data type of this property is string.</li>
                    <li>This is refund number and it must be unique against every refund transaction.</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">rfdRef: string range (up to 25 chars) </span>
                                <div class="property-description">
                                    <p>Unique Refund transaction ID assigned by merchant for this refund request</p>
                                    <p>No duplicate Refund Reference is allowed</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">amount</h5>
                <ul>
                    <li>Data type of this property is number.</li>
                    <li>Value of this property should be refund amount</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">amount: integer range 1 ≤ x ≤ 999999999999999 </span>
                                <div class="property-description">
                                    <p>Refund Amount or the Full Amount of a pre-auth transaction</p>
                                    <ul>
                                        <li>Refund Amount should not exceed the value of total transaction amount</li>
                                        <li>Support multiple partial refund</li>
                                        <li>If the transaction is in pre-auth state currently, then only a full cancellation
                                            is allowed. The amount must be same as the auth amount. Partial amount would
                                            not be allowed.</li>
                                    </ul>
                                    <p>NOTICE: NO comma or dot. For example: Input 10000 instead of 100.00</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">currency </h5>
                <ul>
                    <li>Data type of this property is number.</li>
                    <li>Mentioned currency type against this property.</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">currency: string enum [INR] range (up to 3 chars) </span>
                                <div class="property-description">
                                    <p>Payment Currency (Format: ISO 4217 Alpha)</p>
                                    <table class="table table-bordered property-table">
                                        <thead>
                                            <tr>
                                                <th>Possible Value</th>
                                                <th>Definition</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>INR</td>
                                                <td>Indian Rupee</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">merId</h5>
                <ul>
                    <li>Data type of this property is number.</li>
                    <li>Value should be merchant id.</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">merId: string range (up to 50 chars) </span>
                                <div class="property-description">
                                    <p>MerchantID</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="description-container">
        <p>This method returns JSON response.</p>
    </div>
</div>