import { Component, OnInit } from '@angular/core';
import {
  LevelTwoMenuListModel,
  RoleConfigurationModel,
} from '../Model/common.model';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-role-assignment',
  templateUrl: './role-assignment.component.html',
  styleUrls: ['./role-assignment.component.css'],
})
export class RoleAssignmentComponent implements OnInit {
  RoleDD: any;
  LevelOneMenuDD: any;
  LevelTwoMenuList: Array<LevelTwoMenuListModel>;
  RoleID: string;
  LevelOneMenuID: string;
  RoleConfigurationListModel: Array<RoleConfigurationModel>;

  constructor(private Service: CommonService) {
    this.RoleConfigurationListModel = new Array<RoleConfigurationModel>();
    this.LevelTwoMenuList = new Array<LevelTwoMenuListModel>();
  }

  ngOnInit(): void {
    this.GetRoleDD();
    this.GetLevelOneMenuDD();
  }

  GetRoleDD() {
    this.Service.RoleDD().subscribe((res) => {
      this.RoleDD = res;
    });
  }

  GetLevelOneMenuDD() {
    this.Service.LevelOneMenuDD().subscribe((res) => {
      this.LevelOneMenuDD = res;
    });
  }

  GetLevelTwoMenuDD() {
    debugger;
    this.RoleConfigurationListModel = new Array<RoleConfigurationModel>();
    this.Service.LevelTwoMenuDD(this.LevelOneMenuID, this.RoleID).subscribe(
      (res: Array<LevelTwoMenuListModel>) => {
        debugger;
        this.LevelTwoMenuList = res;
        for (let i = 0; i < this.LevelTwoMenuList.length; i++) {
          this.RoleConfigurationListModel.push({
            RoleID: this.RoleID,
            LevelOneMenuID: this.LevelOneMenuID,
            LevelTwoMenuID: this.LevelTwoMenuList[i].ID,
            IsActive: this.LevelTwoMenuList[i].IsActive,
          });
        }
      }
    );
    console.log(this.RoleConfigurationListModel);
  }

  AssignMenuToRole(LevelTwoMenuID: string, checkboxEvent) {
    debugger;
    for (let i = 0; i < this.RoleConfigurationListModel.length; i++) {
      if (this.RoleConfigurationListModel[i].LevelTwoMenuID == LevelTwoMenuID) {
        if (checkboxEvent.target.checked == true) {
          this.RoleConfigurationListModel[i].IsActive = '1';
        } else {
          this.RoleConfigurationListModel[i].IsActive = '2';
        }
      }
    }
    console.log(this.RoleConfigurationListModel);
  }

  RoleChange() {
    debugger;
    this.LevelTwoMenuList = undefined;
  }

  popupMessage(
    Message: string,
    Addclass: string,
    RemoveClass: string,
    ShowImg: string,
    HideImg: string
  ): void {
    $('#message-text').html(Message);
    $('#success-modal-head').addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
  }
  HideShow() {
    $('#errorclose').show();
    $('#close').hide();
  }
  Submit(): void {
    debugger;
    if (this.RoleID == '' || this.RoleID == null || this.RoleID == undefined) {
      this.HideShow();
      this.popupMessage(
        'Select Role Name',
        'error-modal-head',
        'success-modal-head',
        '#error-img-id',
        '#success-img-id'
      );
    } else if (
      this.LevelOneMenuID == '' ||
      this.LevelOneMenuID == null ||
      this.LevelOneMenuID == undefined
    ) {
      this.HideShow();
      this.popupMessage(
        'Select Menu',
        'error-modal-head',
        'success-modal-head',
        '#error-img-id',
        '#success-img-id'
      );
    } else {
      this.Service.RoleTransactionAssignment(
        this.RoleConfigurationListModel
      ).subscribe((data) => {
        debugger;
        if (data == '0') {
          this.HideShow();
          this.popupMessage(
            'Please select Role Name and Menu',
            'error-modal-head',
            'success-modal-head',
            '#error-img-id',
            '#success-img-id'
          );
        } else {
          $('#errorclose').hide();
          $('#close').show();
          this.popupMessage(
            'Data updated successfully',
            'success-modal-head',
            'error-modal-head',
            '#success-img-id',
            '#error-img-id'
          );
        }
      });
    }
  }
}
