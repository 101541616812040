import { CBEnquiryModel } from './../Model/common.model';
import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-landing-page-new',
  templateUrl: './landing-page-new.component.html',
  styleUrls: ['./landing-page-new.component.css',
                '../../assets/bootstrap.min.css']
})
export class LandingPageNewComponent implements OnInit {
  CBEnquiryModel: CBEnquiryModel;

  constructor(private Service: CommonService) {
    this.CBEnquiryModel = new CBEnquiryModel();
   }

  ngOnInit(): void {
    // var activeNavItem = $('.nav-item');
    // activeNavItem.click(function(){
    //   activeNavItem.removeClass('active');
    //   $(this).addClass('active');
    // })
  }

  Submit(){
    this.Service.CBEnquiry(this.CBEnquiryModel).subscribe((data) => {});
  }
  
}
