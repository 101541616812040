<div class="container-fluid">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">Contact Support List</h3>
        </div>
        <div class="col-xs-6 text-right">
            <a [routerLink]="'../createContactSupport1'" class="btn btn-success "> <i class="fa fa-plus fa-lg"
                    aria-hidden="true"></i> Create New</a>
        </div>
    </div>
    <hr>
    <div class="selectionFilterSection">
        <h3 class="selectionFilterTitle">Selection Filter</h3>
        <div class="row selectionFilterSectionBox">

            <div class="col-md-4 ">
                <div class="form-group">
                    <label>Your Email</label>
                    <input type="text" name="YourEmail" [(ngModel)]="ContactSopportModel.UserEmail"
                        placeholder="Your Email" class="form-control">
                </div>
            </div>
            <div class="col-md-4 col-md-offset-2">
                <label>Your Name</label>
                <input name="YourName" type="text" [(ngModel)]="ContactSopportModel.UserName" placeholder="Your Name"
                    class="form-control">
            </div>
            <div class="col-md-12 text-right SFBtnSection">
                <button type="submit" (click)="ContactSupportlist()" class="btn btn-primary">Search</button>
                <button type="reset" (click)="Clear()" class="btn btn-danger clearBtn">Clear</button>
            </div>
        </div>
    </div>
    <div class="listSection">
        <h3 class="selectionFilterTitle">List</h3>
        <div class="row listSectionBox">
            <table class="table table-bordered" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th scope="col">Your Email</th>
                        <th scope="col">Your Name</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Creation date</th>
                        <th scope="col">Issued Description</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of ContactSupportGrid">
                        <td>{{record.UserEmail}}</td>
                        <td>{{record.UserName}}</td>
                        <td>{{record.CreatedBy}}</td>
                        <td>{{record.CreationDate}}</td>
                        <td>{{record.IssuedDESC}}</td>

                        <td>
                            <button class="btn" style="background-color: #17a2b8;"
                                [routerLink]="['../createContactSupport1', record.ID]">View</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>




<!-- <div class="container-fluid">
    <div class="header" style="margin-top: 10px;">
        <div class="row" style="margin: 5px 0px 0px 5px;">
            <div class="col-md-4" style="font-size: 40px;">
                <h3 style="margin-top: 0px; margin-left: -20px; font-size: 34px;">Contact Support List</h3>
            </div>
            <div class="col-md-8 text-right">
                <button class="btn saveBtn" [routerLink]="'../createContactSupport1'"><i class="fa fa-plus fa-lg"
                        aria-hidden="true"> Create New</i></button>
            </div>

        </div>
    </div>

    <div class="selectionFilter">
        <h5>Selection Filter</h5>
    </div>
    <div class="row"
        style="margin-top: 0px; border: 1px solid; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px;  width: 100%; margin-left: 0px;">
        <form>
            <div class="Selectionfields">
                <div class="col-md-6">
                    <label>Your Email</label>
                    <input type="text" name="YourEmail" [(ngModel)]="ContactSopportModel.UserEmail"
                        placeholder="Your Email">
                </div>
                <div class="col-md-6">
                    <label>Your Name</label>
                    <input name="YourName" type="text" [(ngModel)]="ContactSopportModel.UserName"
                        placeholder="Your Name">
                </div>
                <div class="col-md-12 text-right" style="margin: 10px 0px 10px 0px">
                    <button class="btn btn-primary" (click)="ContactSupportlist()">Search</button>
                    <button class="btn btn-danger" (click)="Clear()" style="margin-left: 5px;">Clear</button>
                </div>
            </div>
        </form>
    </div>
    <div class="selectionFilter">
        <h5>List</h5>
    </div>
    <div class="row"
        style="margin-top: 0px; border: 1px solid; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px; width: 100%; margin-left: 0px;  padding: 5px; margin-bottom: 5px;">
        <table class="table table-bordered" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th scope="col">Your Email</th>
                    <th scope="col">Your Name</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Creation date</th>
                    <th scope="col">Issued Description</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record of ContactSupportGrid">
                    <td>{{record.UserEmail}}</td>
                    <td>{{record.UserName}}</td>
                    <td>{{record.CreatedBy}}</td>
                    <td>{{record.CreationDate}}</td>
                    <td>{{record.IssuedDESC}}</td>
                    <td>
                        <button class="btn" style="background-color: #17a2b8;"
                            [routerLink]="['../createContactSupport1', record.ID]">View</button>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

 -->