<div class="page-container container-fluid">
    <h2 class="page-title">Create User</h2>
    <div class="form-submit">
        <a *ngIf="UserID != undefined" [routerLink]="'../../../customerList'" class="btn btn-primary">Back to List</a>
        <a *ngIf="UserID == undefined" [routerLink]="'../customerList'" class="btn btn-primary">Back to List</a>
    </div>
    <div class="page-content">
        <form>
            <div class="form-section">
                <h3 class="form-section-title">User Detail</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Company Name <span id="starField">*</span></label>

                                <input *ngIf="PageStatus != 'view'" style="position: absolute;right: 15px;top: -4px;" data-target="#company-setup" data-toggle="modal" type="button" class="btn-primary" value="Add New">

                                <select name="ddlCompanyName" id="ddlCompanyName" [(ngModel)]="UserSetupModel.CompanyID" class="form-control">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Date <span id="starField">*</span></label>
                                <input name="WhenEntered" id="when-entered" type="text"
                                    class="form-control" disabled/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>User Name <span id="starField">*</span></label>
                                <input name="txtUserName" id="txtUserName" [(ngModel)]="UserSetupModel.UserName" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>User Email <span id="starField">*</span></label>
                                <input name="txtUserEmail" id="txtUserEmail" [(ngModel)]="UserSetupModel.UserEmail" type="email" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>User Mobile <span id="starField">*</span></label>
                                <input name="txtUserMobile" id="txtUserMobile" [(ngModel)]="UserSetupModel.UserMobile" type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Password <span id="starField">*</span></label>
                                <input name="txtPassword" id="txtPassword" [(ngModel)]="UserSetupModel.Password" type="password" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Role <span id="starField">*</span></label>
                                <select [(ngModel)]="UserSetupModel.RoleID" name="ddlRole" id="ddlRole" class="form-control">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of RoleDD" value="{{record.ID}}">{{record.Role}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Bank IM Name <span id="starField">*</span></label>
                                <input name="txtBankIMName" id="txtBankIMName" [(ngModel)]="UserSetupModel.BankIMName" type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Bank IM Email <span id="starField">*</span></label>
                                <input name="txtBankIMEmail" id="txtBankIMEmail" [(ngModel)]="UserSetupModel.BankIMEmail" type="text"
                                    class="form-control" />
                            </div>
                        </div>                        
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Status <span id="starField">*</span></label>
                                <select name="ddlIsActive" id="ddlIsActive" [(ngModel)]="UserSetupModel.IsActive" class="form-control">
                                    <option value="">Select</option>
                                    <option value="True">Active</option>
                                    <option value="False">Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-right form-submit-section" id="bDiv">
                    <input type="submit" (click)="Submit()" class="btn btn-primary"  id="HideBtn" value="Submit" />
                </div>
            </div>
        </form>
    </div>
</div>

<!-- Create New Role -->
<div id="company-setup" class="modal fade" role="dialog">
    <form class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">New Company</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Company Name <span id="starField">*</span></label>
                            <input [(ngModel)]="CompanySetupModel.Name" name="txtCompanyName" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Status <span id="starField">*</span></label>
                            <select [(ngModel)]="CompanySetupModel.IsActive" name="IsActive" class="form-control">
                                <option value="">Select</option>
                                <option value="True">Active</option>
                                <option value="False">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="CompanySubmit()" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
</div>

<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" id="close" (click)="SuccessRedirect()">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="LoaderStatus == true" class="submit-loader">
    <img src="../../assets/images/loader.gif" alt="">
</div>