

<div class="container" style="margin-top: 40px;">
    <div class="row">
        <h1>Sitemap</h1>
        <h4 class="hTag">View Connect<span class="connectbankclr" >Bank</span> Sitemap</h4>
        <hr style="margin-top: 50px;">
        <h4 class="hTag"> Connect<span class="connectbankclr" >Bank</span> IN > Sitemap</h4>   
    </div>
    <div class="row sitemap">
        <div class="col-md-3">
            <a href="">Home</a>
        </div>
        <div class="col-md-3">
            <a href="/login">Login</a>
        </div>
        <div class="col-md-3">
            <a href="#Overview">Overview</a>
        </div>
        <div class="col-md-3">
            <a href="#features">Features</a>
        </div>
        
    </div>
    <div class="row sitemap">
        <div class="col-md-3">
            <a href="#howitworks">How it works</a>
        </div>
        <div class="col-md-3">
            <a href="#howtostart">How to Start</a>
        </div>
        <div class="col-md-3">
            <a href="#Compability">Compatibilty</a>
        </div>
        <div class="col-md-3">
            <a href="/termsandcondition">Terms & Condition</a>
        </div>
    </div>
    <div class="row sitemap">
        <div class="col-md-3">
            <a href="/privacypolicy">Privacy Policy</a>
        </div>
        <div class="col-md-3">
            <a href="#FAQs">FAQs</a>
        </div>
    </div>
</div>


