import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanySetupModel, UserSetupModel } from '../Model/common.model';
import { CommonService } from '../services/common.service';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.css']
})
export class CreateCustomerComponent implements OnInit {
  PageStatus: string;
  RoleID: any;
  RoleDD: any;
  CompanyDD: any;
  UserID: string;
  CompanyID: string;
  LoaderStatus: boolean = false;
  UserSetupModel: UserSetupModel;
  CompanySetupModel: CompanySetupModel;
  constructor(private Service: CommonService, private Router: Router, private ActivatedRoute: ActivatedRoute) { 
    this.UserSetupModel = new UserSetupModel();
    this.CompanySetupModel = new CompanySetupModel();
  }

  ngOnInit(): void {
    var todaysDate = new Date();
    $("#when-entered").val(todaysDate.getFullYear() + "/" + (todaysDate.getMonth() + 1) + "/" + todaysDate.getDate());
    this.UserID = this.ActivatedRoute.snapshot.params["UserID"];
    this.PageStatus = this.ActivatedRoute.snapshot.params["pageStatus"];
    this.GetCompanyDD();
    this.GetRoleDD();
    if(this.UserID != undefined) {      
      this.GetUserDetail(this.UserID);      
    }
    if(this.PageStatus == 'view'){
      $("#ddlCompanyName").attr('disabled', 'disabled');
      $("#txtUserName").attr('disabled', 'disabled');
      $("#txtUserEmail").attr('disabled', 'disabled');
      $("#txtUserMobile").attr('disabled', 'disabled');
      $("#txtBankIMName").attr('disabled', 'disabled');
      $("#txtBankIMEmail").attr('disabled', 'disabled');
      $("#txtPassword").attr('disabled', 'disabled');
      $("#ddlRole").attr('disabled', 'disabled');
      $("#ddlIsActive").attr('disabled', 'disabled');
    }
  }

  GetUserDetail(UserID) {
    this.Service.GetUserDetail(UserID).subscribe((res: any) => {
        this.UserSetupModel = res;
        $("#when-entered").val(this.UserSetupModel.WhenEntered);
    })
  }

  GetCompanyDD() {
    this.Service.GetCompanyDD().subscribe((res) => {
      this.CompanyDD = res;
    })
  }

  GetRoleDD() {
    this.Service.RoleDD().subscribe((res) => {
      this.RoleDD = res;
    })
  }

  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void{
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $("#succeess-message-box").modal("show");
  }

  HideShow(){
    $("#errorclose").show();
    $("#close").hide();
  }

  Submit(): void {
    this.LoaderStatus = true;
    this.UserSetupModel.WhenEntered = $("#when-entered").val().toString();
    this.UserSetupModel.CreatedBy = localStorage.getItem("UserID");
    if (this.UserSetupModel.CompanyID == "" || this.UserSetupModel.CompanyID == null || this.UserSetupModel.CompanyID == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Select Company Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }    
    else if (this.UserSetupModel.UserName == "" || this.UserSetupModel.UserName == null || this.UserSetupModel.UserName == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Enter User Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.UserSetupModel.UserEmail == "" || this.UserSetupModel.UserEmail == null || this.UserSetupModel.UserEmail == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Enter User Email", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.UserSetupModel.UserMobile == "" || this.UserSetupModel.UserMobile == null || this.UserSetupModel.UserMobile == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Enter User Mobile", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }    
    else if (this.UserSetupModel.Password == "" || this.UserSetupModel.Password == null || this.UserSetupModel.Password == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Enter Password", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.UserSetupModel.RoleID == "" || this.UserSetupModel.RoleID == null || this.UserSetupModel.RoleID == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Select Role", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.UserSetupModel.BankIMName == "" || this.UserSetupModel.BankIMName == null || this.UserSetupModel.BankIMName == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Enter Bank IM's Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.UserSetupModel.BankIMEmail == "" || this.UserSetupModel.BankIMEmail == null || this.UserSetupModel.BankIMEmail == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Enter Bank IM's Email", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.UserSetupModel.IsActive == "" || this.UserSetupModel.IsActive == null || this.UserSetupModel.IsActive == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Please Select Status", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.Service.UserSetup(this.UserSetupModel).subscribe((data) => {
        debugger;
        if(data == "0"){
          this.HideShow();
          this.LoaderStatus = false;
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
        }
        else {
          $("#errorclose").hide();
          $("#close").show();
          this.LoaderStatus = false;
          this.popupMessage("User Setup Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");          
        }
      });
    }
  }

  CompanySubmit() {
    if (this.CompanySetupModel.Name == "" || this.CompanySetupModel.Name == null || this.CompanySetupModel.Name == undefined) {
      this.HideShow();
      this.popupMessage("Please Enter Company Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }    
    else if (this.CompanySetupModel.IsActive == "" || this.CompanySetupModel.IsActive == null || this.CompanySetupModel.IsActive == undefined) {
      this.HideShow();
      this.popupMessage("Please Select Status", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.Service.CompanySetup(this.CompanySetupModel).subscribe((res) => {
        if(res == true) {
          $("#company-setup").modal('hide');
          this.GetCompanyDD();
          this.HideShow();   
          this.popupMessage("Company added successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");          
        }
        else {
          $("#company-setup").modal('hide');
          this.HideShow();   
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");          
        }
      })
    }    
  }

  SuccessRedirect() {
    debugger;
    $("#succeess-message-box").modal("hide");
    if(this.UserID != undefined) {
      this.Router.navigate(['../../../customerList'], { relativeTo: this.ActivatedRoute })
    }
    else {
      this.Router.navigate(['../customerList'], { relativeTo: this.ActivatedRoute })
    }
  }
}
