<div class="container-fluid">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">Create Engagement Work</h3>
        </div>
        <div class="col-xs-6 text-right">
            <!-- <a class="btn btn-success">Back to List</a> -->
            <a class="btn btn-success" *ngIf="Id == undefined" [routerLink]="'../customerWorkList'" >Back to
                List</a>
                <a class="btn btn-success" *ngIf="Id != undefined" [routerLink]="'../../customerWorkList'" >Back to
                    List</a>
        </div>
    </div>
    <hr>
    <div class="formSection">
        <h3 class="selectionFilterTitle">Customer Data</h3>
        <div class="row formSectionBox">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Customer Name <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.CustomerId" name="CustomerId" >
                        <option value="">Select</option>
                        <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Bank IM Name <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.BankIM" name="BankIM" >
                        <option value="">Select</option>
                    <option *ngFor="let record of BankIMDD" value="{{record.UserID}}">{{record.BankIMName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6 ">
                <div class="form-group">
                    <label>ATP Signed <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.ATPSigned" name="ATPSigned" >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Comm Agreed <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.CommAgreed" name="CommAgreed" >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Paid By Customer <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.PaidByCustomer" name="PaidByCustomer" >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>PO Received <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.POReceived" name="POReceived" >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Invoiced <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.Invoiced" name="Invoiced" >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Payment Received <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.PaymentReceived" name="PaymentReceived" >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Start Deployment <span id="starField">*</span></label>
                    <select class="form-control" [(ngModel)]="CustomerWorkSetupModel.StartDeployment" name="StartDeployment" >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div class="col-md-12 form-inline textArea" style>
                <label>Comments</label>
                <textarea  class="form-control"  cols="30"  [(ngModel)]="CustomerWorkSetupModel.CheckListComment" name="CheckListComment" 
                placeholder="Comments"></textarea>
            </div>
        </div>
    </div>
    <div class="formSection" style="margin-top: 30px;">
        <h3 class="selectionFilterTitle">Work Details</h3>
        <div class="row formSectionBox">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Date <span id="starField">*</span></label>
                    <input type="date" class="form-control" style="width: 50%;"  [(ngModel)]="CustomerDetailsModel.Date" name="Date"  />
                </div>
            </div>
            <div class="col-md-6 form-inline" id="arrachmentbottom">
                <label>Attachment</label>
                <input type="file" id="AttachDisabled" class="form-control" style="width: 50%;" (change)="selectFile($event)" >
            </div>
           <div class="col-md-12 form-inline textArea">
                <label>Work Comments <span id="starField">*</span></label>
                <textarea name="IssueDescription" class="form-control" id="DescDisabled" cols="30" rows="4" [(ngModel)]="CustomerDetailsModel.WorkComment" name="WorkComment"   
                placeholder="Work Comments"></textarea>
            </div>
            <button class="btn btn-success" (click)="AddCart()" style="float: right; margin-bottom: 10px;"><i
                class="fa fa-plus" aria-hidden="true"></i>
            Add</button>
            <table class="table table-bordered" >
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Work Comments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of CustomerDetailsList; let i = index">
                        <td>{{record.Date }}</td>
                        <td>{{record.WorkComment}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" style="margin-top: 10px;">
        <div class="col-md-12 text-right">
            <button  id="SubmitBtn"  class="btn btn-primary" (click)="Submit()" >Submit</button>
        </div> 
    </div>
</div>


<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal" (click)="SuccessRedirect()">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>