<div class="container" style="margin-top: 40px;" >
    <div class="row">
        <div class="col-md-12" id="tac">
            <h1>Terms & Conditions</h1>
            <p>IMPORTANT - PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING THIS WEBSITE AND/OR USING THE
                ONLINE SERVICES, YOU AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT ACCEPT ANY
                OF THESE TERMS OR CONDITIONS, YOU MUST IMMEDIATELY DISCONTINUE YOUR ACCESS OF THIS WEBSITE AND/OR USE OF
                THE ONLINE SERVICES.
            </p><br>
            <h4><u>Copyright and Trademark Notices</u></h4>
            <p>
                Except as otherwise expressly stated herein, the copyright and all other intellectual property in the
                contents of this website (including, but not limited to, all design, text, sound recordings, images or
                links) are the property of Copia Consultancy Services Pvt Ltd ( Copia) ("COPIA "). As such, they may not
                be reproduced, transmitted, published, performed, broadcast, stored, adapted, distributed, displayed,
                licensed, altered, hyperlinked or otherwise used in whole or in part in any manner without the prior
                written consent of the and or COPIA . Save and except with the COPIA ’s prior written consent, you may
                not insert a hyperlink to this website or any part thereof on any other website or "mirror" or frame
                this website, any part thereof, or any information or materials contained in this website on any other
                server, website or webpage.
            </p>
            <p>
                All trademarks, service marks and logos used in this website are the property of the COPIA / and/or the
                respective third party proprietors identified in this website. No license or right is granted and your
                access to this website and/or use of the online services should not be construed as granting, by
                implication, estoppel or otherwise, any license or right to use any trademarks, service marks or logos
                appearing on the website without the prior written consent of the COPIA or the relevant third party
                proprietor thereof. Save and except with the COPIA ’s/’s prior written consent, no such trade mark,
                service mark or logo may be used as a hyperlink or to mark any hyperlink to any COPIA ’s member's site
                or any other site.
            </p> <br>
            <h4><u>Disclaimer</u></h4>
            <p>
                The information and materials contained in or accessed through this website are provided on an "as is"
                and
                "as available" basis and are of a general nature which have not been verified, considered or assessed by
                COPIA in relation to the making of any specific investment, business, financial or commercial decision.
                Such
                information and materials are provided for general information only and you should seek professional
                advice
                at all times and obtain independent verification of the information and materials contained herein
                before
                making any decision based on any such information or materials.
            </p>
            <p>The COPIA does not warrant the truth, accuracy, adequacy, completeness or reasonableness of the
                information and materials contained in or accessed through this website and expressly disclaims
                liability for any errors in, or omissions from, such information and materials. No warranty of any kind,
                implied, express or statutory (including but not limited to, warranties of title, merchantability,
                satisfactory quality, non-infringement of third-party intellectual property rights, fitness for a
                particular purpose and freedom from computer virus and other malicious code), is given in conjunction
                with such information and materials, or this website in general.
            </p>
            <p>
                Under no circumstances shall the COPIA be liable regardless of the form of action for any failure of
                performance, system, server or connection failure, error, omission, interruption, breach of security,
                computer virus, malicious code, corruption, delay in operation or transmission, transmission error or
                unavailability of access in connection with your accessing this website and/or using the online services
                even if COPIA had been advised as to the possibility.
            </p>
            <p>
                In no event shall the COPIA be liable to you or any other party for any damages, losses, expenses or
                costs whatsoever (including without limitation, any direct, indirect, special, incidental or
                consequential damages, loss of profits or loss opportunity) arising in connection with your use of this
                website, or reliance on any information, materials or online services provided at this website,
                regardless of the form of action and even if the COPIA had been advised as to the possibility of such
                damages.
            </p><br>
            <h4><u>Hyperlinks</u></h4>
            <p>
                For your convenience, the COPIA may include hyperlinks to websites on the Internet that are owned or
                operated by third parties. Such linked websites are not under the control of COPIA and COPIA cannot
                accept responsibility for the contents of or the consequences of accessing any linked website or any
                link contained in a linked website. Furthermore, the hyperlinks provided in this website shall not be
                considered or construed as an endorsement or verification of such linked websites or the contents
                therein. You agree that your access to and/or use of such linked websites is entirely at your own risk
                and subject to the terms and conditions of access and/or use contained therein.
            </p>
            <br>
            <h4><u>Indemnity</u></h4>
            <p>
                You hereby agree to indemnify and save the COPIA harmless against all damages, losses, expenses and
                costs (including legal costs) suffered or incurred by the COPIA in connection with or arising from (1)
                your access of this website and/or use of the online services, or (2) any other party's access of this
                website and/or use of the online services using your user id and/or login password, or (3) your breach
                of any of these Terms and Conditions of Access, or (4) any other party's breach of any of these Terms
                and Conditions of Access where such party was able to access this website and/or use the online services
                by using your user id and/or login password.
            </p> <br>
            <h4><u>Miscellaneous</u></h4>
            <p>
                The information and materials contained in or accessed through this website shall not be considered or
                construed as an offer or solicitation to sell, buy, give, take, issue, allot or transfer, or as the
                giving of any advice in respect of shares, stocks, bonds, notes, interests, unit trusts, mutual funds or
                other securities, investments, loans, advances, credits or deposits in any jurisdiction.
            </p>
            <p>
                The information and materials herein are subject to change (including, without limitation, modification,
                deletion or replacement thereof) without notice.
            </p>
            <p>
                COPIA may terminate your access to this website and/or your use of the online services at any time
                without notice and without assigning any reason thereof.
            </p>
            <br>
            <h4><u>Governing Law and Jurisdiction</u></h4>
            <p>
                Nothing herein shall be construed as a representation by the COPIA that the information and materials
                contained in or accessed through this website is appropriate or available for use in geographic areas or
                jurisdictions other than India. By accessing this website and/or using the online services, you agree
                that such access and/or use, as well as these Terms and Conditions of Access shall be governed by and
                construed in accordance with Laws of India and you hereby agree to submit to the non-exclusive
                jurisdiction of Courts of India.
            </p>
        </div>
    </div>
</div>
