<div class="container-fluid createWorkFlowPage">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">New Workflow</h3>
        </div>
        <div class="col-xs-6 text-right">
            <a (click)="Save()" style="color: black;" class="btn btn-success"><i class="fa fa-floppy-o fa-lg" aria-hidden="true">
                    Save</i></a>
        </div>
    </div>
    <hr>
    <div *ngIf="status == 'on1'" class="firstSection">
        <div class="row">
            <div class="col-md-6 form-inline">
                <label>Do you need File Transmission? <span id="starField">*</span>
                    <a data-title="Do you need File Transmission?"><i class="fa fa-2x fa-info-circle"></i></a>
                </label>
                <select [(ngModel)]="WorkflowRequirementsModel.FileTransmission" name="FileTransmission"
                    class="form-control">
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>

            </div>
            <ng-template [ngIf]="WorkflowRequirementsModel.FileTransmission == '1'">
                <div class="col-md-6 form-inline">
                    <label>Select Transmission Type <span id="starField">*</span>
                        <a data-title="Select Transmission Type"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <select [(ngModel)]="WorkflowRequirementsModel.TransmissionTypeID" name="TransmissionTypeID"
                        class="form-control">
                        <option value="">Select</option>
                        <option *ngFor="let record of TransmissionTypeDD" value="{{record.ID}}">
                            {{record.TransmissionType}}</option>
                    </select>

                </div>
            </ng-template>
        </div>
        <ng-template [ngIf]="WorkflowRequirementsModel.TransmissionTypeID == '1'">
            <div class="row">
                <div class="col-md-6 form-inline">
                    <label>Do you need ACK files from Bank? <span id="starField">*</span>
                        <a data-title="Do you need ACK files from Bank?"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <select [(ngModel)]="WorkflowRequirementsModel.ACKFromBank" name="ACKFromBank" class="form-control">
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>

                </div>
                <ng-template
                    [ngIf]="WorkflowRequirementsModel.ACKFromBank == '1' && WorkflowRequirementsModel.TransmissionTypeID == '1'">
                    <div class="col-md-6 form-inline">
                        <label>Select Folder name to get ACK Files <span id="starField">*</span>
                            <a data-title="Select Folder name to get ACK Files"><i
                                    class="fa fa-2x fa-info-circle"></i></a>
                        </label>
                        <select [(ngModel)]="WorkflowRequirementsModel.ACKFileFolderNameID" name="ACKFileFolderNameID"
                            class="form-control">
                            <option value="">Select</option>
                            <option *ngFor="let record of SftpFolderDD" value="{{record.ID}}">
                                {{record.Folder}}</option>
                        </select>

                    </div>
                </ng-template>
            </div>
        </ng-template>

        <ng-template [ngIf]="WorkflowRequirementsModel.TransmissionTypeID == '1'">
            <div class="row">
                <div class="col-md-6 form-inline">
                    <label>Do you need reports from bank <span id="starField">*</span>
                        <a data-title="Do you need reports from bank"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <select [(ngModel)]="WorkflowRequirementsModel.ReportFromBak" name="ReportFromBak"
                        class="form-control">
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>

                </div>
                <ng-template
                    [ngIf]="WorkflowRequirementsModel.ReportFromBak == '1' && WorkflowRequirementsModel.TransmissionTypeID == '1'">
                    <div class="col-md-3 marginSmallScreen">
                        <label>Reports <span id="starField">*</span>
                            <a data-title="Select Reports"><i class="fa fa-2x fa-info-circle"></i></a>
                        </label>
                        <button class="btn dropdown-toggle multipleSelection" type="button" data-toggle="dropdown"
                            style="    border-bottom: 1px px solid black">Select
                            <i class="fa fa-angle-down" style="margin-left: 15px;"></i></button>
                        <ul class="dropdown-menu" style="margin-left: 123px;">
                            <li style="margin-left: 25px;" *ngFor="let record of ReportTypeDD"><input
                                    style="margin-left: 0px;" [checked]="record.IsActive == '1'"
                                    (change)="ReportTypeSelection(record.ID, $event)" type="checkbox">
                                <span style="margin-left: 5px;">{{record.ReportType}}</span>
                            </li>
                        </ul>

                    </div>
                    <div class="col-md-3 form-inline">
                        <label title="Select folder name on HSBC report will be fecth">Folder <span
                                id="starField">*</span>
                            <a data-title="Select folder"><i class="fa fa-2x fa-info-circle"></i></a>
                        </label>
                        <select title="Select folder name on HSBC report will be fecth" class="form-control"
                            [(ngModel)]="WorkflowRequirementsModel.ReportFolderID" name="ReportFolderID">
                            <option value="">Select</option>
                            <option *ngFor="let record of SftpFolderDD" value="{{record.ID}}">
                                {{record.Folder}}</option>
                        </select>

                    </div>
                </ng-template>
            </div>
        </ng-template>

        <div class="row">
            <div class="col-md-6 form-inline">
                <label>Need Encryption? <span id="starField">*</span>
                    <a data-title="Need Encryption?"><i class="fa fa-2x fa-info-circle"></i></a>
                </label>
                <select [(ngModel)]="WorkflowRequirementsModel.NeedEncryption" name="NeedEncryption"
                    class="form-control">
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>

            </div>
        </div>
        <div class="row">
            <div class="col-md-12 form-inline">
                <label>Does your ERP provide files in Bank Required format? <span id="starField">*</span>
                    <a data-title="Does your ERP provide files in Bank Required format?"><i
                            class="fa fa-2x fa-info-circle"></i></a>
                </label>
                <select [(ngModel)]="WorkflowRequirementsModel.FilesInBankFormat" name="FilesInBankFormat"
                    class="form-control">
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>

            </div>
        </div>
        <div class="row">
            <div class="col-md-12 form-inline">
                <label> Do you need emails on interface failures <span id="starField">*</span>
                    <a data-title="Do you need emails on interface failures"><i class="fa fa-2x fa-info-circle"></i></a>
                </label>
                <select [(ngModel)]="WorkflowRequirementsModel.NeedEmail" name="NeedEmail" class="form-control">
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>

            </div>
        </div>
        <ng-template [ngIf]="WorkflowRequirementsModel.NeedEmail == '1'">
            <div class="row">
                <div class="col-md-12 form-inline">
                    <label title="Multiple Email addresses will be ';' (Semicolon) separated">Enter receipients email
                        address <span id="starField">*</span>
                        <a data-title="Enter receipients email address"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <input [(ngModel)]="WorkflowRequirementsModel.ReceipientEmail" name="ReceipientEmail" type="text"
                        name="" placeholder="Enter Recipients Email address" class="form-control"
                        title="Multiple Email addresses will be ';' (Semicolon) separated">

                </div>
            </div>
        </ng-template>
    </div>
    <div class="row" *ngIf="status == 'on1'" style="margin-bottom: 5px;">
        <div class="col-md text-right">
            <button class="btn btn-warning nextBtn" (click)="secondfourthFifthPage()">&#8250;</button>
        </div>
    </div>

    <div class="firstSection" *ngIf="status == 'on2'">
        <div class="row">
            <div class="col-md-12">
                <h3 style="font-size: 28px; color: #2466EBFF;"><u>Add Payment Types</u>
                    <button class="btn btn-success" (click)="AddToCartPaymentMethods()" style="float: right;"><i
                            class="fa fa-plus" aria-hidden="true"></i>
                        Add</button>
                </h3>

            </div>
            <table class="table table-bordered ">
                <thead>
                    <tr>
                        <th scope="col">Payment Type <span id="starField">*</span> <a data-title="Payment Type"><i
                                    class="fa fa-2x fa-info-circle"></i></a></th>
                        <th scope="col">Input File Type <span id="starField">*</span> <a data-title="Input File Type"><i
                                    class="fa fa-2x fa-info-circle"></i></a></th>
                        <th scope="col">File Identifier <span id="starField">*</span> <a data-title="File Identifier"><i
                                    class="fa fa-2x fa-info-circle"></i></a></th>
                        <th scope="col">Transmission Type <span id="starField">*</span> <a
                                data-title="Transmission Type"><i class="fa fa-2x fa-info-circle"></i></a></th>
                        <th scope="col">Encryption <span id="starField">*</span> <a data-title="Encryption"><i
                                    class="fa fa-2x fa-info-circle"></i></a></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <select [(ngModel)]="PaymentMethodsModel.PaymentTypeID" name="PaymentTypeID"
                                id="PaymentTypeID" class="form-control">
                                <option value="">Select</option>
                                <option *ngFor="let record of PaymentTypeDD" value="{{record.ID}}">
                                    {{record.PaymentType}}</option>
                            </select>

                        </td>
                        <td>
                            <select [(ngModel)]="PaymentMethodsModel.InputFileTypeID" name="InputFileTypeID"
                                id="InputFileTypeID" class="form-control" style="width: 50%;">
                                <option value="">Select</option>
                                <option *ngFor="let record of InputFileTypeDD" value="{{record.ID}}">
                                    {{record.InputFileType}}</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" placeholder="File Indentifier"
                                [(ngModel)]="PaymentMethodsModel.FileIndentifier" name="FileIndentifier"
                                id="FileIndentifierID" class="form-control" style="width: 60%;">
                        </td>
                        <td>
                            <select [(ngModel)]="PaymentMethodsModel.TransmissionTypeID" name="TransmissionTypeID"
                                id="TransmissionTypeID" class="form-control" style="width: 45%;">
                                <option value="">Select</option>
                                <option *ngFor="let record of TransmissionTypeDD" value="{{record.ID}}">
                                    {{record.TransmissionType}}</option>
                            </select>
                        </td>
                        <td>
                            <select [(ngModel)]="PaymentMethodsModel.EncryptionID" name="Encryption" id="EncryptionID"
                                class="form-control" style="width: 65%;">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngFor="let data of PaymentMethodsList; let i = index">
                        <td>{{data.PaymentType}}</td>
                        <td>{{data.InputFileType}}</td>
                        <td>{{data.FileIndentifier}}</td>
                        <td>{{data.TransmissionType}}</td>
                        <td>{{data.Encryption}}</td>
                        <td><button class="btn btn-danger" (click)="DeleteCartPaymentMethods(i)"><i class="fa fa-trash"
                                    aria-hidden="true"></i> Delete</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="status == 'on2'">
        <div class="col-md text-right">
            <button class="btn btn-warning nextBtn" (click)="firstPage()">&#8249;</button>
            <button class="btn btn-warning nextBtn" (click)="thirdPage()">&#8250;</button>
        </div>
    </div>


    <div style="margin-top: 20px;" *ngIf="status == 'on3'" class="firstSection">
        <div class="col-md-12 form-inline">
            <label>Profile ID <span id="starField">*</span>
                <a data-title="Profile ID"><i class="fa fa-2x fa-info-circle"></i></a>
            </label>
            <input type="text" [(ngModel)]="WorkflowRequirementsModel.ProfileID" name="ProfileID" class="form-control"
                placeholder="Enter Profile ID">

        </div>
        <div class="col-md-12">
            <h3 style="font-size: 28px; color: #2466EBFF;"><u>Add Accounts Number With The Bank</u>
                <button class="btn btn-success" style="float: right;" (click)="AddToCartAccountNumbers()"><i
                        class="fa fa-plus" aria-hidden="true"></i>
                    Add</button>
            </h3>
        </div>
        <div class="row">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Account Name <span id="starField">*</span>
                            <a data-title="Account Name"><i class="fa fa-2x fa-info-circle"></i></a>
                        </th>
                        <th scope="col">Account Number <span id="starField">*</span>
                            <a data-title="Account Number"><i class="fa fa-2x fa-info-circle"></i></a>
                        </th>
                        <th scope="col">Country <span id="starField">*</span>
                            <a data-title="Country"><i class="fa fa-2x fa-info-circle"></i></a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type="text" placeholder="Account Name" [(ngModel)]="AccountNumbersModel.AccountName"
                                name="AccountName" id="AccountName" class="form-control" style="width: 60%">

                        </td>
                        <td>
                            <input type="text" placeholder="Account Number" style="width: 250px;"
                                [(ngModel)]="AccountNumbersModel.AccountNo" name="AccountNo" id="AccountNo"
                                class="form-control">

                        </td>
                        <td>
                            <select [(ngModel)]="AccountNumbersModel.CountryID" name="CountryID" id="Country"
                                style="width: 60%" class="form-control">
                                <option value="">Select</option>
                                <option value="1">India</option>
                            </select>

                        </td>
                        <td><button type="button" title="Add More Info" class="btn btn-primary"
                                (click)="moreInfoModal()">More Info</button></td>
                    </tr>

                    <tr *ngFor="let data of AccountNumberList; let i = index">
                        <td>{{data.AccountName}}</td>
                        <td>{{data.AccountNo}}</td>
                        <td>{{data.Country}}</td>
                        <td><button class="btn btn-danger" (click)="DeleteCartAccountNumbers()"><i class="fa fa-trash"
                                    aria-hidden="true"></i>
                                Delete</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="status == 'on3'">
        <div class="col-md text-right">
            <button class="btn btn-warning nextBtn" (click)="secondPage()">&#8249;</button>
            <button class="btn btn-warning nextBtn" (click)="fourthFifthPage()">&#8250;</button>
        </div>
    </div>

    <div style="margin-top: 20px;" *ngIf="status == 'on4'" class="firstSection">
        <div class="row">
            <div class="col-md-6 form-inline">
                <label>Payments in Foreign Currency (Vostro) <span id="starField">*</span> 
                    <a data-title="Payments in Foreign Currency (Vostro)"><i class="fa fa-2x fa-info-circle"></i></a>
                </label>
                <select [(ngModel)]="WorkflowRequirementsModel.Vostro" name="Vostro" class="form-control">
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
               
            </div>
            <ng-template [ngIf]="WorkflowRequirementsModel.Vostro == '1'">
                <div class="col-md-4 form-inline">
                    <label>BIC Code <span id="starField">*</span> 
                        <a data-title="BIC Code"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <input type="text" [(ngModel)]="WorkflowRequirementsModel.BICCode" name="BICCode"
                        placeholder="BIC Code" class="form-control" style="width: 50%;">
                    
                </div>
            </ng-template>
        </div>
        <ng-template [ngIf]="WorkflowRequirementsModel.Vostro == '1'">
            <div class="row" style="margin-top: 40px;">
                <div class="col-md-4 form-inline">
                    <label>Agent Name <span id="starField">*</span> 
                        <a data-title="Agent Name"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <input type="text" [(ngModel)]="WorkflowRequirementsModel.AgentName" name="AgentName"
                        placeholder="Enter Agent Name" class="form-control" style="width: 50%;">
                   
                </div>
                <div class="col-md-4 form-inline">
                    <label>Building Name <span id="starField">*</span> 
                        <a data-title="Building Name"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <input type="text" [(ngModel)]="WorkflowRequirementsModel.BuildingName" name="BuildingName"
                        placeholder="Enter Building Name" class="form-control" style="width: 50%;">
                    
                </div>
                <div class="col-md-4 form-inline">
                    <label>Street Name <span id="starField">*</span> 
                        <a data-title="Street Name"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <input type="text" [(ngModel)]="WorkflowRequirementsModel.StreetName" name="StreetName"
                        placeholder="Enter Street Name" class="form-control" style="width: 50%;">
                    
                </div>
                <div class="col-md-4 form-inline">
                    <label>Town Name <span id="starField">*</span> 
                        <a data-title="Town Name"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <input type="text" [(ngModel)]="WorkflowRequirementsModel.TownName" name="TownName"
                        placeholder="Enter Town Name" class="form-control" style="width: 50%;">
                    
                </div>
                <div class="col-md-4 form-inline">
                    <label>Pin Code <span id="starField">*</span> 
                        <a data-title="Pin Code"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <input type="text" [(ngModel)]="WorkflowRequirementsModel.Pincode" name="Pincode"
                       placeholder="Enter Pin Code" class="form-control" style="width: 50%;">
                    
                </div>
                <div class="col-md-4 form-inline" >
                    <label>Country <span id="starField">*</span> 
                        <a data-title="Country"><i class="fa fa-2x fa-info-circle"></i></a>
                    </label>
                    <select [(ngModel)]="WorkflowRequirementsModel.Country" name="Country" class="form-control">
                        <option value="">Select</option>
                        <option value="1">India</option>
                    </select>
                   
                </div>
            </div>
        </ng-template>
    </div>

    <div class="row" *ngIf="status == 'on4'">
        <div class="col-md text-right">
            <button class="btn btn-warning nextBtn" (click)="thirdSecondFirstPage()">&#8249;</button>
            <button class="btn btn-warning nextBtn" (click)="fifthPage()">&#8250;</button>
        </div>
    </div>

    <div style="margin-top: 20px;" *ngIf="status == 'on5'" class="firstSection">
        <div class="row">
            <div class="col-md-12 form-inline">
                <label>Server Operating System <span id="starField">*</span> 
                    <a data-title="Server Operating System"><i class="fa fa-2x fa-info-circle"></i></a>
                </label>
                <select [(ngModel)]="WorkflowRequirementsModel.OperatingSystemID" name="OperatingSystemID" class="form-control">
                    <option value="">Select</option>
                    <option *ngFor="let record of OperatingSystemDD" value="{{record.ID}}">
                        {{record.OperatingSystem}}</option>
                </select>
               
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 form-group">
                <label>ERP Specific Comments <span id="starField">*</span> 
                    <a data-title="ERP Specific Comments"><i class="fa fa-2x fa-info-circle"></i></a>
                </label>
                <textarea [(ngModel)]="WorkflowRequirementsModel.ERPComment" name="ERPComment" id="" cols="95"
                    class="form-control" rows="3" placeholder="ERP Specific Comments"></textarea>
                
            </div>
            <div class="col-md-12 form-group" style="margin-top: 15px;">
                <label>Additional Requirements <span id="starField">*</span> 
                    <a data-title="Additional Requirements"><i class="fa fa-2x fa-info-circle"></i></a>
                </label>
                <textarea [(ngModel)]="WorkflowRequirementsModel.AdditionalRequirement" name="AdditionalRequirement"
                    id="" cols="95" class="form-control" rows="3" placeholder="Additional Requirements"></textarea>
            </div>
        </div>
        <div class="col-md-12 text-right" style="margin-top: 20px">
            <button class="btn btn-primary Submit" (click)="Submit()">Submit</button>
        </div>
    </div>

    <div class="row" *ngIf="status == 'on5'">
        <div class="col-md-12 text-right">
            <button class="btn btn-warning nextBtn" (click)="fourthThirdFirstPage()">&#8249;</button>
        </div>
    </div>
</div>
<div class="modal fade" id="moreInfoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            Add More Information
                            <button type="button" class="close" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 form-inline" >
                            <label>Building Name</label>
                            <input type="text" [(ngModel)]="AccountNumbersModel.BuildingName" name="BuildingName"
                                placeholder="Building Name" class="form-control" style="width: 75%;">
                        </div>
                        <div class="col-md-12 form-inline" >
                            <label>Street Name</label>
                            <input type="text" [(ngModel)]="AccountNumbersModel.StreetName" name="StreetName"
                                placeholder="Street Name" class="form-control" style="width: 75%;">
                        </div>
                        <div class="col-md-12 form-inline" >
                            <label>Town Name</label>
                            <input type="text" [(ngModel)]="AccountNumbersModel.TownName" name="TownName"
                                placeholder="Town Name" class="form-control" style="width: 75%;">
                        </div>
                        <div class="col-md-12 form-inline" >
                            <label>Pincode</label>
                            <input type="text" [(ngModel)]="AccountNumbersModel.Pincode" name="Pincode"
                                placeholder="Pincode" class="form-control" style="width: 75%;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="permissionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="font-size: 30px;">Do you really want to submit</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="permissionGranted()" data-dismiss="modal"><i
                        class="fa fa-check" aria-hidden="true"></i> Yes</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-remove"
                        aria-hidden="true"></i> No</button>
            </div>
        </div>
    </div>
</div>

<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default mClose" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-default mErrorClose" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
