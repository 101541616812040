<nav>
    <a class="logo-container" [routerLink]="'../masterPage/home'">
        <img src="../../../assets/images/logo.png" class="img-responsive" alt="logo" />
    </a>
    <ul class="level-one-menu">
        <li style="position: relative" *ngFor="let levelOneMenu of menus; let levelOneMenuIndex = index"
            class="menu-item">
            <a *ngIf="levelOneMenu.hasSubmenu == true" class="has-sub-menu"
                [ngClass]="{'menu-link-has-sub-menu-down': levelOneMenu.showSubMenu == false, 'menu-link-has-sub-menu-up': levelOneMenu.showSubMenu == true, 'menu-link' : true}"
                (click)="showSubMenu(levelOneMenuIndex, undefined)">{{levelOneMenu.levelOneMenu}}</a>
            <a routerLinkActive="active" *ngIf="levelOneMenu.hasSubmenu == false" [routerLink]="levelOneMenu.link"
                class="menu-link">{{levelOneMenu.levelOneMenu}}</a>
            <ul *ngIf="levelOneMenu.showSubMenu == true" class="level-two-menu">
                <li *ngFor="let levelTwoMenu of levelOneMenu.levelTwoMenus;  let levelTwoMenuIndex = index"
                    class="menu-item">
                    <a *ngIf="levelTwoMenu.hasSubmenu == true" class="menu-link-has-sub-menu menu-link"
                        (click)="showSubMenu(levelTwoMenuIndex, levelOneMenuIndex)">{{levelTwoMenu.levelTwoMenu}}</a>
                    <a routerLinkActive="active"
                        *ngIf="levelTwoMenu.hasSubmenu == false && levelTwoMenu.IsRouterLink == true"
                        [routerLink]="levelTwoMenu.link" [routerLinkActiveOptions]="{exact: true}"
                        class="menu-link">{{levelTwoMenu.levelTwoMenu}}</a>
                    <a *ngIf="levelTwoMenu.IsRouterLink == false" class="menu-link" href="{{levelTwoMenu.link}}" target="_blank">{{levelTwoMenu.levelTwoMenu}}</a>
                    <ul *ngIf="levelTwoMenu.showSubMenu == true" class="level-three-menu">
                        <li *ngFor="let levelThreeMenu of levelTwoMenu.levelThreeMenus">
                            <a class="menu-link">{{levelThreeMenu.levelThreeMenu}}</a>
                            <a class="menu-link">{{levelThreeMenu.levelThreeMenu}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>