
import { WorkflowRequirementsModel, PaymentMethodsModel, AccountNumbersModel } from './../Model1/common1.model';
import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-new-work-flow1',
  templateUrl: './create-new-work-flow1.component.html',
  styleUrls: ['./create-new-work-flow1.component.css']
})
export class CreateNewWorkFlow1Component implements OnInit {


  WorkflowRequirementsModel: WorkflowRequirementsModel;
  PaymentMethodsModel: PaymentMethodsModel;
  PaymentMethodsList: Array<PaymentMethodsModel>;
  AccountNumbersModel: AccountNumbersModel;
  AccountNumberList: Array<AccountNumbersModel>;
  status: string;
  file: any;
  // table = "hideTable";
  PaymentTypeDD: any;
  InputFileTypeDD: any;
  TransmissionTypeDD: any;
  SftpFolderDD: any;
  ReportTypeDD: any;
  OperatingSystemDD: any;
  ReportTypeDDSelection = [];
  WorkflowID: any;
  

  constructor(private Service: CommonService, private router: Router, private ActivatedRoute: ActivatedRoute) { 
    this.WorkflowRequirementsModel = new WorkflowRequirementsModel();
    this.PaymentMethodsModel = new PaymentMethodsModel();
    this.PaymentMethodsList = new Array<PaymentMethodsModel>();
    this.AccountNumbersModel = new AccountNumbersModel();
    this.AccountNumberList = new Array<AccountNumbersModel>();
  }

  ngOnInit(): void {
    this.status = "on1";
    this.GetPaymentTypeDD();
    this.GetInputFileTypeDD();
    this.GetTransmissionTypeDD();
    this.GetSftpFolderDD();
    this.GetReportTypeDD();
    this.GetOperatingSystemDD();
    this.GetLatestNumberRange();
    this.WorkflowID = this.ActivatedRoute.snapshot.params["workflowID"];
    if (this.WorkflowID != undefined) {
      this.GetWorkflowDetailNew1(this.WorkflowID);   
    }
  }

  GetPaymentTypeDD() {
    this.Service.GetPaymentTypeDD().subscribe((res) => {
      this.PaymentTypeDD = res;
    })
  }
  GetInputFileTypeDD() {
    this.Service.GetInputFileTypeDD().subscribe((res) => {
      this.InputFileTypeDD = res;
    })
  }
  GetTransmissionTypeDD() {
    this.Service.GetTransmissionTypeDD().subscribe((res) => {
      this.TransmissionTypeDD = res;
    })
  }
  GetSftpFolderDD() {
    this.Service.GetSftpFolderDD().subscribe((res) => {
      this.SftpFolderDD = res;
    })
  }
  GetReportTypeDD() {
    this.Service.GetReportTypeDD().subscribe((res) => {
      this.ReportTypeDD = res;
    })
  }
  GetOperatingSystemDD() {
    this.Service.GetOperatingSystemDD().subscribe((res) => {
      this.OperatingSystemDD = res;
    })
  }
  ReportTypeSelection(ReportID: string, checkboxEvent) {
    debugger;
    for (let i = 0; i < this.ReportTypeDD.length; i++) {
      if (this.ReportTypeDD[i].ID == ReportID) {
        if (checkboxEvent.target.checked == true) {
          this.ReportTypeDDSelection.push(ReportID);
        }
        else {
          var index = this.ReportTypeDDSelection.indexOf(ReportID);
          if (index !== -1) {
            this.ReportTypeDDSelection.splice(index, 1);
          }
        }
      }
    }
    this.WorkflowRequirementsModel.ReportID = this.ReportTypeDDSelection.toString();
  }
  AddToCartAccountNumbers(){
    if (this.AccountNumbersModel.AccountName == "" || this.AccountNumbersModel.AccountName == undefined) {
      this.HideShow();
      this.popupMessage("Enter Account Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.AccountNumbersModel.AccountNo == "" || this.AccountNumbersModel.AccountNo == undefined) {
      this.HideShow();
      this.popupMessage("Enter Account Number", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.AccountNumbersModel.CountryID == "" || this.AccountNumbersModel.CountryID == undefined) {
      this.HideShow();
      this.popupMessage("Select Country", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
     else {
      this.AccountNumbersModel.Country = $("#Country").find(":selected").text();
      this.AccountNumberList.push(this.AccountNumbersModel);
      this.AccountNumbersModel = new AccountNumbersModel();
     }
  }
  DeleteCartAccountNumbers(index) {
    this.AccountNumberList.splice(index, 1);
  }

  AddToCartPaymentMethods(){
    if (this.PaymentMethodsModel.PaymentTypeID == "" || this.PaymentMethodsModel.PaymentTypeID == undefined) {
      this.HideShow();
      this.popupMessage("Select Pyament Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.PaymentMethodsModel.InputFileTypeID == "" || this.PaymentMethodsModel.InputFileTypeID == undefined) {
      this.HideShow();
      this.popupMessage("Select Input File Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.PaymentMethodsModel.FileIndentifier == "" || this.PaymentMethodsModel.FileIndentifier == undefined) {
      this.HideShow();
      this.popupMessage("Select File Indentifier", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.PaymentMethodsModel.TransmissionTypeID == "" || this.PaymentMethodsModel.TransmissionTypeID == undefined) {
      this.HideShow();
      this.popupMessage("Select Transmission Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.PaymentMethodsModel.EncryptionID == "" || this.PaymentMethodsModel.EncryptionID == undefined) {
      this.HideShow();
      this.popupMessage("Select Encryption", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.PaymentMethodsModel.PaymentType = $("#PaymentTypeID").find(":selected").text();
      this.PaymentMethodsModel.InputFileType = $("#InputFileTypeID").find(":selected").text();
      this.PaymentMethodsModel.TransmissionType = $("#TransmissionTypeID").find(":selected").text();
      this.PaymentMethodsModel.Encryption = $("#EncryptionID").find(":selected").text();
      this.PaymentMethodsList.push(this.PaymentMethodsModel);
      this.PaymentMethodsModel = new PaymentMethodsModel();
    }
  }
  DeleteCartPaymentMethods(index) {
    this.PaymentMethodsList.splice(index, 1);
  }

  GetLatestNumberRange() {
    this.Service.GetLatestNumberRange("Workflow").subscribe((res: any) => {
      this.WorkflowRequirementsModel.WorkflowNo = res;
    })
  }

  GetWorkflowDetailNew1(WorkflowID) {
    debugger;
    this.Service.GetWorkflowDetailNew1(WorkflowID).subscribe((res: any) => {
      this.WorkflowRequirementsModel = res;
      this.PaymentMethodsList = res.AdapterDetailsNewList;
      this.AccountNumberList = res.EntitySetupNewList;
      const string1 = this.WorkflowRequirementsModel.ReportID;
      this.ReportTypeDDSelection = string1.split(',');
      for (let i = 0; i < this.ReportTypeDDSelection.length; i++) {
        for (let j = 0; j < this.ReportTypeDD.length; j++) {
          if (this.ReportTypeDDSelection[i] == this.ReportTypeDD[j].ID) {
            this.ReportTypeDD[j].IsActive = "1";
          }
        }
      } 
    }); 
  }
  
  Save() {
    debugger;
    this.WorkflowRequirementsModel.UserID = localStorage.getItem('UserID');
    this.WorkflowRequirementsModel.RoleID = localStorage.getItem('RoleID');
    this.WorkflowRequirementsModel.WorkflowStatus = "1";
    this.WorkflowRequirementsModel.AdapterDetailsNewList = this.PaymentMethodsList;
    this.WorkflowRequirementsModel.EntitySetupNewList = this.AccountNumberList;
    this.Service.WorkflowSetupNew(this.WorkflowRequirementsModel).subscribe(res => {
      $(".mClose").hide();
          $(".mErrorClose").show();
      this.popupMessage("Data updated successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
      if (this.WorkflowID != undefined) {
        this.router.navigate(['../../workflowList1'], { relativeTo: this.ActivatedRoute })
      }
      else {
        this.router.navigate(['../workflowList1'], { relativeTo: this.ActivatedRoute })
      }
    })
  }

  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void{
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $('#succeess-message-box').appendTo("body").modal('show');
  }

  HideShow(){
    $("#errorclose").show();
    $("#close").hide();
  }

  Submit():void {
    debugger;
    this.WorkflowRequirementsModel.ID = this.WorkflowID;
    this.WorkflowRequirementsModel.UserID = localStorage.getItem('UserID');
    this.WorkflowRequirementsModel.RoleID = localStorage.getItem('RoleID');
    this.WorkflowRequirementsModel.WorkflowStatus = "2";
    this.WorkflowRequirementsModel.AdapterDetailsNewList = this.PaymentMethodsList;
    this.WorkflowRequirementsModel.EntitySetupNewList = this.AccountNumberList;

    if (this.WorkflowRequirementsModel.FileTransmission == "" || this.WorkflowRequirementsModel.FileTransmission == undefined) {
      this.HideShow();
      this.popupMessage("Select File Transmission", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }

    else if ((this.WorkflowRequirementsModel.FileTransmission == '1') && (this.WorkflowRequirementsModel.TransmissionTypeID == "" || this.WorkflowRequirementsModel.TransmissionTypeID == undefined)) {
      this.HideShow();
      this.popupMessage("Select Transmission Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }

    else if ((this.WorkflowRequirementsModel.TransmissionTypeID == '1') && (this.WorkflowRequirementsModel.ACKFromBank == "" || this.WorkflowRequirementsModel.ACKFromBank == undefined)) {
      this.HideShow();
      this.popupMessage("Select need ACK files from Bank", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }

    else if ((this.WorkflowRequirementsModel.TransmissionTypeID == '1' && this.WorkflowRequirementsModel.ACKFromBank == '1') && (this.WorkflowRequirementsModel.ACKFileFolderNameID == "" || this.WorkflowRequirementsModel.ACKFileFolderNameID == undefined)) {
      this.HideShow();
      this.popupMessage("Select Folder name to get ACK Files", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }

    else if ((this.WorkflowRequirementsModel.TransmissionTypeID == '1') && (this.WorkflowRequirementsModel.ReportFromBak == "" || this.WorkflowRequirementsModel.ReportFromBak == undefined)) {
      this.HideShow();
      this.popupMessage(" need reports from bank", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }

    else if ((this.WorkflowRequirementsModel.TransmissionTypeID == '1' && this.WorkflowRequirementsModel.ReportFromBak == '1') && (this.WorkflowRequirementsModel.ReportFolderID == "" || this.WorkflowRequirementsModel.ReportFolderID == undefined)) {
      this.HideShow();
      this.popupMessage("Select Folder", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }

    else if (this.WorkflowRequirementsModel.NeedEncryption == "" || this.WorkflowRequirementsModel.NeedEncryption == undefined) {
      this.HideShow();
      this.popupMessage("Select Need Encryption", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }

    else if (this.WorkflowRequirementsModel.FilesInBankFormat == "" || this.WorkflowRequirementsModel.FilesInBankFormat == undefined) {
      this.HideShow();
      this.popupMessage("Select File In Bank Format", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.WorkflowRequirementsModel.NeedEmail == "" || this.WorkflowRequirementsModel.NeedEmail == undefined) {
      this.HideShow();
      this.popupMessage("Select Need Email", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.NeedEmail == '1') && (this.WorkflowRequirementsModel.ReceipientEmail == "" || this.WorkflowRequirementsModel.ReceipientEmail == undefined)) {
      this.HideShow();
      this.popupMessage("Enter Receipient Email", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.FilesInBankFormat == '0') && (this.WorkflowRequirementsModel.ProfileID == "" || this.WorkflowRequirementsModel.ProfileID == undefined)) {
      this.HideShow();
      this.popupMessage("Enter Profile ID", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.TransmissionTypeID == '2') && (this.WorkflowRequirementsModel.Vostro == "" || this.WorkflowRequirementsModel.Vostro == undefined)) {
      this.HideShow();
      this.popupMessage("Select Payments in Foreign Currency (Vostro)", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.Vostro == '1') && (this.WorkflowRequirementsModel.BICCode == "" || this.WorkflowRequirementsModel.BICCode == undefined)) {
      this.HideShow();
      this.popupMessage("Enter BIC Code", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.Vostro == '1') && (this.WorkflowRequirementsModel.AgentName == "" || this.WorkflowRequirementsModel.AgentName == undefined)) {
      this.HideShow();
      this.popupMessage("Enter Agent Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.Vostro == '1') && (this.WorkflowRequirementsModel.BuildingName == "" || this.WorkflowRequirementsModel.BuildingName == undefined)) {
      this.HideShow();
      this.popupMessage("Enter Building Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.Vostro == '1') && (this.WorkflowRequirementsModel.StreetName == "" || this.WorkflowRequirementsModel.StreetName == undefined)) {
      this.HideShow();
      this.popupMessage("Enter Street Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.Vostro == '1') && (this.WorkflowRequirementsModel.TownName == "" || this.WorkflowRequirementsModel.TownName == undefined)) {
      this.HideShow();
      this.popupMessage("Enter Town Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.Vostro == '1') && (this.WorkflowRequirementsModel.Pincode == "" || this.WorkflowRequirementsModel.Pincode == undefined)) {
      this.HideShow();
      this.popupMessage("Enter Pin Code", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.WorkflowRequirementsModel.Vostro == '1') && (this.WorkflowRequirementsModel.Country == "" || this.WorkflowRequirementsModel.Country == undefined)) {
      this.HideShow();
      this.popupMessage("Select Country", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.WorkflowRequirementsModel.OperatingSystemID == "" || this.WorkflowRequirementsModel.OperatingSystemID == undefined) {
      this.HideShow();
      this.popupMessage("Select Operating System", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.WorkflowRequirementsModel.ERPComment == "" || this.WorkflowRequirementsModel.ERPComment == undefined) {
      this.HideShow();
      this.popupMessage("Enter ERP Specific Comments", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.WorkflowRequirementsModel.AdditionalRequirement == "" || this.WorkflowRequirementsModel.AdditionalRequirement == undefined) {
      this.HideShow();
      this.popupMessage("Enter Additional Requirements", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else{
      $('#permissionModal').appendTo("body").modal('show');
   }
  }
  permissionGranted(){
    this.Service.WorkflowSetupNew(this.WorkflowRequirementsModel).subscribe(res => {
      $(".mClose").hide();
      $(".mErrorClose").show();
        this.popupMessage("Data updated successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
    if (this.WorkflowID != undefined) {
      this.router.navigate(['../../workflowList1'], { relativeTo: this.ActivatedRoute })
    }
    else {
      this.router.navigate(['../workflowList1'], { relativeTo: this.ActivatedRoute })
    }
  })
  }

  secondfourthFifthPage(){
    if (this.WorkflowRequirementsModel.FilesInBankFormat == '0' || this.WorkflowRequirementsModel.TransmissionTypeID == '2'){
      this.status = "on2";
    }
    else {
      this.status = "on5";
    }
  }

  thirdPage(){
    if (this.WorkflowRequirementsModel.FilesInBankFormat == '0'){
      this.status = "on3";
    }
    else
    {
      this.status = "on4";
    }
  }

  fourthFifthPage(){
    if (this.WorkflowRequirementsModel.TransmissionTypeID == '2'){
      this.status = "on4";
    } 
    else {
      this.status = "on5"
    }
  }
  fifthPage(){
      this.status = "on5";
  }
  fourthThirdFirstPage(){
    if (this.WorkflowRequirementsModel.TransmissionTypeID == '2'){
      this.status = "on4";
    } 
    else if (this.WorkflowRequirementsModel.FilesInBankFormat == '0'){
      this.status = "on3";
    }
    else {
      this.status = "on1";
    }
  }
  thirdSecondFirstPage(){
    if (this.WorkflowRequirementsModel.TransmissionTypeID == '2' && this.WorkflowRequirementsModel.FilesInBankFormat == "1"){
      this.status = "on2";
    }
    else if (this.WorkflowRequirementsModel.FilesInBankFormat == '0'){
      this.status = "on3";
    } 
    else{
      this.status = "on1";
    }
  }
  secondPage(){
    this.status = "on2";
  }
  firstPage(){
    this.status = "on1";
  }
  
  moreInfoModal(){
    $('#moreInfoModal').appendTo("body").modal('show');
  }
  
  successModal(){
    $('#successModal').appendTo("body").modal('show');
  }
  getFile(event: any){
    this.file = event.target.files[0];
  }

}
