<div class="document-container container-fluid">
    <h1 class="document-title text-center">Omni Channel Wrapper Code -
        <span class="language-name">Angular + Dot Net</span>
    </h1>
    <div class="row document-content-container">
        <div class="col-md-2">
            <div class="document-index">
                <ol>
                    <li>
                        <a>Overview</a>
                    </li>
                    <li>
                        <a>Pre-requisities</a>
                    </li>
                    <li>
                        <a routerLink="../wrapperangularDotnetDetail" fragment="stepsImplementation">Steps of Implementation</a>
                        <ul>
                            <li>
                                <a routerLink="../wrapperangularDotnetDetail" fragment="newPayment">newPayment</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperangularDotnetDetail" fragment="paymentEnquiry">paymentEnquiry</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperangularDotnetDetail" fragment="paymentCancellation">paymentCancellation</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperangularDotnetDetail" fragment="logFile">Log File</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperangularDotnetDetail" fragment="callApi">CallingAPI</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperangularDotnetDetail" fragment="iisSetting">IIS Setting</a>
                            </li>
                            <li>
                                <a routerLink="../wrapperangularDotnetDetail" fragment="callbackNotification">Callback Notification API</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a routerLink="../wrapperangularDotnetDetail" fragment="codeDetails">Code Details</a>
                    </li>
                </ol>
            </div>
        </div>
        <div class="col-md-10 document-description-container">
            <section class="document-section overview-container">
                <h2 class="document-section-title">1. Overview</h2>
                <div class="section-tab">
                    <div class="description-container">
                        <p>
                            Wrapper code has three functionalities which are submit payment, payment enquiry and cancel refund the payment. To implement
                            these functionalities on merchants side need to follow the points mentioned below.
                        </p>
                    </div>
                </div>
            </section>
            <section class="document-section overview-container">
                <h2 class="document-section-title">2. Pre-requisities</h2>
                <div class="section-tab">
                    <div class="description-container">
                        <p>
                            Install packages given below inside merchant’s project:
                        </p>
                    </div>
                    <div class="pre-requisit-list-container">
                        <ul>
                            <li>jose-jwt (v3.1.1): You can get this package from NuGet Package Manager or NuGet Console using
                                command:
                            </li>
                            <li>Install-Package jose-jwt -Version 3.1.1</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="document-section overview-container" id="stepsImplementation">
                <h2 class="document-section-title">3. Steps of Implementation</h2>
                <div class="section-tab">
                    <div class="description-container">
                        <p>
                            After installation of the packages, one need to add methods of wrapper code into merchant’s website code. Merchant just needs
                            to copy and paste the methods into his coding files.
                        </p>
                        <p>
                            These logical methods are related to the Bank’s API call, encryption, decryption and Payment gateway functionality. Below
                            are the details of every method along with the method call.
                        </p>
                    </div>
                    <ul>
                        <li class="method-detail" id="newPayment">
                            <app-wrapper-newpayment></app-wrapper-newpayment>
                        </li>
                        <li class="method-detail" id="paymentEnquiry">
                            <app-wrapper-paymentenquiry></app-wrapper-paymentenquiry>
                        </li>
                        <li class="method-detail" id="paymentCancellation">
                            <app-wrapper-paymentcancel></app-wrapper-paymentcancel>
                        </li>
                        <li class="method-detail" id="logFile">
                            <h3 class="title-level-one">Log Files</h3>
                            <div class="description-container">
                                <p>Create ErrorLog folder in the project and give path in CreateLogFiles method.</p>
                            </div>
                        </li>
                        <li class="method-detail" id="callApi">
                            <h3 class="title-level-one">CallingAPI</h3>
                            <div class="description-container">
                                <p>This method has API calling code which helps to call Bank’s API.</p>
                            </div>
                        </li>
                        <li class="method-detail" id="iisSetting">
                            <h3 class="title-level-one">IIS Setting</h3>
                            <div class="description-container">
                                <p>In IIS 7 and above go to Application Pools and right click on your project and go to advanced
                                    settings.
                                </p>
                                <P>In Advanced Settings pop up set Identity as NetworkService.</P>
                            </div>
                        </li>
                        <li class="method-detail" id="callbackNotification">
                            <h3 class="title-level-one">Callback Notification API</h3>
                            <div class="description-container">
                                <p>The Bank triggers this API and sends payment status back.</p>
                                <p>
                                    Merchant needs to include below method in its Website code. Bank will call this code and send the payment status. Merchant
                                    can update its End User or store the status received as per its implementation.
                                </p>
                                <p>The API has two arguments named as paymentStatus and txnRefNo. Both of these arguments are
                                    type string.
                                    <br>In case of a successful payment set paymentStatus value to success.
                                    <br>In case of payment failure set paymentStatus value to fail.
                                </p>
                                <p>It gives an acknowledgement(response) in JSON format as mentioned below.</p>
                            </div>
                            <div class="code-view">
                                <pre>
                                    <code [innerHTML]="apiResponseCode"></code>
                                </pre>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section class="document-section overview-container" id="codeDetails">
                <h2 class="document-section-title">4. Code Details</h2>
                <div class="section-tab">
                    <p>The wrapper code includes four classes and CommonRepository.cs file.</p>
                    <div class="method-detail">
                        <h3 class="title-level-one">Class Names</h3>
                        <ul>
                            <li>newPayment(PaymentPageRedirectDTOdto)</li>
                            <li>paymentEnquiry(PaymentEnquiryDTOdto)</li>
                            <li>paymentCancellation(PaymentCancellationDTOdto)</li>
                        </ul>
                    </div>
                    <div class="method-detail">
                        <h3 class="title-level-one">API</h3>
                        <ul>
                            <li>CallbackPaymentNotification</li>
                        </ul>
                    </div>
                    <div class="method-detail">
                        <h3 class="title-level-one">File Name</h3>
                        <ul>
                            <li>CommonRepository.cs</li>
                        </ul>
                    </div>
                    <div class="code-detail-container">
                        <h3 class="title-level-one">Method Details</h3>
                        <ol>
                            <li class="method-detail">
                                <h3 class="title-level-two">newPayment</h3>
                                <p>This method is used to make a new payment.</p>
                                <button title='copy code' class="copy-btn" (click)="copyBtn(paymentMethodCode)">
                                    <i class="fa fa-clone" aria-hidden="true"></i>
                                </button>
                                <div class="code-view">
                                    <pre>
                                                <code [innerHTML]="paymentMethodCode"></code>
                                              </pre>
                                </div>
                            </li>
                            <li class="method-detail">
                                <h3 class="title-level-two">paymentEnquiry</h3>
                                <p>This method is used to perform the status enquiry of the payments.</p>
                                <button title='copy code' class="copy-btn" (click)="copyBtn(paymentEnquiryMethodCode)">
                                    <i class="fa fa-clone" aria-hidden="true"></i>
                                </button>
                                <div class="code-view">
                                    <pre>
                                                <code [innerHTML]="paymentEnquiryMethodCode"></code>
                                              </pre>
                                </div>
                            </li>
                            <li class="method-detail">
                                <h3 class="title-level-two">paymentCancellation</h3>
                                <p>This method is used to cancel the order and refund the payment.</p>
                                <div class="code-view">
                                    <button title='copy code' class="copy-btn" (click)="copyBtn(paymentCancellationMethodCode)">
                                        <i class="fa fa-clone" aria-hidden="true"></i>
                                    </button>
                                    <pre>
                                                <code [innerHTML]="paymentCancellationMethodCode"></code>
                                              </pre>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div class="code-detail-container">
                        <h3 class="title-level-one">API Name</h3>
                        <ul>
                            <li class="method-detail">
                                <h3 class="title-level-two">CallbackNotification</h3>
                                <p>
                                    This is an API created at merchant side. HSBC will call this API from their end to send payment status of current transaction
                                    to the merchant side and merchant send response in acknowledgment.
                                </p>
                                <button title='copy code' class="copy-btn" (click)="copyBtn(callingAPICode)">
                                    <i class="fa fa-clone" aria-hidden="true"></i>
                                </button>
                                <div class="code-view">
                                    <pre>
                                                <code [innerHTML]="callingAPICode"></code>
                                              </pre>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="code-detail-container">
                        <h3 class="title-level-one">File Name</h3>
                        <ul>
                            <li class="method-detail">
                                <h3 class="title-level-two">CommonRepository.cs</h3>
                                <p>
                                    This file includes the methods which process the data input from the merchant website.
                                </p>
                                <p>
                                    If merchant already have constant file then copy the all Constant properties in their Constant file.
                                </p>
                                <button title='copy code' class="copy-btn" (click)="copyBtn(commonRepositoryCode)">
                                    <i class="fa fa-clone" aria-hidden="true"></i>
                                </button>
                                <div class="code-view">
                                    <pre>
                                                <code [innerHTML]="commonRepositoryCode"></code>
                                              </pre>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>