import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oracle-help',
  templateUrl: './oracle-help.component.html',
  styleUrls: ['./oracle-help.component.css']
})
export class OracleHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
