import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdapterRequirnmentsModel, ApprovalToProceedModel, CustomerDataModel, GoLiveDetailsModel, InstanceCreationModel, ReuirnmentsFinalizationModel, CompanyDataModel, UATApprovalModel, WorkflowSetupModel, EntityDataModel, AdapterDetailsModel } from '../Model/common.model';
import { CommonService } from '../services/common.service';
import jsPDF from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from 'html-to-pdfmake';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert } from 'bootstrap';

@Component({
  selector: 'app-create-new-work-flow',
  templateUrl: './create-new-work-flow.component.html',
  styleUrls: ['./create-new-work-flow.component.css']
})
export class CreateNewWorkFlowComponent implements OnInit {
  ERPSystem: string;
  CurrentDate: string;
  LoaderStatus: boolean = false;
  PageStatus: string;
  RoleID: string;
  UserID: string;
  CompanyID: string;
  WorkflowID: string;
  CompanyDD: any;
  ReportTypeDD: any;
  ReportTypeDDSelection = [];
  InputFileTypeDD: any;
  PaymentTypeDD: any;
  TransmissionTypeDD: any;
  SftpFolderDD: any;
  ErpSystemDD: any;
  ReverseReportDD: any;
  CountryDD: any;
  EncryptionTypeDD: any;
  PaymentTypeDDSelection = [];
  CompanyDataModel: CompanyDataModel;
  CustomerDataModel: CustomerDataModel;
  AdapterRequirnmentsModel: AdapterRequirnmentsModel;
  ReuirnmentsFinalizationModel: ReuirnmentsFinalizationModel;
  ApprovalToProceedModel: ApprovalToProceedModel;
  InstanceCreationModel: InstanceCreationModel;
  UATApprovalModel: UATApprovalModel;
  GoLiveDetailsModel: GoLiveDetailsModel;
  WorkflowSetupModel: WorkflowSetupModel;
  EntityDataModel: EntityDataModel;
  EntityDataList: Array<EntityDataModel>;
  AdapterDetailsModel: AdapterDetailsModel;
  AdapterDetailsList: Array<AdapterDetailsModel>;

  @ViewChild('pdfTable') pdfTable: ElementRef;
  constructor(private Service: CommonService, private Router: Router, private ActivatedRoute: ActivatedRoute) {
    this.CompanyDataModel = new CompanyDataModel();
    this.CustomerDataModel = new CustomerDataModel();
    this.AdapterRequirnmentsModel = new AdapterRequirnmentsModel();
    this.ReuirnmentsFinalizationModel = new ReuirnmentsFinalizationModel();
    this.ApprovalToProceedModel = new ApprovalToProceedModel();
    this.InstanceCreationModel = new InstanceCreationModel();
    this.UATApprovalModel = new UATApprovalModel();
    this.GoLiveDetailsModel = new GoLiveDetailsModel();
    this.WorkflowSetupModel = new WorkflowSetupModel();
    this.EntityDataModel = new EntityDataModel();
    this.EntityDataList = new Array<EntityDataModel>();
    this.AdapterDetailsModel = new AdapterDetailsModel();
    this.AdapterDetailsList = new Array<AdapterDetailsModel>();
  }

  ngOnInit(): void {
    var todaysDate = new Date();
    this.CurrentDate = todaysDate.getFullYear() + "/" + (todaysDate.getMonth() + 1) + "/" + todaysDate.getDate();
    this.CompanyDataModel.WhenEntered=this.CurrentDate;    
    this.RoleID = localStorage.getItem("RoleID");
    this.GetCompanyDD();
    this.GetReportTypeDD();
    this.GetInputFileTypeDD();
    this.GetPaymentTypeDD();
    this.GetTransmissionTypeDD();
    this.GetSftpFolderDD();
    this.GetErpSystemDD();
    this.GetReverseReportDD();
    this.GetCountryDD();
    this.GetEncryptionTypeDD();
    this.WorkflowID = this.ActivatedRoute.snapshot.params["workflowID"];
    this.PageStatus = this.ActivatedRoute.snapshot.params["pageStatus"];    
    if (this.WorkflowID != undefined) {
      this.GetWorkflowDetail(this.WorkflowID);   
    }
    else {
      this.GetUserDetail();
      this.GetLatestNumberRange();
      this.CompanyDataModel.WorkflowStatusID = "1";
      this.CompanyDataModel.UserID = localStorage.getItem("UserID");       
    }
  }
  AddToCartEntityData() {
    debugger;
    if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.EntityDataModel.EntityName == "" || this.EntityDataModel.EntityName == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter Entity Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.EntityDataModel.AcoountNoWithBank == "" || this.EntityDataModel.AcoountNoWithBank == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter Entity Account No with Bank", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.EntityDataModel.Country == "" || this.EntityDataModel.Country == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Select Entity Country", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.EntityDataModel.ForwardingAgentNeeded == "1") && (this.EntityDataModel.ForwardingAgentName == "" || this.EntityDataModel.ForwardingAgentName == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter Forwarding Agent Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.EntityDataModel.ForwardingAgentNeeded == "1") && (this.EntityDataModel.ForwardingAgentCountry == "" || this.EntityDataModel.ForwardingAgentCountry == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Select Forwarding Agent Country", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.EntityDataList.push(this.EntityDataModel);
      this.EntityDataModel = new EntityDataModel();
    }
  }

  DeleteCartEntityData(index) {
    this.EntityDataList.splice(index, 1);
  }

  AddToCartAdapterDetails() {
    if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterDetailsModel.PaymentTypeID == "" || this.AdapterDetailsModel.PaymentTypeID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Select Payment Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterDetailsModel.InputFileTypeID == "" || this.AdapterDetailsModel.InputFileTypeID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Select Input File Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterDetailsModel.FileIdentifier == "" || this.AdapterDetailsModel.FileIdentifier == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter File Identifier", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterDetailsModel.TransmissionTypeID == "" || this.AdapterDetailsModel.TransmissionTypeID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Select Transmission Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterDetailsModel.EncryptionNeeded == "" || this.AdapterDetailsModel.EncryptionNeeded == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Select Encryption Needed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterDetailsModel.EncryptionNeeded == "1") && (this.AdapterDetailsModel.EncryptionTypeID == "" || this.AdapterDetailsModel.EncryptionTypeID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Select Encryption Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {
      this.AdapterDetailsModel.PaymentType = $("#PaymentTypeID").find(":selected").text();
      this.AdapterDetailsModel.InputFileType = $("#InputFileTypeID").find(":selected").text();
      this.AdapterDetailsModel.TransmissionType = $("#TransmissionTypeID").find(":selected").text();
      this.AdapterDetailsModel.EncryptionType = $("#EncryptionTypeID").find(":selected").text();
      this.AdapterDetailsList.push(this.AdapterDetailsModel);
      this.AdapterDetailsModel = new AdapterDetailsModel();
    }
  }

  DeleteCartAdapterDetails(index) {
    this.AdapterDetailsList.splice(index, 1);
  }

  GetCompanyDD() {
    this.Service.GetCompanyDD().subscribe((res) => {
      this.CompanyDD = res;
    })
  }

  GetReportTypeDD() {
    this.Service.GetReportTypeDD().subscribe((res) => {
      this.ReportTypeDD = res;
    })
  }

  GetInputFileTypeDD() {
    this.Service.GetInputFileTypeDD().subscribe((res) => {
      this.InputFileTypeDD = res;
    })
  }

  GetPaymentTypeDD() {
    this.Service.GetPaymentTypeDD().subscribe((res) => {
      this.PaymentTypeDD = res;
    })
  }
  GetTransmissionTypeDD() {
    this.Service.GetTransmissionTypeDD().subscribe((res) => {
      this.TransmissionTypeDD = res;
    })
  }
  GetSftpFolderDD() {
    this.Service.GetSftpFolderDD().subscribe((res) => {
      this.SftpFolderDD = res;
    })
  }
  GetErpSystemDD() {
    this.Service.GetErpSystemDD().subscribe((res) => {
      this.ErpSystemDD = res;
    })
  }
  GetReverseReportDD() {
    this.Service.GetReverseReportDD().subscribe((res) => {
      this.ReverseReportDD = res;
    })
  }
  GetCountryDD() {
    this.Service.GetCountryDD().subscribe((res) => {
      this.CountryDD = res;
    })
  }
  GetEncryptionTypeDD() {
    this.Service.GetEncryptionTypeDD().subscribe((res) => {
      this.EncryptionTypeDD = res;
    })
  }

  GetLatestNumberRange() {
    this.Service.GetLatestNumberRange("Workflow").subscribe((res: any) => {
      this.CompanyDataModel.WorkflowNo = res;
    })
  }

  GetUserDetail() {
    this.Service.GetUserDetail(localStorage.getItem("UserID")).subscribe((res: any) => {
      this.CompanyDataModel.UserID = res.UserID;
      this.CompanyDataModel.CompanyID = res.CompanyID;
      this.CompanyDataModel.UserName = res.UserName;
      this.CompanyDataModel.UserMobile = res.UserMobile;
      this.CompanyDataModel.UserEmail = res.UserEmail;     
    })
  }

  GetWorkflowDetail(WorkflowID) {
    this.Service.GetWorkflowDetail(WorkflowID).subscribe((res: any) => {
      this.CompanyDataModel = res.CompanyData;
      this.CustomerDataModel = res.CustomerData;
      this.AdapterRequirnmentsModel = res.AdapterRequirnments;
      this.AdapterDetailsList = this.AdapterRequirnmentsModel.AdapterDetailsList;
      this.ReuirnmentsFinalizationModel = res.ReuirnmentsFinalization;
      this.ApprovalToProceedModel = res.ApprovalToProceed;
      this.InstanceCreationModel = res.InstanceCreation;
      this.UATApprovalModel = res.UATApproval;
      this.GoLiveDetailsModel = res.GoLiveDetails;
      this.EntityDataList = res.EntityDataList;
      const string1 = this.AdapterRequirnmentsModel.ReportID;
      this.ReportTypeDDSelection = string1.split(',');
      for (let i = 0; i < this.ReportTypeDDSelection.length; i++) {
        for (let j = 0; j < this.ReportTypeDD.length; j++) {
          if (this.ReportTypeDDSelection[i] == this.ReportTypeDD[j].ID) {
            this.ReportTypeDD[j].IsActive = "1";
          }
        }
      }      
    });
  }

  Save() {
    this.LoaderStatus = true;
    this.WorkflowSetupModel.CompanyData = this.CompanyDataModel;
    this.WorkflowSetupModel.CustomerData = this.CustomerDataModel;
    this.AdapterRequirnmentsModel.AdapterDetailsList = this.AdapterDetailsList;
    this.WorkflowSetupModel.AdapterRequirnments = this.AdapterRequirnmentsModel;
    this.WorkflowSetupModel.ReuirnmentsFinalization = this.ReuirnmentsFinalizationModel;
    this.WorkflowSetupModel.ApprovalToProceed = this.ApprovalToProceedModel;
    this.WorkflowSetupModel.InstanceCreation = this.InstanceCreationModel;
    this.WorkflowSetupModel.UATApproval = this.UATApprovalModel;
    this.WorkflowSetupModel.GoLiveDetails = this.GoLiveDetailsModel;
    this.WorkflowSetupModel.EntityDataList = this.EntityDataList;
    this.Service.WorkflowSetup(this.WorkflowSetupModel).subscribe(res => {
      if (res != "") {
        $("#errorclose").hide();
        $("#close").show();
        this.LoaderStatus = false;
        this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " Draft Saved Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
      }
      else {
        this.LoaderStatus = false;
        this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
      }
    })
  }

  Submit() {    
    this.LoaderStatus = true;
    this.WorkflowSetupModel.CompanyData = this.CompanyDataModel;
    this.WorkflowSetupModel.CustomerData = this.CustomerDataModel;
    this.AdapterRequirnmentsModel.AdapterDetailsList = this.AdapterDetailsList;
    this.WorkflowSetupModel.AdapterRequirnments = this.AdapterRequirnmentsModel;
    this.WorkflowSetupModel.ReuirnmentsFinalization = this.ReuirnmentsFinalizationModel;
    this.WorkflowSetupModel.ApprovalToProceed = this.ApprovalToProceedModel;
    this.WorkflowSetupModel.InstanceCreation = this.InstanceCreationModel;
    this.WorkflowSetupModel.UATApproval = this.UATApprovalModel;
    this.WorkflowSetupModel.GoLiveDetails = this.GoLiveDetailsModel;
    this.WorkflowSetupModel.EntityDataList = this.EntityDataList;
    if(this.CompanyDataModel.WorkflowStatusID == "3") { 
      this.ApprovalToProceedModel.ApprovalDate = $("#approval-date").val().toString();
    }
    
    if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.CustomerDataModel.CustomerName == "" || this.CustomerDataModel.CustomerName == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter Customer Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.CustomerDataModel.CustomerCountryID == "" || this.CustomerDataModel.CustomerCountryID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Customer Country", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.CustomerDataModel.CustomerProfileID == "" || this.CustomerDataModel.CustomerProfileID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter Customer Profile ID", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.ERPSystemID == "" || this.AdapterRequirnmentsModel.ERPSystemID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm ERP System", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.ERPAdapter == "" || this.AdapterRequirnmentsModel.ERPAdapter == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm ERP Adapter Needed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.ERPAdapter == "1") && (this.AdapterRequirnmentsModel.ERPSpecificDetail == "" || this.AdapterRequirnmentsModel.ERPSpecificDetail == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter ERP Specific Details", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.MapperNeeded == "" || this.AdapterRequirnmentsModel.MapperNeeded == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Mapper Needed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.ReverseFeedNeeded == "" || this.AdapterRequirnmentsModel.ReverseFeedNeeded == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Reverse Feed Needed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.ReverseFeedNeeded == "1") && (this.AdapterRequirnmentsModel.SftpFolderID == "" || this.AdapterRequirnmentsModel.SftpFolderID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm SFTP Folder", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.ReverseFeedNeeded == "1") && (this.AdapterRequirnmentsModel.ReverseFeedEncrypted == "" || this.AdapterRequirnmentsModel.ReverseFeedEncrypted == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Reverse Feed Encrypted", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.ReportsNeeded == "" || this.AdapterRequirnmentsModel.ReportsNeeded == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Reports Needed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.ReportsNeeded == "1") && (this.AdapterRequirnmentsModel.ReportID == "" || this.AdapterRequirnmentsModel.ReportID == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Reports", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.EmailNotification == "" || this.AdapterRequirnmentsModel.EmailNotification == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Email Notifications", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.EmailNotification == "1") && (this.AdapterRequirnmentsModel.RecipientEmails == "" || this.AdapterRequirnmentsModel.RecipientEmails == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter Recipient Emails", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if ((this.CompanyDataModel.WorkflowStatusID == "1") && (this.AdapterRequirnmentsModel.AnyOtherRequirnment == "" || this.AdapterRequirnmentsModel.AnyOtherRequirnment == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter Any Other Requirements", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "2" && (this.ReuirnmentsFinalizationModel.AreTheseStandardRequirnments == "" || this.ReuirnmentsFinalizationModel.AreTheseStandardRequirnments == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Whether These Are Standard Requirements", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }

    else if (this.CompanyDataModel.WorkflowStatusID == "2" && (this.ReuirnmentsFinalizationModel.CustomizationNeeded == "" || this.ReuirnmentsFinalizationModel.CustomizationNeeded == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Whether Customization Needed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "2" && (this.ReuirnmentsFinalizationModel.ReviewWithIM == "" || this.ReuirnmentsFinalizationModel.ReviewWithIM == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Whether Review with IM", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "2" && (this.ReuirnmentsFinalizationModel.IMName == "" || this.ReuirnmentsFinalizationModel.IMName == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter IM Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "2" && (this.ReuirnmentsFinalizationModel.Remarks == "" || this.ReuirnmentsFinalizationModel.Remarks == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter Remarks", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "2" && (this.ReuirnmentsFinalizationModel.RequirnmentsFinalized == "" || this.ReuirnmentsFinalizationModel.RequirnmentsFinalized == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Requirements Finalized", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "3" && (this.ApprovalToProceedModel.TermsAndConditionApproval == "" || this.ApprovalToProceedModel.TermsAndConditionApproval == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please accept terms and conditions", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "4" && (this.InstanceCreationModel.EnableInstanceCreation == "" || this.InstanceCreationModel.EnableInstanceCreation == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Instance Creation", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "5" && (this.UATApprovalModel.AdopterName == "" || this.UATApprovalModel.AdopterName == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Adapter Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "5" && (this.UATApprovalModel.TestingCompleted == "" || this.UATApprovalModel.TestingCompleted == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Testing Completed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "5" && (this.UATApprovalModel.UATApproved == "" || this.UATApprovalModel.UATApproved == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm UAT Approved", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "5" && (this.UATApprovalModel.UATApproverName == "" || this.UATApprovalModel.UATApproverName == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Enter UAT Approver Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "5" && (this.UATApprovalModel.EnableProductionInstance == "" || this.UATApprovalModel.EnableProductionInstance == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Enable Production Instance", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "5" && ((this.UATApprovalModel.EnableProductionInstance == "1") && (this.UATApprovalModel.DeployInProduction == "" || this.UATApprovalModel.DeployInProduction == undefined))) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Deploy in production", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "6" && (this.GoLiveDetailsModel.ProductionInstanceCreated == "" || this.GoLiveDetailsModel.ProductionInstanceCreated == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm production Instance Creation", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "6" && (this.GoLiveDetailsModel.KeyCreated == "" || this.GoLiveDetailsModel.KeyCreated == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Key Creation", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "6" && (this.GoLiveDetailsModel.DryRunInProduction == "" || this.GoLiveDetailsModel.DryRunInProduction == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Dry Run in Production", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.CompanyDataModel.WorkflowStatusID == "6" && (this.GoLiveDetailsModel.ProductionDeploymentCompleted == "" || this.GoLiveDetailsModel.ProductionDeploymentCompleted == undefined)) {
      this.LoaderStatus = false;
      this.HideShow();
      this.popupMessage("Please Confirm Production Deployment Completed", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else {

      if (this.GoLiveDetailsModel.ProductionDeploymentCompleted == "1") {
        this.CompanyDataModel.WorkflowStatusID = "8";
      }
      else if (this.UATApprovalModel.EnableProductionInstance == "1") {
        this.CompanyDataModel.WorkflowStatusID = "7";
      }
      else if (this.UATApprovalModel.UATApproved == "1") {
        this.CompanyDataModel.WorkflowStatusID = "6";
      }
      else if (this.InstanceCreationModel.EnableInstanceCreation == "1") {
        this.CompanyDataModel.WorkflowStatusID = "5";
      }
      else if (this.ApprovalToProceedModel.TermsAndConditionApproval == "1") {
        this.CompanyDataModel.WorkflowStatusID = "4";
      }
      else if (this.ReuirnmentsFinalizationModel.RequirnmentsFinalized == "1") {
        this.CompanyDataModel.WorkflowStatusID = "3";
      }
      else {
        this.CompanyDataModel.WorkflowStatusID = "2";
      }

      this.Service.WorkflowSetup(this.WorkflowSetupModel).subscribe(res => {
        debugger;
        if (res != "") {
          $("#errorclose").hide();
          $("#close").show();
          this.LoaderStatus = false;
          if (this.CompanyDataModel.WorkflowStatusID = "1") {
            this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " Draft Saved Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          }
          else if (this.CompanyDataModel.WorkflowStatusID = "2") {
            this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " Created Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          }
          else if (this.CompanyDataModel.WorkflowStatusID = "3") {
            this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " Request Finalized Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          }
          else if (this.CompanyDataModel.WorkflowStatusID = "4") {
            this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " ATP Approved Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          }
          else if (this.CompanyDataModel.WorkflowStatusID = "5") {
            this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " Active Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          }
          else if (this.CompanyDataModel.WorkflowStatusID = "6") {
            this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " Active UAT Completed Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          }
          else if (this.CompanyDataModel.WorkflowStatusID = "7") {
            this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " Active roduction Deployment Completed Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          }
          else if (this.CompanyDataModel.WorkflowStatusID = "8") {
            this.popupMessage("Workflow " + this.CompanyDataModel.WorkflowNo + " Completed Successfully", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
          }
        }
        else {
          this.HideShow();
          this.LoaderStatus = false;
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
        }
      });
    }
  }

  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void {
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $("#succeess-message-box").modal("show");
  }

  HideShow() {
    $("#errorclose").show();
    $("#close").hide();
  }

  downloadAsPDF() {
    debugger;
    const doc = new jsPDF();
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true
    });
    const documentDefinition: any = { content: html, pageOrientation: 'landscape' };
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  }

  SuccessRedirect() {
    debugger;
    $("#succeess-message-box").modal("hide");
    if (this.CompanyDataModel.WorkflowID != undefined) {
      this.Router.navigate(['../../../workflowList'], { relativeTo: this.ActivatedRoute })
    }
    else {
      this.Router.navigate(['../workflowList'], { relativeTo: this.ActivatedRoute })
    }
  }

  UploadTermsandCondition() {
    let formData: FormData = new FormData(),
      xhr: XMLHttpRequest = new XMLHttpRequest();
    var file = (<HTMLInputElement>document.getElementById("terms-attachment")).files[0];
    var filename = file.name;
    var index = filename.lastIndexOf(".");
    var strsubstring = filename.substring(index, filename.length);
    this.ApprovalToProceedModel.TermsAndCondition = filename;
    if (file != null) {
      formData.append("file", file, file.name);
      const headers = new Headers();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      const body = JSON.stringify({ headers: headers });

      this.Service.UploadTermsConditionAttachment(formData).subscribe(Response => {
        debugger;
        $("#page-loader").hide();
        if (JSON.parse(JSON.stringify(Response)) == 'OK') {
          this.ApprovalToProceedModel.TermsAndCondition = filename;
          $("#page-loader").hide();
          this.HideShow();
          this.popupMessage("File uploaded successfully!", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");

        } else {
          $("#page-loader").hide();
          this.HideShow();
          // this.popupMessage("File not uploaded successfully!", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
          this.popupMessage("File uploaded successfully!", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
        }
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        });
    } else {
      $("#page-loader").hide();
      this.HideShow();
      this.popupMessage("Please select File for terms and conditions", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
  }

  UploadUATAttachment() {
    let formData: FormData = new FormData(),
      xhr: XMLHttpRequest = new XMLHttpRequest();
    var file = (<HTMLInputElement>document.getElementById("uat-attachment")).files[0];
    var filename = file.name;
    var index = filename.lastIndexOf(".");
    var strsubstring = filename.substring(index, filename.length);
    this.UATApprovalModel.UATAttachment = filename;
    if (file != null) {
      formData.append("file", file, file.name);
      const headers = new Headers();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      const body = JSON.stringify({ headers: headers });

      this.Service.UploadTermsConditionAttachment(formData).subscribe(Response => {
        debugger;
        $("#page-loader").hide();
        if (JSON.parse(JSON.stringify(Response)) == 'OK') {
          this.ApprovalToProceedModel.TermsAndCondition = filename;
          $("#page-loader").hide();
          this.HideShow();
          this.popupMessage("File uploaded successfully!", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");

        } else {
          $("#page-loader").hide();
          this.HideShow();
          // this.popupMessage("File not uploaded successfully!", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
          this.popupMessage("File uploaded successfully!", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
        }
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        });
    } else {
      $("#page-loader").hide();
      this.HideShow();
      this.popupMessage("Please select File for terms and conditions", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
  }


  ReportTypeSelection(ReportID: string, checkboxEvent) {
    debugger;
    for (let i = 0; i < this.ReportTypeDD.length; i++) {
      if (this.ReportTypeDD[i].ID == ReportID) {
        if (checkboxEvent.target.checked == true) {
          this.ReportTypeDDSelection.push(ReportID);
        }
        else {
          var index = this.ReportTypeDDSelection.indexOf(ReportID);
          if (index !== -1) {
            this.ReportTypeDDSelection.splice(index, 1);
          }
        }
      }
    }
    this.AdapterRequirnmentsModel.ReportID = this.ReportTypeDDSelection.toString();
  }

  TranmissionTypeChange() {
    if (this.AdapterDetailsModel.TransmissionTypeID == "2") {
      this.AdapterDetailsModel.EncryptionNeeded = "1";
      this.AdapterDetailsModel.EncryptionTypeID = "1";
    }
    else if (this.AdapterDetailsModel.TransmissionTypeID == "1") {
      this.AdapterDetailsModel.EncryptionNeeded = "";
      this.AdapterDetailsModel.EncryptionTypeID = "";
    }
  }

  GetATPDate() {
    $("#approval-date").val(this.CurrentDate);
  }

}