<router-outlet></router-outlet>

<nav class="navbar navbar-inverse navbar-fixed-top">
    <div class="container text-center">
        <div style="float: left;" class="navbar-header">
            <a class="navbar-brand" href="#">
                <img class="img-responsive" src="../../assets/images/logo.png" alt="">
            </a>
        </div>
        <div style="float: right;">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                data-target="#navbar" aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>
        <div style="float: right;" id="navbar" class="collapse navbar-collapse">
            <ul class="nav navbar-nav">
                <li><a style="color: #fff; font-weight: bold" href="#Overview">Overview</a></li>
                <li><a style="color: #fff; font-weight: bold" href="#features">Features</a></li>
                <li><a style="color: #fff; font-weight: bold" href="#howitworks">How it works</a></li>
                <li><a style="color: #fff; font-weight: bold" href="#howtostart">How to Start</a></li>
                <li><a style="color: #fff; font-weight: bold" href="#Compability">Compatibilty</a></li>
                <li><a style="color: #fff; font-weight: bold" href="#FAQs">FAQs</a></li>
                <li><a style="color: #fff; font-weight: bold" style="color: #fff; font-weight: bold"
                        [routerLink]="'/login'">LOGIN</a></li>
            </ul>
        </div>
    </div>
</nav>
<footer class="footerprop">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-sm-3 ">
                <h4><a class="foptions" href="/termsandcondition" >Terms & Conditions</a></h4>
            </div>
            <div class="col-sm-3 ">
                <h4><a class="foptions" href="/privacypolicy" >Privacy Policy</a>
                </h4>
            </div>
            <div class="col-sm-3 ">
                <h4><a class="foptions" href="/sitemap" >Sitemap</a></h4>
            </div>
            <div class="col-sm-3 ">
                <h4><a class="foptions" href="">About Us</a></h4>
            </div>

        </div>
        <div class="row ">
            <div class="col-sm-9 ">
                <p class="copyright">&copy;2022 Copia Consultancy Services Pvt Limited</p>
            </div>

            <div class=" col-sm-3 socialSitesLogo">
                <i class="fa fa-facebook-square smediaicon"></i>
                <i class="fa fa-twitter-square smediaicon"></i>
                <i class="fa fa-envelope-square smediaicon"></i>
                <i class="fa fa-linkedin-square smediaicon"></i>
            </div>
            
        </div>
    </div>
</footer>