import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sysbrij-codebase',
  templateUrl: './sysbrij-codebase.component.html',
  styleUrls: ['./sysbrij-codebase.component.css']
})
export class SysbrijCodebaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
