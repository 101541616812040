import { CustomerWorkSetupModel } from './../Model1/common1.model';
import { CompanySetupModel, ContactSopportModel, EntityDataModel, AdapterRequirnmentsModel, UserListFilterModel, UserSetupModel } from './../Model/common.model';
import { Injectable } from '@angular/core';
import { LoginModel, RoleConfigurationModel, RoleListFilterModel, RoleMasterSetupModel, WorkflowListFilterModel, WorkflowSetupModel,ApplyForCBModel, CBEnquiryModel } from '../Model/common.model';
import { WorkflowRequirementsModel, WorkflowListFilterNewModel, StandartRequirementsModel } from '../Model1/common1.model';
import { environment } from '../../environments/environment';
import { HttpClientModule, HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private baseUrl = environment.apiUrl;
  // private baseUrl = "http://localhost:63062/api";
  constructor(private http: HttpClient) { }

  Login(Login: LoginModel){
    return this.http.post(this.baseUrl + '/Login', Login)
  }

  WorkflowSetup(WorkflowSetup: WorkflowSetupModel) {
    return this.http.post(this.baseUrl + '/WorkflowSetup', WorkflowSetup)
  }

  WorkflowList(WorkflowListFilter: WorkflowListFilterModel) {
    return this.http.post(this.baseUrl + '/WorkflowList', WorkflowListFilter)
  }

  UserSetup(UserSetup: UserSetupModel) {
    return this.http.post(this.baseUrl + '/UserSetup', UserSetup)
  }

  CompanySetup(CompanySetup: CompanySetupModel) {
    return this.http.post(this.baseUrl + '/CompanySetup', CompanySetup)
  }

  UserList(UserListFilterModel: UserListFilterModel) {
    return this.http.post(this.baseUrl + '/UserList', UserListFilterModel)
  }

  GetUserDetail(UserID) {
    const url = this.baseUrl + '/UserDetail';
    let params = new HttpParams();
    params = params.append('UserID', UserID);
    return this.http.get<any[]>(url, { params: params })
  }

  GetLatestNumberRange(NumberRangeType) {
    const url = this.baseUrl + '/GetLatestNumberRange';
    let params = new HttpParams();
    params = params.append('NumberRangeType', NumberRangeType);
    return this.http.get<any[]>(url, { params: params })
  }

  GetCustomerDD() {
    return this.http.get(this.baseUrl + '/CustomerDD')
  }

  GetCompanyDD() {
    return this.http.get(this.baseUrl + '/CompanyMasterDD')
  }

  ContactSupportSetup(ContactSopportSetup: ContactSopportModel) {
    return this.http.post(this.baseUrl + '/ContactSupportSetup', ContactSopportSetup)
  }
  ContactSupportlist(UserEmail:any, UserName:any, RoleID:any, UserID: any){
    const url = `${this.baseUrl}/`+'ContactSupportList'; 
    let params = new HttpParams();
    params = params.append('UserEmail', UserEmail); 
    params = params.append('UserName', UserName); 
    params = params.append('RoleID', RoleID); 
    params = params.append('UserID', UserID); 
    return this.http.get<any[]>(url, { params: params })
  }
  GetContactSupportDetail(ID:any) {
    const url = this.baseUrl + '/ContactSupportDetail';
    let params = new HttpParams();
    params = params.append('ID', ID);
    return this.http.get<any[]>(url, { params: params })
  }

  GetWorkflowDetail(WorkflowID) {
    const url = this.baseUrl + '/WorkflowDetail';
    let params = new HttpParams();
    params = params.append('WorkflowID', WorkflowID);
    return this.http.get<any[]>(url, { params: params })
  }
  GetEntityDetail(WorkflowID) {
    const url = this.baseUrl + '/EntityDetail';
    let params = new HttpParams();
    params = params.append('ID', WorkflowID);
    return this.http.get<any[]>(url, { params: params })
  }
  GetAdapterDetailsDetail(WorkflowID) {
    const url = this.baseUrl + '/AdapterDetails';
    let params = new HttpParams();
    params = params.append('ID', WorkflowID);
    return this.http.get<any[]>(url, { params: params })
  }

  RoleMasterSetup(RoleMasterSetup: RoleMasterSetupModel) {
    return this.http.post(this.baseUrl + '/RoleMasterSetup', RoleMasterSetup)
  }

  RoleMasterList(RoleListFilter: RoleListFilterModel) {
    debugger;
    return this.http.post(this.baseUrl + '/RoleMasterList',RoleListFilter)
  }

  RoleDD() {
    return this.http.get(this.baseUrl + '/RoleDD')
  }

  LevelOneMenuDD() {
    return this.http.get(this.baseUrl + '/LevelOneMenuDD')
  }

  LevelTwoMenuDD(LevelOneMenuID: string, RoleID: string) {
    const url = this.baseUrl + '/LevelTwoMenuDD';
    let params = new HttpParams();
    params = params.append('LevelOneMenuID', LevelOneMenuID);
    params = params.append('RoleID', RoleID);
    return this.http.get<any[]>(url, { params: params })
  }

  RoleTransactionAssignment(RoleConfigurationListModel: Array<RoleConfigurationModel>) {
    return this.http.post(this.baseUrl + '/RoleTransactionAssignemnt', RoleConfigurationListModel)
  }

  RoleBasedMenu(RoleID: string) {
    const url = this.baseUrl + '/RoleBasedMenu';
    let params = new HttpParams();
    params = params.append('RoleID', RoleID);
    return this.http.get<any[]>(url, { params: params })
  }

  GetReportTypeDD() {
    return this.http.get(this.baseUrl + '/ReportTypeDD')
  }

  GetPaymentTypeDD() {
    return this.http.get(this.baseUrl + '/PaymentTypeDD')
  }

  GetInputFileTypeDD() {
    return this.http.get(this.baseUrl + '/InputFileTypeDD')
  }

  UploadTermsConditionAttachment(data: any) {
    var url = `${this.baseUrl}/`+'FileUploadCtrl';
      return this.http.post(url,data);
  }

  ApplyForCB(ApplyForCB: ApplyForCBModel) {
    return this.http.post(this.baseUrl + '/EnquiryCB', ApplyForCB)
  }
  CBEnquiry(CBEnquiry: CBEnquiryModel) {
    return this.http.post(this.baseUrl + '/CBEnquiry', CBEnquiry)
  }

  GetTransmissionTypeDD() {
    return this.http.get(this.baseUrl + '/TransmissionTypeDD')
  }
  GetSftpFolderDD() {
    return this.http.get(this.baseUrl + '/SftpFolderDD')
  }
  EntitySetup(EntitySetup: EntityDataModel) {
    return this.http.post(this.baseUrl + '/EntitySetup', EntitySetup)
  }
  AdapterDetails(MapperCartObj1) {
    return this.http.post(this.baseUrl + '/AdapterDetails', MapperCartObj1)
  }
  GetErpSystemDD() {
    return this.http.get(this.baseUrl + '/ErpSystemmDD')
  }
  GetReverseReportDD() {
    return this.http.get(this.baseUrl + '/ReverseReportDD')
  }
  GetCountryDD() {
    return this.http.get(this.baseUrl + '/CountryDD')
  }
  GetEncryptionTypeDD() {
    return this.http.get(this.baseUrl + '/EncryptionTypeDD')
  }


  // Akshay - new UI binding

  WorkflowSetupNew(WorkflowSetupNew: WorkflowRequirementsModel) {
    return this.http.post(this.baseUrl + '/WorkflowSetupNew', WorkflowSetupNew)
  }
  AdapterDetailsNew(WorkflowSetupNew: WorkflowRequirementsModel) {
    return this.http.post(this.baseUrl + '/AdapterDetailsNew', WorkflowSetupNew)
  }
  EntitySetupNew(WorkflowSetupNew: WorkflowRequirementsModel) {
    return this.http.post(this.baseUrl + '/EntitySetupNew', WorkflowSetupNew)
  }

  WorkflowListNew(WorkflowListFilter: WorkflowListFilterNewModel) {
    return this.http.post(this.baseUrl + '/WorkflowListNew', WorkflowListFilter)
  }
  GetOperatingSystemDD() {
    return this.http.get(this.baseUrl + '/OperatingSystemDD')
  }
  GetWorkflowDetailNew(WorkflowID) {
    const url = this.baseUrl + '/WorkflowDetailNew';
    let params = new HttpParams();
    params = params.append('WorkflowID', WorkflowID);
    return this.http.get<any[]>(url, { params: params })
  }
  GetWorkflowDetailNew1(WorkflowID) {
    const url = this.baseUrl + '/WorkflowDetailNew1';
    let params = new HttpParams();
    params = params.append('WorkflowID', WorkflowID);
    return this.http.get<any[]>(url, { params: params })
  }

  StandartRequirements(StandartRequirements: StandartRequirementsModel) {
    return this.http.post(this.baseUrl + '/StandartRequirements', StandartRequirements)
  }
  GetBankIMDD() {
    return this.http.get(this.baseUrl + '/BankIMDD')
  }
  CustomerWorkSetup(CustomerWorkSetupModel: CustomerWorkSetupModel) {
    return this.http.post(this.baseUrl + '/CustomerWorkSetup', CustomerWorkSetupModel)
  }
  CustomerWorklist(CustomerId: any){
    const url = `${this.baseUrl}/`+'CustomerWorkList'; 
    let params = new HttpParams();
    params = params.append('CustomerId', CustomerId); 
    return this.http.get<any[]>(url, { params: params })
  }
  GetCustomerWorkDetail(Id:any) {
    const url = this.baseUrl + '/CustomerWorkDetail';
    let params = new HttpParams();
    params = params.append('Id', Id);
    return this.http.get<any[]>(url, { params: params })
  }
  GetCustomerWorkDetailView(Id:any) {
    const url = this.baseUrl + '/CustomerWorkDetailView';
    let params = new HttpParams();
    params = params.append('Id', Id);
    return this.http.get<any[]>(url, { params: params })
  }
}
