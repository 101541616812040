<nav class="navbar navbar-default navbar-fixed-top">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" style="padding: 3px 3px;" href="#"><img src="../../assets/images/Connectbank Logo.png"
          class="CBLogo" alt="Connect Bank Logo"></a>
    </div>
    
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">
        <li><a class="nav-link" href="#">Home</a></li>
        <li><a class="nav-link" href="#FeaturesSection">Features</a></li>
        <li><a class="nav-link" href="#DeploymentSection">Deployment</a></li>
        <li><a class="nav-link" href="#HowItWorkSection">How it Works</a></li>
        <li><a class="nav-link" href="#ContactUsSection">Contact</a></li>
        <li style=" margin-right: 93px;"><a class="nav-link" href="#FAQSection">FAQs</a></li>
        <li class="text-center"><a class="nav-link" [routerLink]="'/login'">Sign in</a></li>
        <li><a class="btn btn-warning btn-sm SignupBtn" href="#ContactUsSection">Sign up</a></li>
      </ul>
    </div>
  </div>
</nav>
<a class="gotopbtn" href="#"><i class="fa fa-arrow-up"></i></a>


<div class="container">


  <div class="row">
    <div class="col-md-12 text-center">
      <h1 class="CBHeading">Connectbank is a Portal to Automate Vendor Payments</h1>
      <h3 class="SubTitle">Unique B2B platform to configure sysBRIJ Application to do automated vendor payments </h3>
      <div>
        <a class="btn btn-warning" href="#ContactUsSection">Sign up now</a>
        <a class="btn btn-warning BtnSpacing" href="#ContactUsSection">Request demo</a>
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 30px;">
    <div class="col-md-12">
      <img class="LaptopImg" src="../../assets/images/LaptopImage.png" alt="Laptop Handling Image">
    </div>
  </div>
  <div class="OverviewSection SectionMargin" id="OverviewSection">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 class="SectionHeading">Overview</h1>
      </div>
      <div class="col-md-12 PageContent">
        <p><b>Connectbank.in</b> is a web portal which is used to configure and deploy the vendor payment tool
          named – SysBRIJ. On the connect bank portal you need to sign up & create your login. Once you have
          signed up, you can initiate a workflow and specify your payment requirements. Post that, the AI
          based bot will create an application specific to your needs. You can download the application and do
          end to end testing. Once UAT is successful, you can download the production version and start using
          it for your vendor payments.</p>
        <p>At any stage during the process, our team would be available for your assistance and any support that
          may
          be needed. Working though the portal is extremely easy & you can be done in few simple steps
        </p>
      </div>
    </div>
    <div class="row ContentMargin CompanyClientsSection">
      <div class="col-md-12 text-center">
        <h3 class="SubTitle"><b>The SysBRIJ is already working successfully for many large organizations</b></h3>
      </div>
      <div class="col-md-4 ClientLogoMargin">
        <img class="ClientLogo" src="../../assets/images/ParleArgoLogo.png" alt="">
      </div>
      <div class="col-md-4 ClientLogoMargin">
        <img class="ClientLogo" src="../../assets/images/TataInternationalLogo.png" alt="">
      </div>
      <div class="col-md-4 ClientLogoMargin">
        <img class="ClientLogo" src="../../assets/images/KFinLogo.png" alt="">
      </div>
      <div class="col-md-4 ClientLogoMargin">
        <img class="ClientLogo" src="../../assets/images/SudarshanLogo.png" alt="">
      </div>
      <div class="col-md-4 ClientLogoMargin">
        <img class="ClientLogo" src="../../assets/images/HAPLogo.png" alt="">
      </div>
    </div>
  </div>

  <div class="FeaturesSection SectionMargin" id="FeaturesSection">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 class="SectionHeading">SysBRIJ Features</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <h3 class="FeautuersHeading">Universal to Deploy</h3>
        <p class="PageContent">The tool can be installed on any Windows or Linus OS. Works agnostic to OS
          versions.
          You can install it on Any Cloud or Wieb Server or on a laptop.</p>
      </div>
      <div class="col-md-7">
        <img class="FeautersImage" src="../../assets/images/Feature.png" alt="">
      </div>
    </div> 
   <div class="row FeaturesContentMargin">
      <div class="col-md-5 col-md-push-7">
        <h3 class="FeautuersHeading">All Payment Types</h3>
        <p class="PageContent">SysBRIJ can handle all payment types such as NEFT, RTGS, IMPS, Bulk Pay, Multi
          currency, International payments or any other.</p>
      </div>
      <div class="col-md-7 col-md-pull-5">
        <img class="FeautersImage" src="../../assets/images/Feature1.png" alt="">
      </div>
    </div>
    <div class="row FeaturesContentMargin">
      <div class="col-md-5">
        <h3 class="FeautuersHeading">Data Transfer Types </h3>
        <p class="PageContent">It can do File Transfers or API based data transfer to the bank system. It can
          accept any file formats such as CSV, TXT or XLS.</p>
      </div>
      <div class="col-md-7">
        <img class="FeautersImage" src="../../assets/images/Feature2.png" alt="">
      </div>
    </div>
    <div class="row FeaturesContentMargin">
      <div class="col-md-5 col-md-push-7">
        <h3 class="FeautuersHeading">Secured Data Transfer</h3>
        <p class="PageContent">All data from your system to bank is encrypted with PGP keys and tarnsfer in a
          secured transfer mode.</p>
      </div>
      <div class="col-md-7 col-md-pull-5">
        <img class="FeautersImage" src="../../assets/images/Feature3.png" alt="">
      </div>
    </div>
    <div class="row FeaturesContentMargin">
      <div class="col-md-5">
        <h3 class="FeautuersHeading">Reverse Feeds</h3>
        <p class="PageContent">Every payment transfer done with the bank can be tracked via reverse feed files.
          Day end report, Mt 940 , Status check and balance check are available as a standard functionality.
        </p>
      </div>
      <div class="col-md-7">
        <img class="FeautersImage" src="../../assets/images/Feature4.png" alt="">
      </div>
    </div>
    <div class="row FeaturesContentMargin">
      <div class="col-md-5 col-md-push-7">
        <h3 class="FeautuersHeading">Configurable Setup</h3>
        <p class="PageContent">The application is configurable meaning you can opt for only required
          functionality such as file transfer, encryption only or file mapping only and so on.</p>
      </div>
      <div class="col-md-7 col-md-pull-5">
        <img class="FeautersImage" src="../../assets/images/Feature5.png" alt="">
      </div>
    </div>
  </div>


  <div class="DeploymentSection SectionMargin" id="DeploymentSection">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 class="SectionHeading">SysBRIJ Deployment</h1>
        <h3 class="SubTitle"><b>Follow these simple steps to complete the sysBRIJ - The Vendor Payment Application in no
            time</b>
        </h3>
      </div>
    </div>
    <div class="CardSection">
      <div class="row ">
        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <img class="card-img-top" src="../../assets/images/Card1.png" alt="">
            </div>
            <div class="panel-body">
              <h5 class="card-title">1. Sign Up</h5>
              <p class="card-text">Follow these simple steps to complete the sysBRIJ - The Vendor Payment
                Application in no time.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <img class="card-img-top" src="../../assets/images/Card2.png" alt="">
            </div>
            <div class="panel-body">
              <h5 class="card-title">2. Define Scope</h5>
              <p class="card-text">Start your work flow on connect bank portal & define all payment
                requirements.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <img class="card-img-top" src="../../assets/images/Card3.png" alt="">
            </div>
            <div class="panel-body">
              <h5 class="card-title">3. Complete Paperwork</h5>
              <p class="card-text">You need to sign Terms & conditions to use the app & allow the
                deployment on your.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <img class="card-img-top" src="../../assets/images/Card4.png" alt="">
            </div>
            <div class="panel-body">
              <h5 class="card-title">4. Download Application</h5>
              <p class="card-text">Based on the scope, system creates a dedicated application Download the
                app for testing.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <img class="card-img-top" src="../../assets/images/Card5.png" alt="">
            </div>
            <div class="panel-body">
              <h5 class="card-title">5. Complete testing</h5>
              <p class="card-text">Do complete User Acceptance Testing to ensure data, connectivity & use
                cases to ensure no mistakes.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <img class="card-img-top" src="../../assets/images/Card6.png" alt="">
            </div>
            <div class="panel-body">
              <h5 class="card-title">6. Start Vendor Payment</h5>
              <p class="card-text">On successful UAT, system creates a Production instance for you.
                Download it & start vendor payments.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="HowItWorkSection SectionMargin" id="HowItWorkSection">
    <div class="row ">
      <div class="col-md-12 text-center">
        <h1 class="SectionHeading">How It Works?</h1>
      </div>
      <div class="col-md-12">
        <div class="ContentMargin PageContent">
          <p>Connect bank.in is a web portal which is used to define your vendor payment needs and configure it.
            In any business, there is a need to do payments to vendors, employees and many other parties. Gone
            are the days when business will create files from its ERP system and upload it on the bank portal.
            This vendor payment activity can be done automatically by connecting with your bank.
            This Connectbank portal facilitates this vendor payment automation.
          </p>
        </div>
      </div>
    </div>
    <div class="row ContentMargin">
      <div class="col-md-12">
        <h1 class="SectionHeading">How SysBRIJ Works?</h1>
        <div class=" PageContent">
          <p>SysBRIJ is an application for vendor payments. Using the workflow on the portal, you can download the
            SysBRIJ application.
            Once installed, SysBRIJ connects to your ERP & Bank to transfer data in both directions. Following
            steps explain how SysBRIJ works.
          </p>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-6 WorkContentMargin">
          <h3 class="WorkHeading">Pickup the file</h3>
          <p class="PageContent">Once installed, SysBRIJ picks up files from designated folder at 15 min
            frequency .It can read any type of file from any location. It can also convert the file in
            required format using mapper functionality.</p>
        </div>
        <div class="col-md-6 text-center workImageBorder WorkContentMargin">
          <img class="WorkImage" src="../../assets/images/Work1.png" alt="">
        </div>
      </div>
      <div class="row ">
        <div class="col-md-6 col-md-push-6 WorkContentMargin">
          <h3 class="WorkHeading">Encrypt the file</h3>
          <p class="PageContent">Once the file is picked up, SysBRIJ uses PGP alogrithm and encrypts the file.
            It can be decrypted only with other key pair which is stored in bank's system. So file & its
            data is completely secured during the transfer.</p>
        </div>
        <div class="col-md-6 col-md-pull-6 text-center workImageBorder WorkContentMargin">
          <img class="WorkImage" src="../../assets/images/Work2.png" alt="">
        </div>
      </div>
      <div class="row ">
        <div class="col-md-6 WorkContentMargin">
          <h3 class="WorkHeading">Transfer the file</h3>
          <p class="PageContent">SysBRIJ uses another encryption key pair to do secured File Transfer from
            your ERP to Bank's IT system.</p>
        </div>
        <div class="col-md-6 text-center workImageBorder WorkContentMargin">
          <img class="WorkImage" src="../../assets/images/Work3.png" alt="">
        </div>
      </div>
    </div>
  </div>

  <div class="ContactUsSection SectionMargin" id="ContactUsSection">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 class="SectionHeading">Contact Us</h1>
        <h3 class="SubHeading">Any support needed at anytime, just fill up the form</h3>
      </div>
    </div>
    <div class="row" style="margin-top: 20px;">
      <div class="col-md-5 text-center" style="margin-top: 30px;">
        <img class="ContactUsImage" src="../../assets/images/ContactUsImage.png" alt="">
      </div>
      <div class="col-md-7" style="margin-top: 20px;">
        <form>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>First Name <span style="color: red;">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="CBEnquiryModel.FirstName" name="FirstName"
                  placeholder="Enter first name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Last Name <span style="color: red;">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="CBEnquiryModel.LastName" name="LastName"
                  placeholder="Enter last name">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Email <span style="color: red;">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="CBEnquiryModel.Email" name="Email"
                  placeholder="Enter email">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Mobile Number <span style="color: red;">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="CBEnquiryModel.Mobile_No" name="Mobile_No"
                  placeholder="Enter Mobile number">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Company Name <span style="color: red;">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="CBEnquiryModel.CompanyName" name="CompanyName"
                  placeholder="Enter company name">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Select the Topic <span style="color: red;">*</span></label>
                <select class="form-control" [(ngModel)]="CBEnquiryModel.Topic" name="Topic">
                  <option value="">Select</option>
                  <option value="General Enquiry">General Enquiry</option>
                  <option value="Request for Demo">Request for Demo</option>
                  <option value="Registration">Registration</option>
                  <option value="Support Query">Support Query</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>How can we help? </label>
                <textarea class="form-control" [(ngModel)]="CBEnquiryModel.Help" name="Help"
                  placeholder="Please share what you want us to help" cols="30" rows="2"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <button class="btn btn-warning" style="width: 100%;" (click)="Submit()">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="FAQSection SectionMargin" id="FAQSection">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 class="SectionHeading">Frequently asked questions</h1>
        <h3 class="SubHeading">You can go through these questions or search within the list</h3>
      </div>
    </div>
    <input type="text" id="myInput" (keyup)="myFunction()" placeholder="Search for Questions.."
      title="Type Question name">
    <div class="row" id="myDiv">
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse1">
                  <p><a href="#">We have SAP system, do these adapters work with SAP?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>

                </div>
              </h4>
            </div>
            <div id="collapse1" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Yes, we have standard adapters for SAP and SAP S/4 HANA. You just need to
                copy the code in your SAP system and it would be compatible with your
                ERP.
                Apart from SAP, we also have standard adapters for Tally & Oracle ERPs.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse2">
                  <p><a href="#">We use our homegrown ERP solution will these work with this ERP?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse2" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Yes, these adapters can work with any ERP or for that matter any IT
                systems. All you need is extract the relevant file from your ERP in .XLS
                format and rest will be automatic.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse3">
                  <p>
                    <a href="#">We need to have reports on daily basis and monthly basis, how can it support it?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse3" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                There are multiple reports which are generated on daily and monthly
                basis. You can select and choose relevant reports needed and system will
                automatically place these reports on your system at a set frequency
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse4">
                  <p>
                    <a href="#">We use Linux operating system, do these adapters work on Linux?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse4" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Yes, these are OS agnostic and it can work on any Windows or Linux system
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse5">
                  <p>
                    <a href="#">Can these adapters send emails with report attachments?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse5" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Yes, this is a standard feature available. You will need to provide a
                dedicated email address with credentials and allow email this email
                through your gateway. With that it can be setup to send reports emails
                as well as many other emails.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse6">
                  <p>
                    <a href="#">If there is a failure, how would we be notified and how corrections can happen?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse6" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                If you have enabled email functionality, system will send emails to
                designated recipients on any kind of failures. Also, you can always
                check in log files in the system.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse7">
                  <p>
                    <a href="#">How can we get started?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse7" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Its very simple. Just fill up the form on contact us page and we will
                connect with you for further information.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse8">
                  <p>
                    <a href="#">How data is transmitted to Bank?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse8" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                This system uses PGP encryption and Host to host connectivity to send
                data to the bank. Its most secured method of data transmission over
                internet.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse9">
                  <p>
                    <a href="#">This is confidential data for our organization, is its shared anywhere?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse9" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                No, The application is hosted on your IT landscape. So all data resides
                in your landscape and encrypted there. The encrypted data packet is sent
                to the bank over internet.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse10">
                  <p>
                    <a href="#"> What is host to host connection?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse10" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Host-to-Host is an automated, two-way data transfer service for
                high-volume online banking that gives you direct, anytime access to
                Business Online from various applications. Host-to-Host is a direct
                system-to-system integration between your ERP system and the bank, and
                with no user intermediary involved.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse11">
                  <p>
                    <a href="#"> Do we need to have network connectivity with the bank?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse11" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Yes, in order to use Host To Host connection which is secured connection,
                you need to establish direct connectivity with the bank. Its easy to
                setup this connectivity. Just fillup the form and Bank representative
                will connect with your network person to setup the connectivity.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse12">
                  <p>
                    <a href="#">How to setup network connectivity with the bank?
                    </a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse12" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Its easy to setup this connectivity. Just fillup the form and Bank
                representative will connect with your network person to setup the
                connectivity.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse13">
                  <p>
                    <a href="#">Does this system uses any Database?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse13" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                No, this does not use any kind of database.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse14">
                  <p>
                    <a href="#">Are there any licenses for this system?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse14" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                No, this is completely free from any licenses.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse15">
                  <p>
                    <a href="#"> Are there any third party applications used in the solution?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse15" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Standard open source third party libraries are used in the system, which
                are worldwide used in any application.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse16">
                  <p>
                    <a href="#">After installation, if there is any issue how do we get the support?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse16" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Once you login to the portal, you can create the support ticket and our
                support team will fix the issue.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse17">
                  <p>
                    <a href="#">Currently we use our own file format and don’t want to change it.
                      Can we use this system?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse17" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Yes, this system supports any input file format and structure. So if your
                ERP is creating a file output which is currently in use, it can consume
                that file as well.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse18">
                  <p>
                    <a href="#">What if we want to use only one of the adapter?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse18" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                Yes, its completely pick and choose method. You don’t need to use all the
                tools available in the system. Just select ones which are needed for
                your organization.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse19">
                  <p>
                    <a href="#">Our organization does not allow installation of any other softwares, how can we handle
                      it?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse19" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                You need to connect with your security team and get their approval for
                installation of this software in your IT landscape.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  href="#collapse20">
                  <p>
                    <a href="#">Is this system compliant with VAPT?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse20" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                This is an application and VAPT is more towards the network
                vulnerability. You can connect with your risk or security team for VAPT
                with bank network.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default FAQ">
            <div class="panel-heading">
              <h4 class="panel-title">
                <div class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion"
                  data-target="#collapse21">
                  <p>
                    <a href="#">How much time is needed to setup the system?</a>
                    <span><i class="glyphicon glyphicon-chevron-down rotate-icon"></i></span>
                  </p>
                </div>
              </h4>
            </div>
            <div id="collapse21" class="panel-collapse collapse">
              <div class="panel-body FAQ-Body">
                The installation and testing happen with 3 to 5 business days. It depends
                on your organization’s preparedness to test and deploy the system. But
                it does not take more than 4 to 6 weeks from start to end.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <br><br><br>
</div>


<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header1" id="success-modal-head">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title success-modal-title">Message</h4>
      </div>
      <div class="modal-body">
        <p>
          <img id="success-img-id" style="width: 40px;margin: 0px; "
            src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
          <img id="error-img-id" style="width: 28px; margin: 0px;"
            src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
          <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
        </p>
      </div>
      <div class="modal-footer message-modal-footer">
        <button type="button" class="btn btn-default" id="close">Ok</button>
        <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>