import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplyForCBModel } from '../Model/common.model';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  score: any;
  firstField: any = 0;
  secondField: any = 0;
  thirdField: any = 0;
  fourthField: any = 0;
  fifthField: any = 0;
  sixthField: any = 0;
  seventhField: any = 0;
  LoaderStatus: boolean = false;
  ApplyForCBModel: ApplyForCBModel;

  constructor(private Service: CommonService, private Router: Router, private ActivatedRoute: ActivatedRoute) {
    this.ApplyForCBModel = new ApplyForCBModel();
  }

  ngOnInit(): void {
    this.hideScore();
    this.cap()
  }

  whichERP() {
    if (this.firstField == 8) {
      // $("#hideField").show();
      $("#hideField").removeAttr('disabled')
    }
    else {
      // $("#hideField").hide();
      $("#hideField").attr('disabled', 'disabled')
      $('#hideField').val('0');
    }
  }

  hideScore() {
    $("#scorecal").hide();
  }

  Submit2() {
    this.score = +this.firstField + +this.secondField + +this.thirdField + +this.fourthField + +this.fifthField + +this.sixthField + +this.seventhField;
    $("#scorecal").show();
  }

  cap() {
    var alpha = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '!', '@', '#', '$', '%', '^', '&', '*', '+'];
    var a = alpha[Math.floor(Math.random() * 19)];
    var b = alpha[Math.floor(Math.random() * 19)];
    var c = alpha[Math.floor(Math.random() * 19)];
    var d = alpha[Math.floor(Math.random() * 19)];
    var e = alpha[Math.floor(Math.random() * 19)];
    var f = alpha[Math.floor(Math.random() * 19)];

    var final = a + b + c + d + e + f;
    const inputElement = document.getElementById('capt') as HTMLInputElement
    inputElement.value = final;
  }

  validcap() {
    const stg1 = (document.getElementById('capt') as HTMLInputElement).value
    const stg2 = (document.getElementById('textinput') as HTMLInputElement).value
    if (stg1 == stg2) {
      alert("Form is validated Succesfully");
      return true;
    } else {
      alert("Please enter a valid captcha");
      return false;
    }
  }

  popupMessage(Message: string, Addclass: string, RemoveClass: string, ShowImg: string, HideImg: string): void {
    $("#message-text").html(Message);
    $("#success-modal-head").addClass(Addclass).removeClass(RemoveClass);
    $(ShowImg).show();
    $(HideImg).hide();
    $("#succeess-message-box").modal("show");
  }

  HideShow() {
    $("#errorclose").show();
    $("#close").hide();
  }

  Submit(): void {
    debugger;
    this.LoaderStatus = true;
    const stg1 = (document.getElementById('capt') as HTMLInputElement).value

    const stg2 = (document.getElementById('textinput') as HTMLInputElement).value

    if (this.ApplyForCBModel.Name == "" || this.ApplyForCBModel.Name == null || this.ApplyForCBModel.Name == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Enter Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ApplyForCBModel.Email == "" || this.ApplyForCBModel.Email == null || this.ApplyForCBModel.Email == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Enter Email", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ApplyForCBModel.Mobile_No == "" || this.ApplyForCBModel.Mobile_No == null || this.ApplyForCBModel.Mobile_No == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Enter Mobile Number", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ApplyForCBModel.OrganizationName == "" || this.ApplyForCBModel.OrganizationName == null || this.ApplyForCBModel.OrganizationName == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Enter Organization Name", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ApplyForCBModel.OrganizationType == "" || this.ApplyForCBModel.OrganizationType == null || this.ApplyForCBModel.OrganizationType == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Enter Organization Type", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (this.ApplyForCBModel.ToolSet == "" || this.ApplyForCBModel.ToolSet == null || this.ApplyForCBModel.ToolSet == undefined) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Enter Customer Contact Mobile", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
    }
    else if (stg1 != stg2) {
      this.HideShow();
      this.LoaderStatus = false;
      this.popupMessage("Enter Valid Captcha", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");

    } else {
      this.Service.ApplyForCB(this.ApplyForCBModel).subscribe((data) => {
        if (data == "0") {
          this.HideShow();
          this.LoaderStatus = false;
          this.popupMessage("Some Error Occured", "error-modal-head", "success-modal-head", "#error-img-id", "#success-img-id");
        }
        else {
          $("#errorclose").hide();
          $("#close").show();
          this.HideShow();
          this.LoaderStatus = false;
          this.popupMessage("Application Successfully Submitted", "success-modal-head", "error-modal-head", "#success-img-id", "#error-img-id");
        }
      });
    }
    this.ApplyForCBModel.Name = "";
    this.ApplyForCBModel.Email = "";
    this.ApplyForCBModel.Mobile_No ="";
    this.ApplyForCBModel.OrganizationName = "";
    this.ApplyForCBModel.OrganizationType = "";
    this.ApplyForCBModel.ToolSet = "";
    this.ApplyForCBModel.Comments="";

  }
}






