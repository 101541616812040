<div class="container" style="margin-top: 40px;">
    <div class="row">
        <div class="col-md-12">
            <h1>Privacy Policy</h1>
            <br>
            <h4>This policy applies to Copia Consultancy Services Private Limited ( Copia).</h4>
            <br>
            <h4><u>Clear and easily accessible Statements of Practices and Policies</u></h4>
            <p>As a part of COPIA’s ongoing efforts to ensure the compliance of modern Softwares and customer trust/
                satisfaction, security oriented IT services, COPIA has adopted the privacy policy aimed at protecting
                the personal information entrusted and disclosed by the customer (the "Policy"). This Policy governs the
                way in which COPIA collects, uses, discloses, stores, secures and disposes of personal information.
            </p>
            <p>
                The Policy is in compliance with the Information Technology (Reasonable security practices and
                procedures and sensitive personal data or information) Rules 2011 (the “IT Rules”) contained in the
                Information Technology Act 2000.
            </p>
            <br>
            <h4><u>Purpose of Collection and Use of Personal Information</u></h4>
            <p>COPIA collects and uses the information and other personal information from its customers. This
                information is collected and used for specific business purposes or for other related purposes
                designated by COPIA or for a lawful purpose to comply with the applicable laws and regulations. 
                COPIA shall not divulge any personal information collected from the customer, for cross selling or any other
                purposes.
            </p>
            <p>The authenticity of the personal information provided by the customer shall not be the responsibility of
                COPIA.</p>
            <p>Any information that is freely available or accessible in public domain or furnished under the Right to
                Information Act, 2005 or any other law for the time being in force shall not be regarded as personal
                information for the purposes of this Policy and COPIA shall not be responsible for the same.
            </p>
            <br>
            <h4><u>Disclosure of Personal Information</u></h4>
            <p>
                The personal information collected by COPIA shall not be disclosed to any other organization except:
            </p>
            <p>
                1. where the disclosure has been agreed in a written contract or otherwise between COPIA and the
                customer;
                <br>
                2. where the disclosure has been agreed in a written contract or otherwise between COPIA and the
                customer;
                <br>
                3. where COPIA is required to disclose the personal information to a third party on a need-to-know
                basis, provided that in such case COPIA shall inform such third party of the confidential nature of the
                personal information and shall keep the same standards of information/ data security as that of COPIA.
            </p>
            <br>
            <h4><u>Reasonable Security Practices and Procedures</u></h4>
            <p>
                COPIA shall take reasonable steps and measures to protect the security of the customer’s personal
                information from misuse and loss, unauthorised access, modification or disclosure. COPIA maintains its
                security systems to ensure that the personal information of the customer is appropriately protected.
            </p>
            <p>COPIA ensures that its employees respect the confidentiality of any personal information held by COPIA.
            </p>
            <br>
            <h4><u>Amendments</u></h4>
            <p>COPIA reserves the right to change or update this Policy or practice, at any time with reasonable notice
                to customers on COPIA web site so that customers are always aware of the information which is collected,
                for what purpose COPIA uses it, and under what circumstances, if any, COPIA may disclose it.
            </p>
            <br>
            <h4><u>Response to Enquiries and Complaints</u></h4>
            <p>
                COPIA encourages customer enquiries, feedback and complaints which will help us identify and improve the
                services provided to our customers. For any queries please contact us via email to info@copiacs.com
            </p>
        </div>
    </div>
</div>

