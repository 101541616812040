<div class="page-container container-fluid">
    <h2 class="page-title" id="titleHeading">Create Contact Support</h2>
    <div class="form-submit" id="changeBtn">
        <a [routerLink]="'../contactSupportList'" id="aBtn" class="btn btn-primary">Back to List</a>
    </div>
    <div class="page-content">
        <form>
            <div class="form-section">
                <h3 class="form-section-title">Contact Support Details</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Your Email <span id="starField">*</span></label> 
                                <input name="YourEmail" [(ngModel)]="ContactSopportModel.UserEmail" type="email" 
                                 class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Your Name <span id="starField">*</span></label>
                                <input name="YourName" type="text" [(ngModel)]="ContactSopportModel.UserName" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Created By</label>
                                <input name="CreatedBy" disabled type="text" [(ngModel)]="ContactSopportModel.CreatedBy" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Creation Date</label>
                                <input name="CreationDate" disabled   class="form-control"  value="{{ todayDate | date: 'dd-MM-yyyy'}}">
                                <!-- <h5>
                                    {{ todayDate | date: 'yyyy-MM-dd'}}
                                </h5> -->
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Issued Description</label>
                                <!-- <input name="CustomerContactEmail" type="text" class="form-control" /> -->
                                <textarea name="IssueDescription" required class="form-control" cols="30" [(ngModel)]="ContactSopportModel.IssuedDESC" rows="3"></textarea>
                            </div>
                        </div>
                        <!-- <div class="col-md-3">
                            <div class="form-group">
                                <label>Status <span id="starField">*</span></label>
                                <select name="IsActive" [(ngModel)]="ContactSopportModel.IsActive" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <div class="form-group ">
                                <label>Attachment - </label>
                                <input id="terms-attachment" name="TermsAndCondition" type="file" class="form-control" />
                                <!-- <a href="../../assets/images/{{ApprovalToProceedModel.TermsAndCondition}}" target="_blank">Terms and Condition</a> -->
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer text-right form-submit-section" id="bDiv">
                    <input type="submit" (click)="Submit()" class="btn btn-primary" data-toggle="modal" 
                    data-target="#succeess-message-box" id="HideBtn" value="Submit" />
                </div>
            </div>
        </form>
    </div>
</div>

<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>

    </div>
</div>

