
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6 col-md-4 col-md-offset-4" >
            <div class="account-wall">
                <img class="profile-img" src="../../assets/images/Connectbank Logo.png" alt="Connect Bank Logo">
                <form class="form-signin">
                <input type="text" class="form-control" name="Email" [(ngModel)]="LoginModel.Email" placeholder="Email" >
                <input type="password" class="form-control" name="Password" [(ngModel)]="LoginModel.Password" placeholder="Password" >
                <button class="btn btn-lg btn-primary btn-block" type="submit" (click)="Login()">
                    Sign in</button>
                <a [routerLink]="'/'" class="pull-right need-help">Back to home page </a><span class="clearfix"></span>
                </form>
            </div>
        </div>
    </div>
</div>



<!-- <div class="login-overlay">
    <div class="logo">
        <img src="../../assets/images/Connectbank Logo.png" alt="">
        
    </div>
    <div class="login" (keyup.enter)="handleKeyboardEvent($event)">
        <h2 class="login-title text-center">Login</h2>
        <div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Email</label>
                        <input name="Email" [(ngModel)]="LoginModel.Email" class="form-control" type="email" email required />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Password</label>
                        <input name="Password" [(ngModel)]="LoginModel.Password" class="form-control" type="password" required />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <input (click)="Login()" class="btn btn-primary" type="submit" value="Login" />
                </div>
                <div class="col-md-6 text-right">
                    <div class="form-group">
                        <a class="forgot" [routerLink]="'/'">Back To Home Page</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>


