
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { jsPDF } from 'jspdf';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MasterPageComponent } from './master-page/master-page.component';
import { HeaderComponent } from './master-page/header/header.component';
import { NavigationMenuComponent } from './master-page/navigation-menu/navigation-menu.component';
import { HomeComponent } from './home/home.component';
import { DataTablesModule } from "angular-datatables";
import { WorkFLowListComponent } from './work-flow-list/work-flow-list.component';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CreateNewWorkFlowComponent } from './create-new-work-flow/create-new-work-flow.component';
import { RoleAssignmentComponent } from './role-assignment/role-assignment.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { SapHelpComponent } from './help-files/sap-help/sap-help.component';
import { OracleHelpComponent } from './help-files/oracle-help/oracle-help.component';
import { TallyHelpComponent } from './help-files/tally-help/tally-help.component';
import { SysbrijHelpComponent } from './help-files/sysbrij-help/sysbrij-help.component';
import { WrapperHelpComponent } from './help-files/wrapper-help/wrapper-help.component';
import { SapCodebaseComponent } from './code-base-files/sap-codebase/sap-codebase.component';
import { OracleCodebaseComponent } from './code-base-files/oracle-codebase/oracle-codebase.component';
import { SysbrijCodebaseComponent } from './code-base-files/sysbrij-codebase/sysbrij-codebase.component';
import { TallyCodebaseComponent } from './code-base-files/tally-codebase/tally-codebase.component';
import { WrapperCodebaseComponent } from './code-base-files/wrapper-codebase/wrapper-codebase.component';
import { WrapperAndroidDetailComponent } from './wrapper-android-detail/wrapper-android-detail.component';
import { WrapperAngulardotnetDetailComponent } from './wrapper-angulardotnet-detail/wrapper-angulardotnet-detail.component';
import { WrapperAngularnodejsDetailComponent } from './wrapper-angularnodejs-detail/wrapper-angularnodejs-detail.component';
import { WrapperDotnetDetailComponent } from './wrapper-dotnet-detail/wrapper-dotnet-detail.component';
import { WrapperJavaDetailComponent } from './wrapper-java-detail/wrapper-java-detail.component';
import { WrapperNodeDetailComponent } from './wrapper-node-detail/wrapper-node-detail.component';
import { WrapperNewpaymentComponent } from './wrapper-newpayment/wrapper-newpayment.component';
import { WrapperPaymentcancelComponent } from './wrapper-paymentcancel/wrapper-paymentcancel.component';
import { WrapperPaymentenquiryComponent } from './wrapper-paymentenquiry/wrapper-paymentenquiry.component';
import { WrapperPhpDetailComponent } from './wrapper-php-detail/wrapper-php-detail.component';
import { WrapperWordpressDetailComponent } from './wrapper-wordpress-detail/wrapper-wordpress-detail.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ContactSupportListComponent } from './contact-support-list/contact-support-list.component';
import { CreateContactSupportComponent } from './create-contact-support/create-contact-support.component';
import { WrapperPageContainerComponent } from './wrapper-page-container/wrapper-page-container.component';

import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { LandingPageHeaderComponent } from './landing-page-header/landing-page-header.component';
import { CreateNewWorkFlow1Component } from './create-new-work-flow1/create-new-work-flow1.component';
import { MenuHeaderComponent } from './menu-header/menu-header.component';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';

import { WorkFlowList1Component } from './work-flow-list1/work-flow-list1.component';
import { CustomerList1Component } from './customer-list1/customer-list1.component';
import { CreateCustomer1Component } from './create-customer1/create-customer1.component';
import { ContactSupportList1Component } from './contact-support-list1/contact-support-list1.component';
import { CreateContactSupport1Component } from './create-contact-support1/create-contact-support1.component';
import { RoleMaster1Component } from './role-master1/role-master1.component';
import { RoleAssignment1Component } from './role-assignment1/role-assignment1.component';
import { WrapperHelp1Component } from './help-files1/wrapper-help1/wrapper-help1.component';
import { SysbrijHelp1Component } from './help-files1/sysbrij-help1/sysbrij-help1.component';
import { SysbrijCodebase1Component } from './code-base-files1/sysbrij-codebase1/sysbrij-codebase1.component';
import { WrapperCodebase1Component } from './code-base-files1/wrapper-codebase1/wrapper-codebase1.component';
import { LandingPageNewComponent } from './landing-page-new/landing-page-new.component';
import { LandingPageNew1Component } from './landing-page-new1/landing-page-new1.component';
import { CreateCustomerWorkComponent } from './create-customer-work/create-customer-work.component';
import { CustomerWorkListComponent } from './customer-work-list/customer-work-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MasterPageComponent,
    HeaderComponent,
    NavigationMenuComponent,
    HomeComponent,
    WorkFLowListComponent,
    CreateCustomerComponent,
    CustomerListComponent,
    CreateNewWorkFlowComponent,
    RoleAssignmentComponent,
    RoleMasterComponent,
    SapHelpComponent,
    OracleHelpComponent,
    TallyHelpComponent,
    SysbrijHelpComponent,
    WrapperHelpComponent,
    SapCodebaseComponent,
    OracleCodebaseComponent,
    SysbrijCodebaseComponent,
    TallyCodebaseComponent,
    WrapperCodebaseComponent,
    WrapperAndroidDetailComponent,
    WrapperAngulardotnetDetailComponent,
    WrapperAngularnodejsDetailComponent,
    WrapperDotnetDetailComponent,
    WrapperJavaDetailComponent,
    WrapperNodeDetailComponent,
    WrapperNewpaymentComponent,
    WrapperPaymentcancelComponent,
    WrapperPaymentenquiryComponent,
    WrapperPhpDetailComponent,
    WrapperWordpressDetailComponent,
    LandingPageComponent,
    ContactSupportListComponent,
    CreateContactSupportComponent,
    WrapperPageContainerComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    SitemapComponent,
    LandingPageHeaderComponent,
    CreateNewWorkFlow1Component,

    MenuHeaderComponent,
    WorkFlowList1Component,
    CustomerList1Component,
    CreateCustomer1Component,
    ContactSupportList1Component,
    CreateContactSupport1Component,
    RoleMaster1Component,
    RoleAssignment1Component,
    WrapperHelp1Component,
    SysbrijHelp1Component,
    SysbrijCodebase1Component,
    WrapperCodebase1Component,
    LandingPageNewComponent,
    LandingPageNew1Component,
    CreateCustomerWorkComponent,
    CustomerWorkListComponent,
  ],
imports: [
  BrowserModule,
  HttpClientModule,
  AppRoutingModule,
  FormsModule,
  ReactiveFormsModule,
  DataTablesModule,
  HttpClientModule,
  BrowserAnimationsModule,
  MatToolbarModule,
  MatSidenavModule,
  MatButtonModule,
  MatIconModule,
  MatDividerModule,
  MatMenuModule

],
providers: [],
bootstrap: [AppComponent]
})
export class AppModule { }
