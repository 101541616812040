import { LandingPageNew1Component } from './landing-page-new1/landing-page-new1.component';
import { LandingPageNewComponent } from './landing-page-new/landing-page-new.component';
import { WrapperCodebase1Component } from './code-base-files1/wrapper-codebase1/wrapper-codebase1.component';
import { SysbrijCodebase1Component } from './code-base-files1/sysbrij-codebase1/sysbrij-codebase1.component';
import { RoleAssignment1Component } from './role-assignment1/role-assignment1.component';
import { RoleMaster1Component } from './role-master1/role-master1.component';
import { CreateContactSupport1Component } from './create-contact-support1/create-contact-support1.component';
import { ContactSupportList1Component } from './contact-support-list1/contact-support-list1.component';
import { CreateCustomer1Component } from './create-customer1/create-customer1.component';
import { CustomerList1Component } from './customer-list1/customer-list1.component';
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { CreateNewWorkFlow1Component } from './create-new-work-flow1/create-new-work-flow1.component';
import { LandingPageHeaderComponent } from './landing-page-header/landing-page-header.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

import { ContactSupportListComponent } from './contact-support-list/contact-support-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { listenerCount } from 'process';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MasterPageComponent } from './master-page/master-page.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { WorkFLowListComponent } from './work-flow-list/work-flow-list.component';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { CreateNewWorkFlowComponent } from './create-new-work-flow/create-new-work-flow.component';
import { RoleAssignmentComponent } from './role-assignment/role-assignment.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { SapHelpComponent } from './help-files/sap-help/sap-help.component';
import { OracleHelpComponent } from './help-files/oracle-help/oracle-help.component';
import { SysbrijHelpComponent } from './help-files/sysbrij-help/sysbrij-help.component';
import { TallyHelpComponent } from './help-files/tally-help/tally-help.component'
import { WrapperHelpComponent } from './help-files/wrapper-help/wrapper-help.component';
import { SapCodebaseComponent } from './code-base-files/sap-codebase/sap-codebase.component';
import { OracleCodebaseComponent } from './code-base-files/oracle-codebase/oracle-codebase.component';
import { SysbrijCodebaseComponent } from './code-base-files/sysbrij-codebase/sysbrij-codebase.component';
import { TallyCodebaseComponent } from './code-base-files/tally-codebase/tally-codebase.component';
import { WrapperCodebaseComponent } from './code-base-files/wrapper-codebase/wrapper-codebase.component';
import { WrapperNodeDetailComponent } from './wrapper-node-detail/wrapper-node-detail.component';
import { WrapperPhpDetailComponent } from './wrapper-php-detail/wrapper-php-detail.component';
import { WrapperWordpressDetailComponent } from './wrapper-wordpress-detail/wrapper-wordpress-detail.component';
import { WrapperJavaDetailComponent } from './wrapper-java-detail/wrapper-java-detail.component';
import { WrapperDotnetDetailComponent } from './wrapper-dotnet-detail/wrapper-dotnet-detail.component';
import { WrapperAndroidDetailComponent } from './wrapper-android-detail/wrapper-android-detail.component';
import { WrapperAngularnodejsDetailComponent } from './wrapper-angularnodejs-detail/wrapper-angularnodejs-detail.component';
import { WrapperAngulardotnetDetailComponent } from './wrapper-angulardotnet-detail/wrapper-angulardotnet-detail.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CreateContactSupportComponent } from './create-contact-support/create-contact-support.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { WorkFlowList1Component } from './work-flow-list1/work-flow-list1.component';
import { SysbrijHelp1Component } from './help-files1/sysbrij-help1/sysbrij-help1.component';
import { WrapperHelp1Component } from './help-files1/wrapper-help1/wrapper-help1.component';
import { CreateCustomerWorkComponent } from './create-customer-work/create-customer-work.component';
import { CustomerWorkListComponent } from './customer-work-list/customer-work-list.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageNew1Component,
    children:
    [
      // { 
      //   path:'',
      //   component :LandingPageComponent
      // },
      {
        path: 'termsandcondition',
        component: TermsAndConditionsComponent
      },
      {
        path: 'privacypolicy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'sitemap',
        component: SitemapComponent
      },
    ]
  },
// {
//   path:'new',
//   component :LandingPageNew1Component,

// },

  {
    path: 'login',
    component: LoginComponent
  },
  // {
  //   path: 'termsandcondition',
  //   component: TermsAndConditionsComponent
  // },
  // {
  //   path: 'privacypolicy',
  //   component: PrivacyPolicyComponent
  // },
  // {
  //   path: 'sitemap',
  //   component: SitemapComponent
  // },
  {
    path: 'menuHeader',
    component: MenuHeaderComponent,
    children: [
      {
        path: 'createnewworkflow1',
        component: CreateNewWorkFlow1Component,
      },
      {
        path: 'createnewworkflow1/:workflowID',
        component: CreateNewWorkFlow1Component,
      },
      {
        path: 'workflowList1',
        component: WorkFlowList1Component,
      },
      {
        path: 'customerList1',
        component: CustomerList1Component,
      },
      {
        path: 'createCustomer1',
        component: CreateCustomer1Component,
      },
      {
        path: 'createcustomer1/:UserID/:pageStatus',
        component: CreateCustomer1Component,
      },
      {
        path: 'contactSupportList1',
        component: ContactSupportList1Component,
      },
      {
        path: 'createContactSupport1',
        component: CreateContactSupport1Component,
      },
      {
        path: 'createContactSupport1/:ID',
        component: CreateContactSupport1Component,
      },
      {
        path: 'createContactSupport1',
        component: CreateContactSupport1Component,
      },
      {
        path: 'createCustomerWork',
        component: CreateCustomerWorkComponent,
      },
      {
        path: 'createCustomerWork/:Id',
        component: CreateCustomerWorkComponent,
      },
      {
        path: 'customerWorkList',
        component: CustomerWorkListComponent,
      },
      {
        path: 'roleMaster1',
        component: RoleMaster1Component,
      },
      {
        path: 'roleAssignment1',
        component: RoleAssignment1Component,
      },
      {
        path: 'sysBrijHelpFile',
        component: SysbrijHelp1Component,
      },
      {
        path: 'wrapperHelpFile',
        component: WrapperHelp1Component,
      },
      {
        path: 'sysBrijCodeBase',
        component: SysbrijCodebase1Component,
      },
      {
        path: 'wrapperCodeBase',
        component: WrapperCodebase1Component,
      },
      {
        path: "wrapperCodeBase", component: WrapperCodebase1Component, children: [
          {
            path: "wrapperNodeDetail", component: WrapperNodeDetailComponent
          },
          {
            path: "wrapperPhpDetail", component: WrapperPhpDetailComponent
          },
          {
            path: "wrapperWordpressDetail", component: WrapperWordpressDetailComponent
          },
          {
            path: "wrapperJavaDetail", component: WrapperJavaDetailComponent
          },
          {
            path: "wrapperDotnetDetail", component: WrapperDotnetDetailComponent
          },
          {
            path: "wrapperAndroidDetail", component: WrapperAndroidDetailComponent
          },
          {
            path: "wrapperangularNodejsDetail", component: WrapperAngularnodejsDetailComponent
          },
          {
            path: "wrapperangularDotnetDetail", component: WrapperAngulardotnetDetailComponent
          }
        ]
      },
    ]
  },

  {
    path: 'masterPage',
    component: MasterPageComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'customerList',
        component: CustomerListComponent,
      },
      {
        path: 'workflowList',
        component: WorkFLowListComponent,
      },
      {
        path: 'createcustomer',
        component: CreateCustomerComponent,
      },
      {
        path: 'createcustomer/:UserID/:pageStatus',
        component: CreateCustomerComponent,
      },
      {
        path: 'createnewworkflow',
        component: CreateNewWorkFlowComponent,
        
      },
       {
        path: 'createnewworkflow1',
        component: CreateNewWorkFlow1Component,
      },
       {
        path: 'createnewworkflow1',
        component: CreateNewWorkFlow1Component,
      },
      {
        path: 'createnewworkflow/:workflowID/:pageStatus',
        component: CreateNewWorkFlowComponent,
      },
      {
        path: 'roleAssignment',
        component: RoleAssignmentComponent,
      },
      {
        path: 'roleMaster',
        component: RoleMasterComponent,
      },
      {
        path: 'sapHelp',
        component: SapHelpComponent,
      },
      {
        path: 'oracleHelp',
        component: OracleHelpComponent,
      },
      {
        path: 'sysbrijHelp',
        component: SysbrijHelpComponent,
      },
      {
        path: 'tallyHelp',
        component: TallyHelpComponent,
      },
      {
        path: 'wrapperHelp',
        component: WrapperHelpComponent,
      },
      {
        path: 'sapCodebase',
        component: SapCodebaseComponent,
      },
      {
        path: 'oracleCodebase',
        component: OracleCodebaseComponent,
      },
      {
        path: 'sysbrijCodebase',
        component: SysbrijCodebaseComponent,
      },
      {
        path: 'tallyCodebase',
        component: TallyCodebaseComponent,
      },
      {
        path: 'wrapperCodebase',
        component: WrapperCodebaseComponent,
      },
      {
        path: "wrapperCodebase", component: WrapperCodebaseComponent, children: [
          {
            path: "wrapperNodeDetail", component: WrapperNodeDetailComponent
          },
          {
            path: "wrapperPhpDetail", component: WrapperPhpDetailComponent
          },
          {
            path: "wrapperWordpressDetail", component: WrapperWordpressDetailComponent
          },
          {
            path: "wrapperJavaDetail", component: WrapperJavaDetailComponent
          },
          {
            path: "wrapperDotnetDetail", component: WrapperDotnetDetailComponent
          },
          {
            path: "wrapperAndroidDetail", component: WrapperAndroidDetailComponent
          },
          {
            path: "wrapperangularNodejsDetail", component: WrapperAngularnodejsDetailComponent
          },
          {
            path: "wrapperangularDotnetDetail", component: WrapperAngulardotnetDetailComponent
          }
        ]
      },
      {
        path: 'createContactSupport',
        component: CreateContactSupportComponent,
      },
      {
        path: 'createContactSupport/:ID',
        component: CreateContactSupportComponent,
      },
      {
        path: 'contactSupportList',
        component: ContactSupportListComponent,
      },
    ]
  },

];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 48]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
