import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.css']
})
export class NavigationMenuComponent implements OnInit {
  menus: any;
  menuDemo: any;
  roleID: string;
  constructor(private Service: CommonService, private menuService: MenuService, private renderer: Renderer2) { 
    this.renderer.listen('window', 'click', (e: Event) => {
      debugger;
      console.log(e.target);
      console.log($(e.target).hasClass("has-sub-menu"));
      if($(e.target).hasClass("has-sub-menu") == false) {
        for (let i = 0; i < this.menus.length; i++) {
          this.menus[i].showSubMenu = false
        }
      }
    });
  }

  ngOnInit(): void {
    this.RoleBasedMenu();
    // this.menus = this.menuService.getMenus();
  }

  // For Nested Menu
  showSubMenu(childIndex, parentIndex) {
    debugger;
    // this.menuService.menuToggle(childIndex, parentIndex, false);
    this.menuToggle(childIndex, parentIndex, false);
  }

  RoleBasedMenu() {
    debugger;
    this.roleID = localStorage.getItem("RoleID");
    this.Service.RoleBasedMenu(this.roleID).subscribe((res) => {
      debugger;
      this.menus = res;
      // this.menuDemo = res;
      console.log(this.menus);
    })
  }

  menuToggle(childIndex, ParentIndex, windowClick) {
    debugger;
    if (windowClick == false) {
      if (ParentIndex == undefined) {
        this.menus[childIndex].showSubMenu = !this.menus[childIndex].showSubMenu;
      }
      for (let i = 0; i < this.menus.length; i++) {
        if (i != childIndex) {
          this.menus[i].showSubMenu = false;
        }
      }
    }
    else {
      for (let i = 0; i < this.menus.length; i++) {
        this.menus[i].showSubMenu = false
      }
    }
  }

}
