import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tally-help',
  templateUrl: './tally-help.component.html',
  styleUrls: ['./tally-help.component.css']
})
export class TallyHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
