<div class="container-fluid workFlowListPage">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">Workflow List</h3>
        </div>
        <div class="col-xs-6 text-right">
            <a [routerLink]="'../createnewworkflow1'" class="btn btn-success"> <i class="fa fa-plus fa-lg"
                    aria-hidden="true"></i> Create New</a>
        </div>
    </div>
    <hr>
    <div class="selectionFilterSection">
        <h3 class="selectionFilterTitle">Selection Filter</h3>
        <div class="row selectionFilterSectionBox">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Workflow Number</label>
                    <input type="text" [(ngModel)]="WorkflowListFilterNewModel.WorkflowNo" class="form-control"
                        name="WorkflowNo" placeholder="Workflow Number">
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label>User Name</label>
                    <input type="text" [(ngModel)]="WorkflowListFilterNewModel.UserName" name="UserName"
                        class="form-control" placeholder="User Name">
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1">
                <label>Bank IM Name</label>
                <select [(ngModel)]="WorkflowListFilterNewModel.IMName" name="IMName" class="form-control" id="ddlRole">
                    <option value="">Select</option>
                    <option *ngFor="let record of BankIMDD" value="{{record.UserID}}">{{record.BankIMName}}</option>
                </select>
            </div>
            <div class="col-md-12 text-right SFBtnSection">
                <button type="submit" (click)="WorkflowList()" class="btn btn-primary">Search</button>
                <button type="reset" (click)="Clear()" class="btn btn-danger clearBtn">Clear</button>
            </div>
        </div>
    </div>
    <div class="listSection">
        <h3 class="selectionFilterTitle">list</h3>
        <div class="row listSectionBox">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">WorkFlow No</th>
                        <th scope="col">Creation Date</th>
                        <th scope="col">IM Name</th>
                        <th scope="col">WorkFlow Status</th>
                        <th scope="col">Next Action</th>
                        <th scope="col">Action</th>
                        <th scope="col">Download</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of WorkflowGrid">
                        <td><a (click)="GetWorkflowDetailNew(record.ID, 'view')"
                                title="To view entire data">{{record.WorkflowNo}}</a></td>
                        <td>{{record.WorkflowCreationDate}}</td>
                        <td>{{record.IMName}}</td>
                        <td>{{record.WorkflowStatus}}</td>
                        <td>{{record.Description}}</td>

                        <td *ngIf="record.WorkflowStatusID == '1'">
                            <button class="btn btn-primary"
                                (click)="modal(record.WorkflowStatusID, record.ID)">Edit</button>
                        </td>
                        <td *ngIf="RoleID == '5' && record.WorkflowStatusID == '2'">
                            <button class="btn btn-primary"
                                (click)="modal(record.WorkflowStatusID, record.ID)">Edit</button>
                        </td>
                        <td *ngIf="RoleID == '1' && record.WorkflowStatusID == '3'">
                            <button class="btn btn-primary"
                                (click)="modal(record.WorkflowStatusID, record.ID)">Edit</button>
                        </td>
                        <td *ngIf="RoleID == '5' && record.WorkflowStatusID == '4'">
                            <button class="btn btn-primary"
                                (click)="modal(record.WorkflowStatusID, record.ID)">Edit</button>
                        </td>
                        <td *ngIf="RoleID == '5' && record.WorkflowStatusID == '6'">
                            <button class="btn btn-primary"
                                (click)="modal(record.WorkflowStatusID, record.ID)">Edit</button>
                        </td>
                        <td *ngIf="RoleID == '1' && record.WorkflowStatusID == '5'">
                            <button class="btn btn-primary"
                                (click)="modal(record.WorkflowStatusID, record.ID)">Edit</button>
                        </td>
                        <td *ngIf="RoleID == '5' && record.WorkflowStatusID == '7'">
                            <button class="btn btn-primary"
                                (click)="modal(record.WorkflowStatusID, record.ID)">Edit</button>
                        </td>
                        <td *ngIf="RoleID == '5' && record.WorkflowStatusID == '5'"></td>
                        <td *ngIf="record.WorkflowStatusID == '5' && record.UATFileLocation != ''"><a
                                class="btn btn-success" href="{{record.UATFileLocation}}" target="_blank">UAT <i
                                    class="fa fa-lg fa-download" aria-hidden="true"></i></a>
                        </td>
                        <td *ngIf="RoleID == '1' && record.WorkflowStatusID == '7'"></td>
                        <td *ngIf="record.WorkflowStatusID == '7' && record.ProdFileLocation != ''"><a
                                class="btn btn-success" href="{{record.ProdFileLocation}}" target="_blank">Prod <i
                                    class="fa fa-lg fa-download" aria-hidden="true"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>





<div class="modal fade" id="requirementsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            Requirements review & Initiate ATP
                            <button type="button" class="close modalBtn" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid reviewContainer">
                    <div class="row allRequirements">
                        <div class="col-md-12">
                            <h4 class="reviewContent">Transmission Needed - <span>{{WorkflowRequirementsModel.FileTransmission}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.FileTransmission == 'Yes'">
                            <h4 class="reviewContent">Transmission Type - <span>{{WorkflowRequirementsModel.TransmissionTypeID}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                            <h4 class="reviewContent">Needed ACK files from Bank - <span>{{WorkflowRequirementsModel.ACKFromBank}}</span></h4>
                        </div>
                        <div class="col-md-12"
                            *ngIf="WorkflowRequirementsModel.ACKFromBank == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                            <h4 class="reviewContent">Folder name to get ACK Files - <span>{{WorkflowRequirementsModel.ACKFileFolderNameID}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                            <h4 class="reviewContent">Needed reports from bank - <span>{{WorkflowRequirementsModel.ReportFromBak}}</span></h4>
                        </div>
                        <div class="col-md-12"
                            *ngIf="WorkflowRequirementsModel.ReportFromBak == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                            <h4 class="reviewContent">Reports - <span>{{WorkflowRequirementsModel.ReportID}}</span></h4>
                        </div>
                        <div class="col-md-12"
                            *ngIf="WorkflowRequirementsModel.ReportFromBak == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                            <h4 class="reviewContent">Folder Name - <span>{{WorkflowRequirementsModel.ReportFolderID}}</span></h4>
                        </div>
                        <div class="col-md-12">
                            <h4 class="reviewContent">Encryption Needed - <span>{{WorkflowRequirementsModel.NeedEncryption}}</span></h4>
                        </div>
                        <div class="col-md-12">
                            <h4 class="reviewContent">ERP provide files in Bank Required format - <span>{{WorkflowRequirementsModel.FilesInBankFormat}}</span>
                                </h4>
                        </div>
                        <div class="col-md-12">
                            <h4 class="reviewContent">Needed emails on interface failures - <span>{{WorkflowRequirementsModel.NeedEmail}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.NeedEmail == 'Yes'">
                            <h4 class="reviewContent">Receipients email address - <span>{{WorkflowRequirementsModel.ReceipientEmail}}</span></h4>
                        </div>
                        <div class="col-md-12 " *ngIf="PaymentMethodsList.length > 0">
                            <h4 class="reviewContent">Payment methods</h4>
                            <table class="table table-bordered " >
                                <thead>
                                    <tr>
                                        <th scope="col">Payment Type</th>
                                        <th scope="col">Input File Type</th>
                                        <th scope="col">File Identifier</th>
                                        <th scope="col">Transmission Type</th>
                                        <th scope="col">Encryption</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let record of PaymentMethodsList">
                                        <td>{{record.PaymentType}}</td>
                                        <td>{{record.InputFileType}}</td>
                                        <td>{{record.FileIndentifier}}</td>
                                        <td>{{record.TransmissionType}}</td>
                                        <td>{{record.Encryption}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12 " *ngIf="AccountNumberList.length > 0">
                            <h4 class="reviewContent">Account details</h4>
                            <table class="table table-bordered " >
                                <thead>
                                    <tr>
                                        <th scope="col">Account Name</th>
                                        <th scope="col">Account Number</th>
                                        <th scope="col">Country</th>
                                        <th scope="col">Building Name</th>
                                        <th scope="col">Street Name</th>
                                        <th scope="col">Town Name</th>
                                        <th scope="col">Pincode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let record of AccountNumberList">
                                        <td>{{record.AccountName}}</td>
                                        <td>{{record.AccountNo}}</td>
                                        <td>
                                            <span *ngIf="record.CountryID == 1">India</span>
                                            <span *ngIf="record.CountryID != 1">Other</span>
                                        </td>
                                        <td>{{record.BuildingName}}</td>
                                        <td>{{record.StreetName}}</td>
                                        <td>{{record.TownName}}</td>
                                        <td>{{record.Pincode}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'API'">
                            <h4 class="reviewContent">Payments in Foreign Currency (Vostro) - <span>{{WorkflowRequirementsModel.Vostro}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                            <h4 class="reviewContent">BIC Code - <span>{{WorkflowRequirementsModel.BICCode}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                            <h4 class="reviewContent">Agent Name - <span>{{WorkflowRequirementsModel.AgentName}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                            <h4 class="reviewContent">Building Name - <span>{{WorkflowRequirementsModel.BuildingName}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                            <h4 class="reviewContent">Street Name - <span>{{WorkflowRequirementsModel.StreetName}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                            <h4 class="reviewContent">Town Name - <span>{{WorkflowRequirementsModel.TownName}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                            <h4 class="reviewContent">Pincode - <span>{{WorkflowRequirementsModel.Pincode}}</span></h4>
                        </div>
                        <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                            <h4 class="reviewContent">Country - <span>{{WorkflowRequirementsModel.Country}}</span></h4>
                        </div>
                        <div class="col-md-12">
                            <h4 class="reviewContent">Server Operating System - <span>{{WorkflowRequirementsModel.OperatingSystemID}}</span></h4>
                        </div>
                        <div class="col-md-12">
                            <h4 class="reviewContent">ERP Specific Comments - <span>{{WorkflowRequirementsModel.ERPComment}}</span></h4>
                        </div>
                        <div class="col-md-12">
                            <h4 class="reviewContent">Additinal Requirements - <span>{{WorkflowRequirementsModel.AdditionalRequirement}}</span></h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-12 text-center">
                            <button class="btn btn-primary" style="width: 100%;" (click)="toShowAllReq()">To Show All
                                Requirements</button>
                        </div>

                        <div class="col-md-12">
                            <h2 class="horizontalLine"></h2>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 form-inline">
                            <label>Are these standard Requirements? <span class="starField">*</span></label>
                            <select [(ngModel)]="StandartRequirementsModel.StandardRequirements" class="form-control"
                                name="StandardRequirements">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                                <option value="2">Not Sure</option>
                            </select>
                        </div>
                        <div class="col-md-12 form-inline">
                            <label>Requirements finalized <span class="starField">*</span></label>
                            <select [(ngModel)]="StandartRequirementsModel.RequirementsFinalized" class="form-control"
                                name="RequirementsFinalized">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-md-12 form-inline">
                            <label>Bank IM Name<span class="starField">*</span></label>
                            <input type="text" [(ngModel)]="StandartRequirementsModel.ConfirmBankIM"
                                class="form-control" name="ConfirmBankIM" placeholder="Confirm with Bank IM">
                        </div>
                        <div class="col-md-12 form-inline">
                            <label>Attachment</label>
                            <input type="file" class="form-control" (change)="selectFile($event)">
                        </div>
                        <div class="col-md-12 form-inline"
                            *ngIf="StandartRequirementsModel.RequirementsFinalized == '1'">
                            <label>Send ATP <span class="starField">*</span></label>
                            <select [(ngModel)]="StandartRequirementsModel.SendATP" name="SendATP" class="form-control">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="horizontalLine"><span>OR</span></h2>
                        </div>
                        <div class="col-md-12 form-inline" style="margin-top: 0px; margin-bottom: 20px;">
                            <label>Close the Workflow</label>
                            <select [(ngModel)]="StandartRequirementsModel.CloseWorkflow" name="CloseWorkflow"
                                class="form-control">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="Submit1()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ATPApproval" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" >
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            ATP Approval
                            <button type="button" class="close modalBtn" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6 form-inline">
                            <label>Accept T&C <span class="starField">*</span></label>
                            <select [(ngModel)]="StandartRequirementsModel.AcceptTC" class="form-control" name="AcceptTC">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-md-6 text-right">
                            <button class="btn btn-primary" (click)="DownloadATP()">Download ATP</button>
                        </div>
                    </div>
                    <div class="row"
                        style="border: 1px dashed black; background-color: rgb(224, 218, 218); border-radius: 10px; margin-top: 35px; margin-left: 0px;">
                        <div class="col-md-12 text-center"
                            style=" border-radius: 10px; margin-top: 0px;">
                            <div *ngIf="!file">
                                <input type="file" style="height: 50px; width: 100%; margin-left: 0px; opacity: 0;" class="form-control"
                                    (change)="getFile($event)" class="file">
                            </div>
                            <div *ngIf="file" style="margin: 5px;">
                                File Name : {{file.name}}
                                <button (click)="file=undefined" style="color: red;"><i class="fa fa-times fa-lg"
                                        aria-hidden="true"></i></button>
                            </div>
                            <div>
                                <button class="btn"><i class="fa fa-cloud-upload fa-lg" aria-hidden="true"></i></button>
                                <h4>Drop Signed ATP Here</h4>
                                <p>Support fromat: PDF, Doc</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="Submit2()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="QAInstance" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            Create QA Instance
                            <button type="button" class="close modalBtn" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 form-inline">
                            <label>Generate QA instance  <span class="starField">*</span></label>
                            <select [(ngModel)]="StandartRequirementsModel.ApprovedToQA" name="ApprovedToQA" class="form-control" >
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-md-12 form-inline">
                            <label>Approved By <span class="starField">*</span></label>
                            <input type="text" placeholder="Approved By"
                                [(ngModel)]="StandartRequirementsModel.ApprovedBy" name="ApprovedBy" class="form-control" >
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="Submit3()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ProdInstance" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            Create Production Instance
                            <button type="button" class="close modalBtn" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 form-inline" >
                            <label>Generate Production instance <span class="starField">*</span></label>
                            <select [(ngModel)]="StandartRequirementsModel.ApprovedToProd" name="ApprovedToProd" class="form-control">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-md-12 form-inline">
                            <label>Approved By <span class="starField">*</span></label>
                            <input type="text" placeholder="Approved By"
                                [(ngModel)]="StandartRequirementsModel.ApprovedByProd" name="ApprovedByProd" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="Submit4()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="UATSignoff" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" >
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            UAT Sign off
                            <button type="button" class="close modalBtn" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 form-inline" >
                            <label>UAT Completed <span class="starField">*</span></label>
                            <select [(ngModel)]="StandartRequirementsModel.UATCompleted" name="UATCompleted" class="form-control">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-md-12 form-inline">
                            <label>UAT Approved By <span class="starField">*</span></label>
                            <input type="text" placeholder="UAT Approved By"
                                [(ngModel)]="StandartRequirementsModel.UATApprovedBy" name="UATApprovedBy" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="Submit5()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="DeploymentCompletion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" >
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            Deployment Completion
                            <button type="button" class="close modalBtn" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 form-inline">
                            <label>Deployment Completed <span class="starField">*</span></label>
                            <select [(ngModel)]="StandartRequirementsModel.DeploymentCompleted" class="form-control"
                                name="DeploymentCompleted">
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="Submit6()">Submit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="RequirementsView" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            SysBRIJ Requirement Details
                            <span class="modalBtn">
                                <button class="btn btn-primary" style="margin-right: 30px" (click)="downloadAsPDF()"><i class="fa fa-lg fa-download"
                                    aria-hidden="true"></i></button>
                                <button type="button" class="close" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                    <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid ">
                    <div class="Section1">
                        <div class="row ">
                            <div class="col-md-8">
                                <h5 class="reqReviewSection">Workflow Details</h5>
                            </div>
                            <div class="col-md-4">
                                <h5 class="reqReviewSection">Date: {{WorkflowRequirementsModel.WorkflowSubmitDate}}</h5>
                            </div>
                        </div>
                        <div class="row pdfData section">
                            <div class="col-md-12">
                                <h4 class="reviewContent">Transmission Needed - <span>{{WorkflowRequirementsModel.FileTransmission}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.FileTransmission == 'Yes'">
                                <h4 class="reviewContent">Transmission Type - <span>{{WorkflowRequirementsModel.TransmissionTypeID}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                                <h4 class="reviewContent">Needed ACK files from Bank - <span>{{WorkflowRequirementsModel.ACKFromBank}}</span></h4>
                            </div>
                            <div class="col-md-12"
                                *ngIf="WorkflowRequirementsModel.ACKFromBank == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                                <h4 class="reviewContent">Folder name to get ACK Files - <span>{{WorkflowRequirementsModel.ACKFileFolderNameID}}</span>
                                </h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                                <h4 class="reviewContent">Needed reports from bank - <span>{{WorkflowRequirementsModel.ReportFromBak}}</span></h4>
                            </div>
                            <div class="col-md-12"
                                *ngIf="WorkflowRequirementsModel.ReportFromBak == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                                <h4 class="reviewContent">Reports - <span>{{WorkflowRequirementsModel.ReportID}}</span></h4>
                            </div>
                            <div class="col-md-12"
                                *ngIf="WorkflowRequirementsModel.ReportFromBak == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
                                <h4 class="reviewContent">Folder Name - <span>{{WorkflowRequirementsModel.ReportFolderID}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Encryption Needed - <span>{{WorkflowRequirementsModel.NeedEncryption}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">ERP provide files in Bank Required format -
                                    <span>{{WorkflowRequirementsModel.FilesInBankFormat}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Needed emails on interface failures - <span>{{WorkflowRequirementsModel.NeedEmail}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.NeedEmail == 'Yes'">
                                <h4 class="reviewContent">Recipients email address - <span>{{WorkflowRequirementsModel.ReceipientEmail}}</span>
                                </h4>
                            </div>
                            <div class="col-md-12 reviewContainer" *ngIf="PaymentMethodsList.length > 0">
                                <h4 class="reviewContent">Payment methods</h4>
                                <table class="table table-bordered " >
                                    <thead>
                                        <tr>
                                            <th scope="col">Payment Type</th>
                                            <th scope="col">Input File Type</th>
                                            <th scope="col">File Identifier</th>
                                            <th scope="col">Transmission Type</th>
                                            <th scope="col">Encryption</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of PaymentMethodsList">
                                            <td>{{record.PaymentType}}</td>
                                            <td>{{record.InputFileType}}</td>
                                            <td>{{record.FileIndentifier}}</td>
                                            <td>{{record.TransmissionType}}</td>
                                            <td>{{record.Encryption}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12 reviewContainer" *ngIf="AccountNumberList.length > 0">
                                <h4 class="reviewContent ">Account details</h4>
                                <table class="table table-bordered " >
                                    <thead>
                                        <tr>
                                            <th scope="col">Account Name</th>
                                            <th scope="col">Account Number</th>
                                            <th scope="col">Country</th>
                                            <th scope="col">Building Name</th>
                                            <th scope="col">Street Name</th>
                                            <th scope="col">Town Name</th>
                                            <th scope="col">Pincode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of AccountNumberList">
                                            <td>{{record.AccountName}}</td>
                                            <td>{{record.AccountNo}}</td>
                                            <td>
                                                <span *ngIf="record.CountryID == 1">India</span>
                                                <span *ngIf="record.CountryID != 1">Other</span>
                                            </td>
                                            <td>{{record.BuildingName}}</td>
                                            <td>{{record.StreetName}}</td>
                                            <td>{{record.TownName}}</td>
                                            <td>{{record.Pincode}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'API'">
                                <h4 class="reviewContent">Payments in Foreign Currency (Vostro) - <span>{{WorkflowRequirementsModel.Vostro}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                                <h4 class="reviewContent">BIC Code - <span>{{WorkflowRequirementsModel.BICCode}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                                <h4 class="reviewContent">Agent Name - <span>{{WorkflowRequirementsModel.AgentName}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                                <h4 class="reviewContent">Building Name - <span>{{WorkflowRequirementsModel.BuildingName}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                                <h4 class="reviewContent">Street Name - <span>{{WorkflowRequirementsModel.StreetName}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                                <h4 class="reviewContent">Town Name - <span>{{WorkflowRequirementsModel.TownName}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                                <h4 class="reviewContent">Pincode - <span>{{WorkflowRequirementsModel.Pincode}}</span></h4>
                            </div>
                            <div class="col-md-12" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
                                <h4 class="reviewContent">Country - <span>{{WorkflowRequirementsModel.Country}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Server Operating System - <span>{{WorkflowRequirementsModel.OperatingSystemID}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">ERP Specific Comments - <span>{{WorkflowRequirementsModel.ERPComment}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Additional Requirements - <span>{{WorkflowRequirementsModel.AdditionalRequirement}}</span></h4>
                            </div>
                        </div>
                    </div>

                    <div class="Section2 sectionGap">
                        <div class="row">
                            <div class="col-md-8">
                                <h5 class="reqReviewSection">Requirements Finalized - ATP Sent Details</h5>
                            </div>
                            <div class="col-md-4">
                                <h5 class="reqReviewSection">Date: {{WorkflowRequirementsModel.ATPSentDate}}</h5>
                            </div>
                        </div>
                        <div class="row pdfData section">
                            <div class="col-md-12">
                                <h4 class="reviewContent" >Standard Requirements - <span>{{WorkflowRequirementsModel.StandardRequirements}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent" >Requirements finalized - <span>{{WorkflowRequirementsModel.RequirementsFinalized}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent" >Bank IM Name - <span>{{WorkflowRequirementsModel.ConfirmBankIM}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent" >Attachment - <a href="{{WorkflowRequirementsModel.Attachment1}}"
                                        target="_blank">{{WorkflowRequirementsModel.Attachment1}}</a></h4>

                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent" >Send ATP - <span>{{WorkflowRequirementsModel.SendATP}}</span></h4>
                            </div>
                        </div>
                    </div>

                    <div class="Section3 sectionGap">
                        <div class="row">
                            <div class="col-md-8">
                                <h5 class="reqReviewSection">ATP Approval Details</h5><span></span>
                            </div>
                            <div class="col-md-4">
                                <h5 class="reqReviewSection">Date: {{WorkflowRequirementsModel.ATPApprovalDate}}</h5>
                            </div>
                        </div>
                        <div class="row pdfData section">
                            <div class="col-md-12">
                                <h4  class="reviewContent">Accept Terms and Conditions - <span>{{WorkflowRequirementsModel.AcceptTC}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4  class="reviewContent">Signed ATP file - <a href="{{WorkflowRequirementsModel.SignedATPFileLocation}}"
                                        target="_blank">{{WorkflowRequirementsModel.SignedATPFileLocation}}</a></h4>
                            </div>
                        </div>
                    </div>

                    <div class="Section4 sectionGap">
                        <div class="row">
                            <div class="col-md-8">
                                <h5 class="reqReviewSection">UAT Instnace Creation Details </h5>
                            </div>
                            <div class="col-md-4">
                                <h5 class="reqReviewSection">Date: {{WorkflowRequirementsModel.UATRadyDate}}</h5>
                            </div>
                        </div>
                        <div class="row pdfData section">
                            <div class="col-md-12">
                                <h4 class="reviewContent">Generated QA instance - <span>{{WorkflowRequirementsModel.ApprovedToQA}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Approved By - <span>{{WorkflowRequirementsModel.ApprovedBy}}</span></h4>
                            </div>
                        </div>
                    </div>

                    <div class="Section4 sectionGap">
                        <div class="row">
                            <div class="col-md-8">
                                <h5 class="reqReviewSection">UAT Sign off Details </h5>
                            </div>
                            <div class="col-md-4">
                                <h5 class="reqReviewSection">Date: {{WorkflowRequirementsModel.UATSignoffDate}}</h5>
                            </div>
                        </div>
                        <div class="row pdfData section">
                            <div class="col-md-12">
                                <h4 class="reviewContent">UAT Completed - <span>{{WorkflowRequirementsModel.UATCompleted}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">UAT Approved By - <span>{{WorkflowRequirementsModel.UATApprovedBy}}</span></h4>
                            </div>
                        </div>
                    </div>

                    <div class="Section4 sectionGap">
                        <div class="row">
                            <div class="col-md-8">
                                <h5 class="reqReviewSection">Production Instance Creation Details</h5>
                            </div>
                            <div class="col-md-4">
                                <h5 class="reqReviewSection">Date: {{WorkflowRequirementsModel.ProdInstanceDate}}</h5>
                            </div>
                        </div>
                        <div class="row pdfData section">
                            <div class="col-md-12">
                                <h4 class="reviewContent">Generated Production instance - <span>{{WorkflowRequirementsModel.ApprovedToProd}}</span></h4>
                            </div>
                            <div class="col-md-12">
                                <h4 class="reviewContent">Approved By - <span>{{WorkflowRequirementsModel.ApprovedByProd}}</span></h4>
                            </div>
                        </div>
                    </div>

                    <div class="Section4 sectionGap">
                        <div class="row">
                            <div class="col-md-8">
                                <h5 class="reqReviewSection">Deployment Completion Details</h5>
                            </div>
                            <div class="col-md-4">
                                <h5 class="reqReviewSection">Date: {{WorkflowRequirementsModel.DeployementCmpltDate}}</h5>
                            </div>
                        </div>
                        <div class="row pdfData section">
                            <div class="col-md-12">
                                <h4 class="reviewContent">Deployment Completed - <span>{{WorkflowRequirementsModel.DeploymentCompleted}}</span></h4>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<div class="container" id="pdfTable" #pdfTable>
    <div class="header">
        <h1 style="text-align: center;">SysBRIJ Requirement Details</h1>
        <p style="margin-left: 60px;">Customer Name: {{CompanyName}}</p>
        <pre
            style="margin-left: 60px;">Workflow No - {{WorkflowNo}}                                     Workflow Creation Date: {{WorkflowCreationDate}}</pre>
        <p style="margin-left: 60px;">Workflow Submitted By: {{UserName}}</p>
        -----------------------------------------------------------------------------------------------------------------------------------------------------------
    </div>
    <div class="col-md-11">
        <p>Transmission Needed - {{WorkflowRequirementsModel.FileTransmission}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.FileTransmission == 'Yes'">
        <p>Transmission Type - {{WorkflowRequirementsModel.TransmissionTypeID}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
        <p>Needed ACK files from Bank - {{WorkflowRequirementsModel.ACKFromBank}}</p>
    </div>
    <div class="col-md-11"
        *ngIf="WorkflowRequirementsModel.ACKFromBank == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
        <p>Folder name to get ACK Files - {{WorkflowRequirementsModel.ACKFileFolderNameID}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
        <p>Needed reports from bank - {{WorkflowRequirementsModel.ReportFromBak}}</p>
    </div>
    <div class="col-md-11"
        *ngIf="WorkflowRequirementsModel.ReportFromBak == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
        <p>Reports - {{WorkflowRequirementsModel.ReportID}}</p>
    </div>
    <div class="col-md-11"
        *ngIf="WorkflowRequirementsModel.ReportFromBak == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
        <p>Folder Name - {{WorkflowRequirementsModel.ReportFolderID}}</p>
    </div>
    <div class="col-md-11">
        <p>Encryption Needed - {{WorkflowRequirementsModel.NeedEncryption}}</p>
    </div>
    <div class="col-md-11">
        <p>ERP provide files in Bank Required format - {{WorkflowRequirementsModel.FilesInBankFormat}}</p>
    </div>
    <div class="col-md-11">
        <p>Needed emails on interface failures - {{WorkflowRequirementsModel.NeedEmail}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.NeedEmail == 'Yes'">
        <p>Recipients email address - {{WorkflowRequirementsModel.ReceipientEmail}}</p>
    </div>
    <div class="col-md-11" *ngIf="PaymentMethodsList.length > 0">
        <p>Payment methods</p>
        <table class="table table-bordered " style="width: 78%;">
            <thead>
                <tr>
                    <th scope="col">Payment Type</th>
                    <th scope="col">Input File Type</th>
                    <th scope="col">File Identifier</th>
                    <th scope="col">Transmission Type</th>
                    <th scope="col">Encryption</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record of PaymentMethodsList">
                    <td>{{record.PaymentType}}</td>
                    <td>{{record.InputFileType}}</td>
                    <td>{{record.FileIndentifier}}</td>
                    <td>{{record.TransmissionType}}</td>
                    <td>{{record.Encryption}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-11" *ngIf="AccountNumberList.length > 0">
        <p>Account details</p>
        <table class="table table-bordered " style="width: 78%;">
            <thead>
                <tr>
                    <th scope="col">Account Name</th>
                    <th scope="col">Account Number</th>
                    <th scope="col">Country</th>
                    <th scope="col">Building Name</th>
                    <th scope="col">Street Name</th>
                    <th scope="col">Town Name</th>
                    <th scope="col">Pincode</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record of AccountNumberList">
                    <td>{{record.AccountName}}</td>
                    <td>{{record.AccountNo}}</td>
                    <td>
                        <span *ngIf="record.CountryID == 1">India</span>
                        <span *ngIf="record.CountryID != 1">Other</span>
                    </td>
                    <td>{{record.BuildingName}}</td>
                    <td>{{record.StreetName}}</td>
                    <td>{{record.TownName}}</td>
                    <td>{{record.Pincode}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'API'">
        <p>Payments in Foreign Currency (Vostro) - {{WorkflowRequirementsModel.Vostro}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
        <p>BIC Code - {{WorkflowRequirementsModel.BICCode}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
        <p>Agent Name - {{WorkflowRequirementsModel.AgentName}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
        <p>Building Name - {{WorkflowRequirementsModel.BuildingName}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
        <p>Street Name - {{WorkflowRequirementsModel.StreetName}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
        <p>Town Name - {{WorkflowRequirementsModel.TownName}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
        <p>Pincode - {{WorkflowRequirementsModel.Pincode}}</p>
    </div>
    <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
        <p>Country - {{WorkflowRequirementsModel.Country}}</p>
    </div>
    <div class="col-md-11">
        <p>Server Operating System - {{WorkflowRequirementsModel.OperatingSystemID}}</p>
    </div>
    <div class="col-md-11">
        <p>ERP Specific Comments - {{WorkflowRequirementsModel.ERPComment}}</p>
    </div>
    <div class="col-md-11">
        <p>Additional Requirements - {{WorkflowRequirementsModel.AdditionalRequirement}}</p>
    </div>
</div>

<div class="container" id="ATPSentToUser" #ATPSentToUser>
    <div class="row">
        <p style="font-weight: bold">&#60;Print on Company Letter Head and sign by Authorised signatory&#62;</p>
        <p style="font-weight: bold">Date : {{ todayDate | date: 'dd-MM-yyyy'}}</p>
        <div>
            <span style="font-weight: bold">To : Copia Consultancy Services Private Limited</span>
            <div>Address: 501 Signia Oceans, Sect 10 A Near D Mart, Airoli Navi Mumbai, 400708, India</div>
        </div>
        <p style="font-weight: bold">From : {{CompanyName}}</p>
        <p style="font-weight: bold">Regarding : <span>Software Deployment Authorisation Cum Enablement Request</span>
        </p>
        <p>We hereby authorise Copia Consultancy to deploy and enable software services as per the below statement of
            works in our ERP integration with the bank/s and will be governed by the terms and conditions listed
            therein. We have read and understood the Terms and Conditions, and hereby agree to be bound by such Terms
            and Conditions at all times.</p>
        <br>
        <p style="font-weight: bold; text-align: center;">Statement of Work (SoW)</p>

        <div class="col-md-11">
            <p>Transmission Needed - {{WorkflowRequirementsModel.FileTransmission}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.FileTransmission == 'Yes'">
            <p>Transmission Type - {{WorkflowRequirementsModel.TransmissionTypeID}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
            <p>Needed ACK files from Bank - {{WorkflowRequirementsModel.ACKFromBank}}</p>
        </div>
        <div class="col-md-11"
            *ngIf="WorkflowRequirementsModel.ACKFromBank == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
            <p>Folder name to get ACK Files - {{WorkflowRequirementsModel.ACKFileFolderNameID}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
            <p>Needed reports from bank - {{WorkflowRequirementsModel.ReportFromBak}}</p>
        </div>
        <div class="col-md-11"
            *ngIf="WorkflowRequirementsModel.ReportFromBak == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
            <p>Reports - {{WorkflowRequirementsModel.ReportID}}</p>
        </div>
        <div class="col-md-11"
            *ngIf="WorkflowRequirementsModel.ReportFromBak == 'Yes' && WorkflowRequirementsModel.TransmissionTypeID == 'H2H'">
            <p>Folder Name - {{WorkflowRequirementsModel.ReportFolderID}}</p>
        </div>
        <div class="col-md-11">
            <p>Encryption Needed - {{WorkflowRequirementsModel.NeedEncryption}}</p>
        </div>
        <div class="col-md-11">
            <p>ERP provide files in Bank Required format - {{WorkflowRequirementsModel.FilesInBankFormat}}</p>
        </div>
        <div class="col-md-11">
            <p>Needed emails on interface failures - {{WorkflowRequirementsModel.NeedEmail}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.NeedEmail == 'Yes'">
            <p>Receipients email address - {{WorkflowRequirementsModel.ReceipientEmail}}</p>
        </div>
        <div class="col-md-11" *ngIf="PaymentMethodsList.length > 0">
            <p>Payment methods</p>
            <table class="table table-bordered " style="width: 78%;">
                <thead>
                    <tr>
                        <th scope="col">Payment Type</th>
                        <th scope="col">Input File Type</th>
                        <th scope="col">File Identifier</th>
                        <th scope="col">Transmission Type</th>
                        <th scope="col">Encryption</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of PaymentMethodsList">
                        <td>{{record.PaymentType}}</td>
                        <td>{{record.InputFileType}}</td>
                        <td>{{record.FileIndentifier}}</td>
                        <td>{{record.TransmissionType}}</td>
                        <td>{{record.Encryption}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-11" *ngIf="AccountNumberList.length > 0">
            <p>Account details</p>
            <table class="table table-bordered " style="width: 78%;">
                <thead>
                    <tr>
                        <th scope="col">Account Name</th>
                        <th scope="col">Account Number</th>
                        <th scope="col">Country</th>
                        <th scope="col">Building Name</th>
                        <th scope="col">Street Name</th>
                        <th scope="col">Town Name</th>
                        <th scope="col">Pincode</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of AccountNumberList">
                        <td>{{record.AccountName}}</td>
                        <td>{{record.AccountNo}}</td>
                        <td>
                            <span *ngIf="record.CountryID == 1">India</span>
                            <span *ngIf="record.CountryID != 1">Other</span>
                        </td>
                        <td>{{record.BuildingName}}</td>
                        <td>{{record.StreetName}}</td>
                        <td>{{record.TownName}}</td>
                        <td>{{record.Pincode}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.TransmissionTypeID == 'API'">
            <p>Payments in Foreign Currency (Vostro) - {{WorkflowRequirementsModel.Vostro}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
            <p>BIC Code - {{WorkflowRequirementsModel.BICCode}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
            <p>Agent Name - {{WorkflowRequirementsModel.AgentName}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
            <p>Building Name - {{WorkflowRequirementsModel.BuildingName}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
            <p>Street Name - {{WorkflowRequirementsModel.StreetName}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
            <p>Town Name - {{WorkflowRequirementsModel.TownName}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
            <p>Pincode - {{WorkflowRequirementsModel.Pincode}}</p>
        </div>
        <div class="col-md-11" *ngIf="WorkflowRequirementsModel.Vostro == 'Yes'">
            <p>Country - {{WorkflowRequirementsModel.Country}}</p>
        </div>
        <div class="col-md-11">
            <p>Server Operating System - {{WorkflowRequirementsModel.OperatingSystemID}}</p>
        </div>
        <div class="col-md-11">
            <p>ERP Specific Comments - {{WorkflowRequirementsModel.ERPComment}}</p>
        </div>
        <div class="col-md-11">
            <p>Additinal Requirements - {{WorkflowRequirementsModel.AdditionalRequirement}}</p>
        </div>

        <p>I/We hereby confirm on the below Terms & Conditions for the enableing the Software for the above
            requirements.</p>
        <br><br><br>
        <p style="font-weight: bold">Company Seal and signature</p>
        <div style="font-weight: bold">Place :</div>
        <div style="font-weight: bold">Date :</div>
        <br>
        <p style="font-weight: bold">Terms and Conditions: </p>

        <div>
            <p>GENERAL: </p>
            <p style="margin-left: 20px;">1. Copia Consultancy will configure the solution as per agreed SOW.</p>
            <p style="margin-left: 20px;">2. We will provide the necessary access required by Copia Consultancy for
                installing / setup the programs and for any assistance that may be requiremend from them during testing.
                ( eg Vpn, ERP logins with proper authorizations, write access to folders etc )</p>
            <p style="margin-left: 20px;">3. We agree that we will test the programs / software for all expected
                scenarios and provide UAT sign-off </p>
            <p style="margin-left: 20px;">4. Copia Consultancy will NOT be held responsible for any errors that might
                occur after UAT sign-off and go-live. However, Copia Consultancy will provide the technical support (as
                and when needed) to resolve any errors / any other issues which may occur after UAT sign-off and go-live
            </p>
            <p style="margin-left: 20px;">5. Copia Consultancy is expected to resolve any issues during the warranty
                period ( 1 month ) from the date of go-live</p>
            <p style="margin-left: 20px;">6. Post warranty period, any support needed will be on chargeable basis on per
                day efforts.</p>

            <p class="subHeading">INSTRUCTIONS:</p>
            <p style="margin-left: 20px;">7. We agree to use the software solution in the form which COPIA CONSULTANCY
                has advised to use from time to time</p>
            <p style="margin-left: 20px;">8. COPIA CONSULTANCY is not under any obligation to review whether the
                software solution conflicts with any other system at our environment.</p>
            <p style="margin-left: 20px;">9. We shall promptly acquire, maintain, update and install (where relevant)
                any equipment, software, telecommunications facilities, networks, connections, patches, releases and/or
                updates as required by the software solution.</p>
            <p style="margin-left: 20px;">10. We shall not alter, reverse engineer, copy (other than to the extent
                necessary for the permitted use), publish or impart to any third party any products or services provided
                under plug and play solution.</p>
            <p style="margin-left: 20px;">11. We are responsible for obtaining and maintaining the software and hardware
                to use the solution provided by COPIA CONSULTANCY . </p>

            <p class="subHeading">WARRANTIES:</p>
            <p style="margin-left: 20px;">12. We undertakes to inform COPIA CONSULTANCY regarding any theft, fraud,
                illegal activity, loss, damage or other misuse of plug and play solution.</p>
            <p style="margin-left: 20px;">13. COPIA CONSULTANCY shall not be liable for any Loss suffered as a result of
                the acts or omissions of an Infrastructure Provider.</p>

            <p class="subHeading">AMENDMENTS: </p>
            <p style="margin-left: 20px;">14. We agree that COPIA CONSULTANCY will not be responsible if we make any
                changes to the programs/software which may cause disruption in the functionality agreed in SoW.</p>
            <p style="margin-left: 20px;">15. We understand that COPIA CONSULTANCY may make amendments to the terms and
                conditions which will become effective on the expiry of 45 days notice to us.</p>

            <p class="subHeading">SECURITY MEASURES: </p>
            <p style="margin-left: 20px;">16. We will be responsible for our environment security and internal controls.
                We shall establish, maintain and review our internal security measures for its use and access by the
                software solution, including the installation and ongoing update of anti-virus software.</p>
            <p style="margin-left: 20px;">17. We shall adopt and review our internal security measures on a regular
                basis to ensure protection remains up to date and in line with regulatory and industry best practice
                guidance. </p>
            <p style="margin-left: 20px;">18. These enviornmental security / internal controls / internal security
                measures should include, but not be limited to, malware protection, network restrictions, physical
                access restrictions, remote access restrictions, computer security settings, monitoring of improper
                usage, guidance on acceptable web browsers and email usage including how to avoid acquiring malware.</p>

            <p class="subHeading">LIMITATION OF LIABILITY:</p>
            <p style="margin-left: 20px;">19. Notwithstanding anything contrary contained herein or elsewhere, COPIA
                CONSULTANCY shall have no liability to us or any other person for any consequential, compensatory,
                special or other damages, including without limitation damages for lost profits or loss or damages from
                subsequent wrongful dishonour caused by any error, act, delay or omission by Plug and Play solution</p>
            <p style="margin-left: 20px;">20. With respect to the COPIA CONSULTANCY’s liability under these T&Cs, the
                provisions set out in these T&Cs shall govern unless they are in conflict with any Applicable Law, in
                which case the relevant liability will be determined as required by such Applicable Law.</p>

            <p class="subHeading">INDEMNITY</p>
            <p style="margin-left: 20px;">We shall indemnify and hold harmless from COPIA CONSULTANCY , its officers and
                employees against all liabilities, claims, costs, losses, damages and expenses (including legal fees) of
                any kind which may be incurred by any of them and all actions or proceedings which may be brought by or
                against them in relation to the Services performed by COPIA CONSULTANCY under these T&Cs. COPIA
                CONSULTANCY shall indemnify, defend and hold harmless the EPL, its successors, directors, employees;
                from and against all direct and indirect liabilities, losses, deductions, penalties, consequences,
                damages, claims, actions, demands, payments, lawyers’ fees, costs, taxes etc and action or proceeding
                commenced by any person; by virture of or in connection with this SOW and its T&C, claims and/or the
                Service, any act, omission, failure, breach, misconduct and/or negligence of the COPIA CONSULTANCY, its
                employees, staffs, officers and others. </p>
        </div>
    </div>
</div>

<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close modalBtn" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>