<div class="document-container container-fluid">
    <h1 class="document-title text-center">Omni Channel Wrapper Code -
      <span class="language-name">Java</span>
    </h1>
    <div class="row document-content-container">
      <div class="col-md-2">
        <div class="document-index">
          <ol>
            <li>
              <a>Overview</a>
            </li>
            <li>
              <a>Pre-requisities</a>
            </li>
            <li>
              <a routerLink="../wrapperJavaDetail" fragment="stepsImplementation">Steps of Implementation</a>
              <ul>
                <li>
                  <a routerLink="../wrapperJavaDetail" fragment="newPayment">newPayment</a>
                </li>
                <li>
                  <a routerLink="../wrapperJavaDetail" fragment="paymentEnquiry">paymentEnquiry</a>
                </li>
                <li>
                  <a routerLink="../wrapperJavaDetail" fragment="paymentCancellation">paymentCancellation</a>
                </li>
                <li>
                  <a routerLink="../wrapperJavaDetail" fragment="logFile">Log File</a>
                </li>
                <li>
                  <a routerLink="../wrapperJavaDetail" fragment="callApi">CallingAPI</a>
                </li>
                <li>
                    <a routerLink="../wrapperJavaDetail" fragment="callbackNotification">Callback Notification API</a>
                  </li>
              </ul>
            </li>
            <li>
              <a routerLink="../wrapperJavaDetail" fragment="codeDetails">Code Details</a>
            </li>
          </ol>
        </div>
      </div>
      <div class="col-md-10 document-description-container">
        <section class="document-section overview-container">
          <h2 class="document-section-title">1. Overview</h2>
          <div class="section-tab">
            <div class="description-container">
              <p>
                Wrapper code has three functionalities which are submit payment, payment status enquiry and Order cancel and refund the payment.
                This document describes the steps to be followed to include the Wrapper code in merchant website implementation.
              </p>
            </div>
          </div>
        </section>
        <section class="document-section overview-container">
          <h2 class="document-section-title">2. Pre-requisities</h2>
          <div class="section-tab">
            <div class="description-container">
              <p>
                Install packages given below inside merchant’s Website code:
              </p>
            </div>
            <div class="pre-requisit-list-container">
              <ul>
                <li>apache-commons-lang.jar</li>
                <li>asm-1.0.2.jar</li>
                <li>com.fasterxml.jackson.core.jar</li>
                <li>com.fasterxml.jackson.databind.jar</li>
                <li>commons-codec-1.11.jar</li>
                <li>commons-logging-1.2.jar</li>
                <li>gson-2.8.2.jar</li>
                <li>httpclient-4.5.13.jar</li>
                <li>httpcore-4.4.13.jar</li>
                <li>jackson-annotations-2.1.2.jar</li>
                <li>java-json.jar</li>
                <li>javax.annotation.jar</li>
                <li>javax.servlet.jar</li>
                <li>json-smart-2.2.1.jar</li>
                <li>log4j-1.2.17.jar</li>
                <li>log4j-core-2.8.2.jar</li>
                <li>nimbus-jose-jwt-5.8.0.wso2v1.jar</li>
                <li>org.apache.commons.io.jar</li>
                <li>org.springframework.core-3.0.4.RELEASE.jar</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="document-section overview-container" id="stepsImplementation">
          <h2 class="document-section-title">3. Steps of Implementation</h2>
          <div class="section-tab">
            <div class="description-container">
              <p>
                After installation of the packages, one needs to add methods of wrapper code into merchant’s website code. Merchant just
                needs to copy and paste the methods into his coding files.
              </p>
              <p>
                These logical methods are related to API call, encryption, decryption and Payment gateway functionality. Below are the details
                of every method along with the method call.
              </p>
            </div>
            <ul>
              <li class="method-detail" id="newPayment">
                <app-wrapper-newpayment></app-wrapper-newpayment>
              </li>
              <li class="method-detail" id="paymentEnquiry">
                <app-wrapper-paymentenquiry></app-wrapper-paymentenquiry>
              </li>
              <li class="method-detail" id="paymentCancellation">
                <app-wrapper-paymentcancel></app-wrapper-paymentcancel>
              </li>
              <li class="method-detail" id="logFile">
                <h3 class="title-level-one">Log Files</h3>
                <div class="description-container">
                  <p>Create info.log file in the project and give path in log4j.properties file.</p>
                </div>
              </li>
              <li class="method-detail" id="callApi">
                <h3 class="title-level-one">CallingAPI</h3>
                <div class="description-container">
                  <p>This method is internally calling the Bank’s API and returns the response to the calling block of code.</p>
                </div>
              </li>
              <li class="method-detail" id="callbackNotification">
                <h3 class="title-level-one">Callback Notification API</h3>
                <div class="description-container">
                  <p>The Bank triggers this API and sends payment status back.</p>
                  <p>
                    Merchant needs to include below method in its Website code. Bank will call this code and send the payment status. Merchant
                    can update its End User or store the status received as per its implementation.
                  </p>
                  <p>The API has two arguments named as paymentStatus and txnRefNo. Both of these arguments are type string.
                    <br>In case of a successful payment set paymentStatus value to success.
                    <br>In case of payment failure set paymentStatus value to fail.
                  </p>
                  <p>It gives an acknowledgement(response) in JSON format as mentioned below.</p>
                </div>
                <div class="code-view">
                  <pre>
                    <code [innerHTML]="apiResponseCode"></code>
                  </pre>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section class="document-section overview-container" id="codeDetails">
          <h2 class="document-section-title">4. Code Details</h2>
          <div class="section-tab">
            <p>The wrapper code includes four classes and CommonRepository.cs file.</p>
            <div class="method-detail">
              <h3 class="title-level-one">Classes</h3>
              <ul>
                <li>newPayment(PaymentPageRedirectDTO dto)</li>
                <li>paymentEnquiry(PaymentEnquiryDTO dto)</li>
                <li>paymentCancellation(PaymentCancellationDTO dto)</li>
              </ul>
            </div>
            <div class="method-detail">
              <h3 class="title-level-one">Callback Notification</h3>
              <ul>
                <li>CallbackPaymentNotification</li>
              </ul>
            </div>
            <div class="method-detail">
              <h3 class="title-level-one">File Name</h3>
              <ul>
                <li>CommonRepository.cs</li>
              </ul>
            </div>
            <div class="code-detail-container">
              <h3 class="title-level-one">Details of Method</h3>
              <ol>
                <li class="method-detail">
                  <h3 class="title-level-two">newPayment</h3>
                  <p>This method is used to make a payment.</p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(paymentMethodCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="paymentMethodCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">paymentEnquiry</h3>
                  <p>This method is used to enquire the status of the payment.</p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(paymentEnquiryMethodCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="paymentEnquiryMethodCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">paymentCancellation</h3>
                  <p>This method is used to cancel the order and refund the payment.</p>
                  <div class="code-view">
                    <button title='copy code' class="copy-btn" (click)="copyBtn(paymentCancellationMethodCode)">
                      <i class="fa fa-clone" aria-hidden="true"></i>
                    </button>
                    <pre>
                            <code [innerHTML]="paymentCancellationMethodCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">web.xml</h3>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(webXmlCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                            <code [innerHTML]="webXmlCode"></code>
                          </pre>
                  </div>
                </li>
                <li class="method-detail">
                  <h3 class="title-level-two">HttpUtil</h3>
                  <p>This class includes the methods used to call the API’s.</p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(httpUtilCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                              <code [innerHTML]="httpUtilCode"></code>
                            </pre>
                  </div>
                </li>
              </ol>
            </div>
            <div class="code-detail-container">
              <h3 class="title-level-one">File Name</h3>
              <ul>
                <li class="method-detail">
                  <h3 class="title-level-two">Common Methods</h3>
                  <p>
                    Below are the methods which process the input data entered on the merchant website.
                  </p>
                  <p>
                    If merchant already have constant file then copy the all properties of this file in their Constant file.
                  </p>
                  <button title='copy code' class="copy-btn" (click)="copyBtn(commonMethodCode)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                  <div class="code-view">
                    <pre>
                                <code [innerHTML]="commonMethodCode"></code>
                              </pre>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>