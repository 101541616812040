<div class="page-container container-fluid">
    <h2 class="page-title">Deployment WorkFlow List</h2>
    <div class="form-submit">
        <a [routerLink]="'../createnewworkflow'" class="btn btn-primary">Create New</a>
    </div>
    <div class="page-content">
        <!-- Selection Filter Section Started -->
        <form>
            <div class="form-section">
                <h3 class="form-section-title">Selection Filters</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-4" *ngIf="RoleID != '1'">
                            <div class="form-group">
                                <label>Company Name</label>
                                <select [(ngModel)]="WorkflowListModel.CompanyID" class="form-control"
                                    name="ddlCompanyID">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>WorkFlow No</label>
                                <input [(ngModel)]="WorkflowListModel.WorkflowNo" name="WorkflowNo" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>User Name</label>
                                <input [(ngModel)]="WorkflowListModel.UserName" name="txtUserName" type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>IM Name</label>
                                <input [(ngModel)]="WorkflowListModel.IMName" name="txtIMName" type="text" class="form-control" />
                            </div>
                        </div>                        
                        <div class="col-md-4">
                            <div class="form-group form-btn-align">
                                <input type="submit" (click)="WorkflowList()" class="btn btn-primary" value="Search" /> | 
                                <input type="reset" (click)="Clear()" class="btn btn-danger" value="Clear" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- Selection Filter Section Ended -->
        <!-- List Section Sarted -->
        <div class="table-section">
            <div class="table-section-content">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-striped">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>WorkFlow No</th>
                            <th>Creation Date</th>
                            <th>IM Name</th>
                            <th>Workflow Status</th>
                            <th>Application</th>
                            <th>Edit</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of WorkflowGrid">
                            <td>{{record.CompanyName}}</td>
                            <td>{{record.WorkflowNo}}</td>
                            <td>{{record.WorkflowCreationDate}}</td>
                            <td>{{record.IMName}}</td>
                            <td>{{record.WorkflowStatus}}</td>
                            <td>
                                <a *ngIf="record.WorkflowStatusID >= 5" class="btn btn-primary" href="../../assets/application/ConnectBank.zip">Download</a>
                            </td>
                            <td>
                                <button [routerLink]="['../createnewworkflow', record.WorkflowID, 'edit']" class="action-btn edit-btn">Edit</button>
                            </td>
                            <td>
                                <button [routerLink]="['../createnewworkflow', record.WorkflowID, 'view']" class="action-btn view-btn">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="loaderActive == true" class="list-loader">
                <img src="../../assets/images/loader.gif" alt=""> Loading...
            </div>
        </div>
        <!-- List Section Ended -->
    </div>
    <!-- List Section Started -->
</div>