<div class="page-container container-fluid">
    <h3 *ngIf="WorkflowID == undefined" class="page-title">Create New Workflow</h3>
    <h3 *ngIf="WorkflowID != undefined" class="page-title">Workflow Setup <span
            class="workflow-status">{{WorkflowStatus}}</span></h3>
    <div class="form-submit">
        <a *ngIf="WorkflowID != undefined" [routerLink]="'../../../workflowList'" class="btn btn-primary">Back to
            List</a>
        <a *ngIf="WorkflowID == undefined" [routerLink]="'../workflowList'" class="btn btn-primary">Back to List</a>
    </div>
    <div class="page-content" id="entire-form">
        <form>
            <div class="form-section">
                <h3 class="form-section-title">Basic Company Information</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Company Name</label>
                                <select [(ngModel)]="CompanyDataModel.CompanyID" class="form-control" name="ddlCompany"
                                    id="ddlCompany" disabled>
                                    <option value="">Select</option>
                                    <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Workflow No</label>
                                <input [(ngModel)]="CompanyDataModel.WorkflowNo" name="WorkflowNo" type="text"
                                    class="form-control" disabled />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Workflow Date</label>
                                <input name="WhenEntered" type="text" [(ngModel)]="CompanyDataModel.WhenEntered"
                                    id="when-entered" class="form-control" disabled />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>User Name</label>
                                <input [(ngModel)]="CompanyDataModel.UserName" name="txtUserName" type="text"
                                    class="form-control" disabled />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>User Email</label>
                                <input [(ngModel)]="CompanyDataModel.UserEmail" name="txtUserEmail" type="text"
                                    class="form-control" disabled />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>User Mobile</label>
                                <input [(ngModel)]="CompanyDataModel.UserMobile" name="txtUserMobile" type="text"
                                    class="form-control" disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3 class="form-section-title">Customer Information</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Customer Name <span id="starField">*</span></label>
                                <input [(ngModel)]="CustomerDataModel.CustomerName" name="CustomerName" type="text"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Profile ID with Bank <span id="starField">*</span></label>
                                <input [(ngModel)]="CustomerDataModel.CustomerProfileID" name="CustomerProfileID"
                                    type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Country <span id="starField">*</span></label>
                                <select class="form-control" [(ngModel)]="CustomerDataModel.CustomerCountryID"
                                    name="CustomerCountryID" name="CustomerCountry">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of CountryDD" value="{{record.ID}}">
                                        {{record.Country}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Building Number</label>
                                <input [(ngModel)]="CustomerDataModel.CustomerBuildingNo" name="CustomerBuildingNo"
                                    type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Street Name</label>
                                <input type="text" [(ngModel)]="CustomerDataModel.CustomerStreetName"
                                    name="CustomerStreetName" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Town Name</label>
                                <input type="text" [(ngModel)]="CustomerDataModel.CustomerTownName"
                                    name="CustomerTownName" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Pin Code</label>
                                <input type="text" [(ngModel)]="CustomerDataModel.CustomerPinCode"
                                    name="CustomerPinCode" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Client ID </label>
                                <input type="text" [(ngModel)]="CustomerDataModel.CustomerClientID"
                                    name="CustomerClientID" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Client Secret </label>
                                <input type="text" [(ngModel)]="CustomerDataModel.CustomerClientSecret"
                                    name="CustomerClientSecret" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3 class="form-section-title">Entity Information</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Entity Name <span id="starField">*</span></label>
                                <input type="text" [(ngModel)]="EntityDataModel.EntityName" name="EntityName"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Account Number with Bank <span id="starField">*</span></label>
                                <input type="text" [(ngModel)]="EntityDataModel.AcoountNoWithBank"
                                    name="AcoountNoWithBank" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Country <span id="starField">*</span></label>
                                <select class="form-control" [(ngModel)]="EntityDataModel.Country" name="EntityCountry">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of CountryDD" value="{{record.ID}}">
                                        {{record.Country}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Building Number</label>
                                <input type="text" [(ngModel)]="EntityDataModel.BuildingNumber" name="BuildingNumber"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Street Name</label>
                                <input type="text" [(ngModel)]="EntityDataModel.StreetName" name="StreetName"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Town Name</label>
                                <input type="text" [(ngModel)]="EntityDataModel.TownName" name="TownName"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Pin Code</label>
                                <input type="text" [(ngModel)]="EntityDataModel.PinCode" name="PinCode"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>BIC </label>
                                <input type="text" [(ngModel)]="EntityDataModel.BIC" name="BIC" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Forwarding Agent Needed</label>
                                <select class="form-control" [(ngModel)]="EntityDataModel.ForwardingAgentNeeded"
                                    name="ForwardingAgentNeeded">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <ng-template [ngIf]="EntityDataModel.ForwardingAgentNeeded == '1'">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Forwarding Agent Name <span id="starField">*</span></label>
                                    <input type="text" [(ngModel)]="EntityDataModel.ForwardingAgentName"
                                        name="ForwardingAgentName" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Building Number</label>
                                    <input type="text" [(ngModel)]="EntityDataModel.ForwardingAgentBuildingName"
                                        name="ForwardingAgentBuildingName" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Street Name</label>
                                    <input type="text" [(ngModel)]="EntityDataModel.ForwardingAgentStreetName"
                                        name="ForwardingAgentStreetName" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Town Name</label>
                                    <input type="text" [(ngModel)]="EntityDataModel.ForwardingAgentTownName"
                                        name="ForwardingAgentTownName" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Pin Code</label>
                                    <input type="text" [(ngModel)]="EntityDataModel.ForwardingAgentPinCode"
                                        name="ForwardingAgentPinCode" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Country <span id="starField">*</span></label>
                                    <input type="text" [(ngModel)]="EntityDataModel.ForwardingAgentCountry"
                                        name="ForwardingAgentCountry" class="form-control" />
                                </div>
                            </div>
                        </ng-template>
                        <div class="col-md-3">
                            <button class="btn btn-primary" *ngIf="PageStatus != 'view'" id="attToCartBtn"
                                (click)="AddToCartEntityData()">Add To
                                Cart</button>
                        </div>
                    </div>
                    <table class="table table-bordered table-width" id="table">
                        <thead style="background-color: #d1ccc5;">
                            <tr>
                                <th>Entity Name</th>
                                <th>Account Number with Bank</th>
                                <th *ngIf="PageStatus != 'view'">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of EntityDataList; let i = index">
                                <td>{{data.EntityName}}</td>
                                <td>{{data.AcoountNoWithBank}}</td>
                                <td *ngIf="PageStatus != 'view'"><button class="btn btn-sm btn-danger"
                                        (click)="DeleteCartEntityData(i)">Delete</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-section">
                <h3 class="form-section-title">ERP Adapter Requirements</h3>
                <div class="form-field-section">
                    <div class="row ">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">ERP System <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterRequirnmentsModel.ERPSystemID" name="ERPTypeID"
                                    id="ddlERPSystem" class="form-control">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of ErpSystemDD" value="{{record.ID}}">
                                        {{record.ErpSystem}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">ERP Adapter Needed <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterRequirnmentsModel.ERPAdapter" name="ERPAdapter"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="AdapterRequirnmentsModel.ERPAdapter == '1'">
                            <div class="form-group">
                                <label>ERP Specific Detail <span id="starField">*</span></label>
                                <textarea [(ngModel)]="AdapterRequirnmentsModel.ERPSpecificDetail"
                                    name="ERPSpecificDetail" class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3 class="form-section-title">SysBrij Adapter Requirements - Forward Flow</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Mapper Needed <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterRequirnmentsModel.MapperNeeded" name="MapperNeeded"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Payment Type <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterDetailsModel.PaymentTypeID" name="PaymentTypeID"
                                    id="PaymentTypeID" class="form-control">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of PaymentTypeDD" value="{{record.ID}}">
                                        {{record.PaymentType}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Input File Type <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterDetailsModel.InputFileTypeID" name="InputFileTypeID"
                                    id="InputFileTypeID" class="form-control">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of InputFileTypeDD" value="{{record.ID}}">
                                        {{record.InputFileType}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>File Identifier <span id="starField">*</span></label>
                                <input type="text" class="form-control" [(ngModel)]="AdapterDetailsModel.FileIdentifier"
                                    name="FileIdentifier" id="FileIdentifier">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Transmission Type<span id="starField">*</span></label>
                                <select class="form-control" [(ngModel)]="AdapterDetailsModel.TransmissionTypeID"
                                    name="TransmissionTypeID" id="TransmissionTypeID"
                                    (change)="TranmissionTypeChange()">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of TransmissionTypeDD" value="{{record.ID}}">
                                        {{record.TransmissionType}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Encryption <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterDetailsModel.EncryptionNeeded" name="Encryption"
                                    id="Encryption" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="AdapterDetailsModel.EncryptionNeeded == '1'">
                            <div class="form-group">
                                <label for="">Encryption Type <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterDetailsModel.EncryptionTypeID" name="EncryptionTypeID"
                                    id="EncryptionTypeID" class="form-control">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of EncryptionTypeDD" value="{{record.ID}}">
                                        {{record.EncryptionType}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-primary" *ngIf="PageStatus != 'view'" id="attToCartBtn"
                                (click)="AddToCartAdapterDetails()">Add To
                                Cart</button>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-bordered" id="table1">
                                <thead style="background-color: #d1ccc5;">
                                    <tr>
                                        <th>Payment Type</th>
                                        <th>Input File Type</th>
                                        <th>File Identifier</th>
                                        <th>Transmission Type</th>
                                        <th>Encryption</th>
                                        <th *ngIf="PageStatus != 'view'">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of AdapterDetailsList; let i = index">
                                        <td>{{data.PaymentType}}</td>
                                        <td>{{data.InputFileType}}</td>
                                        <td>{{data.FileIdentifier}}</td>
                                        <td>{{data.TransmissionType}}</td>
                                        <td>
                                            <span *ngIf="data.EncryptionNeeded == 1">Yes</span>
                                            <span *ngIf="data.EncryptionNeeded == 0">No</span>
                                        </td>
                                        <td *ngIf="PageStatus != 'view'"><button class="btn btn-sm btn-danger"
                                                (click)="DeleteCartAdapterDetails(i)">Delete</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3 class="form-section-title">SysBrij Adapter Requirements - Reverse Flow</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Reverse Feed Needed <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterRequirnmentsModel.ReverseFeedNeeded"
                                    name="ReverseFeedNeeded" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="AdapterRequirnmentsModel.ReverseFeedNeeded == '1'">
                            <div class="form-group">
                                <label for="">SFTP Folder <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterRequirnmentsModel.SftpFolderID" name="SftpFolderID"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of SftpFolderDD" value="{{record.ID}}">
                                        {{record.Folder}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="AdapterRequirnmentsModel.ReverseFeedNeeded == '1'">
                            <div class="form-group">
                                <label for="">Reverse Feed Encrypted <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterRequirnmentsModel.ReverseFeedEncrypted"
                                    name="ReverseFeedEncrypted" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Reports Needed <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterRequirnmentsModel.ReportsNeeded" name="ReportsNeeded"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="AdapterRequirnmentsModel.ReportsNeeded == '1'">
                            <div class="form-group">
                                <label for="">Select Report <span id="starField">*</span></label>
                                <!-- <select class="form-control" [(ngModel)]="AdapterRequirnmentsModel.ReportID"
                                    name="ReportID">
                                    <option value="">Select</option>
                                    <option *ngFor="let record of ReverseReportDD" value="{{record.ID}}">
                                        {{record.Report}}</option>
                                </select> -->

                                <div class="dropdown">
                                    <button class="btn btn-primary dropdown-toggle" type="button"
                                        data-toggle="dropdown">Select
                                        <span class="caret"></span></button>
                                    <ul class="dropdown-menu">
                                        <li *ngFor="let record of ReportTypeDD"><input [checked]="record.IsActive == '1'" (change)="ReportTypeSelection(record.ID, $event)" type="checkbox">
                                            {{record.ReportType}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3 class="form-section-title">Any Other Requirements</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Email Notifications <span id="starField">*</span></label>
                                <select [(ngModel)]="AdapterRequirnmentsModel.EmailNotification"
                                    name="EmailNotification" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="AdapterRequirnmentsModel.EmailNotification == '1'">
                            <div class="form-group">
                                <label>Recipients Emails <span id="starField">*</span></label>
                                <input type="text" [(ngModel)]="AdapterRequirnmentsModel.RecipientEmails"
                                    name="RecipientEmails" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="form-group">
                                <label for="">Any Other Requirements <span id="starField">*</span></label>
                                <textarea [(ngModel)]="AdapterRequirnmentsModel.AnyOtherRequirnment"
                                    name="AnyOtherRequirnment" class="form-control" rows="2"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="RoleID != '1' && CompanyDataModel.WorkflowStatusID >= '2'" class="form-section">
                <h3 class="form-section-title">Requirements Finalization</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Are these standard Requirements?<span id="starField">*</span></label>
                                <select [(ngModel)]="ReuirnmentsFinalizationModel.AreTheseStandardRequirnments"
                                    name="AreTheseStandardRequirnments" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Customization Needed<span id="starField">*</span></label>
                                <select [(ngModel)]="ReuirnmentsFinalizationModel.CustomizationNeeded"
                                    name="CustomizationNeeded" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Review with IM<span id="starField">*</span></label>
                                <select [(ngModel)]="ReuirnmentsFinalizationModel.ReviewWithIM" name="ReviewWithIM"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>IM Name <span id="starField">*</span></label>
                                <input [(ngModel)]="ReuirnmentsFinalizationModel.IMName" name="IMName" type="text"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Requirements Finalized<span id="starField">*</span></label>
                                <select [(ngModel)]="ReuirnmentsFinalizationModel.RequirnmentsFinalized"
                                    name="RequirnmentsFinalized" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Remarks <span id="starField">*</span></label>
                                <textarea [(ngModel)]="ReuirnmentsFinalizationModel.Remarks" name="Remarks"
                                    class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section" *ngIf="CompanyDataModel.WorkflowStatusID >= '3'">
                <h3 class="form-section-title">Approval to Proceed</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div #pdfTable>
                            <div class="col-md-12 line-break" style="text-align:center;">
                                <h3><u>Approval to Proceed</u></h3> 
                            </div>
                            <div class="col-md-12 line-break">
                                WorkflowNo: <b>{{CompanyDataModel.WorkflowNo}}</b>
                            </div>
                            <br>
                            <div class="col-md-12 line-break">
                                Date: <b>{{CurrentDate}}</b>
                            </div>
                            <br>                            
                            <div class="col-md-12 line-break">
                                <span>To: <b>Copia Consultancy Services Pvt Ltd</b></span>
                                  <div style="margin-left: 24px;"><b>501, Signia Oceans, Sect 10 A, Airoli, Navi
                                    Mumbai. 400708</b></div>                                
                            </div>
                            <br>
                            <div class="col-md-12 line-break">
                                From: <b>{{CompanyDataModel.CompanyName}}</b>
                            </div>
                            <br>
                            <div class="col-md-12 line-break">
                                Regarding: <b>Software Deployment Authorisation Cum Enablement Request</b>
                            </div>
                            <br>
                            <div class="col-md-12 line-break">
                                We hereby authorise Copia Consultancy to deploy and enable software services as per the
                                below statement of works in our ERP integration with the bank/s and will be governed by
                                the terms and conditions listed therein. We have read and understood the Terms and
                                Conditions, and hereby agree to be bound by such Terms and Conditions at all times.
                            </div>
                            <br>
                            <div class="col-md-12 line-break">
                                <b><u>REQUIREMENT SUMMARY</u></b>
                            </div>
                            <br>
                            <div class="col-md-12 line-break">
                                <table class="table table-bordered table-width">
                                    <thead style="background-color: #d1ccc5;">
                                        <tr>
                                            <th>
                                                Requirements
                                            </th>
                                            <th>
                                                Function
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td rowspan="3"><b>ERP Adapter</b></td>
                                            <td>ERP System: {{AdapterRequirnmentsModel.ERPSystem}}</td>
                                        </tr>
                                        <tr>
                                            <td>ERP Adapter Needed:
                                                <span *ngIf="AdapterRequirnmentsModel.ERPAdapter == 1">Yes</span>
                                                <span *ngIf="AdapterRequirnmentsModel.ERPAdapter == 0">No</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td *ngIf="AdapterRequirnmentsModel.ERPAdapter == 1">
                                                <span>ERP Specific Details:
                                                    {{AdapterRequirnmentsModel.ERPSpecificDetail}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2"><b>SysBrij Adapter - Forward Flow</b></td>
                                            <td>Mapper Needed:
                                                <span *ngIf="AdapterRequirnmentsModel.MapperNeeded == 1">Yes</span>
                                                <span *ngIf="AdapterRequirnmentsModel.MapperNeeded == 0">No</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Payment Types:
                                                <span
                                                    *ngFor="let data of AdapterDetailsList; let i = index">{{data.PaymentType}},</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="4"><b>SysBrij Adapter - Reverse Flow</b></td>
                                            <td>Reverse Feed Needed:
                                                <span *ngIf="AdapterRequirnmentsModel.ReverseFeedNeeded == 1">Yes</span>
                                                <span *ngIf="AdapterRequirnmentsModel.ReverseFeedNeeded == 0">No</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td *ngIf="AdapterRequirnmentsModel.ReverseFeedNeeded == 1">
                                                SFTP Folder:
                                                <span *ngIf="AdapterRequirnmentsModel.SftpFolderID == 1">Root</span>
                                                <span *ngIf="AdapterRequirnmentsModel.SftpFolderID == 2">Inbox</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reports Needed:
                                                <span *ngIf="AdapterRequirnmentsModel.ReportsNeeded == 1">Yes</span>
                                                <span *ngIf="AdapterRequirnmentsModel.ReportsNeeded == 0">No</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td *ngIf="AdapterRequirnmentsModel.ReportsNeeded == 1">
                                                Reports:
                                                <span *ngIf="AdapterRequirnmentsModel.ReportID == 1">Collection</span>
                                                <span *ngIf="AdapterRequirnmentsModel.ReportID == 2">Account
                                                    Balance</span>
                                                <span *ngIf="AdapterRequirnmentsModel.ReportID == 3">Account
                                                    Statement</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="3"><b>Other Requirements</b></td>
                                            <td>Email Notification:
                                                <span *ngIf="AdapterRequirnmentsModel.EmailNotification == 1">Yes</span>
                                                <span *ngIf="AdapterRequirnmentsModel.EmailNotification == 0">No</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td *ngIf="AdapterRequirnmentsModel.EmailNotification == 1">
                                                Recipient Emails: {{AdapterRequirnmentsModel.RecipientEmails}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Any Other Requirement: {{AdapterRequirnmentsModel.AnyOtherRequirnment}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br>
                            <div class="col-md-12 line-break" style="margin-bottom: 50px;">
                                I/We hereby confirm on the below Terms & Conditions for the enableing the Software for
                                the
                                above requirements.
                            </div>
                            <br>
                            <div class="col-md-12 line-break" style="margin-bottom: 100px;">
                                <p><b>Company Seal and signature</b></p>
                                <p><b>Place:</b></p>
                                <p><b>Date:</b></p>
                            </div>
                            <br>
                            <br>
                            <br>
                            <div class="col-md-12 line-break" style="text-align: center;">
                                <b><u>TERMS & CONDITIONS</u></b>
                            </div>
                            <div class="col-md-12 line-break">
                                <p><b>GENERAL</b></p>
                                <p> 1. Copia Consultancy will configure the solution as per agreed SOW.</p>
                                <p> 2. We will provide the necessary access required by Copia for installing /
                                    setup the programs and
                                    for any assistance that may be requiremend from them during testing. (
                                    eg Vpn, ERP logins with
                                    proper authorizations, write access to folders etc )</p>
                                <p> 3. We agree that we will test the programs/software for all expected scenarios
                                    and provide UAT
                                    sign-off</p>
                                <p> 4. Copia Consultancy willNOT be held responsible for any errors that might
                                    occur after UAT
                                    sign-off and go-live</p>
                                <p> 5. Copia Consultancy is expected to resolve any issues during the warranty
                                    period ( 1month ) from
                                    the date of go-live</p>
                            </div>
                            <div class="col-md-12 line-break">
                                <p><b>INSTRUCTION</b></p>

                                <p> 6. We agree to use the software solution in the form which Copia Consultancy
                                    has advised to use
                                    from time to time</p>
                                <p> 7. Copia Consultancy is not under any obligation to review whether the
                                    software solution conflicts
                                    with any other system at our environment.</p>
                                <p> 8. We shall promptly acquire, maintain, update and install(where relevant) any
                                    equipment,
                                    software, telecommunications facilities,networks, connections, patches,
                                    releases and/or updates
                                    as required by the software solution.</p>
                                <p>
                                    9. We shall not alter, reverse engineer, copy (other than to the extent
                                    necessary for the permitted use), publish or impart to any third party any
                                    products or services provided under plug and play solution.
                                </p>
                                <p>
                                    10. We are responsible for obtaining and maintaining the software and hardware
                                    to use the solution provided by Copia Consultancy.
                                </p>
                            </div>
                            <div class="col-md-12 line-break">

                                <p><b>WARRANTIES:</b></p>

                                <p> 11. We undertakes to inform Copia Consultancy regardingany theft, fraud,
                                    illegal activity, loss,
                                    damage or other misuse of plug and play solution.</p>
                                <p> 12. Copia Consultancy shall not beliable for any Loss suffered as a result of
                                    the acts or omissions
                                    of an Infrastructure Provider.</p>
                            </div>
                            <div class="col-md-12 line-break">
                                <p><b>AMENDMENTS:</b></p>

                                <p>13. We agree that Copia Consultancy will not be responsible if we make any
                                    changes to the
                                    programs/software which may cause disruption in the functionality agreed in
                                    SoW.</p>
                                <p> 14. We understand that Copia Consultancy may make amendments to the terms and
                                    conditions which
                                    will become effective on the expiry of 45 days notice to us.</p>
                            </div>
                            <div class="col-md-12 line-break">
                                <p><b>SECURITY MEASURES:</b></p>

                                <p> 15. We will be responsible for our environment security and internal controls.
                                    We shall
                                    establish, maintain and review our internal security measures for its use
                                    and accessbythe
                                    software solution, includingthe installation and ongoing update of
                                    anti-virus software.</p>
                                <p> 16. We shall adopt and review our internal security measures ona regular basis
                                    to ensure
                                    protection remains up to date and in line withregulatory and industry best
                                    practice guidance.
                                </p>
                                <p> 17. These should include, butnot be limited to, malware protection, network
                                    restrictions,
                                    physical accessrestrictions, remote access restrictions, computer security
                                    settings,monitoring
                                    of improper usage, guidance on acceptable web browsers and email usage
                                    including how to avoid
                                    acquiring malware.</p>
                            </div>
                            <div class="col-md-12 line-break">
                                <p><b>LIMITATION OF LIABILITY:</b></p>

                                <p>18. Notwithstanding anything contrary contained herein or elsewhere, Copia
                                    Consultancy shall
                                    have no liability to us or any other person for any consequential,
                                    compensatory, special or
                                    other damages, including without limitation damages for lost profits or loss
                                    or damages from
                                    subsequent wrongful dishonour caused by any error, act, delay or omission by
                                    Plug and Play
                                    solution</p>
                                <p> 19. With respect to the Copia Consultancy liability under these T&Cs, the
                                    provisions set out in
                                    these T&Cs shall govern unless they are in conflict with any Applicable Law,
                                    in which case the
                                    relevant liability will be determined as required by such Applicable Law.
                                </p>
                            </div>
                            <div class="col-md-12 line-break">
                                <p><b>INDEMNITY:</b></p>
                                <p>20. We shall indemnify and hold harmless from (time to time and at all times)
                                    Copia Consultancy
                                    , its officers and employees against all liabilities, claims, costs, losses,
                                    damages and
                                    expenses (including legal fees) of any kind which may be incurred by any of
                                    them and all actions
                                    or proceedings which may be brought by or against them in relation to the
                                    Services performed by
                                    Copia Consultancy under these T&Cs.</p>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <a class="btn btn-primary form-control" style="margin-top: 24px;" (click)="downloadAsPDF()">Download T&C</a>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Attachment - </label>
                                    <input id="terms-attachment" name="TermsAndCondition" type="file"
                                        class="form-control" />
                                </div>
                            </div>
                            <div class="cold-md-4">
                                <input type="button" class="btn btn-primary" (click)="UploadTermsandCondition()"
                                    value="Upload" style="margin-top: 24px;">
                            </div>
                        </div>
                        <div class="col-md-3 pull-left">
                            <div class="form-group">
                                <label>Accpet all T&C and provide ATP? <span
                                        id="starField">*</span></label>
                                <select [(ngModel)]="ApprovalToProceedModel.TermsAndConditionApproval"
                                    name="TermsAndConditionApproval" class="form-control" (change)="GetATPDate()">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>

                        </div>
                        <div class="col-md-3 pull-right">
                            <div class="form-group">
                                <label>Date<span id="starField">*</span> - </label>
                                <input id="approval-date" name="ApprovalDate" type="text" class="form-control" disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="RoleID != '1' && CompanyDataModel.WorkflowStatusID >= '4'" class="form-section">
                <h3 class="form-section-title">UAT Instance Creation</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Enable Instance Creation</label>
                                <select [(ngModel)]="InstanceCreationModel.EnableInstanceCreation"
                                    name="EnableInstanceCreation" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section" *ngIf="CompanyDataModel.WorkflowStatusID >= '5'">
                <h3 class="form-section-title">UAT Approval</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Adapter Name<span id="starField">*</span></label>
                                <select [(ngModel)]="UATApprovalModel.AdopterName" name="ddlUATAdapter"
                                    class="form-control">
                                    <option value="0">Select</option>
                                    <option value="5">SysBrij</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Testing Completed<span id="starField">*</span></label>
                                <select [(ngModel)]="UATApprovalModel.TestingCompleted" name="TestingCompleted"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>UAT Approved<span id="starField">*</span></label>
                                <select [(ngModel)]="UATApprovalModel.UATApproved" name="UATApproved"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>UAT Approver Name <span id="starField">*</span></label>
                                <input [(ngModel)]="UATApprovalModel.UATApproverName" name="UATApproverName" type="text"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Enable Production Instance <span id="starField">*</span></label>
                                <select [(ngModel)]="UATApprovalModel.EnableProductionInstance"
                                    name="EnableProductionInstance" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="UATApprovalModel.EnableProductionInstance == '1'" class="col-md-3">
                            <div class="form-group">
                                <label>Deploy in Production<span id="starField">*</span></label>
                                <select [(ngModel)]="UATApprovalModel.DeployInProduction" name="DeployInProduction"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Attachment</label>
                                <input id="uat-attachment" type="file" class="form-control" />
                                <!-- <a
                                    href="E:/Projects/SysBrijPortal/SysBrijPortal_API/SysBrijPortal_API/UploadedFile/{{UATApprovalModel.UATAttachment}}">UAT
                                    Attachment</a> -->
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group form-btn-align">
                                <input (click)="UploadUATAttachment()" type="button" class="btn btn-primary"
                                    value="Upload" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="RoleID != '1' && CompanyDataModel.WorkflowStatusID >= '6'" class="form-section">
                <h3 class="form-section-title">Go Live Details</h3>
                <div class="form-field-section">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Production Instance Created <span id="starField">*</span></label>
                                <select [(ngModel)]="GoLiveDetailsModel.ProductionInstanceCreated"
                                    name="ProductionInstanceCreated" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Key Created <span id="starField">*</span></label>
                                <select [(ngModel)]="GoLiveDetailsModel.KeyCreated" name="KeyCreated"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Dry Run in Production <span id="starField">*</span></label>
                                <select [(ngModel)]="GoLiveDetailsModel.DryRunInProduction" name="DryRunInProduction"
                                    class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Production Deployment Completed<span id="starField">*</span></label>
                                <select [(ngModel)]="GoLiveDetailsModel.ProductionDeploymentCompleted"
                                    name="ProductionDeploymentCompleted" class="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right form-submit-section">
                <input *ngIf="PageStatus != 'view' && CompanyDataModel.WorkflowStatusID == '1'" type="submit"
                    (click)="Save()" class="btn btn-primary" value="Save" /> |
                <input *ngIf="PageStatus != 'view'" type="submit" (click)="Submit()" class="btn btn-primary"
                    value="Submit" />
            </div>
        </form>
    </div>
</div>
<div *ngIf="LoaderStatus == true" class="submit-loader">
    <img src="../../assets/images/loader.gif" alt="">
</div>
<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" (click)="SuccessRedirect()" id="close">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>