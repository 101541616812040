import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Subject } from '../../../node_modules/rxjs';
import { ContactSopportModel } from '../Model/common.model';


@Component({
  selector: 'app-contact-support-list1',
  templateUrl: './contact-support-list1.component.html',
  styleUrls: ['./contact-support-list1.component.css']
})
export class ContactSupportList1Component implements OnInit {

  loaderActive: boolean = false;
  menuToggleCheck: boolean = false;
  ContactSupportGrid: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  ContactSopportModel: ContactSopportModel
  UserID: string='';
  RoleID: string='';

  constructor(private Service: CommonService) {
    this.ContactSopportModel = new ContactSopportModel();
    // this.ContactSupportlist();
   }

  ngOnInit(): void {
    this.UserID = localStorage.getItem("UserID");
    this.RoleID = localStorage.getItem("RoleID");
  }
  

  menuToggle(data) {
    this.menuToggleCheck = data;
  }

  ContactSupportlist() {
    debugger
    this.loaderActive = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      // paging: false,
      searching: false,
      retrieve: true,
    };
    this.Service.ContactSupportlist(this.ContactSopportModel.UserEmail, this.ContactSopportModel.UserName, this.RoleID, this.UserID).subscribe((res) => {
      debugger;
      this.ContactSupportGrid = res;
      this.dtTrigger.next();
      this.loaderActive = false;
    })
  }

  Clear() {
    this.ContactSopportModel = new ContactSopportModel();
    this.ContactSupportlist();
  }

}
