import { Component, ViewChild, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { delay, filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.css']
})
export class MenuHeaderComponent implements OnInit {

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  UserName: string;
  RoleName: string;
  RoleID: string;

  constructor(private observer: BreakpointObserver, private router: Router) {}
  

  ngOnInit(): void {
    this.UserName = localStorage.getItem("UserName");
    this.RoleName = localStorage.getItem("RoleName");
    this.RoleID = localStorage.getItem("RoleID");
  }
  logout() {
    // localStorage.setItem('loginStatus','0');
    this.router.navigate([''])
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
      

    // this.router.events
    //   .pipe(
    //     untilDestroyed(this),
    //     filter((e) => e instanceof NavigationEnd)
    //   )
    //   .subscribe(() => {
    //     if (this.sidenav.mode === 'over') {
    //       this.sidenav.close();
    //     }
    //   });
  }

  // ngAfterViewInit() {
    
  // }

}
