<h3 class="title-level-one">paymentEnquiry</h3>
<div>
    <div class="description-container">
        <p>This method is used to do payment enquiry of all payment transactions.</p>
        <p>It has to be called from merchant’s code of payment status enquiry which gets called on click of ‘Status Enquiry’
            button.
        </p>
        <p>This is a parameterized method. Arguments should be of specific types as mentioned below.</p>
    </div>
    <div class="syntax-container">
        <h4>Method Syntax</h4>
        <div class="section-tab">
            <p>paymentEnquiry(txnRef, merchant)</p>
        </div>
    </div>
    <div class="arguments-detail-container">
        <h4>Arguments Detail</h4>
        <div class="section-tab">
            <div class="arguments-detail">
                <h5 class="argument-name">txnRef</h5>
                <ul>
                    <li>This is transaction reference number.</li>
                    <li>Data type of this property is string.</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">txnRef: string range (up to 25 chars) </span>
                                <div class="property-description">
                                    <p>Merchant to pass Transaction Reference that refers to one specific transaction</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="arguments-detail">
                <h5 class="argument-name">merchant </h5>
                <ul>
                    <li>This property is for merchant id.</li>
                    <li>Data type of this property is string</li>
                    <li>
                        <div class="property-details">
                            <div class="individual-property">
                                <span class="property-name">merId: string range (up to 50 chars) </span>
                                <div class="property-description">
                                    <p>Distributed by HSBC to merchant for identifying each merchant's identity</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="description-container">
        <p>This method returns payment details in JSON format against requested 'transaction reference number'.</p>
    </div>
</div>