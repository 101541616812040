<div class="container-fluid">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">Role Master</h3>
        </div>
        <div class="col-xs-6 text-right">
            <button class="btn saveBtn " style="color: black; background-color: #64A10FFF; " (click)="NewRoleMaster()"><i class="fa fa-plus fa-lg" aria-hidden="true">
                Create New</i></button>
        </div>
    </div>
    <hr>
      <div class="selectionFilterSection">
        <h3 class="selectionFilterTitle">Selection Filter</h3>
        <div class="row selectionFilterSectionBox">
            
             <div class="col-md-4 ">
                <div class="form-group">
                    <label>Role Name</label>
                    <select [(ngModel)]="RoleListFilterModel.RoleID" name="RoleID" class="form-control">
                        <option value="">Select</option>
                        <option *ngFor="let record of RoleDD" value="{{record.ID}}">{{record.Role}}</option>
                    </select>
                </div>
             </div>
            <div class="col-md-4 col-md-offset-2">
                <label>Status</label>
                    <select [(ngModel)]="RoleListFilterModel.IsActive" name="IsActive" class="form-control">
                        <option value="">Select</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
            </div>
          <div class="col-md-12 text-right SFBtnSection">
                <button type="submit" (click)="RoleMasterList()" class="btn btn-primary" style="background-color:#1457d1ed;">Search</button>
                <button type="reset" (click)="Clear()" class="btn btn-danger clearBtn">Clear</button>
            </div> 
        </div> 
    </div>
  <div class="listSection">
        <h3 class="selectionFilterTitle">List</h3>
        <div class="row listSectionBox">
            <table class="table table-bordered" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th scope="col">Role Name</th>
                        <th scope="col">Role Description</th>
                        <th scope="col">Status</th>
                        <th scope="col">Edit</th>
                        <th scope="col">View</th>                    
                    </tr>
                </thead>
                  <tbody>
                    <tr *ngFor="let record of RoleMasterGrid">
                        <td>{{ record.RoleName }}</td>
                        <td>{{ record.Description }}</td>
                        <td>
                            <span *ngIf="record.IsActive == 1">Active</span>
                            <span *ngIf="record.IsActive == 0">Inactive</span>
                        </td>
                        <td><button class="btn btn-success" (click)="GetRoleMasterDetail(record, 'edit')">Edit</button></td>
                        <td><button class="btn" style="background-color: #17a2b8;"
                                (click)="GetRoleMasterDetail(record, 'view')">View</button></td>
                    </tr>
                </tbody>
            </table>
        </div>-
    </div>    
</div>




<!-- <div class="container-fluid">
    <div class="header" style="margin-top: 10px;">
        <div class="row" style="margin: 5px 0px 0px 5px;">
            <div class="col-md-4" style="font-size: 40px;">
                <h3 style="margin-top: 0px; margin-left: -20px; font-size: 34px;">Role Master</h3>
            </div>
            <div class="col-md-8 text-right">
                <button class="btn saveBtn" (click)="NewRoleMaster()"><i class="fa fa-plus fa-lg" aria-hidden="true">
                        Create New</i></button>
            </div>

        </div>
    </div>

    <div class="selectionFilter">
        <h5>Selection Filter</h5>
    </div>
    <div class="row"
        style="margin-top: 0px; border: 1px solid; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px;  width: 100%; margin-left: 0px;">
        <div class="Selectionfields">
            <form>
                <div class="col-md-6">
                    <label>Role Name</label>
                    <select [(ngModel)]="RoleListFilterModel.RoleID" name="RoleID">
                        <option value="">Select</option>
                        <option *ngFor="let record of RoleDD" value="{{record.ID}}">{{record.Role}}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label>Status</label>
                    <select [(ngModel)]="RoleListFilterModel.IsActive" name="IsActive">
                        <option value="">Select</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>
                <div class="col-md-12 text-right" style="margin: 10px 0px 10px 0px">
                    <button class="btn btn-primary" (click)="RoleMasterList()"><i class="fa fa-search"
                            aria-hidden="true"></i> Search</button>
                    <button class="btn btn-danger" (click)="Clear()" style="margin-left: 5px;"><i
                            class="fa fa-remove"></i> Clear</button>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="this.listSectionShow == 'show'">
        <div class="selectionFilter">
            <h5>List</h5>
        </div>
        <div class="row"
            style="margin-top: 0px; border: 1px solid; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px; width: 100%; margin-left: 0px; margin-bottom: 5px;  padding: 5px;">
            <table class="table table-bordered" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th scope="col">Role Name</th>
                        <th scope="col">Role Description</th>
                        <th scope="col">Status</th>
                        <th scope="col">Edit</th>
                        <th scope="col">View</th>
                </thead>
                <tbody>
                    <tr *ngFor="let record of RoleMasterGrid">
                        <td>{{ record.RoleName }}</td>
                        <td>{{ record.Description }}</td>
                        <td>
                            <span *ngIf="record.IsActive == 1">Active</span>
                            <span *ngIf="record.IsActive == 0">Inactive</span>
                        </td>
                        <td><button class="btn btn-success" (click)="GetRoleMasterDetail(record, 'edit')">Edit</button></td>
                        <td><button class="btn" style="background-color: #17a2b8;"
                                (click)="GetRoleMasterDetail(record, 'view')">View</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->

<div id="roleSetup" class="modal fade" role="dialog">
    <form class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-title" id="exampleModalLabel">
                            Role Setup
                            <button type="button" class="close" style="margin: 5px 0px 5px;" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Role Name <span id="starField">*</span></label>
                            <input [(ngModel)]="RoleMasterSetupModel.RoleName" placeholder="Enter Role Name" name="RoleName" type="text" id="RoleNameD"
                                class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group textArea">
                            <label for="">Role Description <span id="starField">*</span></label>
                            <textarea [(ngModel)]="RoleMasterSetupModel.Description" class="form-control" placeholder="Enter Role Description"  name="Description" id="RoleDescD"
                                class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Status <span id="starField">*</span></label>
                            <select [(ngModel)]="RoleMasterSetupModel.IsActive" name="IsActive" id="StatusD" class="form-control">
                                <option value="">Select</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="Submit()" class="btn btn-primary" id="SubmitBtn" data-dismiss="modal">Submit</button>
            </div>
        </div>
    </form>
</div>

<div id="succeess-message-box" class="modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header1" id="success-modal-head">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title success-modal-title">Message</h4>
            </div>
            <div class="modal-body">
                <p>
                    <img id="success-img-id" style="width: 40px;"
                        src="../../../../assets/images/success-img.gif" />&nbsp;&nbsp;
                    <img id="error-img-id" style="width: 28px;"
                        src="../../../../assets/images/error-img.png" />&nbsp;&nbsp;
                    <span id="message-text" style="font-size: 15px;">Data Inserted Successfully</span>
                </p>
            </div>
            <div class="modal-footer message-modal-footer">
                <button type="button" class="btn btn-default" ng-click="$ctrl.Close();" id="close"
                    data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-default" id="errorclose" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>