<div class="container-fluid">
    <div class="row pageHeader">
        <div class="col-xs-6" style="padding: 0px;">
            <h3 class="pageTitle">User List</h3>
        </div>
        <div class="col-xs-6 text-right">
            <a [routerLink]="'../createCustomer1'" class="btn btn-success"> <i class="fa fa-plus fa-lg"
                    aria-hidden="true"></i> Create New</a>
        </div>
    </div>
    <hr>
    <div class="selectionFilterSection">
        <h3 class="selectionFilterTitle">Selection Filter</h3>
        <div class="row selectionFilterSectionBox">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Company Name</label>
                    <select [(ngModel)]="UserListFilterModel.CompanyID" name="ddlCompanyID" class="form-control">
                        <option value="">Select</option>
                        <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1">
                <div class="form-group">
                    <label>User Name</label>
                    <input [(ngModel)]="UserListFilterModel.UserName" name="txtUserName" class="form-control"
                        type="text" placeholder="Enter User Name">
                </div>
            </div>
            <div class="col-md-3 col-md-offset-1">
                <label>User Mobile</label>
                <input [(ngModel)]="UserListFilterModel.MobileNumber" name="IMName" type="text" class="form-control"
                    placeholder="Enter User Mobile">
            </div>
            <div class="col-md-12 text-right SFBtnSection">
                <button type="submit" (click)="UserList()" class="btn btn-primary">Search</button>
                <button type="reset" (click)="Clear()" class="btn btn-danger clearBtn">Clear</button>
            </div>
        </div>
    </div>
    <div class="listSection">
        <h3 class="selectionFilterTitle">list</h3>
        <div class="row listSectionBox">
            <table class="table table-bordered" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th scope="col">Company Name</th>
                        <th scope="col" style="width: 107px;">User Name</th>
                        <th scope="col">User Email</th>
                        <th scope="col">User Mobile</th>
                        <th scope="col">Role Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Edit</th>
                        <th scope="col">View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of UserGrid">
                        <td>{{ record.CompanyName }}</td>
                        <td>{{ record.UserName }}</td>
                        <td>{{ record.UserEmail }}</td>
                        <td>{{ record.UserMobile }}</td>
                        <td>{{record.RoleName}}</td>
                        <td>
                            <span *ngIf="record.UserStatus == 'True'">Active</span>
                            <span *ngIf="record.UserStatus == 'False'">Inactive</span>
                        </td>
                        <td>
                            <button [routerLink]="['../createcustomer1', record.UserID, 'edit']"
                                class="action-btn edit-btn">Edit</button>
                        </td>
                        <td>
                            <button [routerLink]="['../createcustomer1', record.UserID, 'view']"
                                class="action-btn view-btn">View</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<!-- <div class="container-fluid">
    <div class="header" style="margin-top: 10px;">
        <div class="row" style="margin: 5px 0px 0px 5px;">
            <div class="col-sm-6" style="font-size: 40px;">
                <h3 style="margin-top: 0px; margin-left: -20px; font-size: 34px;">User List</h3>
            </div>
            <div class="col-sm-6 text-right">
                <a [routerLink]="'../createCustomer1'" class="btn saveBtn"> <i class="fa fa-plus fa-lg"
                        aria-hidden="true"></i> Create New</a>
            </div>
        </div>
    </div>

    <div class="selectionFilter">
        <h5>Selection Filter</h5>
    </div>
    <div class="row"
        style="margin-top: 0px; border: 1px solid; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px; width: 100%; margin-left: 0px; ">
        <div class="Selectionfields">
            <div class="col-sm-4">
                <label>Company Name</label>
                <select [(ngModel)]="UserListFilterModel.CompanyID" name="ddlCompanyID">
                    <option value="">Select</option>
                    <option *ngFor="let record of CompanyDD" value="{{record.ID}}">{{record.Name}}</option>
                </select>
            </div>
            <div class="col-sm-4">
                <label>User Name</label>
                <input [(ngModel)]="UserListFilterModel.UserName" name="txtUserName" type="text"
                    placeholder="Enter User Name">
            </div>
            <div class="col-sm-4">
                <label>User Mobile</label>
                <input [(ngModel)]="UserListFilterModel.MobileNumber" name="IMName" type="text"
                    placeholder="Enter User Mobile">
            </div>
            <div class="col-sm-12 text-right" style="margin: 10px 0px 10px 0px">
                <button type="submit" (click)="UserList()" class="btn btn-primary">Search</button>
                <button type="reset" (click)="Clear()" class="btn btn-danger" style="margin-left: 5px;">Clear</button>
            </div>
        </div>
    </div>

    <div class="selectionFilter">
        <h5>List</h5>
    </div>
    <div class="row1"
        style="margin-top: 0px; border: 1px solid; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top-right-radius: 10px;  margin-left: 0px; margin-bottom: 5px; padding: 5px;">
        <div class="tablefixed">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Company Name</th>
                        <th scope="col" style="width: 107px;">User Name</th>
                        <th scope="col">User Email</th>
                        <th scope="col">User Mobile</th>
                        <th scope="col">Role Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Edit</th>
                        <th scope="col">View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of UserGrid">
                        <td>{{ record.CompanyName }}</td>
                        <td>{{ record.UserName }}</td>
                        <td>{{ record.UserEmail }}</td>
                        <td>{{ record.UserMobile }}</td>
                        <td>{{record.RoleName}}</td>
                        <td>
                            <span *ngIf="record.UserStatus == 'True'">Active</span>
                            <span *ngIf="record.UserStatus == 'False'">Inactive</span>
                        </td>
                        <td>
                            <button [routerLink]="['../createcustomer1', record.UserID, 'edit']"
                                class="action-btn edit-btn">Edit</button>
                        </td>
                        <td>
                            <button [routerLink]="['../createcustomer1', record.UserID, 'view']"
                                class="action-btn view-btn">View</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->